import React, { Component } from 'react';
import sanitizeHtml from 'sanitize-html';
import styled from 'styled-components';

const StyledRichText = styled.div`
  img{
    max-width:80%;
    max-height:300px;
    display:block;
    margin: 10px auto;
  }
  p {
    white-space: pre-line;
  }
  a {
    color: purple;
    text-decoration: underline;
  }
`;

export default function RichTextViewer({ content, style, className }) {
  if (!content) return null;
  return (
    <StyledRichText>
      <p
        style={{style}}
        className={`${className}`}
        dangerouslySetInnerHTML={{
          __html:
        sanitizeHtml(content, {
          allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img', 'u']),
          allowedAttributes: {
            a: ['href', 'name', 'target'],
            img: ['src'],
          },
          transformTags: {
            a(tagName, attribs) {
              return {
                tagName: 'a',
                attribs: {
                  'href': attribs.href,
                  'target': '_blank',
                  'rel': 'noopener noreferrer',
                }
              }
            }
          }
        }),
        }}
      />
    </StyledRichText>
  );
}