import React, { useState, useEffect } from 'react';
import { useToast, Box, Button, Heading, Text, Input, Checkbox } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import api from '../../../api';
import useStore from '../../../store';

function Settings(props) {
  const { trial } = props;
  const [newPassword, setNewPassword] = useState('');
  const [defaultNotificationTitle, setDefaultNotificationTitle] = useState('');
  const toast = useToast();
  const { updateTrial } = useStore();

  useEffect(() => {
    if (props.trial.password) setNewPassword('password');
    if (props.trial.defaultNotificationTitle) setDefaultNotificationTitle(props.trial.defaultNotificationTitle);
  }, [props.trial.password, props.trial.defaultNotificationTitle]);

  function updateRestrictRegistration(enabled) {
    api.trials.update(trial._id, { restrictRegistration: !enabled }, t => updateTrial(t._id, t), err => toast({status:'error', title: err.message}));
  }

  function updateEnableLookback(enabled) {
    api.trials.update(trial._id, { enableLookback: enabled }, t => updateTrial(t._id, t)), err => toast({status: 'error', title: err.message});
  }

  function saveDefaultNotificationTitle() {
    api.trials.update(props.trial._id, { defaultNotificationTitle }, (t) => {
      updateTrial(t._id, t);
      toast({status: 'success', title: 'Default notification title updated'});
    } , err => toast({status: 'error', title: err.message}));
  }

  function savePassword() {
    if (newPassword === 'password') return toast({status:'error', title: 'Password has not changed or is too weak.'});
    api.trials.update(props.trial._id, { password: newPassword }, (t) => {
      updateTrial(t._id, t);
      toast({status: 'success', title: 'Password updated'});
    }, err => toast({status:'error', title:err.message}));
  }

  return (
    <Box justifyContent='center'>
      <Box>
        <Box>
          <Box rounded='md' shadow='md' borderWidth='1px' p={3} bg='gray.50' mb={10}>
            <Heading as='h3' size='md' mb={5}>Open registration</Heading>
            <Checkbox mb={5} isChecked={!trial.restrictRegistration} onChange={e => updateRestrictRegistration(e.target.checked)}>Enable open registration</Checkbox>
            <Text>When Open Registration is enabled, participants will be able to join the trial using any Participant ID, and they will be auto-registered after they first login with this Participant ID.</Text>
            <Text mt={2}>If you disable this setting, you will need to pre-register Participant IDs on the <Link to={`/trials/${trial._id}/participants`}>Participants page</Link> to determine which IDs can be used to access the trial.</Text>
          </Box>

          <Box rounded='md' shadow='md' borderWidth='1px' p={3} bg='gray.50' mb={10}>
            <Heading as='h3' size='md' mb={5}>Participant look-back</Heading>
            <Checkbox mb={5} isChecked={trial.enableLookback} onChange={e => updateEnableLookback(e.target.checked)}>Enable look-back</Checkbox>
            <Text>When enabled, participants can be shown previously-inputted values for numeric fields and sliders. In some cases, this may allow participants to make better comparisons over time.</Text>
          </Box>

          <Box rounded='md' shadow='md' borderWidth='1px' p={3} bg='gray.50' mb={10}>
            <Heading as='h3' size='md'>Default notification title</Heading>
            <Text mb={5}>Set the default title for notifications sent to participants. You can sometimes override this title when sending individual notifications.</Text>
            <Input bg='white' placeholder='Message from trial admin' value={defaultNotificationTitle} onChange={e => setDefaultNotificationTitle(e.target.value)} />
            <Button colorScheme='blue' mt={3} onClick={saveDefaultNotificationTitle}>Save default title</Button>
          </Box>

          <Box rounded='md' shadow='md' borderWidth='1px' p={3} bg='gray.50'>
            <Heading as='h3' size='md'>Trial password</Heading>
            <Text>Optionally set a password that participants will need in addition to their Participant ID to access the trial. If you leave it blank, participants can login without a password (unless one has been assigned to them individually).</Text>
            <Input bg='white' mt={5} type='password' placeholder='Trial password' value={newPassword} onChange={e => setNewPassword(e.target.value)} />
            <Button colorScheme='blue' mt={3} onClick={savePassword}>Save password</Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Settings;
