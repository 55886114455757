import React, { useState, useEffect, useCallback } from 'react';
import { useToast, Stack, Box, Text, Textarea, Button, CircularProgress } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import moment from 'moment';

import ConfirmButton from './ConfirmButton';
import utils from '../../utils/utils';
import api from '../../api';
import useStore from '../../store';

export default function ParticipantNote({ note, participant }) {
  const { user, addParticipantNote, deleteParticipantNote } = useStore();
  const [text, setText] = useState('');
  const [saving, setSaving] = useState(false);
  const [focused, setFocused] = useState(false);
  const toast = useToast();
  
  useEffect(() => {
    if (note) setText(note.note || '');
  }, [note]);
  
  useEffect(() => {
    setSaving(true);
    save(text);
  }, [text]);
  
  const save = useCallback(utils.debounce((text) => {
    if (text === note?.note) return setSaving(false);
    api.participantNotes.update(participant.trial, participant._id, note._id, { note: text }, (newNote) => {
      addParticipantNote(newNote);
      setSaving(false);
    }, err => {
      toast({status: 'error', description: err.message });
    });
  }), [participant, note]);
  
  function deleteNote() {
    api.participantNotes.delete(participant.trial, participant._id, note._id, () => {
      toast({status: 'success', description: 'Note deleted' });
      deleteParticipantNote(note._id);
    }, err => {
      toast({status: 'error', description: err.message });
    });
  }

  const canEdit = user?._id === note?.user;
  if (!note) return null;
  return (
    <Box p={1} bg='yellow.200' shadow='md'>
      <Stack direction='row' alignItems='center' justifyContent='start'>
        <Text fontSize='xs' color='yellow.500'>
          {moment(note.createdAt).format('DD/MM/YYYY HH:mm')}
          {note.userObject && ` by ${note.userObject.firstName} ${note.userObject.lastName}`}
        </Text>
        {canEdit &&
          <Box display='flex' alignItems='center'>
            <ConfirmButton
              trigger={<Button size='xs' variant='ghost' colorScheme='orange'><DeleteIcon /></Button>}
              header='Really delete this note?'
              action={deleteNote}
              actionColor='red'
            />
            {saving && focused &&
              <Text fontSize='xs' ml={3}><CircularProgress colorScheme='green' isIndeterminate size={3} mr={1} /> Saving...</Text>
            }
          </Box>
        }
      </Stack>

      <Textarea variant='unstyled'
        placeholder={canEdit ? 'Start writing...' : 'No notes'}
        isReadOnly={!canEdit}
        onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}
        value={text} onChange={e => setText(e.target.value)}
      />
    </Box>
  );
}
