import React, { useEffect, useState } from 'react';
import { Box, Stack, Text, Alert, Checkbox, Button } from '@chakra-ui/react';
import utils from '../../utils/utils';

import SimpleTable from './SimpleTable';

export default function RAVLTEditor({ value, dataType, onChange }) {
  const [rememberedTestSets, setRememberedTestSets] = useState([]);
  const [rememberedInterruptionSet, setRememberedInterruptionSet] = useState([]);

  useEffect(() => {
    if (dataType) {
      const totalTestSets = (dataType.preInterruptionTests || 0) + (dataType.postInterruptionTests || 0);
      setRememberedTestSets(new Array(totalTestSets).fill([]));
    }
  }, [dataType]);

  function finish() {
    if (window.confirm('This will end the test and you will not be able to make any futher changes. Are you ready to continue?')) {
      onChange({
        rememberedTestSets,
        rememberedInterruptionSet,
      });
    }
  }

  function resetTest() {
    if (window.confirm('Are you sure you want to reset the test? The previously recorded entries will be lost.')) {
      onChange(null);
    }
  }

  const headers = [];
  const rows = [];
  for (let i = 0; i < dataType?.preInterruptionTests; i++) {
    headers.push(`Pre Test ${i + 1}`);
  }

  return (
    <Box>
      {value ?
        <Box>
          <Alert status='success' flexDirection='column' alignItems='start'>
            <Text mb={5}>This test has been completed.</Text>
            <Button onClick={resetTest}>Conduct test again</Button>
          </Alert> 
        </Box>
      :
        <Box>
          <SimpleTable
            headers={[
              ...Array(dataType?.preInterruptionTests || 0).fill('Pre Test').map((_, index) => `Pre Test ${index + 1}`),
              'Interruption',
              ...Array(dataType?.postInterruptionTests || 0).fill('Post Test').map((_, index) => `Post Test ${index + 1}`),
            ]}
          rows={dataType?.testSet?.map((testWord, index) => ([
            ...Array(dataType?.preInterruptionTests || 0).fill('Pre Test').map((_, index) => <RAVLTCell word={testWord} rememberedWords={rememberedTestSets[index]} setRememberedWords={(words) => setRememberedTestSets([...rememberedTestSets.slice(0, index), words, ...rememberedTestSets.slice(index + 1)])} />),
            <RAVLTCell word={dataType?.interruptionSet[index]} rememberedWords={rememberedInterruptionSet} setRememberedWords={setRememberedInterruptionSet} />,
            ...Array(dataType?.postInterruptionTests || 0).fill('Post Test').map((_, index) => <RAVLTCell word={testWord} rememberedWords={rememberedTestSets[dataType?.preInterruptionTests + index]} setRememberedWords={(words) => setRememberedTestSets([...rememberedTestSets.slice(0, dataType?.preInterruptionTests + index), words, ...rememberedTestSets.slice(dataType?.preInterruptionTests + index + 1)])} />),
              ]))}
          />

          <Box mt={5} display='flex' justifyContent='center'>
            <Button onClick={finish} colorScheme='green'>Complete test</Button>
          </Box>
        </Box>
      }
    </Box>
  );
}

function RAVLTCell({ word, rememberedWords, setRememberedWords }) {
  return (
    <Stack alignItems='center' direction='row'>
      <Checkbox isChecked={rememberedWords?.includes(word)} onChange={(e) => { setRememberedWords(e.target.checked ? [...rememberedWords, word] : rememberedWords?.filter((w) => w !== word)) }} />
      <Text>{word}</Text>
    </Stack>
  );
}
