import api from '.';

export const dashboards = {
  listForTrial(trialId, success, fail) {
    api.authenticatedRequest('GET', `/trials/${trialId}/dashboards`, null, success, fail);
  },
  create(trialId, data, success, fail) {
    api.authenticatedRequest('POST', `/trials/${trialId}/dashboards`, data, success, fail);
  },
  get(trialId, dashboardId, success, fail) {
    api.authenticatedRequest('GET', `/trials/${trialId}/dashboards/${dashboardId}`, null, success, fail);
  },
  update(trialId, dashboardId, data, success, fail) {
    api.authenticatedRequest('PUT', `/trials/${trialId}/dashboards/${dashboardId}`, data, success, fail);
  },
  delete(trialId, dashboardId, success, fail) {
    api.authenticatedRequest('DELETE', `/trials/${trialId}/dashboards/${dashboardId}`,  null, success, fail);
  },
};
