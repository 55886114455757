import React, { useState, useEffect } from 'react';
import { Alert, AlertIcon, Box, Heading, Text, Icon, Button, Table, Thead, Tr, Th, Tbody, Td, Badge, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverHeader, PopoverBody } from '@chakra-ui/react';
import { ArrowRightIcon, ArrowLeftIcon } from '@chakra-ui/icons';
import moment from 'moment';

import api from '../../api';
import useStore from '../../store';

export default function TeamEvents() {
  const [page, setPage] = useState(1);
  const [logs, setLogs] = useState([]);
  const { team } = useStore();

  useEffect(() => {
    api.teams.getLogs(team._id, page, (data) => setLogs(data.logs));
  }, [team._id, page]);

  return (
    <>
      <Heading as='h3' size='md'>{team?.name || 'Your team'} events</Heading>
      <Text>For audit purposes, Trialflare logs actions taken by members of your team.</Text>

      <Alert mt={5} variant='left-accent'><AlertIcon /> You can find the event logs relating to a specific trial on the trial's settings page.</Alert>

      <Box mt={15}>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>User</Th>
              <Th>Event</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {logs.map(log =>
              <Tr key={log._id}>
                <Td>{moment(log.createdAt).format('L LTS')}</Td>
                <Td>{log.userEmail}</Td>
                <Td>{log.type}</Td>
                <Td>
                  <Popover trigger='hover'>
                    <PopoverTrigger>
                      <Badge>Client</Badge>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverHeader>Client information</PopoverHeader>
                      <PopoverBody fontSize='2xs'>
                        <pre>{JSON.stringify(log.client, null, 2)}</pre>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                  
                  <Popover trigger='hover'>
                    <PopoverTrigger>
                      <Badge ml={2} colorScheme='primary' variant='outline'>Data</Badge>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverHeader>Data</PopoverHeader>
                      <PopoverBody fontSize='2xs'>
                        <pre>{JSON.stringify(log.event, null, 2)}</pre>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>

      <Box mt={5} display='flex' justifyContent='space-between'>
        <Box>{page > 1 && <Button onClick={e => setPage(page - 1)}><ArrowLeftIcon mr={3} /> Previous page</Button>}</Box>
        <Box>{logs.length > 0 &&<Button onClick={e => setPage(page + 1)}><ArrowRightIcon mr={3} /> Next page</Button>}</Box>
      </Box>
    </>
  );
}
