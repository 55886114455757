import { Doughnut } from "react-chartjs-2";

const PASTEL_COLOURS = ['#f5b7b1', '#d7bde2', '#a9cce3', '#a3e4d7', '#a9dfbf', '#f9e79f', '#f5cba7'];

export default function SimpleDoughnutChart({ labels, datasets }) {
  datasets.forEach((dataset, i) => {
    dataset.backgroundColor = dataset.data.map((_, j) => PASTEL_COLOURS[j % PASTEL_COLOURS.length]);
    dataset.borderColor = dataset.backgroundColor;
    dataset.borderWidth = 1;
  });
  return (
    <Doughnut
      data={{
        labels,
        datasets,
      }}
    />
  );
}
