import { Box, Heading, Text, Image, Link } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import BugImage from '../../images/towing.png'

export default function ErrorElement () {
  return (
    <Box p={4} borderWidth={4} borderRadius={8} borderColor='red.200' bg='yellow.50' textAlign='center'>
      <Box display='flex' justifyContent='center' alignItems='center' mb={4}>
        <Image src={BugImage} alt='Trialflare issue' maxW='100%' maxH='300px' />
      </Box>
      <Heading as='h3' size='md'>Trialflare has encountered an error</Heading>
      <Text>We are sorry, but there was a problem when showing this part of the app.</Text>
      <Text mt={5}>You can try to <Link as={RouterLink} to='/' color='blue.500'>return to the home page</Link>.</Text>
    </Box>
  )
}
