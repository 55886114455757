import React from 'react';
import { Box, IconButton } from '@chakra-ui/react';
import { FaQrcode } from 'react-icons/fa';
import SimplePopover from './SimplePopover';
import QrCode from './QrCode';

export default function QrPopover({ trigger, name, url, size}) {
  return (
    <SimplePopover
      trigger={trigger || <IconButton colorScheme='blue' size={size} icon={<FaQrcode />} />}
      header='QR Code'
      body={
        <Box>
          <QrCode name={name} url={url} size={size} withDownload />
        </Box>
      }
    />
  );
}
