import React, { useEffect, useState } from 'react';
import { Box, Select, FormControl, FormLabel, InputGroup, InputRightAddon, Input } from '@chakra-ui/react';
import BMITag from './BMITag';
import utils from '../../utils/utils';

// Value = { bmi, height, weight }. Height in cm. Weight in kg.
export default function BMIEditor({ value, onChange }) {
  const [measurement, setMeasurement] = useState('metric');
  const [bmi, setBmi] = useState();
  const [weight, setWeight] = useState();
  const [height, setHeight] = useState();
  const [weightPounds, setWeightPounds] = useState();
  const [weightOunces, setWeightOunces] = useState();
  const [heightFeet, setHeightFeet] = useState();
  const [heightInches, setHeightInches] = useState();

  useEffect(() => {
    const heightVal = value?.height?.toString().replace(/^0.+/, '') || '';
    setHeight(heightVal);
    const imperialHeight = utils.cmToFeet(heightVal || 0);
    if (imperialHeight) {
      setHeightFeet(imperialHeight.feet);
      setHeightInches(imperialHeight.inches);
    }

    const weightVal = value?.weight?.toString().replace(/^0.+/, '') || '';
    setWeight(weightVal);
    const imperialWeight = utils.kToLbs(weightVal || 0);
    if (imperialWeight) {
      setWeightPounds(imperialWeight.pounds);
      setWeightOunces(imperialWeight.ounces);
    }

    calculateBmi(value?.weight, value?.height);
  }, [value]);

  const calculateBmi = (weight, height) => {
    let newBmi = 0;
    if (weight && height) {
      const heightInMetres = parseFloat(height) / 100;
      newBmi = parseFloat(weight) / (heightInMetres * heightInMetres);
    }
    setBmi(newBmi);
    return newBmi;
  };

  const handleChange = (weight, height) => {
    if (height && weight) {
      onChange({ height, weight, bmi: calculateBmi(weight, height) })
    }
  }

  const updateMetricHeight = cm => {
    if (cm === '' || parseFloat(cm) >= 0) {
      setHeight(cm);
      handleChange(weight, cm);
    }
    const imperial = utils.cmToFeet(cm || 0);
    if (imperial) {
      setHeightFeet(imperial.feet);
      setHeightInches(imperial.inches);
    }
  };
  const updateMetricWeight = kg => {
    if (kg === '' || parseFloat(kg) >= 0) {
      setWeight(kg);
      handleChange(kg, height);
    }
    const imperial = utils.kToLbs(kg || 0);
    if (imperial) {
      setWeightPounds(imperial.pounds);
      setWeightOunces(imperial.ounces);
    }
  };

  const updateImperialHeight = (feet, inches) => {
    setHeightFeet(feet);
    if (inches === '' || (parseFloat(inches) >= 0 && parseFloat(inches) < 12)) {
      setHeightInches(inches);
    }
    if (feet || inches) {
      const cm = utils.feetToCm(feet || 0, inches || 0);
      setHeight(cm);
      handleChange(weight, cm);
    }
  };
  const updateImperialWeight = (pounds, ounces) => {
    setWeightPounds(pounds);
    if (ounces === '' || (parseFloat(ounces) >= 0 && parseFloat(ounces) < 16)) {
      setWeightOunces(ounces);
    }
    if (pounds || ounces) {
      const kg = utils.lbToK(pounds || 0, ounces || 0);
      setWeight(kg);
      handleChange(kg, height);
    }
  };

  return (
    <Box>
      <Box>
        <Select w='200px' placeholder='Measurement type...' size='xs' mr={2} bg='white'
          value={measurement} onChange={e => setMeasurement(e.target.value || 'metric')}>
          <option value='metric'>Metric (kg & cm)</option>
          <option value='imperial'>Imperial (lb & ft)</option>
        </Select>
      </Box>

      <Box display={{md: 'flex'}} mt={2}>
        {measurement === 'metric' && <>
          <FormControl bg='blue.100' rounded='md' p={2} w='150px'>
            <FormLabel fontSize='xs'><strong>Height</strong></FormLabel>
            <InputGroup size='sm'>
              <Input width='80px' bg='white' size='sm' value={height} onChange={e => updateMetricHeight( e.target.value.replace(/[^\d.]/g, ''))} />
              <InputRightAddon children='cm' />
            </InputGroup>
          </FormControl>
          <FormControl bg='blue.100' rounded='md' p={2} w='150px' ml={2}>
            <FormLabel fontSize='xs'><strong>Weight</strong></FormLabel>
            <InputGroup size='sm'>
              <Input width='80px' bg='white' size='sm' value={weight} onChange={e => updateMetricWeight(e.target.value.replace(/[^\d.]/g, ''))} />
              <InputRightAddon children='kg' />
            </InputGroup>
          </FormControl>
        </>}

        {measurement === 'imperial' && <>
          <FormControl bg='blue.100' rounded='md' p={2} w='270px'>
            <FormLabel fontSize='xs'><strong>Height</strong></FormLabel>
            <Box display='flex'>
            <InputGroup size='sm'>
              <Input width='60px' bg='white' size='sm' value={heightFeet} onChange={e => updateImperialHeight(e.target.value.replace(/[^\d.]/g, ''), heightInches)} />
              <InputRightAddon children='feet' />
            </InputGroup>
            <InputGroup size='sm'>
              <Input width='60px' bg='white' size='sm' value={heightInches} onChange={e => updateImperialHeight(heightFeet, e.target.value.replace(/[^\d.]/g, ''))} />
              <InputRightAddon children='inches' />
            </InputGroup>
            </Box>
          </FormControl>

          <FormControl bg='blue.100' rounded='md' p={2} w='270px' ml={2}>
            <FormLabel fontSize='xs'><strong>Weight</strong></FormLabel>
            <Box display='flex'>
            <InputGroup size='sm'>
              <Input width='60px' bg='white' size='sm' value={weightPounds} onChange={e => updateImperialWeight(e.target.value.replace(/[^\d.]/g, ''), weightOunces)} />
              <InputRightAddon children='lbs' />
            </InputGroup>
            <InputGroup size='sm'>
              <Input width='60px' bg='white' size='sm' value={weightOunces} onChange={e => updateImperialWeight(weightPounds, e.target.value.replace(/[^\d.]/g, ''))} />
              <InputRightAddon children='oz' />
            </InputGroup>
            </Box>
          </FormControl>
        </>}

        <Box ml={2}>
          <BMITag bmi={bmi} asCircular />
        </Box>
      </Box>
    </Box>
  );
}