import React, { useState, useEffect } from 'react';
import { useToast, Box, Heading, Button, CircularProgress, Input, Text, Checkbox } from '@chakra-ui/react';
import { useParams, Link } from 'react-router-dom';
import api from '../../api';

const FEATURES = [
  {key: 'basicDataTypes', name: 'Basic data types'},
  {key: 'cognitionDataTypes', name: 'Cognitive data types'},
  {key: 'foodDataType', name: 'Nutrition data type'},
  {key: 'fileDataType', name: 'File data type'},
  {key: 'imageDataType', name: 'Image data type'},
  {key: 'videoDataType', name: 'Video data type'},
  {key: 'mobileApp', name: 'Mobile app'},
  {key: 'integrations', name: 'Integrations'},
  {key: 'advancedExport', name: 'SPSS and Graphpad export'},
  {key: 'eConsent', name: 'eConsent'},
  {key: 'eTmf', name: 'eTMF'},
  {key: 'pollMode', name: 'Poll mode'},
  {key: 'fitbit', name: 'Fitbit wearables'},
  {key: 'garmin', name: 'Garmin wearables'},
];

function RootEditTeam({ }) {
  const [loading, setLoading] = useState(false);
  const [team, setTeam] = useState();
  const { teamId } = useParams();
  const toast = useToast();

  useEffect(() => {
    setLoading(true);
    if (!teamId) return;
    api.root.getTeam(teamId, team => {
      setLoading(false);
      setTeam(team);
    });
  }, [teamId]);

  const update = (key, value) => {
    const newTeam = Object.assign({}, team);
    newTeam[key] = value;
    setTeam(newTeam);
  };
  const toggleFeature = (feature) => {
    const features = Object.assign([], team.enabledFeatures || []);
    const index = features.indexOf(feature.key);
    if (index === -1) features.push(feature.key);
    else features.splice(index, 1);
    update('enabledFeatures', features);
  };

  const save = () => {
    api.root.updateTeam(teamId, {
      maxUsers: team.maxUsers,
      maxTrials: team.maxTrials,
      maxParticipants: team.maxParticipants,
      maxParticipantsPerTrial: team.maxParticipantsPerTrial,
      enabledFeatures: team.enabledFeatures,
    }, newTeam => {
      setTeam(newTeam);
      toast({status: 'success', title: 'Team updated'});
    }, err => {
      toast({status: 'error', title: err.message})
    });
  };

  if (loading) return <CircularProgress indeterminate />;
  if (!team) return null;
  return (
    <Box>
      <Button size='sm' as={Link} to='/root'>Back to root</Button>
      <Heading size='md' mt={10} mb={3}>Edit team {team.name}</Heading>

      <Box p={3} rounded='md' bg='gray.100'>
        <Heading size='sm'>Team Limits</Heading>

        <Text mt={3}>Max users (team seats)</Text>
        <Input placeholder='5' value={team.maxUsers} onChange={e => update('maxUsers', parseInt(e.target.value))} />

        <Text mt={3}>Max trials</Text>
        <Input placeholder='5' value={team.maxTrials} onChange={e => update('maxTrials', parseInt(e.target.value))} />

        <Text mt={3}>Max participants (across all trials)</Text>
        <Input placeholder='5' value={team.maxParticipants} onChange={e => update('maxParticipants', parseInt(e.target.value))} />

        <Text mt={3}>Max participants per trial</Text>
        <Input placeholder='5' value={team.maxParticipantsPerTrial} onChange={e => update('maxParticipantsPerTrial', parseInt(e.target.value))} />
      </Box>

      <Box p={3} rounded='md' bg='gray.100' mt={5}>
        <Heading size='sm'>Enabled Features</Heading>

        {FEATURES.map(feature =>
          <Checkbox key={feature.key} m={3}
            isChecked={team.enabledFeatures?.indexOf(feature.key) > -1}
            onChange={e => toggleFeature(feature)}>
              {feature.name}
          </Checkbox>
        )}


      </Box>

      <Button mt={5} onClick={save} colorScheme='primary'>Save changes</Button>
    </Box>
  );
}

export default RootEditTeam;
