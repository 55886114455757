import React, { useState, useEffect } from 'react';
import { Box, Heading, Icon, Button } from '@chakra-ui/react';
import { FaRegFile, FaDownload, FaTimes } from 'react-icons/fa';
import api from '../../api';
import ConfirmButton from './ConfirmButton';

export default function PrivateFile({ forType, forObject, name, displayName, preview, onRemove }) {
  const [url, setUrl] = useState();
  const [openFull, setOpenFull] = useState(false);
  const forId = forObject?._id;

  useEffect(() => {
    if (forType && forId && name) {
      api.uploads.generateFileDownloadRequest({ forType, forId, name }, ({ request }) => {
        setUrl(request);
      });
    }
  }, [forType, forId, name]);

  if (!url) return null;
  if (preview) {
    return (
      <Box p={2} bg='white' rounded='md' shadow='md' display='flex' alignItems='center' justifyContent='space-between'>
        <Heading size='xs' alignItems='center' display='flex'><Icon as={FaRegFile} mr={1} /> {displayName}</Heading>
        <Button ml={2} size='sm' download={displayName} colorScheme='blue' as='a' target='_blank' rel='noopener noreferrer' href={url}><Icon as={FaDownload} mr={2} /> Download</Button>
      </Box>
    );
  }
  return (
    <Box display='flex' justifyContent='center'>
      <Box minWidth='200px' maxWidth='400px' bg='white' p={4} rounded='md' shadow='md' textAlign='center'>
        <Heading size='sm' display='flex' alignItems='center' justifyContent='center'><Icon as={FaRegFile} mr={1} /> {displayName}</Heading>
        <Box display='flex' justifyContent='center' mt={5}>
          <Button colorScheme='blue' as='a' target='_blank' rel='noopener noreferrer' href={url}><Icon as={FaDownload} mr={2} /> Download</Button>
          {onRemove &&
            <ConfirmButton
              header='Really remove this file?'
              trigger={<Button ml={3} variant='ghost'><Icon as={FaTimes} mr={2} /> Remove</Button>}
              action={onRemove}
            />
          }
        </Box>
      </Box>
    </Box>
  );
}