import React, { useEffect } from 'react';
import { Box, Heading, Text, Button, Stack } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';

import ParticipantNote from './ParticipantNote';
import EmptyBox from './EmptyBox';
import api from '../../api';
import utils from '../../utils/utils';
import useStore from '../../store';

export default function ParticipantNotes({ participant }) {
  const { addParticipantNote, getParticipantNotesForParticipant, trial, user } = useStore();
  const participantId = participant?._id;
  const notes = getParticipantNotesForParticipant(participantId);
  
  useEffect(() => {
    if (!participant) return;
    api.participantNotes.listForParticipant(participant.trial, participant._id, data => {
      data?.notes.forEach(addParticipantNote);
    });
  }, [participantId]);
  
  function createNote() {
    api.participantNotes.create(participant.trial, participant._id, {note: ''}, note => {
      addParticipantNote(note);
    });
  }
  
  return (
    <Box bg='yellow.50' rounded='md' p={3}>
      <Box display='flex' justifyContent='space-between'>
        <Heading size='md'>Notes</Heading>
        {utils.canWriteParticipant(user, trial, participant) &&
          <Button colorScheme='yellow' size='sm' onClick={createNote}><AddIcon mr={2} /> New note</Button>
        }
      </Box>
      <Text mb={5} fontSize='sm'>These are not visible to participants, but are visible to other trial staff.</Text>
      
      {notes?.length === 0 &&
        <EmptyBox title='Notes' description='Notes about this participant will appear here once created' />
      }
      
      <Stack spacing={2} maxH='500px' overflowY='scroll' pb={3}>
        {notes?.map(note =>
          <ParticipantNote key={note._id} note={note} participant={participant} />
        )}
      </Stack>
    </Box>
  );
}
