import React, { useState, useEffect } from 'react';
import { Container, Box, Heading, Text, Button, Input, Alert, AlertTitle, FormControl, FormLabel, FormHelperText, CircularProgress, Stack } from '@chakra-ui/react';
import { FaGoogle, FaMicrosoft } from 'react-icons/fa';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import useStore from '../../store';
import { api } from '../../api';
import utils from '../../utils/utils.js';

import useTitle from '../../hooks/useTitle';

export default function Login({ withWarning }) {
  const store = useStore();
  const [loginToken, setLoginToken] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [otpChallenge, setOtpChallenge] = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  useTitle('Login');

  useEffect(() => {
    const params = new URLSearchParams(search);
    if (params?.get('token')) {
      setLoginToken(params.get('token'));
    }
  }, [search]);
  
  useEffect(() => {
    if (loginToken) login();
  }, [loginToken]);

  function login(e) {
    e?.preventDefault();
    setLoading(true);
    setError();
    api.accounts.login({
      loginToken: loginToken || null,
      email: email || null,
      password: password || null,
      otp: otp || null,
    }, (data) => {
      setLoading(false);
      if (data.challenge) {
        setOtpChallenge(true);
      }
      if (data.token) {
        store.completeLogin(data.token);
        if (window.location.pathname === '/login') navigate('/');
      }
    }, (err) => {
      setError(err);
      setLoading(false);
    });
  }
  
  if (loginToken) {
    return (
      <Container mt={10}>
        {loading && <Box>
          <Heading mb={2}>Logging you in...</Heading>
          <Text>Please wait while we check your account.</Text>
          <Box display='flex' justifyContent='center'>
            <CircularProgress mt={5} isIndeterminate />
          </Box>
        </Box>}
        {error && <Box>
          <Heading mb={2}>There was a problem logging you in</Heading>
          <Text>{error.message}</Text>
          
          <Text mt={10}>We recommend closing this tab and trying again.</Text>
        </Box>}
      </Container>
    );
  }

  return (
    <Container mt={5} mb={5}>
      {withWarning &&
        <Alert mb={5} status='info' variant='left-accent' rounded='md' shadow='md'>
          <Text>For security, you are required to login to see this page.</Text>
        </Alert>
      }
      <Heading size='lg' mb={5}>Login to Trialflare</Heading>
      {error &&
        <Alert status="warning" variant="left-accent" mt={3}>
          <AlertTitle>{error.message}</AlertTitle>
        </Alert>
      }

      <Box mt={5} p={4} bg='gray.50' rounded='md'>
        <Heading size='sm' mb={3}>If supported by your team, you can login with single sign-on</Heading>
        <Stack direction={{base: 'column', sm: 'row'}}>
          <Button colorScheme='teal' onClick={utils.loginWithGoogle}><Box as={FaGoogle} mr={2} /> Login with Google</Button>
          <Button colorScheme='teal' onClick={utils.loginWithMicrosoft}><Box as={FaMicrosoft} mr={2} /> Login with Microsoft</Button>
        </Stack>
      </Box>

      <Heading size='sm' mt={5} mb={3}>Or login with your Trialflare email and password</Heading>

      <form onSubmit={login}>
        <FormControl mt={5}>
          <FormLabel htmlFor="email">Email address</FormLabel>
          <Input autoFocus type="email" id="email" placeholder='jane@company.com' value={email} onChange={e => setEmail(e.target.value)}/>
        </FormControl>

        <FormControl mt={5}>
          <FormLabel htmlFor="password">Password</FormLabel>
          <Input type="password" id="password" aria-describedby="password-helper-text" placeholder='Your password' value={password} onChange={e => setPassword(e.target.value)}/>
          <FormHelperText id="password-helper-text">
            <Link to="/password/forgotten">Forgotten your password?</Link>
          </FormHelperText>
        </FormControl>

        {otpChallenge &&
          <FormControl mt={5}>
            <FormLabel htmlFor="otp">Enter the six-digit code generated by your device</FormLabel>
            <Input autoFocus type="number" id="otp" aria-describedby="otp-helper-text" placeholder='123456' value={otp} onChange={e => setOtp(e.target.value)}/>
            <FormHelperText id="otp-helper-text">
              You are being asked for an extra one-time password because you have 2FA enabled on your account.
            </FormHelperText>
          </FormControl>
        }

        <Button mt={4} tabIndex="3" colorScheme="primary" type='submit' isLoading={loading}>Login</Button>
      </form>
    </Container>
  );
}
