import { Stat, StatLabel, StatNumber, StatHelpText } from "@chakra-ui/react";

export default function SimpleStat({ name, number, total, helpText, helpTextAsPercentage }) {
  return (
    <Stat>
      <StatLabel mb={2}>{name}</StatLabel>
      <StatNumber>{number}</StatNumber>
      {(helpText || helpTextAsPercentage) &&
        <StatHelpText>
          {helpText || `${((number / total) * 100).toFixed(2)}%`}
        </StatHelpText>
      }
    </Stat>
  );
}
