import React from 'react';
import { Button, Stack } from '@chakra-ui/react';

import SimpleModal from './SimpleModal';

export default function UnsavedModal({ header, body, blocker }) {
  return (
    <SimpleModal
      isOpen={blocker?.state === "blocked"}
      onClose={() => blocker.reset()}
      header={header || 'Really leave this page?'}
      body={body || 'You may have unsaved changes that will be lost.'}
      footer={
        <Stack direction='row' spacing={4}>
          <Button onClick={() => blocker?.proceed()}>
            Leave without saving
          </Button>
          <Button colorScheme='primary' onClick={() => blocker?.reset()}>
            Stay on page
          </Button>
        </Stack>
      }
    />
  );
}
