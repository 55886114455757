import React, { useEffect, useState } from 'react';
import { Box, Select, FormControl, FormLabel, InputGroup, InputRightAddon, Input } from '@chakra-ui/react';
import utils from '../../utils/utils';

export default function HeightEditor({ value, onChange }) {
  const [measurement, setMeasurement] = useState('metric');
  const [height, setHeight] = useState();
  const [heightFeet, setHeightFeet] = useState();
  const [heightInches, setHeightInches] = useState();

  useEffect(() => {
    const val = value?.toString().replace(/^0.+/, '') || '';
    setHeight(val);
    const imperial = utils.cmToFeet(val || 0);
    if (imperial) {
      setHeightFeet(imperial.feet);
      setHeightInches(imperial.inches);
    }
  }, [value])

  const updateMetricHeight = cm => {
    if (cm === '' || parseFloat(cm) >= 0) {
      setHeight(cm);
      onChange(cm);
    }
    const imperial = utils.cmToFeet(cm || 0);
    if (imperial) {
      setHeightFeet(imperial.feet);
      setHeightInches(imperial.inches);
    }
  };

  const updateImperialHeight = (feet, inches) => {
    setHeightFeet(feet);
    if (inches === '' || (parseFloat(inches) >= 0 && parseFloat(inches) < 12)) {
      setHeightInches(inches);
    }
    if (feet || inches) {
      const cm = utils.feetToCm(feet || 0, inches || 0);
      setHeight(cm);
      onChange(cm);
    }
  };

  return (
    <Box>
      <Box>
        <Select w='200px' placeholder='Measurement type...' size='xs' mr={2} bg='white'
          value={measurement} onChange={e => setMeasurement(e.target.value || 'metric')}>
          <option value='metric'>Metric (cm)</option>
          <option value='imperial'>Imperial (ft & inches)</option>
        </Select>
      </Box>

      <Box display={{md: 'flex'}} mt={2}>
        {measurement === 'metric' && <>
          <FormControl bg='blue.100' rounded='md' p={2} w='150px'>
            <FormLabel fontSize='xs'><strong>Height</strong></FormLabel>
            <InputGroup size='sm'>
              <Input width='80px' bg='white' size='sm' value={height} onChange={e => updateMetricHeight( e.target.value.replace(/[^\d.]/g, ''))} />
              <InputRightAddon children='cm' />
            </InputGroup>
          </FormControl>
        </>}

        {measurement === 'imperial' && <>
          <FormControl bg='blue.100' rounded='md' p={2} w='270px'>
            <FormLabel fontSize='xs'><strong>Height</strong></FormLabel>
            <Box display='flex'>
            <InputGroup size='sm'>
              <Input width='60px' bg='white' size='sm' value={heightFeet} onChange={e => updateImperialHeight(e.target.value.replace(/[^\d.]/g, ''), heightInches)} />
              <InputRightAddon children='feet' />
            </InputGroup>
            <InputGroup size='sm'>
              <Input width='60px' bg='white' size='sm' value={heightInches} onChange={e => updateImperialHeight(heightFeet, e.target.value.replace(/[^\d.]/g, ''))} />
              <InputRightAddon children='inches' />
            </InputGroup>
            </Box>
          </FormControl>
        </>}
      </Box>
    </Box>
  );
}