import api from '.';

export const sites = {
  create(trialId, data, success, fail) {
    api.authenticatedRequest('POST', `/trials/${trialId}/sites`, data, success, fail);
  },
  getForTrial(trialId, success, fail) {
    api.authenticatedRequest('GET', `/trials/${trialId}/sites`, null, success, fail);
  },
  get(id, success, fail) {
    api.authenticatedRequest('GET', `/sites/${id}`, null, success, fail);
  },
  update(id, data, success, fail) {
    api.authenticatedRequest('PUT', `/sites/${id}`, data, success, fail);
  },
  delete(id, success, fail) {
    api.authenticatedRequest('DELETE', `/sites/${id}`, null, success, fail);
  },
  addMember(id, participantId, success, fail) {
    api.authenticatedRequest('PUT', `/sites/${id}/memberships/${participantId}`, null, success, fail);
  },
  removeMember(id, participantId, success, fail) {
    api.authenticatedRequest('DELETE', `/sites/${id}/memberships/${participantId}`, null, success, fail);
  },
};
