import React, { useState, useEffect } from 'react';
import { useToast, Box, Heading, Text, Textarea, Button, Alert, AlertDescription, CircularProgress, Badge } from '@chakra-ui/react';
import {CheckIcon } from '@chakra-ui/icons';
import moment from 'moment';

import api from '../../api';
import useStore from '../../store';
import NoMessages from '../../images/messages.png';
import EmptyBox from './EmptyBox';

export default function ParticipantMessenger({ trial, participant }) {
  const [loadingMessages, setLoadingMessages] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');  
  const toast = useToast();
  const trialId = trial?._id;
  const participantId = participant?._id;
  const { updateTrial } = useStore();
  
  useEffect(() => {
    getMessages();
  }, [trialId, participantId]);
  
  useEffect(() => {
    const interval = setInterval(getMessages, 30000);
    return () => clearInterval(interval);
  }, []);
  
  function getMessages() {
    if (participantId) {
      setLoadingMessages(true);
      api.trials.getParticipantMessages(trialId, participantId, ({ messages }) => {
        setLoadingMessages(false);
        setMessages(messages);
      });
    }
  }
  
  function createMessage() {
    setSendingMessage(true);
    api.trials.createParticipantMessage(trial._id, participantId, { message: newMessage }, message => {
      const newMessages = Object.assign([], messages);
      newMessages.splice(0, 0, message);
      setSendingMessage(false);
      setMessages(newMessages);
      setNewMessage('');
    }, err => {
      toast({title: 'Unable to send message', description: err.message, status: 'error'});
    });
  }
  
  function markAsRead(participantId) {
    api.trials.readParticipantMessages(trial._id, participantId, data => {
      updateTrial(trial._id, { unreadMessages: data.unreadTrialMessages });
      setMessages(data.messages)
    }, err => {
      toast({title: 'Unable to mark as read'});
    })
  }
  
  return (
    <Box>
      {!participant ?
        <Alert variant='left-accent'>
          <AlertDescription>Please select a participant from the list to view and send messages.</AlertDescription>
        </Alert>
      :
        <Box height={600} overflowY='scroll'>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Heading as='h3' size='md'>Messages with {participant.id}</Heading>
            {messages?.filter(m => !m.isRead)?.length > 0 &&
              <Button colorScheme='blue' size='sm' onClick={e => markAsRead(participant._id)}><CheckIcon mr={2}/> Mark all as read</Button>
            }
          </Box>
          <Textarea placeholder={`Write a new message to ${participant.id}...`} mt={5} value={newMessage} onChange={e => setNewMessage(e.target.value)} />
          <Button onClick={createMessage} isLoading={sendingMessage} colorScheme='primary' mt={3}>Send message</Button>
            {!loadingMessages && !messages?.length && <EmptyBox image={NoMessages} title='There are no messages with this participant yet' />}
          {loadingMessages && <CircularProgress isIndeterminate />}
          {messages?.sort((a,b) => a.createdAt < b.createdAt)?.map(m =>
            <Box key={m._id} display='flex' justifyContent={m.sender === 'admin' ? 'end' : 'start'} mt={5}>
              <Box bg={m.sender === 'admin' ? 'primary.500' : 'gray.100'} width={{md: '70%'}} p={3} borderRadius={5}>
                <Box display='flex' alignItems='baseline' mb={2}>
                  <Text fontSize='sm' color='gray.400' mr={2}>{moment(m.createdAt).fromNow()}</Text>
                  {!m.isRead && <Badge mb={2} colorScheme="yellow">Unread</Badge>}
                </Box>
      
                <Text whiteSpace='pre-line' color={m.sender === 'admin' ? 'white' : null}>{m.message}</Text>
              </Box>
            </Box>
          )}
        </Box>
      }
    </Box>
  )
}
