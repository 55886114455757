import React from 'react';
import { Box, Heading, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import useStore from '../../store';
import utils from '../../utils/utils.js';
import useTitle from '../../hooks/useTitle';
import Overview from './Overview';
import Users from './Users';
import Integrations from './Integrations';
import Settings from './Settings';
import Branding from './Branding';
import Events from './Events';
import Login from '../entry/Login';

function Team({ }) {
  const { user, team } = useStore();
  useTitle('Manage Team');

  if (!user) return <Login withWarning />;

  return (
    <Box p={10}>
      <Heading mb={5} as='h2' size='lg'>{team ? team.name + '\'s' : 'Your'} team account</Heading>
      {team &&
        <Tabs isLazy>
          <TabList>
            <Tab>Overview</Tab>
            <Tab>Branding</Tab>
            <Tab>Settings</Tab>
            <Tab>Users</Tab>
            <Tab>Integrations</Tab>
            {utils.canManageTeam(user, team) &&
              <Tab>Events</Tab>
            }
          </TabList>

          <TabPanels pt={5}>
            <TabPanel><Overview /></TabPanel>
            <TabPanel><Branding /></TabPanel>
            <TabPanel><Settings /></TabPanel>
            <TabPanel><Users /></TabPanel>
            <TabPanel><Integrations /></TabPanel>
            {utils.canManageTeam(user, team) &&
              <TabPanel><Events /></TabPanel>
            }
          </TabPanels>
        </Tabs>
      }
    </Box>
  );
}

export default Team;
