import React, { useEffect, useState } from 'react';
import { Container, Badge, CircularProgress, Box, Heading, Text, Button, Alert, FormControl, Input, FormLabel, FormHelperText, Switch, Checkbox, InputGroup, InputLeftAddon, Select, Stack } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import useTitle from '../hooks/useTitle';
import api from '../api';
import useStore from '../store';

import RichTextViewer from './includes/RichTextViewer';

import ShortText from './fields/ShortText';
import LongText from './fields/LongText';
import Integer from './fields/Integer';
import Float from './fields/Float';
import Slider from './fields/Slider';
import Choice from './fields/Choice';

export const POLL_FIELDS = {
  'text': ShortText,
  'paragraph': LongText,
  'integer': Integer,
  'float': Float,
  'slider': Slider,
  'choice': Choice
};

export default function Poll() {
  const [stage, setStage] = useState(null);
  const [responses, setResponses] = useState({});
  const [postSubmissionText, setPostSubmissionText] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [errorComponent, setErrorComponent] = useState('');
  const [loading, setLoading] = useState(false);
  const { stageId, linkId } = useParams();
  const { team, setTeam } = useStore();
  useTitle(stage ? stage.name : 'Poll');

  useEffect(() => {
    if (!stageId) setError('No poll could be found.');
    else {
      setLoading(true);
      api.stages.getPoll(stageId, data => {
        setStage(data);
        setTeam(data.ownerTeam);
        setLoading(false);
      }, err => {
        setError('There was an error fetching the poll.')
        setLoading(false);
      });
    }
  }, [stageId]);

  function submit() {
    setError();
    setErrorComponent();
    api.stages.submitPollData(stage._id, { response: responses, accessId: linkId }, data => {
      setSuccess(true);
      setPostSubmissionText(data.postSubmissionText);
    }, err => {
      let message = err.message || '';
      if (message?.indexOf('[') > -1) {
        const match = err.message.match(/\[([a-z0-9\-]+)\]/i);
        setErrorComponent(match[1]);
        message = message.replace(match[0], '');
      }
      setError(message);
    });
  }
  
  return (
    <Container mt={5} mb={5}>
      
      <Heading size='lg'>Welcome</Heading>
      {loading && <Box mt={5} display='flex' justifyContent='center'>
        <CircularProgress isIndeterminate color='primary.300' />
      </Box>}
      {error && <Alert status='error' mt={5}>{error}</Alert>}

      {stage &&
        <Box mt={5}>
          <Heading size='md'>{stage.name}</Heading>
          <Text color='gray.500' mb={5}>{stage.description}</Text>

          {success ? <Box>
            <Alert status='success' mb={5} flexDirection='column'>
              <Heading size='sm'>Thank you</Heading>
              Your response has been submitted successfully.
            </Alert>
            {postSubmissionText && <RichTextViewer content={postSubmissionText} />}
          </Box> : <Box>
            <Stack spacing={5}>
              {stage.components?.map(component => {
                const Field = POLL_FIELDS[component.type?.type];
                const value = responses[component.dataType]?.value;
                const onChange = v => setResponses({ ...responses, [component.dataType]: {
                  value: v,
                  createdAt: new Date()
                } });
                return (
                  <Box key={component.id} p={3} borderWidth='1px' rounded='md' bg={errorComponent === component.id ? 'red.100' : 'gray.50'}>
                    {component.isRequired &&
                      <Box mb={1} display='flex' justifyContent='flex-end'>
                        <Badge colorScheme='red'>Required</Badge>
                      </Box>
                    }

                    <Heading as='h3' size='md'>{component.title}</Heading>
                    <RichTextViewer content={component.description} />
                    <Box mt={3}>
                      {Field ? <Field dataType={component.type} value={value} onChange={onChange} />
                        : <Alert variant='left-accent'>This question type is not yet supported in polls.</Alert>
                      }
                    </Box>
                  </Box>
                );
              })}
            </Stack>
            {error && <Alert status='error' mt={10}>{error}</Alert>}
            <Button w='100%' mt={10} colorScheme='primary' onClick={submit}>Submit</Button>
          </Box>
          }
        </Box>
      }
        
    </Container>
  )
};
