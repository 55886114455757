import React from 'react';
import { Box, Heading, Text, Alert, Input, Textarea, Badge } from '@chakra-ui/react';

import FormField from '../../includes/FormField';

const Code = ({ trial, fields, data, updateData}) => {
  return (
    <Box>
      <Box rounded='md' shadow='md' bg='gray.50' borderWidth='1px' p={3}>
        <Heading as='h3' size='md'>Trial code <Badge colorScheme='yellow' ml={3}>Required</Badge></Heading>
        <Text>The trial code is a word or phrase that your participants will use to login to the trial. It must be unique across all Trialflare trials but doesn't need to be secure (since you can add a trial password separately).</Text>
        <Alert mt={10} mb={5} variant='left-accent'><Text>We recommend using a short word that can easily be understood, memorised, and entered by participants later. For example, <strong>sleepquality</strong>.</Text></Alert>

        {fields.map(field =>
          <FormField key={field.name} label={field.label} type={field.type} value={data[field.key]} onChange={e => updateData({ [field.key]: e.target.value })} />  
        )}
      </Box>
    </Box>
  );
};

export default Code;
