import React, { useState, useEffect } from 'react';
import { useToast, Button, Box, Input, Text, Heading, Select } from '@chakra-ui/react';
import { FaTrash } from 'react-icons/fa';
import styled from 'styled-components'
import api from '../../api';

import EmptyBox from '../includes/EmptyBox';

const HookTable = styled.table`
  width: 100%;
  th {
    text-align:left;
    border-bottom: 1px solid rgb(200,200,200);
    padding-bottom:10px;
    font-size: 14px;
  }
  tr {
    border-bottom: 1px solid rgb(230,230,230);
    margin-bottom:30px;
  }
  td {
    padding: 5px;
    text-align:left;
    font-size:14px;
    :last-child{
      text-align: right;
    }
  }
`;

export default function WebhookManager(props) {
  const { type, typeId, options } = props;
  const [webhooks, setWebhooks] = useState([]);
  const [newHookUrl, setNewHookUrl] = useState('');
  const [newHookEvent, setNewHookEvent] = useState(null);
  const [creating, setCreating] = useState(false);
  const toast = useToast();

  useEffect(() => {
    api.webhooks.get(type, typeId, ({ webhooks }) => setWebhooks(webhooks));
  }, [type, typeId]);

  function createHook() {
    if (newHookUrl && newHookEvent) {
      setCreating(true);
      api.webhooks.create({ type, typeId, url: newHookUrl, event: newHookEvent }, webhook => {
        webhooks.push(webhook);
        setWebhooks(webhooks);
        setCreating(false);
        setNewHookUrl('');
        setNewHookEvent(null);
      }, err => {
        toast({status:'error', title:'Unable to create webhook', description: err.message});
        setCreating(false);
      });
    }
  }
  function deleteHook(id) {
    api.webhooks.delete(id, () => {
      setWebhooks(webhooks.filter(w => w._id !== id));
    }, err => toast({status:'error', title:err.message}));
  }

  return (
    <>
      <Text>HTTP POST requests will be sent about the events listed below.</Text>
      <Box display={{md:'flex'}} mt={5}>
        <Box width={{md:'40%'}} rounded='md' p={3} borderWidth='1px'>
          <Heading as='h3' size='md'>Set-up a webhook</Heading>
          <Text mb={5}>Configure a webhook to be delivered when an event occurs.</Text>
          <Text>Event trigger</Text>
          <Select placeholder='Select an event...' value={newHookEvent} onChange={(e,s) => setNewHookEvent(e.target.value)}>
            {options.map(o => <option key={o.key} value={o.value}>{o.text}</option>)}
          </Select>
          <Text mt={3}>URL to request (HTTP POST)</Text>
          <Input value={newHookUrl} onChange={e => setNewHookUrl(e.target.value)} placeholder='https://example.com/incoming'/>
          <Button mt={5} colorScheme='blue' onClick={createHook} isLoading={creating}>Create webhook</Button>
        </Box>

        <Box width={{md:'60%'}} p={3}>
          {webhooks.length > 0 ?
            <HookTable>
              <thead>
                <tr>
                  <th>Event</th>
                  <th>URL <code>(HTTP POST)</code></th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {webhooks.map(w =>
                  <tr key={w._id}>
                    <td><code>{w.events[0]}</code></td>
                    <td><code>{w.url}</code></td>
                    <td>
                      <Button size='sm' colorScheme='red' variant='ghost' onClick={e => deleteHook(w._id)}><Box as={FaTrash} /></Button>
                    </td>
                  </tr>
                )}
              </tbody>
            </HookTable>
          :
            <EmptyBox title='Webhooks will appear here once created' />
          }
        </Box>
      </Box>
    </>
  );
}