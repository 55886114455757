import api from '.';

export const files= {
  create(trialId, data, success, fail) {
    api.authenticatedRequest('POST', `/trials/${trialId}/files`, data, success, fail);
  },
  getForTrial(trialId, success, fail) {
    api.authenticatedRequest('GET', `/trials/${trialId}/files`, null, success, fail);
  },
  get(id, success, fail) {
    api.authenticatedRequest('GET', `/files/${id}`, null, success, fail);
  },
  update(id, data, success, fail) {
    api.authenticatedRequest('PUT', `/files/${id}`, data, success, fail);
  },
  delete(id, success, fail) {
    api.authenticatedRequest('DELETE', `/files/${id}`, null, success, fail);
  },
  createVersion(id, data, success, fail) {
    api.authenticatedRequest('POST', `/files/${id}/versions`, data, success, fail);
  },
};
