import api from '.';

export const accounts = {
  autoLogin(success) {
    const token = localStorage.getItem('auth_token');
    if (token && token.length) success(token);
  },
  enrol(token, firstName, lastName, password, termsAgreed, success, fail) {
    api.unauthenticatedRequest('POST', '/accounts/enrol', { token, firstName, lastName, password, termsAgreed }, (data) => {
      accounts.storeToken(data.token);
      success(data.token);
    }, fail);
  },
  create(email, password, firstName, lastName, termsAgreed, success, fail) {
    api.unauthenticatedRequest('POST', '/accounts', { email, password, firstName, lastName, termsAgreed }, success, fail);
  },
  login(data, success, fail) {
    api.unauthenticatedRequest('POST', '/accounts/sessions', data, (data) => {
      if (data.token) accounts.storeToken(data.token);
      success(data);
    }, fail);
  },
  storeToken(token) {
    localStorage.setItem('auth_token', token);
  },
  localLogout(success) {
    localStorage.removeItem('auth_token');
    success && success();
  },
  logout(success, fail) {
    api.authenticatedRequest('DELETE', '/accounts/sessions', null, () => {
      localStorage.removeItem('auth_token');
      success && success();
    }, fail);
  },
  sendPasswordResetEmail(email, success, fail) {
    api.unauthenticatedRequest('POST', '/accounts/password/reset', { email }, success, fail);
  },
  updatePasswordWithToken(token, newPassword, success, fail) {
    api.authenticatedRequest('PUT', '/accounts/password', { token, newPassword }, success, fail);
  },
  updatePassword(currentPassword, newPassword, success, fail) {
    api.authenticatedRequest('PUT', '/accounts/password', { currentPassword, newPassword }, success, fail);
  },
  delete(password, success, fail) {
    api.authenticatedRequest('DELETE', '/accounts', { password }, success, fail);
  },
  getOtpUri(success, fail) {
    api.authenticatedRequest('GET', `/accounts/otp`, null, success, fail);
  },
  enableMfa(otp, success, fail) {
    api.authenticatedRequest('POST', `/accounts/otp`, { otp }, success, fail);
  },
  disableMfa(password, success, fail) {
    api.authenticatedRequest('DELETE', `/accounts/otp`, { password }, success, fail);
  },
  createApiToken(data, success, fail) {
    api.authenticatedRequest('POST', '/accounts/apiTokens', data, success, fail);
  },
  getApiTokens(success, fail) {
    api.authenticatedRequest('GET', '/accounts/apiTokens', null, success, fail);
  },
  deleteApiToken(token_id, success, fail) {
    api.authenticatedRequest('DELETE', `/accounts/apiTokens/${token_id}`, null, success, fail);
  }
};
