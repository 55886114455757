import { Table, Thead, Tbody, Tr, Th, Td } from "@chakra-ui/react";

export default function SimpleTable({ headers, rows, tableProps }) {
  return (
    <Table {...tableProps}>
      <Thead>
        <Tr>
          {headers.map((header) => (
            <Th key={header}>{header}</Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {rows.map((row, index) => (
          <Tr key={index}>
            {row.map((cell, index) => (
              <Td key={index}>{cell}</Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
}
