import React from 'react';
import { Box } from '@chakra-ui/react';

import SimpleSlider from '../includes/SimpleSlider';

export default function Slider({ dataType, value, onChange }) {

  const localOnChange = val => {
    onChange(val); 
  }
  
  return (
    <Box>
      <SimpleSlider value={value} onChange={localOnChange} minValue={dataType?.minimum ?? 1} maxValue={dataType?.maximum ?? 10} /> 
    </Box>
  );
}
