import React from 'react';
import { Box, Stack, Checkbox } from '@chakra-ui/react';

export default function Choice({ dataType, value, onChange }) {

  const localOnChange = val => {
    let newValue = Object.assign([], value);
    if (dataType?.multiple) {
      if (newValue.includes(val)) {
        newValue.splice(newValue.indexOf(val), 1);
      } else {
        newValue.push(val);
      }
    }
    else {
      if (newValue.includes(val)) {
        newValue = [];
      }
      else {
        newValue = [val];
      }
    }
    onChange(newValue);
  }
  
  return (
    <Box>
      <Stack spacing={2}>
        {dataType?.options?.map((option, index) => (
          <Checkbox key={index} isChecked={value?.includes(option)} onChange={() => localOnChange(option)}>{option}</Checkbox>
        ))}
      </Stack>
    </Box>
  );
}
