import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useToast, Heading, Box, Alert, AlertIcon, Text, Icon, Button } from '@chakra-ui/react';
import { FaCloud, FaGoogleDrive, FaMicrosoft, FaDropbox } from 'react-icons/fa';
import utils from '../../../utils/utils';
import api from '../../../api';
import useStore from '../../../store';

import WebhookManager from '../../includes/WebhookManager';
import ConfirmButton from '../../includes/ConfirmButton';

function ResultsIntegrations({ }) {
  const [connectedServices, setConnectedServices] = useState([]);
  const { id } = useParams();
  const location = useLocation();
  const toast = useToast();
  const { user, trial, team } = useStore();

  // Handle trial connection to an OAuth service. If code present in navigation, assume trying to connect
  useEffect(() => {
    const { state } = location; // This is passed from navigate() in OAuthConnect component
    if (state?.code) {
      api.trials.connectToService(trial._id, state.service, state.scope, state.code, data => {
        setConnectedServices(data.connectedServices);
        toast({title: 'Connection success', description: 'Trialflare has successfully connected to the service.', status: 'success'})
      }, err => {
        toast({title: 'Unable to connect trial', description: err.message, status: 'error'});
        api.trials.getConnectedServices(trial._id, data => setConnectedServices(data.connectedServices));
      });
    }
    else {
      api.trials.getConnectedServices(trial._id, data => setConnectedServices(data.connectedServices));
    }
  }, [location]);

  const disconnectService = name => {
    api.trials.disconnectFromService(trial._id, name, data => {
      setConnectedServices(data.connectedServices);
    }, err => toast({title: 'Unable to connect trial', description: err.message, status: 'error'}));
  };

  const googleConnection = connectedServices.filter(s => s.name === 'google')[0];
  const microsoftConnection = connectedServices.filter(s => s.name === 'microsoft')[0];
  const dropboxConnection = connectedServices.filter(s => s.name === 'dropbox')[0];

  return (
    <Box>

      <Box justifyContent='center' borderWidth='1px' p={3} mb={10} rounded='md'>
        <Heading as='h3' size='md'><Icon as={FaGoogleDrive} /> Google Drive</Heading>
        <Text mb={5}>When you connect your Google Drive account, Trialflare will create a Google Sheet for this trial and sync data through to it in real-time.</Text>
        {googleConnection ?
          <Box>
            <Alert status='success' variant='left-accent'><AlertIcon /> Trialflare is connected to this service.</Alert>
            <Heading size='sm' mt={8} mb={3}>This trial is connected to a Google Sheet in {googleConnection.connectedUser?.firstName} {googleConnection.connectedUser?.lastName}'s account</Heading>

            <Button colorScheme='blue' as='a' href={`https://docs.google.com/spreadsheets/d/${googleConnection.fileId}`} target='_blank' rel='noopener noreferrer'><Icon as={FaGoogleDrive} mr={3} /> Open in Google Sheets</Button>

            <ConfirmButton
              trigger={<Button ml={4} variant='outline' colorScheme='grey'>Disconnect from Drive</Button>}
              header='Really disconnect from Google Drive?'
              footer={'The trial\'s Sheet will remain in Drive until you choose to delete it.'}
              action={e => disconnectService('google')}
              actionColor='red'
            />
          </Box>
        :
          (utils.canManageTrial(user, trial) ? <>
            {utils.hasFeature(team, 'integrations') ?
              <Button colorScheme='primary' onClick={() => utils.connectToGoogle('https://www.googleapis.com/auth/drive.file')}>Connect to Google Drive</Button>
            :
              <Alert variant='left-accent'>Your team plan does not include this type of integration.</Alert>
            }
          </>
          :
            <Alert status='warning' variant='left-accent'>Your account does not have permission to set up this integration.</Alert>
          )
        }
      </Box>

      <Box justifyContent='center' borderWidth='1px' p={3} mb={10} rounded='md'>
        <Heading as='h3' size='md'><Icon as={FaMicrosoft} /> Microsoft Office365</Heading>
        <Text mb={5}>When you connect your Microsoft Office account, Trialflare will create an Excel file in OneDrive for this trial and sync data through to it in real-time.</Text>
        {microsoftConnection ?
          <Box>
            <Alert status='success' variant='left-accent'><AlertIcon /> Trialflare is connected to this service.</Alert>
            <Heading size='sm' mt={8} mb={3}>This trial is connected to an Excel workbook in {microsoftConnection.connectedUser?.firstName} {microsoftConnection.connectedUser?.lastName}'s account</Heading>

            <Button colorScheme='blue' as='a' href={microsoftConnection.url} target='_blank' rel='noopener noreferrer'><Icon as={FaMicrosoft} mr={3} /> Open in Excel in Office365</Button>

            <ConfirmButton
              trigger={<Button ml={4} variant='outline' colorScheme='grey'>Disconnect from Office365</Button>}
              header='Really disconnect from Office365?'
              footer={'The trial\'s Excel spreadsheet will remain in OneDrive until you choose to delete it.'}
              action={e => disconnectService('microsoft')}
              actionColor='red'
            />
          </Box>
        :
          (utils.canManageTrial(user, trial) ? <>
            {utils.hasFeature(team, 'integrations') ?
              <Button colorScheme='primary' onClick={() => utils.connectToMicrosoft('offline_access Files.ReadWrite')}>Connect to Office365</Button>
            :
              <Alert variant='left-accent'>Your team plan does not include this type of integration.</Alert>
            }
          </>
          :
            <Alert status='warning' variant='left-accent'>Your account does not have permission to set up this integration.</Alert>
          )
        }
      </Box>

      <Box justifyContent='center' borderWidth='1px' p={3} mb={10} rounded='md'>
        <Heading as='h3' size='md'><Icon as={FaDropbox} /> Dropbox</Heading>
        <Text mb={5}>When you connect your Dropbox account, Trialflare will create a file in Dropbox for this trial and sync data through to it in real-time.</Text>
        {dropboxConnection ?
          <Box>
            <Alert status='success' variant='left-accent'><AlertIcon /> Trialflare is connected to this service.</Alert>
            <Heading size='sm' mt={8} mb={3}>This trial is connected to a CSV file in {dropboxConnection.connectedUser?.firstName} {dropboxConnection.connectedUser?.lastName}'s Dropbox</Heading>

            <Button colorScheme='blue' as='a' href={dropboxConnection.url} target='_blank' rel='noopener noreferrer'><Icon as={FaDropbox} mr={3} /> Open Trialflare folder in Dropbox</Button>

            <ConfirmButton
              trigger={<Button ml={4} variant='outline' colorScheme='grey'>Disconnect from Dropbox</Button>}
              header='Really disconnect from Dropbox?'
              footer={'The trial\'s data file will remain in Dropbox until you choose to delete it.'}
              action={e => disconnectService('dropbox')}
              actionColor='red'
            />
          </Box>
        :
          (utils.canManageTrial(user, trial) ? <>
            {utils.hasFeature(team, 'integrations') ?
              <Button colorScheme='primary' onClick={() => utils.connectToDropbox('files.content.write')}>Connect to Dropbox</Button>
            :
              <Alert variant='left-accent'>Your team plan does not include this type of integration.</Alert>
            }
          </>
          :
            <Alert status='warning' variant='left-accent'>Your account does not have permission to set up this integration.</Alert>
          )
        }
      </Box>

      <Box justifyContent='center' borderWidth='1px' p={3} mb={10} rounded='md'>
        <Heading as='h3' size='md'><Icon as={FaCloud} /> Webhooks</Heading>
        <Text mb={5}>Webhooks allow you to send trial data in real-time through to another system or service. This could be something you run yourself or a service like Zapier.</Text>
        {utils.canManageTrial(user, trial) ?
          <WebhookManager type='trial' typeId={trial._id} options={[
              { key: 'participantJoined', value: 'participantJoined', text: 'New participant joins the trial' },
              { key: 'responseSubmitted', value: 'responseSubmitted', text: 'Participant submits a response to a stage' },
          ]}/>
        :
          <Alert status='warning' variant='left-accent'>Your account does not have permission to set up this integration.</Alert>
        }
      </Box>
    </Box>
  );
}

export default ResultsIntegrations;
