import React, { useState, useEffect, useCallback } from 'react';
import { useToast, Alert, AlertIcon, Progress, Badge, Box, Stack, Heading, Text, Button, Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverHeader, PopoverFooter, Input, Select, Tag, Menu, MenuButton, MenuList, MenuOptionGroup, MenuItemOption, MenuItem, MenuDivider, Table, Thead, Tbody, Th, Tr, Td } from '@chakra-ui/react';
import { SettingsIcon, DeleteIcon, WarningIcon, CloseIcon } from '@chakra-ui/icons';
import moment from 'moment';
import utils, { TEAM_ROLES } from '../../utils/utils.js';
import api from '../../api';
import useStore from '../../store';

import ConfirmButton from '../includes/ConfirmButton';
import SimplePopover from '../includes/SimplePopover';

export default function TeamUsers() {
  const [creating, setCreating] = useState(false)
  const [newUserEmail, setNewUserEmail] = useState('');
  const [members, setMembers] = useState([]);
  const [page, setPage] = useState(1);
  const [filterTerm, setFilterTerm] = useState('');
  const [sort, setSort] = useState('firstName|asc');
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { user, team } = useStore();

  useEffect(() => {
    const [sortKey, sortOrder] = sort.split('|');
    setLoading(true);
    getUsers(team._id, page, filterTerm, sortKey, sortOrder);
  }, [team._id, page, filterTerm, sort]);

  const getUsers = useCallback(utils.debounce((teamId, page, term, sortKey, sortOrder, success, fail) => {
    api.teams.getUsers(team._id, page, term, sortKey, sortOrder, d => {
      setMembers(d.users);
      setLoading(false);
    });
  }, 500), []);

  function create() {
    setCreating(true);
    api.teams.createUser(team._id, { email: newUserEmail, sendInvite: true }, newUser => {
      toast({title: 'User invited', description: `We've invited the person to your team`, status: 'success'});
      const newMembers = Object.assign([], members);
      newMembers.push(newUser);
      setMembers(newMembers);
      setCreating(false);
      setNewUserEmail('');
    }, err => {
      toast({title: 'Unable to create user', description: err.message, status: 'error'});
      setCreating(false);
    });
  }

  const updateRoles = (userId, scope, permissions) => {
    api.users.updatePermissions(userId, scope, permissions, data => {
      setMembers(Object.assign([], members).map(u => {
        if (u._id === userId) u.permissions = Object.assign({}, {[scope]: data.permissions});
        return u;
      }));
    }, err => {
      toast({title: 'Unable to update user', description: err.message, status: 'error'});
    });
  }
  const deleteUser = (userId) => {
    api.users.delete(userId, () => {
      setMembers(members.filter(m => m._id !== userId));
      toast({title: 'User deleted', status: 'success'});
    }, err => {
      toast({title: 'Unable to delete user', description: err.message, status: 'error'});
    });
  }
  const resetLoginAttempts = (userId) => {
    if (!window.confirm('Really reset the incorrect logins for this user?')) return;
    api.users.update(userId, { incorrectLoginAttempts: null }, newUser => {
      setMembers(members.map(m => {
        if (m._id === userId) return Object.assign({}, m, { incorrectLoginAttempts: null });
        return m;
      }));
      toast({title: 'User updated', status: 'success'});
    }, err => {
      toast({title: 'Unable to update user', description: err.message, status: 'error'});
    });
  }

  return (
    <>
      <Box display='flex' justifyContent='space-between'>
        <Box>
          <Heading as='h3' size='md'>Users in {team?.name || 'your team'}</Heading>
          <Text>The users listed below are members of your team. Please note that trial participants are not considered as team members.</Text>
        </Box>
        <SimplePopover
          on='hover'
          trigger={<Button size='sm' variant='outline'>Learn about adding users to a trial</Button>} 
          header='Adding users to a trial'
          body={<Box>
            <Text>In order for a team member to get access to a trial, they need to be <strong>added as a collaborator</strong> in the trial's settings. The team member list shows all users belonging to the team even if they don't have access to any trials.</Text>
            <Text mt={2}>Please note that users with the "manage any trial" permission can access all trials in the team without being added as a collaborator.</Text>
          </Box>
        } />
      </Box>

      <Box display='flex' alignItems='center' justifyContent='space-between' mt={5}>
        <Stack direction='row'>
          <Input size='sm' placeholder='Filter users...' value={filterTerm} onChange={e => setFilterTerm(e.target.value)} />
          <Select size='sm' value={sort} onChange={e => setSort(e.target.value)}>
            <option value='firstName|asc'>First name (A-Z)</option>
            <option value='firstName|desc'>First name (Z-A)</option>
            <option value='createdAt|asc'>Oldest first</option>
            <option value='createdAt|desc'>Newest first</option>
          </Select>
        </Stack>

        {utils.hasPermission(user, 'users.create', team?._id) &&
          <Box display='flex' justifyContent='end'>
            <Popover placement='left'>
              <PopoverTrigger>
                <Button colorScheme='primary'>Add a new user</Button>
              </PopoverTrigger>
              <PopoverContent bg='gray.100'>
                <PopoverHeader>
                  <Heading size='md' as='h4' mb={3}>Invite someone to join {team.name}</Heading>
                  <Text>Inviting users allows them to help out with managing trials or your team account.</Text>
                </PopoverHeader>
                <PopoverBody>
                  <Text>Work email address</Text>
                  <Input bg='white' autoFocus type='email' value={newUserEmail} onChange={e => setNewUserEmail(e.target.value)} placeholder='jane@example.com'/>
                  <Button mt={5} colorScheme='blue' onClick={create} isLoading={creating}>Send invitation</Button>
                </PopoverBody>
                <PopoverFooter>After you invite them, we'll send an email to the new user asking them to set-up their account.</PopoverFooter>
              </PopoverContent>
            </Popover>
          </Box>
        }
      </Box>

      <Box mt={15}>
        {loading && <Progress size='xs' isIndeterminate />}
        <Table size='sm' variant='striped'>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Registered</Th>
              <Th>Last seen</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {members?.map(m => {
              let allPermissions = 0;
              Object.keys(m?.permissions || {})?.forEach(scope => {
                allPermissions += m.permissions[scope]?.length || 0;
              });
              return (
                <Tr key={m._id}>
                  <Td>{m.firstName} {m.lastName}</Td>
                  <Td flex={1}>{m.email}
                    {m.invitePending &&
                      <Tag colorScheme='primary' size='sm' ml={5}>Invited {moment(m.invitedAt).fromNow()}</Tag>
                    }
                    {utils.hasPermission(m, 'root', 'global') && <Tag size='sm' ml={5} colorScheme='blue'>Account manager</Tag>}
                    {allPermissions === 0 && <Tag colorScheme='orange' size='sm' ml={5}><WarningIcon mr={2} /> This user has no permissions</Tag>}
                    {m.incorrectLoginAttempts > 0 &&
                      <Badge size='xs' ml={5} colorScheme='red' alignItems='center'>
                        {m.incorrectLoginAttempts} login attempts
                        <CloseIcon ml={2} cursor='pointer' onClick={e => resetLoginAttempts(m._id)} />
                      </Badge>
                    }
                  </Td>
                  <Td>{moment(m.createdAt).format('DD/MM/YYYY HH:mm')}</Td>
                  <Td>{m.lastSeenAt ? moment(m.lastSeenAt).fromNow() : 'Never'}</Td>
                  <Td>
                    {(utils.hasPermission(user, 'users.manage', user.team ) || utils.hasPermission(user, 'users.delete', user.team)) &&
                    <Menu closeOnSelect={false}>
                      <MenuButton variant='outline'><SettingsIcon /></MenuButton>
                      <MenuList placement='bottom-left'>
                        {utils.hasPermission(user, 'users.manage', user.team) &&
                          <MenuOptionGroup title="Assign team-level permissions" type="checkbox" value={(m?.permissions && m.permissions[user.team]) || []} onChange={v => updateRoles(m._id, user.team, v)}>
                            {TEAM_ROLES.map(r => <MenuItemOption key={r.key} value={r.key}>{r.description}</MenuItemOption>)}
                          </MenuOptionGroup>
                        }
                        <MenuDivider />
                        {utils.hasPermission(user, 'users.delete', user.team) &&
                        <ConfirmButton
                          trigger={<MenuItem p={2}><DeleteIcon mr={2} /> Delete user</MenuItem>}
                          header={`Really delete ${m.firstName || 'this user'}'s account?`}
                          footer='This action cannot be undone, however the user can then be invited again.'
                          action={() => deleteUser(m._id)}
                          closeOnBlur={false}
                        />}
                      </MenuList>
                    </Menu>}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>

        <Stack direction='row' justifyContent='center' mt={5}>
          <Button size='sm' onClick={() => setPage(page - 1)} isDisabled={page === 1}>Previous page</Button>
          <Button size='sm' onClick={() => setPage(page + 1)} isDisabled={members?.length < 30}>Next page</Button>
        </Stack>
      </Box>
    </>
  );
}
