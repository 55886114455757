import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Heading, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import useStore from '../../../store';
import useTitle from '../../../hooks/useTitle';

import General from './General';
import Integrations from './Integrations';
import Collaboration from './Collaboration';
import Events from './Events';
import Delete from './Delete';

export default function Settings() {
  const { id } = useParams();
  const { user, trial } = useStore();
  useTitle('Settings', trial);

  return (
    <Box width={{md:'75%'}}>
      <Heading as='h2' size='lg' mb={5}>Trial settings</Heading>
      <Tabs>
        <TabList>
          <Tab>Participation</Tab>
          <Tab>Integrations</Tab>
          <Tab>Collaboration</Tab>
          <Tab>Events</Tab>
          <Tab>Delete trial</Tab>
        </TabList>
        <TabPanels pt={5}>
          <TabPanel>
            <General user={user} trial={trial}/>
          </TabPanel>
          <TabPanel>
            <Integrations user={user} trial={trial}/>
          </TabPanel>
          <TabPanel>
            <Collaboration user={user} trial={trial} />
          </TabPanel>
          <TabPanel>
            <Events trial={trial} />
          </TabPanel>
          <TabPanel>
            <Delete trial={trial}/>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
