import React, { useState } from 'react';
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Box, Heading, Text, Textarea, Alert } from '@chakra-ui/react';
import api from '../../api';

export default function TrialLocker({ trial, isLocking, trigger, onComplete }) {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState('');
  const [justification, setJustification] = useState('');
  
  const toggleLock = () => {
    setError('');
    setLoading(true);
    api.trials.update(trial?._id, { isLocked: !trial.isLocked, lockJustification: justification }, newTrial => {
      onComplete(newTrial?.isLocked);
      setLoading(false);
      setOpen(false);
    }, err => {
      setError(err.message);
      setLoading(false);
    });
  }
  
  return (
    <>
      {trigger
        ? React.cloneElement(trigger, { onClick: () => setOpen(true) })
        : <Button colorScheme="blue" onClick={() => setOpen(true)}>{isLocking ? 'Lock trial' : 'Unlock trial'}</Button>
      }
      <Modal isOpen={open} onClose={() => setOpen(false)} size='xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isLocking ? `Lock trial` : `Unlock trial`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>{isLocking ? 'When the trial is locked, stages and datatypes cannot be edited.' : 'Unlocking the trial will enable the editing of stages and datatypes.'}</Text>
            <Heading size='md' mt={5}>Justification</Heading>
            <Text>Write a brief justification or sign-off note for this action.</Text>
            <Textarea mt={5} value={justification} onChange={e => setJustification(e.target.value)} />
            
            <Alert mt={10} variant='left-accent'>Please note that trial administrators will be notified of this change.</Alert>
          </ModalBody>
          <ModalFooter>
            {error && <Alert mr={2} variant='left-accent' status='error'>{error}</Alert>}
            <Button mr={2} onClick={e => setOpen(false)}>Cancel</Button>
            <Button isLoading={loading} colorScheme='primary' onClick={toggleLock}>{isLocking ? 'Lock trial' : 'Unlock trial'}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
