import React, { useState, useEffect } from 'react';
import { useToast, Stack, Badge, Box, Heading, CircularProgress, Table, Thead, Tbody, Tr, Td, Th, Menu, MenuButton, MenuList, MenuItem, Button, IconButton, Tag, Text, Select, Alert, Input, Tabs, TabList, Tab, TabPanels, TabPanel, SimpleGrid } from '@chakra-ui/react';
import { AddIcon, CloseIcon, CheckIcon } from '@chakra-ui/icons';
import { FaEllipsisH } from "react-icons/fa";
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import api from '../../../api';
import EmptyBox from '../../includes/EmptyBox';
import SimpleStat from '../../includes/SimpleStat';
import SimpleMenu from '../../includes/SimpleMenu';
import SimpleLineChart from '../../includes/SimpleLineChart';
import SimpleDoughnutChart from '../../includes/SimpleDoughnutChart';
import SimpleBarChart from '../../includes/SimpleBarChart';

import NotFoundImage from '../../../images/notFound.png';
import SegmentImage from '../../../images/segment.png';
import DashboardImage from '../../../images/dashboard.png';

export default function Dashboards() {
  const [dashboardIndex, setDashboardIndex] = useState(0);
  const [dashboardList, setDashboardList] = useState([]);
  const [selectedDashboard, setSelectedDashboard] = useState(null);
  const [stages, setStages] = useState([]);
  const [dataTypes, setDataTypes] = useState([]);
  const [dataTypeFilter, setDataTypeFilter] = useState('');
  const [results, setResults] = useState([]);
  const [columns, setColumns] = useState([]);
  const [availableGroups, setAvailableGroups] = useState([]);
  const [group, setGroup] = useState('');
  const [stage, setStage] = useState('');
  const [stageRecurrence, setStageRecurrence] = useState();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { id } = useParams();
  const trialId = id;

  // Fetch groups, stages, dataTypes to show in the selector
  useEffect(() => {
    if (!trialId) return;
    api.dashboards.listForTrial(trialId, d => setDashboardList(d.dashboards));
    api.trials.getGroups(trialId, r => setAvailableGroups(r.groups));
    api.trials.getStages(trialId, d => setStages(d.stages));
    api.trials.getDataTypes(trialId, d => setDataTypes(d.dataTypes));
  }, [trialId]);

  // Each time dashboardIndex changes fetch dashboard
  useEffect(() => {
    if (!trialId) return;
    if (dashboardList.length === 0) {
      setSelectedDashboard(null);
      return;
    }
    const dashboard = dashboardList[dashboardIndex];
    if (!dashboard) return;
    api.dashboards.get(trialId, dashboard._id, d => {
      setSelectedDashboard(d); 
    });
  }, [dashboardIndex, dashboardList]);

  function createDashboard() {
    const name = window.prompt('Enter the name of the dashboard');
    if (name) {
      api.dashboards.create(trialId, { name }, d => {
        setDashboardList([...dashboardList, d]);
        setDashboardIndex(dashboardList.length);
      });
    }
  }

  function renameDashboard(dashboardId) {
    const newName = window.prompt('Enter the new name of the dashboard');
    if (newName) {
      api.dashboards.update(trialId, dashboardId, { name: newName }, d => {
        setDashboardList(dashboardList.map(d => d._id === dashboardId ? { ...d, name: newName } : d));
      });
    }
  }

  function deleteDashboard(dashboardId) {
    if (window.confirm('Are you sure you want to delete this dashboard?')) {
      api.dashboards.delete(trialId, dashboardId, () => {
        setDashboardList(dashboardList.filter(d => d._id !== dashboardId));
        setDashboardIndex(0);
      });
    }
  }

  function createSegment(dashboardId, segment) {
    const dashboard = dashboardList.find(d => d._id === dashboardId);
    dashboard.segments = [...dashboard.segments, segment];
    api.dashboards.update(trialId, dashboardId, { segments: dashboard.segments }, newDash => {
      setSelectedDashboard(newDash);
    }, err => toast({title: 'Unable to create your segment', description: err.message, status: 'error' }));
  }

  function updateSegment(dashboardId, segmentId, segment) {
    const segments = selectedDashboard?.segments.map(s => s.id === segmentId ? {
      id: segmentId,
      stage: segment.stage,
      dataType: segment.dataType
    } : {
      id: s.id,
      stage: s.stage,
      dataType: s.dataType
    });
    api.dashboards.update(trialId, dashboardId, { segments }, newDash => {
      setSelectedDashboard(newDash);
    }, err => toast({title: 'Unable to update your segment', description: err.message, status: 'error' }));
  }

  function deleteSegment(dashboardId, segmentId) {
    const dashboard = dashboardList.find(d => d._id === dashboardId);
    dashboard.segments = dashboard.segments.filter(s => s.id !== segmentId);
    api.dashboards.update(trialId, dashboardId, { segments: dashboard.segments }, newDash => {
      setSelectedDashboard(newDash);
    }, err => toast(err.message, { type: 'error' }));
  }

  return (
    <Box>
      <Tabs variant='soft-rounded' colorScheme='green' index={dashboardIndex} onChange={setDashboardIndex}>
        <TabList display='flex' justifyContent='start' alignItems='center'>
          <Box display='flex'>
            {dashboardList?.map(d => <Tab key={d.id}>{d.name}</Tab>)}
          </Box>
          <Button size='sm' colorScheme='green' ml={5} onClick={createDashboard}><AddIcon mr={2} /> Create dashboard</Button> 
        </TabList>
        {selectedDashboard && (
          <Box p={3} mt={3} rounded='md' border='1px solid' borderColor='green.100'>
            <Box display='flex' justifyContent='end'>
              <SimpleMenu
                trigger={<Button size='sm' variant='outline'>Dashboard settings</Button>}
                tfrigger='Dashboard settings' buttonProps={{ size: 'sm', colorScheme: 'primary' }}
                items={[
                  { text: 'Rename', onClick: () => renameDashboard(selectedDashboard._id) },
                  { text: 'Delete', onClick: () => deleteDashboard(selectedDashboard._id) }
                ]}
              />
            </Box>
            <SimpleGrid columns={[1,2,2,2,3]} spacing={5} mt={5}>
              {selectedDashboard.segments?.map(s =>
                <DashboardSegment key={s.id} segment={s} stages={stages} dataTypes={dataTypes} onUpdateSegment={d => updateSegment(selectedDashboard._id, s.id, d)} onDeleteSegment={() => deleteSegment(selectedDashboard._id, s.id)} />
              )}
              <NewDashboardSegment dashboardId={selectedDashboard._id} stages={stages} dataTypes={dataTypes} onCreate={s => createSegment(selectedDashboard._id, s)} />
            </SimpleGrid>
          </Box>
        )}

        {(!selectedDashboard || !dashboardList.length) && (
          <EmptyBox image={DashboardImage} title='This trial has no dashboards' description='Dashboards can be used to visualise trial data. Get started by creating your first dashboard.' action={<Button colorScheme='green' onClick={createDashboard}>Create a dashboard</Button>} />
        )}
      </Tabs>
    </Box>
  );
}

function EditDashboardSegment({ segment, dashboardId, stages, dataTypes, onSave, onCancel }) {
  const [stage, setStage] = useState(segment?.stage || '');
  const [dataType, setDataType] = useState(segment?.dataType || '');

  function save() {
    if (!stage || !dataType) return;
    onSave({ id: segment?.id || uuidv4(), stage, dataType });
  }

  const VALID_DATA_TYPES = ['integer', 'float', 'slider', 'choice'];
  const validDataTypes = dataTypes?.filter(d => VALID_DATA_TYPES.includes(d.type));

  return (
    <Box>
      <Select bg='white' placeholder='Select data to visualise...' value={dataType} onChange={e => setDataType(e.target.value)}>
        {validDataTypes?.map(d => <option key={d._id} value={d._id}>{d.name}</option>)}
      </Select>
      <Select mt={2} bg='white' placeholder='Select a stage...' value={stage} onChange={e => setStage(e.target.value)}>
        {stages.map(s => <option key={s._id} value={s._id}>{s.name}</option>)}
      </Select>
      
      <Button mt={2} onClick={save} colorScheme='primary' variant='outline' size='sm' w='100%'>Save</Button>
      <Button mt={2} onClick={onCancel} variant='outline' size='sm' w='100%'>Cancel</Button>
    </Box>
  );
}

function DashboardSegment({ segment, stages, dataTypes, onUpdateSegment, onDeleteSegment }) {
  const [isEditing, setIsEditing] = useState(false);
  const dataType = dataTypes.find(d => d._id === segment.dataType);
  const stage = stages.find(s => s._id === segment.stage);

  function deleteSegment() {
    if (window.confirm('Are you sure you want to delete this segment?')) {
      onDeleteSegment();
    }
  }

  function updateSegment(newData) {
    onUpdateSegment(newData);
    setIsEditing(false);
  }

  if (!dataType || !stage) return null;
  if (isEditing) {
    return (
      <Box p={3} bg='gray.50' rounded='md'>
        <Heading mb={4} size='sm' textAlign='center'>Edit segment</Heading>
        <EditDashboardSegment segment={segment} dashboardId={segment.dashboard} stages={stages} dataTypes={dataTypes} onSave={updateSegment} onCancel={() => setIsEditing(false)} />;
      </Box>
    );
  }
  return (
    <Box p={3} bg='gray.50' rounded='md'>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Badge size='sm' colorScheme='blue'>{segment.dataTypeObject?.name} in {segment.stageObject?.name}</Badge>
        <SimpleMenu
          trigger={<IconButton size='sm' variant='ghost' icon={<FaEllipsisH />} />}
          items={[
            { text: 'Edit segment', onClick: () => setIsEditing(true) },
            { text: 'Delete segment', onClick: deleteSegment }
          ]}
        />
      </Box>

      {segment.displayType === 'numericSummary' &&
        <Box>
          <Box display='flex' justifyContent='center' alignItems='center' mt={3}> 
            <SimpleStat name='Average' number={segment.data?.avg?.toFixed(2) ?? 'No Data'} />
          </Box>
          <Box display='flex' justifyContent='center' alignItems='center' mt={3}> 
            <SimpleStat name='Min' number={segment.data?.min ?? 'No Data'} />
            <SimpleStat name='Max' number={segment.data?.max ?? 'No Data'} />
          </Box>
        </Box>
      }
      {segment.displayType === 'lineChart' &&
        <Box minH='200px'>
          <SimpleLineChart labels={segment.data?.x || []} datasets={[
            {
              label: 'All participants',
              data: segment.data?.y || [], 
            },
          ]} options={{
            scales: {
              x: {title: {text: 'Stage progression', display: true}},
              y: {title: {text: 'Average value', display: true}}
            }
          }} />
        </Box> 
      }
      {segment.displayType === 'pieChart' &&
        <Box>
          <SimpleDoughnutChart labels={Object.keys(segment.data) || []} datasets={[
            {
              label: 'All participants',
              data: Object.values(segment.data) || [], 
            },
          ]} />
        </Box> 
      }
      {segment.displayType === 'barChart' &&
        <Box minH='200px'>
          <SimpleBarChart labels={segment.data?.x || []} datasets={
            segment.data?.ys ? Object.keys(segment.data.ys).map(k => ({ label: k, data: segment.data.ys[k] })) : []
          } options={{
            scales: {
              x: {title: {text: 'Stage progression', display: true}},
              y: {title: {text: 'Selection count', display: true}}
            }
          }} />
        </Box>
      }
      
    </Box>
  );
}

function NewDashboardSegment({ dashboardId, stages, dataTypes, onCreate }) {
  const [isEditing, setIsEditing] = useState(false);

  function create(data) {
    onCreate(data);
    setIsEditing(false);
  }

  if (isEditing) {
    return (
      <Box p={3} bg='green.50' rounded='md'>
        <Heading mb={4} size='sm' textAlign='center'>Create a new segment</Heading>
        <EditDashboardSegment dashboardId={dashboardId} stages={stages} dataTypes={dataTypes} onSave={create} onCancel={() => setIsEditing(false)} />;
      </Box>
    );
  }
  return (
    <Box p={3} bg='green.100' rounded='md'>
      <Stack alignItems='center' justifyContent='center' spacing={3}>
        <Heading size='sm' textAlign='center'>Segments are small visualisations that make up your dashboard</Heading> 
        <img src={SegmentImage} width='200px' /> 
        <Button w='100%' size='sm' colorScheme='green' onClick={() => setIsEditing(true)}>Create segment</Button>
      </Stack>
    </Box>
  );
}
