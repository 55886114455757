import React, { useState, useEffect } from 'react';
import { Box, Heading } from '@chakra-ui/react';
import { Routes, Route, useParams, Outlet } from 'react-router-dom';

function RootDashboard({ }) {
  
  return (
    <Box m={3}>
      <Heading as='h1' mb={2}>Trialflare root</Heading>
      <Outlet />
    </Box>
  );
}

export default RootDashboard;
