import React,  { useEffect, useState } from 'react';
import { useToast, Alert, AlertTitle, AlertDescription, Button, Heading, Box, Text, Input, InputGroup, InputRightElement, Textarea, FormControl, FormLabel, FormHelperText, Switch, Tabs, TabList, Tab, TabPanels, TabPanel, Table, Thead, Tr, Th, Tbody, Td, Link } from '@chakra-ui/react';
import { DeleteIcon, AddIcon, CheckCircleIcon, WarningIcon, DownloadIcon } from '@chakra-ui/icons';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import moment from 'moment';
import uuid from 'uuid';
import api from '../../api';
import useStore from '../../store';
import useTitle from '../../hooks/useTitle';

import RichText from '../includes/RichText';
import EmptyBox from '../includes/EmptyBox';
import ConfirmButton from '../includes/ConfirmButton';

export default function TrialConsent(){
  const [activeConsentDocument, setActiveConsentDocument] = useState();
  const [email, setEmail] = useState('');
  const [postConsentText, setPostConsentText] = useState('');
  const [consentDocument, setConsentDocument] = useState('');
  const [eligibility, setEligibility] = useState('');
  const [conditions, setConditions] = useState([]);
  const [savingDocument, setSavingDocument] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [consents, setConsents] = useState([]);
  const [error, setError] = useState('');
  const { user, trial, updateTrial, team } = useStore();
  const toast = useToast();
  useTitle('Consent', trial);
  const trialId = trial?._id;
  
  useEffect(() => {
    if (!trialId) return;
    api.trials.getConsentDocuments(trialId, data => {
      const active = data?.documents?.filter(d => d.isActive);
      if (active && active[0]) {
        setActiveConsentDocument(active[0]);
        setConsentDocument(active[0].document || '');
        setEligibility(active[0].eligibilityCriteria || '');
        setConditions(active[0].conditions || []);
        setEmail(active[0].notificationEmail || '');
        setPostConsentText(active[0].postConsentText || '');
      }
      setShowEditor(true);
    });
    api.trials.listConsentsForTrial(trialId, data => {
      setConsents(data.consents);
    });
  }, [trialId]);
  
  function enable(eConsentEnabled) {
    api.trials.update(trial._id, { eConsentEnabled }, newTrial => {
      updateTrial(newTrial._id, newTrial);
    })
  }
  
  function saveDocument() {
    setSavingDocument(true);
    setError();
    api.trials.createConsentDocument(trial._id, {
      document: consentDocument,
      notificationEmail: email ? email : null,
      eligibilityCriteria: eligibility,
      conditions,
      postConsentText
    }, newDoc => {
      setActiveConsentDocument(newDoc);
      setSavingDocument(false);
      toast({title: 'eConsent configuration updated', status: 'success'});
    }, err => {
      setSavingDocument(false);
      setError(err.message);
    });
  }
  
  const copyUrl = (u) => {
    navigator.clipboard.writeText(u).then(
      () => toast({title: 'URL copied to clipboard', status: 'success'}),
      err => toast({title: 'Error copying', status: 'error'})
    );
  };
  
  const consentUrl = `https://app.trialflare.com/consent/${trial?.code}`;

  return (
    <>
      <Heading as='h2' size='lg'>Manage eConsent</Heading>
      <Text mb={5}>Trialflare can ensure your participants have eConsented via SMS or email before taking part in this trial.</Text>
      
      {trial?.eConsentEnabled &&
        <Alert status='success' variant='left-accent' mb={5}>
          <Box>
            <Heading size='sm'>eConsent is enabled for this trial</Heading>
            <Text>Participants must eConsent before they can take part in the trial. Participants can eConsent at the URL shown below.</Text>
            <InputGroup size="sm" mt={2}>
             <Input bg='white' size='sm' readonly value={consentUrl} />
              <InputRightElement width="4.5rem">
                <Button h="1.5rem" size="xs" colorScheme='blue' onClick={() => copyUrl(consentUrl)}>Copy</Button>
              </InputRightElement>
            </InputGroup>
          </Box>
        </Alert>
      }
      
      <Heading as='h3' size='sm'>Enable eConsent</Heading>
      <Text mb={3}>When eConsent is enabled, participants are required to provide their consent using their email address or phone number before they can join the trial.</Text>
      <FormControl display='flex' alignItems='center'>
        <FormLabel htmlFor='enable' mb='0'>
          Enable eConsent
        </FormLabel>
        <Switch id='enable' isChecked={trial?.eConsentEnabled} onChange={e => enable(e.target.checked)} />
      </FormControl>

      <Alert colorScheme='gray' variant='left-accent' mt={5}>
        <Box>
          <Heading as='h3' size='sm'>About eConsent Privacy</Heading>
          <Text>The eConsent process collects personal data from participants. This data is processed and stored securely by Trialflare. However, as data controller, you must configure a Privacy statement (<Link as={RouterLink} to={`/trials/${trial?._id}/info`} color='teal'>in the "About" section</Link>) to inform participants about how their data will be used and safeguarded. Participants will be asked to agree to this statement before they can complete their consent.</Text>
        </Box>
      </Alert>
      
      <Tabs mt={10}>
        <TabList>
          <Tab>Participant Information Sheet</Tab>
          <Tab>Eligibility</Tab>
          <Tab>Conditions</Tab>
          <Tab>Settings</Tab>
          <Tab>Consents</Tab>
        </TabList>
        
        <TabPanels>
          <TabPanel>
            <Heading as='h3' size='sm'>Participant Information Sheet (PIS)</Heading>
            <Text mb={3}>In the box below, write the text that you'd like participants to read and consent to before joining this trial.</Text>
            {showEditor &&
              <RichText value={consentDocument} onChange={e => setConsentDocument(e)} forType='trial' forId={trial?._id} />
            }
          </TabPanel>
          
          <TabPanel>
            <Heading as='h3' size='sm'>Eligibility</Heading>
            <Text mb={3}>In the box below, write any eligbility criteria that participants must comply with in order to take part in this trial.</Text>
            {showEditor &&
              <RichText value={eligibility} onChange={e => setEligibility(e)} forType='trial' forId={trial?._id} />
            }
          </TabPanel>
          
          <TabPanel>
            <Heading as='h3' size='sm'>Conditions</Heading>
            <Text mb={3}>Use this section to add any conditions that participants must agree to in order to join the trial.</Text>
            
            {conditions?.length > 0 &&
              <Table>
                <Thead>
                  <Tr>
                    <Th>Title</Th>
                    <Th>Description</Th>
                    <Th />
                  </Tr>
                </Thead>
                <Tbody>
                  {conditions?.map((condition, i) =>
                    <Tr key={condition.id}>
                      <Td><Input placeholder='Condition title'
                      value={condition.title}
                      onChange={e => {
                        const newConditions = Object.assign([], conditions);
                        conditions[i].title = e.target.value;
                        setConditions(newConditions);
                      }} /></Td>
                      <Td><Textarea placeholder='Condition description'
                      value={condition.description}
                      onChange={e => {
                        const newConditions = Object.assign([], conditions);
                        conditions[i].description = e.target.value;
                        setConditions(newConditions);
                      }} /></Td>
                      <Td>
                        <ConfirmButton closeOnBlur
                          trigger={<Button variant='ghost'><DeleteIcon /></Button>}
                          header='Really delete this condition?'
                          footer='You will be able to re-add it by adding a new condition.'
                          action={() => {
                            const newConditions = Object.assign([], conditions).filter(c => c.id != condition.id);
                            setConditions(newConditions);
                          }}
                          actionColor='red'
                        />
                       
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            }
            
            <Button colorScheme='primary' variant='outline' onClick={() => {
              const newConditions = Object.assign([], conditions);
              newConditions.push({ id: uuid.v4(), title: '', description: ''});
              setConditions(newConditions);
            }}><AddIcon mr={2} /> Add a condition</Button>
          </TabPanel>
          
          <TabPanel>
            <Heading as='h3' size='sm'>Notification email</Heading>
            <Text mb={3}>Optionally provide an email address to which all signed consent forms will be sent.</Text>
            <FormControl>
              <Input id='email' value={email} onChange={e => setEmail(e.target.value)} placeholder='jane@example.com' />
            </FormControl>
            
            <Heading mt={10} as='h3' size='sm'>Post-consent information</Heading>
            <Text mb={3}>Optionally write some text that will be sent to the consenting participant via an SMS (and email, if provided) once they have given their consent.</Text>
            <FormControl>
              <Textarea id='info' value={postConsentText} onChange={e => setPostConsentText(e.target.value)} placeholder='Thank you for providing your eConsent. The next steps are...' />
              <FormHelperText color={postConsentText?.length > 400 ? 'red' : null}>{postConsentText?.length || 0}/400 characters</FormHelperText>
            </FormControl>
          </TabPanel>
          
          <TabPanel>
            <Heading as='h3' size='sm'>Recorded consents</Heading>
            <Text mb={3}>Find below the currently-recorded consent documents submitted by participants for this trial.</Text>
            
            {consents?.length === 0 &&
              <EmptyBox title='No consents yet' description='eConsent documents will show here once they are submitted.' />
            }
            {consents?.length > 0 &&
              <Table>
                <Thead>
                  <Tr>
                    <Th>Consented at</Th>
                    <Th>Document version</Th>
                    <Th>Phone</Th>
                    <Th>Email</Th>
                    <Th />
                  </Tr>
                </Thead>
                <Tbody>
                  {consents?.map((consent, i) =>
                    <Tr key={consent.id}>
                      <Td>{moment(consent.createdAt).format('DD/MM/YYYY HH:mm')}</Td>
                      <Td>{activeConsentDocument?._id === consent.consentDocument ?
                        <Text><CheckCircleIcon mr={2} /> Current version</Text>
                      : <Text><WarningIcon mr={2} /> Older version</Text>
                      }</Td>
                      <Td>{consent.phone}</Td>
                      <Td>{consent.email}</Td>
                      <Td>
                        {consent.fileUrl &&
                          <Button as='a' target='_blank' href={consent.fileUrl} size='xs' colorScheme='primary' variant='outline'><DownloadIcon mr={2} /> Download</Button>
                        }
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            }
          </TabPanel>
        </TabPanels>
      </Tabs>      
      
      <Box mt={10} ml={10} mr={10} position='sticky' style={{bottom: 10}} boxShadow='lg' p={3} rounded='md' bg='primary.50'>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          {error ?
            <Alert mr={3} status='error' variant='left-accent'>
              <Box>
                <AlertTitle>There was a problem saving your trial details</AlertTitle>
                <AlertDescription>{error}. Please fix these issues and try again.</AlertDescription>
              </Box>
            </Alert>
          : <Box />
          }
          
          <ConfirmButton closeOnBlur
            trigger={<Button colorScheme='primary' isLoading={savingDocument}>Save eConsent settings</Button>}
            header={<Box>
              <Text>Ready to update the currently-active eConsent document?</Text>
              {activeConsentDocument?.consentCount > 0 && <Alert mt={3} status='warning' size='sm' variant='left-accent'>The currently-active consent document has {activeConsentDocument?.consentCount} consents already made against it and participants may have already joined on this basis. Are you sure you want to continue?</Alert>}
            </Box>}
            footer={<Box>
              <Text>A new "eConsent version" is created each time you save this page. We strongly recommend that all changes are finalised before participants begin to consent or join this trial.</Text>
            </Box>}
            action={saveDocument}
            actionColor='primary'
          />
        </Box>
      </Box>
    </>
  );
}
