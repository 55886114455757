import React, { useState, useEffect } from 'react';
import { useToast, Box, Button, Heading, Input, Badge, Tabs, TabList, Tab, TabPanels, TabPanel, Table, Thead, Tbody, Th, Tr, Td } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import moment from 'moment';
import api from '../../api';
import useStore from '../../store';

export default function Root() {
  const [newTeamName, setNewTeamName] = useState('');
  const [newTeamCode, setNewTeamCode] = useState('');
  const [teams, setTeams] = useState([]);
  const [users, setUsers] = useState([]);
  const toast = useToast();
  const navigate = useNavigate();
  const { user, updateUser, setTeam } = useStore();

  useEffect(() => {
    api.root.getTeams(({ teams }) => setTeams(teams));
    api.root.getUsers(({ users }) => setUsers(users));
  }, []);

  function createTeam() {
    api.teams.create({ code: newTeamCode, name: newTeamName }, newTeam => {
      const newTeams = Object.assign([], teams);
      newTeams.push(newTeam);
      setTeams(newTeams);
      navigate(`/root/teams/${newTeam._id}/edit`);
    }, err => toast({title: 'Unable to create team', description:err.message, status:'error'}));
  }
  function switchTeam(teamId) {
    api.users.updateTeam(user._id, teamId, () => {
      updateUser(user._id, { team: teamId });
    }, err => toast({title: 'Unable to switch team', description:err.message, status:'error'}));
  }

  function testSentry() {
    const x = {};
    console.log(x.y.z);
  }

  return (
    <Box>
      <Tabs>
        <TabList>
          <Tab>Teams</Tab>
          <Tab>Users</Tab>
          <Tab>Settings</Tab>
        </TabList>

        <TabPanels pt={5}>
          <TabPanel>
            <Box p={3} rounded='md' borderWidth='1px'>
              <Heading as='h3' size='md'>Create new team</Heading>
              <Input mt={5}placeholder='Team code' value={newTeamCode} onChange={e => setNewTeamCode(e.target.value)} />
              <Input mt={3}placeholder='Team name' value={newTeamName} onChange={e => setNewTeamName(e.target.value)} />
              <Button mt={3} colorScheme='primary' onClick={createTeam}>Create team</Button>
            </Box>

            <Box p={3} rounded='md' borderWidth='1px' mt={15}>
              <Heading as='h3' size='md' mb={5}>Existing teams</Heading>
              <Table size='sm'>
                <Thead>
                  <Tr>
                    <Th>Team name</Th>
                    <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {teams.map(t =>
                    <Tr key={t._id}>
                      <Td>{t.name}</Td>
                      <Td collapsing>{user.team !== t._id ?
                        <Button size='xs' colorScheme='blue' onClick={e => switchTeam(t._id)}>Switch</Button>
                      :
                        <Badge>You're in this team</Badge>
                      }
                        <Button ml={2} size='xs' as={Link} to={`/root/teams/${t._id}/edit`}>Edit</Button>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
            </Box>
          </TabPanel>

          <TabPanel>
            <Table size='sm'>
              <Thead>
                <Tr>
                  <Th>Email</Th>
                  <Th>Name</Th>
                  <Th>Team</Th>
                  <Th>Last seen</Th>
                </Tr>
              </Thead>
              <Tbody>
                {users.map(u =>
                  <Tr key={u._id}>
                    <Td>{u.email}</Td>
                    <Td>{u.firstName} {u.lastName}</Td>
                    <Td>{u.ownerTeam?.name}</Td>
                    <Td>{u.lastSeenAt ? moment(u.lastSeenAt).fromNow() : <Badge>Never</Badge>}</Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TabPanel>

          <TabPanel>
            <Button onClick={testSentry}>Test Sentry</Button>
          </TabPanel>
        </TabPanels>
      </Tabs>

    </Box>
  );
}
