import api from '.';

export const dataTypes = {
  get(id, success, fail) {
    api.authenticatedRequest('GET', `/dataTypes/${id}`, null, success, fail);
  },
  update(id, data, success, fail) {
    api.authenticatedRequest('PUT', `/dataTypes/${id}`, data, success, fail);
  },
  delete(id, success, fail) {
    api.authenticatedRequest('DELETE', `/dataTypes/${id}`, null, success, fail);
  },
};
