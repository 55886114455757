import React, { useState, useEffect } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { ChromePicker } from 'react-color';

import SimplePopover from './SimplePopover';

export default function ColourChooser({ value, onChange }) {
  const [colour, setColour] = useState();

  useEffect(() => {
    setColour(value || '#000000');
  }, [value]);

  return (
    <>
      <SimplePopover header='Choose a colour'
        trigger={<Button colorScheme='primary' size='sm'>Choose a colour</Button>}
        body={
          <Box display='flex' alignItems='center' flexDirection='column'>
            <ChromePicker color={colour} onChange={({ hex }) => setColour(hex)}/>
            <Button mt={5} size='sm' w='100%' colorScheme='primary' onClick={() => onChange(colour)}>Save</Button>
          </Box>
        }
      />
    </>
  );
}
