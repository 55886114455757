import React from 'react'
import { Box, Heading, Button, Popover, PopoverTrigger, PopoverContent, Icon } from '@chakra-ui/react'
import { FaBars } from 'react-icons/fa'

export default function VerticalNavSystem ({ title, image, nav, body }) {
  return (
    <Box display={{ base: 'inherit', md: 'flex' }}>
      <Box w='200px' bg='gray.100' minH='100vh' display={{ base: 'none', md: 'block' }}>
        {image
          ? (
            <Box
              position='relative' w='100%' h='200px'
              style={{ backgroundImage: `url(${image})`, backgroundSize: 'cover', backgroundPosition: 'center center' }}
            >
              <Box
                bg='linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.6))' h='100%' w='100%'
                display='flex' justifyContent='end' flexDirection='column'
              >
                <Heading m={3} size='md' mb={4} color='white'>{title}</Heading>
              </Box>
            </Box>
            )
          : <Heading m={3} size='md' mb={4}>{title}</Heading>}
        {nav}
      </Box>
      <Box p={5} flex={1}>
        <Box display={{ base: 'flex', md: 'none' }} justifyContent='space-between'>
          <Heading size='lg' mb={4}>{title}</Heading>
          <Popover>
            <PopoverTrigger>
              <Button size='sm'><Icon as={FaBars} mr={2} /> Menu</Button>
            </PopoverTrigger>
            <PopoverContent bg='gray.50'>
              {nav}
            </PopoverContent>
          </Popover>
        </Box>

        {body}
      </Box>
    </Box>
  )
}
