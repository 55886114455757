import React, { useRef } from 'react';
import { Box,  Heading, Radio } from '@chakra-ui/react';

function RadioHeader({ name, value, checked, onChange, title, content }) {
  const input = useRef(null);
  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <Box mb={3}>
        <Radio ref={input} size="lg" isChecked={checked} name={name} value={value} onChange={onChange}/>
      </Box>
      <Box textAlign='center' flex="1" onClick={e => input?.current?.click()}>
        <Heading cursor='pointer' as='h4' size='md'>{title}</Heading>
        {content}
      </Box>
    </Box>
  );

}

export default RadioHeader;