import React, { useState, useRef, useEffect } from 'react';
import { useToast, Badge, Heading, Text, Box, Button, Icon, Input, Textarea, Alert, AlertTitle, AlertDescription } from '@chakra-ui/react';
import { ArrowLeftIcon } from '@chakra-ui/icons';
import { Link, useParams, useNavigate } from 'react-router-dom';
import api from '../../api';
import utils from '../../utils/utils';
import useStore from '../../store';
import useTitle from '../../hooks/useTitle';

import FileChooser from '../includes/FileChooser';

export default function NewSite({ }){
  const [site, setSite] = useState();
  const [error, setError] = useState();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [address, setAddress] = useState('');
  const toast = useToast();
  const { id, siteId } = useParams();
  const { user, trial, updateTrial } = useStore();
  useTitle('Edit Site', trial);
  const navigate = useNavigate();
  const isEditing = !!site?._id;

  useEffect(() => {
    if (!siteId) return;
    api.sites.get(siteId, d => {
      setSite(d);
      if (d.name) setName(d.name);
      if (d.description) setDescription(d.description);
      if (d.image) setImage(d.image);
      if (d.imageUrl) setImageUrl(d.imageUrl);
      if (d.contactName) setContactName(d.contactName);
      if (d.contactEmail) setContactEmail(d.contactEmail);
      if (d.address) setAddress(d.address);
    });
  }, [siteId]);
  
  function create() {
    const data = { name, description, image, contactName, contactEmail: contactEmail ? contactEmail : null, address };
    if (isEditing) {
      api.sites.update(siteId, data, updatedSite => {
        toast({title:'Site updated', status:'success'});
        navigate(`/trials/${trial._id}/sites`);
      }, err => setError(err.message));
    } else {
      api.sites.create(id, data, newSite => {
        updateTrial(trial._id, { siteCount: (trial.siteCount ?? 0) + 1 });
        toast({title: 'Site created', status: 'success'});
        navigate(`/trials/${trial._id}/sites`);
      }, err => setError(err.message));
    }
  }
  
  function updateImage(data) {
    if (data) {
      setImage(data.storedName);
      setImageUrl(data.expectedUrl);
    }
    else {
      setImage(null);
      setImageUrl(null);
    }
  }

  return (
    <>
      <Button mb={5} as={Link} to={`/trials/${trial._id}/sites`} variant='link' colorScheme='primary'><ArrowLeftIcon mr={3} /> Back to sites</Button>
      <Heading as='h2' size='lg'>{isEditing ? `Edit site ${site?.name}` : `Create a new site`}</Heading>
      
      <Box p={3} bg='gray.50' rounded='md' mt={10}>
        <Heading size='md'>About your site</Heading>
        <Heading mt={5} size='sm' as='h4'>Name</Heading>
        <Text color='gray.500'>Give your site a unique name.</Text>
        <Input bg='white' mt={1} placeholder='My Site' value={name} onChange={e => setName(e.target.value)}/>
        
        <Heading mt={5} size='sm' as='h4'>Description</Heading>
        <Text color='gray.500'>Optional. Briefly describe the site.</Text>
        <Textarea bg='white' mt={1} placeholder='Write a short description...' value={description} onChange={e => setDescription(e.target.value)}/>
        
        <Heading mt={5} size='sm' as='h4'>Site logo or image</Heading>
        {imageUrl ?
          <img src={imageUrl} style={{width: 200, marginTop: 15}} />
        :
          <Alert mt={5} variant='left-accent'>No image is currently set.</Alert>
        }
        <Box display='flex' mt={5}>
          <FileChooser accept='image/*'
            forType='trial' forObject={trial}
            onComplete={updateImage}
            trigger={<Button colorScheme='blue'>Choose an image</Button>}
          />
          {image &&
            <Button ml={2} onClick={e => updateImage({ storedName: null })}>Remove image</Button>
          }
        </Box>
      </Box>
      
      <Box p={3} bg='gray.50' rounded='md' mt={10}>
        <Heading size='md'>Contact information</Heading>
        <Text>This information will allow participants to know where to find the site and who to contact if they get stuck.</Text>
        
        <Heading mt={5} size='sm' as='h4'>Key contact name</Heading>
        <Text color='gray.500'>Recommended. The name of a person representing the site for this trial.</Text>
        <Input bg='white' mt={1} placeholder='Jane Smith' value={contactName} onChange={e => setContactName(e.target.value)}/>
        
        <Heading mt={5} size='sm' as='h4'>Key contact email</Heading>
        <Text color='gray.500'>Recommended. An email address for the person representing the site for this trial.</Text>
        <Input bg='white' mt={1} placeholder='jane@company.com' value={contactEmail} onChange={e => setContactEmail(e.target.value)}/>
        
        <Heading mt={5} size='sm' as='h4'>Site address</Heading>
        <Text color='gray.500'>Recommended. A physical address to allow participants to find the site.</Text>
        <Textarea bg='white' mt={1} placeholder='Enter the site address...' value={address} onChange={e => setAddress(e.target.value)}/>
      </Box>
      
      
      <Box mt={10} ml={10} mr={10} position='sticky' style={{bottom: 10}} boxShadow='lg' p={3} rounded='md' bg='primary.50'>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          {error ?
            <Alert mr={3} status='error' variant='left-accent'>
              <Box>
                <AlertTitle>There was a problem saving your site</AlertTitle>
                <AlertDescription>{error}. Please fix these issues and try again.</AlertDescription>
              </Box>
            </Alert>
          : <Box />
          }
          <Button size='lg' colorScheme='primary' onClick={create}>{isEditing ? 'Save site' : 'Create site'}</Button>
        </Box>
      </Box>
    </>
  );
}

