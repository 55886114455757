import React, { useState, useEffect } from 'react';
import { useToast, Heading, Box, Icon, Text, Button, Input, InputGroup, InputRightElement, Checkbox, Popover, PopoverContent, PopoverTrigger, PopoverHeader, PopoverBody, Menu, MenuButton, MenuList, MenuItem, MenuGroup, MenuDivider, Progress, CircularProgress, Badge, CloseButton, Tag, TagRightIcon, TagLabel, Table, Thead, Tbody, Tr, Th, Td, Tabs, TabList, Tab, TabPanels, TabPanel, Select, Tooltip } from '@chakra-ui/react';
import { CheckIcon, CheckCircleIcon, EditIcon, AddIcon, ArrowBackIcon, ArrowForwardIcon, ChevronDownIcon, DownloadIcon, InfoIcon } from '@chakra-ui/icons';
import { FaTable, FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import api from '../../api';
import utils from '../../utils/utils';
import useStore from '../../store';
import useTitle from '../../hooks/useTitle';

import Empty from '../../images/empty.png';
import EmptyBox from '../includes/EmptyBox';
import ConfirmButton from '../includes/ConfirmButton';
import ParticipantCreator from '../includes/ParticipantCreator';
import GroupCreator from '../includes/GroupCreator';

export default function Participants({ t }){
  const [participants, setParticipants] = useState([]);
  const [groups, setGroups] = useState([]);
  const [sites, setSites] = useState([]);
  const [selectionParticipants, setSelectionParticipants] = useState([]);
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('asc');
  const [total, setTotal] = useState(0);
  const [nextPageAvailable, setNextPageAvailable] = useState(false);
  const [prevPageAvailable, setPrevPageAvailable] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedSite, setSelectedSite] = useState();
  const toast = useToast();
  const { id } = useParams();
  const { user, trial, updateTrial } = useStore();
  useTitle('Participants', trial);

  useEffect(fetchParticipants, [trial && trial._id, page, searchTerm, selectedGroup?._id, selectedSite?._id, sort, sortOrder]);

  function fetchParticipants() {
    setLoading(true);
    api.trials.getParticipants(trial._id, page, searchTerm, selectedGroup?._id, selectedSite?._id, false, sort, sortOrder, ({ participants, nextPageAvailable, prevPageAvailable, total }) => {
      setLoading(false);
      setParticipants(participants);
      setTotal(total);
      setNextPageAvailable(nextPageAvailable);
      setPrevPageAvailable(prevPageAvailable);
    }, err => setLoading(false));
    api.trials.getGroups(trial._id, ({ groups }) => setGroups(groups));
    api.sites.getForTrial(trial._id, ({ sites }) => setSites(sites));
  }

  function addToGroup(id, groupId) {
    api.groups.addMember(groupId, id, ({ groups }) => {
      const newParticipants = Object.assign([], participants).map(p => {
        if (p._id === id) p.groups = groups;
        return p;
      });
      setParticipants(newParticipants);
    }, err => toast({title: 'Unable to add participant to group', description: err.message, status:'error'}));
  }
  function removeFromGroup(id, groupId) {
    api.groups.removeMember(groupId, id, ({ groups }) => {
      const newParticipants = Object.assign([], participants).map(p => {
        if (p._id === id) p.groups = groups;
        return p;
      });
      setParticipants(newParticipants);
    }, err => toast({title: 'Unable to remove participant from group', description: err.message, status: 'error'}));
  }

  function addToSite(id, siteId) {
    api.sites.addMember(siteId, id, ({ sites }) => {
      const newParticipants = Object.assign([], participants).map(p => {
        if (p._id === id) p.sites = sites;
        return p;
      });
      setParticipants(newParticipants);
    }, err => toast({title: 'Unable to add participant to site', description: err.message, status:'error'}));
  }
  function removeFromSite(id, siteId) {
    api.sites.removeMember(siteId, id, ({ sites }) => {
      const newParticipants = Object.assign([], participants).map(p => {
        if (p._id === id) p.sites = sites;
        return p;
      });
      setParticipants(newParticipants);
    }, err => toast({title: 'Unable to remove participant from site', description: err.message, status: 'error'}));
  }

  function deleteParticipant(id) {
    api.trials.deleteParticipant(trial._id, id, () => {
      toast({title: 'Participant removed', status: 'success'});
      const newParticipants = Object.assign([], participants).filter(p => p._id !== id);
      setParticipants(newParticipants);
      setSelectionParticipants([]);
      updateTrial(trial._id, { participantCount: trial.participantCount - 1 });
    }, err => toast({title: 'Unable to delete participant', description: err.message, status:'error'}));
  }

  function bulkDeleteParticipants() {
    const really = window.confirm('Really delete all of the selected participants?');
    if (!really) return;
    const ids = selectionParticipants;
    if (!ids || !ids.length) alert('No participants selected');
    api.trials.deleteParticipants(trial._id, { participants: ids }, () => {
      toast({title: 'Participants removed', status: 'success'});
      const newParticipants = Object.assign([], participants).filter(p => ids.indexOf(p._id) === -1);
      setParticipants(newParticipants);
      setSelectionParticipants([]);
      updateTrial(trial._id, { participantCount: trial.participantCount - ids.length });
    }, err => toast({title: 'Unable to delete participants', description: err.message, status: 'error'}));
  }
  function bulkNotifyParticipants() {
    const ids = selectionParticipants;
    if (!ids || !ids.length) alert('No participants selected');
    const message = window.prompt(`Write a message to send to these ${ids.length} participants' devices.`);
    if (message) {
      api.trials.createParticipantNotifications(trial._id, { message, participants: ids }, () => {
        toast({title: 'Notifications sent', status: 'success'});
      }, err => toast({title: 'Unable to notify participants', description: err.message, status: 'error'}));
    };
  }

  function exportCSV() {
    setExporting(true);
    api.trials.getParticipantsCsv(trial._id, ({ csv }) => {
      utils.downloadFile(`data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`, `${trial.name.replace(/ /g, '_')}_participants.csv`);
      toast({status:'success', title:'CSV file generated successfully', description: 'It should now be in your downloads folder.'});
      setExporting(false);
    }, err => {
      toast({status:'error', title:err.message});
      setExporting(false);
    });
  }

  function toggleSelected(id) {
    const newSelectionParticipants = Object.assign([], selectionParticipants);
    const index = newSelectionParticipants.indexOf(id);
    if (index === -1) newSelectionParticipants.push(id);
    if (index > -1) newSelectionParticipants.splice(index, 1);
    setSelectionParticipants(newSelectionParticipants);
  }

  participants.forEach(p => {
    p.groupObjects = groups?.filter(g => p.groups && p.groups.indexOf(g._id) > -1);
    p.siteObjects = sites?.filter(s => p?.sites?.indexOf(s._id) > -1);
  });
  const filteredParticipants = participants;

  return (
    <>
      <Heading as='h2' size='lg'>Trial participants</Heading>

      <Box mb={5} mt={5} display='flex' justifyContent='space-between'>
        <Box display='flex' alignItems='center'>
          <Input width={180} size='sm' value={searchTerm} onChange={e => setSearchTerm(e.target.value)} placeholder='Search participants...' />
          {sites?.length > 0 &&
            <Menu>
              <MenuButton ml={2} as={Button} size='sm' rightIcon={<ChevronDownIcon />}>{selectedSite ? selectedSite.name : 'All sites'}</MenuButton>
              <MenuList>
                <MenuItem onClick={() => setSelectedSite(null)}>All sites</MenuItem>
                {sites?.map(site =>
                  <MenuItem key={site._id} onClick={() => setSelectedSite(site)}>{site.name}</MenuItem>
                )}
              </MenuList>
            </Menu>
          }

          {(groups?.length || utils.canManageTrial(user, trial)) &&
            <Menu>
              <MenuButton ml={2} as={Button} size='sm' bg={selectedGroup ? selectedGroup.colour : null} rightIcon={<ChevronDownIcon />}>{selectedGroup ? selectedGroup.name : 'All groups'}</MenuButton>
              <MenuList>
                <MenuItem onClick={() => setSelectedGroup(null)}>All groups</MenuItem>
                {groups?.map(group =>
                  <MenuItem bg={group.colour} key={group._id} onClick={() => setSelectedGroup(group)}>
                    {group.name}
                    {utils.canManageTrial(user, trial) &&
                      <GroupCreator
                        trigger={<Button size='xs' ml={5}>Edit</Button>}
                        trial={trial}
                        group={group}
                        onUpdated={newGroup => {
                          const newGroups = Object.assign([], groups).map(g => {
                            if (g._id === newGroup._id) return newGroup;
                            return g;
                          });
                          if (selectedGroup?._id === newGroup?._id) setSelectedGroup(newGroup);
                          setGroups(newGroups);
                        }}
                        onDeleted={oldGroupId => {
                          const newGroups = groups.filter(g => g._id !== oldGroupId);
                          setGroups(newGroups);
                          setSelectedGroup(null);
                        }}
                      />
                    }
                  </MenuItem>
                )}
                <MenuDivider />
                {utils.canManageTrial(user, trial) &&
                  <GroupCreator
                    trigger={<MenuItem>Create a new group</MenuItem>}
                    trial={trial}
                    onCreated={newGroup => {
                      const newGroups = Object.assign([], groups);
                      newGroups.push(newGroup);
                      setGroups(newGroups);
                    }}
                  />
                }
              </MenuList>
            </Menu>
          }
        </Box>
      </Box>

      {loading && !total &&
        <Box textAlign='center'><CircularProgress isIndeterminate /></Box>
      }
      
      <Box mt={15}>
        <Tabs isLazy>
            <TabList justifyContent='space-between' alignItems='center'>
              <Box display='flex'>
                <Tab><Icon as={FaTable} mr={2} /> Table</Tab>
                <Box alignItems='center' display='flex'>
                  {prevPageAvailable && <Button ml={2} size='xs' onClick={e => setPage(page - 1)}><ArrowBackIcon mr={2} /> Previous page</Button>}
                  {nextPageAvailable && <Button ml={2} size='xs' onClick={e => setPage(page + 1)}>Next page <ArrowForwardIcon ml={2} /></Button>}
                </Box>
              </Box>
              <Box display='flex' alignItems='center'>
                {utils.canManageTrial(user, trial) &&
                  <ParticipantCreator
                    trigger={<Button size='xs' colorScheme='blue'><AddIcon mr={2} /> Register participants</Button>}
                    trial={trial}
                    groups={groups}
                    onCreated={registeredParticipants => {
                      const newParticipants = Object.assign([], participants);
                      registeredParticipants.forEach(p => newParticipants.push(p));
                      setParticipants(newParticipants);
                      updateTrial(trial._id, { participantCount: (trial.participantCount ?? 0) + (registeredParticipants?.length || 0) });
                    }}
                  />
                }
                {utils.canReadTrial(user, trial) &&
                  <Button ml={2} size='xs' colorScheme='blue' variant='outline' onClick={exportCSV} isLoading={exporting}><DownloadIcon mr={2} /> Export</Button>
                }
                <Badge ml={2} colorScheme='blue'>{total} total participants</Badge>
              </Box>
            </TabList>
            {loading ? <Progress isIndeterminate size='sm'/> : <Box h={2} />}
            
            {!loading && !participants.length && !searchTerm &&
              <EmptyBox image={Empty} title='No participants were found' description='Participants will appear here once they join the trial' action={utils.canManageTrial(user, trial) && <Button colorScheme='primary' as={Link} to={`/trials/${trial._id}/manage`}>See instructions</Button>} />
            }
            {!loading && !participants.length && searchTerm &&
              <EmptyBox title='No participants match your search' description='Change your search terms and try again.' />
            }

            {participants && participants.length > 0 &&
              <TabPanels>

                <TabPanel>
                  <Table size='sm'>
                    <Thead>
                      <Tr>
                        <Box pt={2}>
                          <Menu>
                            <MenuButton as={Button} size='xs' ml={3}>{selectionParticipants.length} <ChevronDownIcon /></MenuButton>
                            <MenuList placement='right'>
                              <MenuGroup title='Selection options'>
                                <MenuItem onClick={() => {
                                  const newSelected = Object.assign([], selectionParticipants);
                                  filteredParticipants?.forEach(p => {
                                    if (newSelected.indexOf(p._id) === -1) newSelected.push(p._id);
                                  });
                                  setSelectionParticipants(newSelected);
                                }}>Select all on page</MenuItem>
                                {selectionParticipants?.length > 0 &&
                                  <MenuItem onClick={() => setSelectionParticipants([])}>De-select all</MenuItem>
                                }
                              </MenuGroup>
                              {selectionParticipants?.length > 0 &&
                                <MenuGroup title='Options'>
                                  <MenuItem onClick={bulkNotifyParticipants}>Send push notification</MenuItem>
                                  <MenuItem onClick={bulkDeleteParticipants}>Delete participants</MenuItem>
                                </MenuGroup>
                              }
                            </MenuList>
                          </Menu>
                        </Box>
                        <SortableTh sortKey='id' content='ID' sort={sort} sortOrder={sortOrder} setSort={setSort} setSortOrder={setSortOrder} />
                        <Th>Status</Th>
                        <SortableTh sortKey='lastSeenAt' content='Last seen' sort={sort} sortOrder={sortOrder} setSort={setSort} setSortOrder={setSortOrder} />
                        <Th>Sites</Th>
                        <Th>Groups</Th>
                        <Th />
                      </Tr>
                    </Thead>
                    <Tbody>
                    {filteredParticipants.map(p =>
                      <Tr key={p._id} style={{marginBottom: 5}}>
                        <Td style={{textAlign:'center'}}>
                          <Checkbox isChecked={selectionParticipants.indexOf(p._id) > -1} onChange={e => toggleSelected(p._id)}/>
                        </Td>
                        <Td>
                          <Button as={Link} colorScheme='primary' size='xs' to={`/trials/${trial._id}/participants/${p._id}`}>{p.id}</Button>
                        </Td>
                        <Td>
                          {p.pushAvailable && <Badge colorScheme='purple' variant='subtle' fontSize='xs' mr={1} mb={1}>
                            Push
                          </Badge>}
                          {p.lastSeenAt && <Badge colorScheme='green' variant='subtle' fontSize='xs' mr={1} mb={1}>Joined</Badge>}
                          {p.connectedServices?.map(service =>
                            <Badge key={service.name} colorScheme='blue' variant='subtle' fontSize='xs' mr={1} mb={1}>
                              {service.name}
                            </Badge>
                          )}
                        </Td>
                        <Td>
                          <small>{p.lastSeenAt && moment.utc(p.lastSeenAt).fromNow()}<br />
                          {p.lastLocation && <span><small>{p.lastLocation.city}, {p.lastLocation.country_name}</small></span>}</small>
                        </Td>
                        <Td>
                          {p.siteObjects.map(s =>
                            <Tag size='sm' key={s._id} variant='subtle' mr={1} mb={1}>
                              <TagLabel cursor='pointer' onClick={() => setSelectedSite(s)}>{s.name}</TagLabel>
                              {utils.canManageTrial(user, trial) && groups?.length > 0 &&
                                <TagRightIcon boxSize='12px' as={CloseButton} onClick={e => removeFromSite(p._id, s._id)}/>
                              }
                            </Tag>
                        
                          )}
                          {utils.canManageTrial(user, trial) && sites?.length > 0 &&
                            <Menu>
                              <MenuButton as={Button} size='xs' colorScheme='blue' variant='ghost'><AddIcon mr={2} /> Add</MenuButton>
                              <MenuList>
                                {sites.map(s =>
                                  <MenuItem key={s._id} onClick={e => addToSite(p._id, s._id)}>{s.name}</MenuItem>
                                )}
                              </MenuList>
                            </Menu>
                          }
                        </Td>
                        <Td>
                          {p.groupObjects.map(g =>
                            <Tag size='sm' key={g._id} variant='subtle' bg={g.colour} mr={1} mb={1}>
                              <TagLabel cursor='pointer' onClick={() => setSelectedGroup(g)}>{g.name}</TagLabel>
                              {utils.canManageTrial(user, trial) && groups?.length > 0 &&
                                <TagRightIcon boxSize='12px' as={CloseButton} onClick={e => removeFromGroup(p._id, g._id)}/>
                              }
                            </Tag>

                          )}
                          {utils.canManageTrial(user, trial) && groups?.length > 0 &&
                            <Menu>
                              <MenuButton as={Button} size='xs' colorScheme='blue' variant='ghost'><AddIcon mr={2} /> Add</MenuButton>
                              <MenuList>
                                {groups.map(g =>
                                  <MenuItem key={g._id} onClick={e => addToGroup(p._id, g._id)}>{g.name}</MenuItem>
                                )}
                              </MenuList>
                            </Menu>
                          }
                        </Td>
                        <Td>
                          {utils.canManageTrial(user, trial) &&
                            <ConfirmButton
                              header='Really delete this participant?'
                              body='Associated data across all stages will also be removed.'
                              trigger={<Button size='xs' colorScheme='red' variant='outline'>Delete</Button>}
                              action={e => deleteParticipant(p._id)}
                            />
                          }
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </TabPanel>
            </TabPanels>
          }
        </Tabs>

      </Box>
    </>
  );
}

function SortableTh({ sortKey, content, sort, sortOrder, setSort, setSortOrder }) {
  const icon = sort === sortKey ? (
    sortOrder === 'asc' ? FaSortUp : FaSortDown
  ) : FaSort;
  return (
    <Th cursor='pointer'>
      <Box display='flex' alignItems='center' justifyContent='space-between'
        onClick={e => {
          if (sort === sortKey) setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
          else setSort(sortKey);
        }}
        >
        <Text color={sortKey === sort ? 'blue.400' : 'black'}>{content}</Text>
        <Icon color='gray.500' as={icon} />
      </Box>
    </Th>
  )
}
