import React from 'react';
import { FormControl, FormLabel, Input, Textarea, Switch, Select, FormHelperText, Badge } from '@chakra-ui/react';
import DatePicker from 'react-datepicker';

export default function FormField({ fieldProps, label, type, value, isChecked, options, onChange, helper, required, placeholder }) {

  function getInput() {
    if (type === 'textarea') return (<Textarea bg='white' value={value} onChange={onChange} placeholder={placeholder} />);
    if (type === 'switch') return (<Switch isChecked={isChecked} onChange={onChange} />);
    if (type === 'select') return (<Select bg='white' value={value} onChange={onChange} placeholder={placeholder}>
      {options.map(option => <option key={option.value} value={option.value}>{option.label}</option>)}
    </Select>);
    if (type === 'date') return (
      <DatePicker placeholderText='Tap to select a date...'
        showMonthDropdown showYearDropdown
        dropdownMode="select" todayButton="Today" dateFormat="dd/MM/yyyy"
        selected={value ? new Date(value) : null} onChange={onChange}
        customInput={<Input bg='white' />}
      />
    )
    else return (<Input bg='white' type={type} value={value} onChange={onChange} placeholder={placeholder} />);
  }

  return (
    <FormControl {...fieldProps}>
      <FormLabel>{label} {required && <Badge colorScheme='yellow' ml={3}>Required</Badge>}</FormLabel>
      {getInput()}
      {helper && <FormHelperText>{helper}</FormHelperText>}
    </FormControl>
  );
}

