import create from 'zustand'
import api from './api';

const useStore = create((set, get) => ({
  token: null,
  userId: null,
  user: null,
  loadingUser: false,
  team: null,
  trial: null,
  
  completeLogin: token => {
    if (!token) return;
    const base64Token = token.split('.')[1].replace('-', '+').replace('_', '/');
    const tokenData = JSON.parse(window.atob(base64Token));
    api.accounts.storeToken(token);
    set({ userId: tokenData.sub, token, loadingUser: true });
    api.users.me(user => set({ loadingUser: false, user }));
  },
  completeLogout: () => set({ token: null, user: null, userId: null, loadingUser: false }),
  
  updateUser: (id, upd) => set(state => {
    return { user: Object.assign({}, { ...state.user}, upd) };
  }),
  
  setTeam: team => set({ team }),
  updateTeam: (upd) => set(state => {
    return { team: Object.assign({}, { ...state.team}, upd) };
  }),
  
  setTrial: trial => set({ trial }),
  updateTrial: (id, upd) => set(state => {
    return { trial: Object.assign({}, { ...state.trial}, upd) };
  }),
  
  participantNotes: [],
  addParticipantNote: note => set(state => {
    const newNotes = Object.assign([], state.participantNotes);
    const index = newNotes?.findIndex(n => n._id === note._id);
    if (index === -1) newNotes.push(note);
    else newNotes[index] = {...newNotes[index], ...note};
    return { participantNotes: newNotes }
  }),
  deleteParticipantNote: noteId => set(state => 
    ({ participantNotes: state.participantNotes?.filter(n => n._id !== noteId) })
  ),
  getParticipantNotesForParticipant: pId => get().participantNotes?.filter(n => n.participant === pId)?.sort((n, n2) => n.createdAt < n2.createdAt),
}));

export default useStore;