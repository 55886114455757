import React, { useState, useEffect } from 'react';
import { useToast, Alert, AlertIcon, Box, Button, InputGroup, InputLeftAddon, InputRightAddon, Input, Heading, Text, Table, Tbody, Tr, Td, NumberInput, NumberInputField } from '@chakra-ui/react';
import api from '../../api';
import useStore from '../../store';
import utils, { TEAM_ROLES } from '../../utils/utils.js';
import FileChooser from '../includes/FileChooser';
import SimpleTable from '../includes/SimpleTable';

function TeamSettings({ }) {
  const [userCreationNotificationEmail, setUserCreationNotificationEmail] = useState('');
  const toast = useToast();
  const { user, team, updateTeam } = useStore();

  useEffect(() => {
    setUserCreationNotificationEmail(team?.userCreationNotificationEmail || '');
  }, [team]);

  function updateDefaultPermissions(newDefaultPermissions) {
    api.teams.update(team._id, { defaultPermissions: newDefaultPermissions }, team => {
      updateTeam(team);
    }, err => toast({title: 'Unable to update team', description: err.message, status: 'error'}));
  }

  function save() {
    const { _id, emailDomain, activeDirectoryTenantId } = team;
    api.teams.update(_id, { emailDomain, activeDirectoryTenantId }, team => {
      updateTeam(team);
      toast({title: 'Team updated', status: 'success'});
    }, err => toast({title: 'Unable to update team', description: err.message, status: 'error'}));
  }

  function updateEmailDomain(e) {
    const newTeam = Object.assign({}, team);
    newTeam.emailDomain = e.target.value;
    updateTeam(newTeam);
  }
  function updateTenantId(e) {
    const newTeam = Object.assign({}, team);
    newTeam.activeDirectoryTenantId = e.target.value;
    updateTeam(newTeam);
  }

  function savePasswordPolicy() {
    const { passwordMinimumLength, passwordMinimumCapitals, passwordMinimumSpecials, passwordMinimumNumbers, allowedLoginAttempts } = team;
    api.teams.update(team._id, {
      passwordMinimumLength: passwordMinimumLength || null,
      passwordMinimumCapitals: passwordMinimumCapitals || null,
      passwordMinimumSpecials: passwordMinimumSpecials || null,
      passwordMinimumNumbers: passwordMinimumNumbers || null,
      allowedLoginAttempts: allowedLoginAttempts || null,
    }, team => {
      updateTeam(team);
      toast({title: 'Policy updated', status: 'success'});
    }, err => toast({title: 'Unable to update team', description: err.message, status: 'error'}));
  }

  function saveUserNotificationEmail() {
    api.teams.update(team._id, { userCreationNotificationEmail: userCreationNotificationEmail || null }, team => {
      updateTeam(team);
      toast({title: 'Notification email updated', status: 'success'});
    }, err => toast({title: 'Unable to update team', description: err.message, status: 'error'}));
  }

  const canEdit = utils.hasPermission(user, 'team.manage', user.team);
  const passwordPolicyFields = [
    {key: 'passwordMinimumLength', name: 'Minimum length'},
    {key: 'passwordMinimumCapitals', name: 'Minimum number of capitals'},
    {key: 'passwordMinimumSpecials', name: 'Minimum number of special characters'},
    {key: 'passwordMinimumNumbers', name: 'Minimum number of numbers'},
  ];
  return (<>
    {utils.hasPermission(user, 'team.manage', team?._id) ? <>
      <Box p={3} rounded='md' borderWidth='1px' m={1}>
        <Heading as='h3' size='md'>Default permissions</Heading>
        <Text color='gray.500'>Choose the permissions that new users in the team start with.</Text>
        <SimpleTable tableProps={{size: 'sm', mt: 2}}
          headers={['Permission', 'Is default']}
          rows={TEAM_ROLES.map(role =>
            [role.description,
            <input type='checkbox' checked={team.defaultPermissions?.includes(role.key)} onChange={e => {
              const newPermissions = e.target.checked ? [...team.defaultPermissions, role.key] : team.defaultPermissions.filter(p => p !== role.key);
              updateTeam({defaultPermissions: newPermissions});
              updateDefaultPermissions(newPermissions);
            }} />
          ]
        )} />
      </Box>

      <Box display={{md:'flex'}}>
        <Box p={3} rounded='md' borderWidth='1px' m={1} mt={5}>
          <Heading as='h3' size='md'>Single sign-on setup</Heading>
          <Text color='gray.500'>If your organisation uses Google Workspace or Microsoft Office365 (through EntraID), your staff can use single sign-on on Trialflare's login page. If you configure the information below, staff can use these single sign-on options without needing to be explicitly invited.</Text>

          <Heading as='h3' size='sm' mt={5}>Email suffix</Heading>
          <Text color='gray.700'>If the email addresses of your staff all end in the same thing (e.g. they all end in @company.com) you can provide this suffix below and we'll automatically add new users with this type of email address to your team when they login for the first time.</Text>
          <Alert colorScheme='grey' variant='left-accent' mt={2}>Only admins can edit this field.</Alert>
          <InputGroup mt={3}>
            <InputLeftAddon children='Add users to my team if their email ends with' />
            <Input readOnly={!(canEdit && utils.hasPermission(user, 'root'))} placeholder='company.com' value={team?.emailDomain || ''} onChange={updateEmailDomain}/>
          </InputGroup>
  
          <Heading as='h3' size='sm' mt={5}>EntraID tenant ID</Heading>
          <Text color='gray.700'>If your organisation uses EntraID to manage staff accounts, you can provide your EntraID tenant ID below and we'll automatically add users in this directory to your team when they login for the first time.</Text>
          <InputGroup mt={3}>
            <InputLeftAddon children='Add users to my team if their EntraID tenant ID is' />
            <Input readOnly={!canEdit} placeholder='ab123456-a12b-1234-a12b-12345abcdef' value={team?.activeDirectoryTenantId || ''} onChange={updateTenantId}/>
          </InputGroup>
  
          <Alert status='info' variant='left-accent' mt={5}><AlertIcon /> If a tenant ID or email suffix is not provided, users will first have to be invited to your team before they can use single sign-on.</Alert>
  
          {canEdit &&
            <Button colorScheme='primary' mt={5} onClick={save}>Save single sign-on configuration</Button>
          }
        </Box>
      </Box>
  
      <Box mt={5} display='flex'>
        <Box p={3} rounded='md' borderWidth='1px'>
          <Heading as='h3' size='md'>Password policy</Heading>
          <Text>Configure password complexity for user accounts belonging to {team?.name}. Leave a value blank for no minimum.</Text>
          <Table>
            <Tbody>
              {passwordPolicyFields.map(field =>
                <Tr key={field.key}>
                  <Td flex={1}>{field.name}</Td>
                  <Td>
                    <NumberInput width='100px' size='sm' value={team[field.key] || ''}
                      onChange={e => updateTeam({[field.key]: parseInt(e)})}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
  
          <Button colorScheme='primary' mt={5} onClick={savePasswordPolicy}>Save password policy</Button>
        </Box>
  
        <Box p={3} ml={3} rounded='md' borderWidth='1px' maxWidth='50%'>
          <Heading as='h3' size='md'>Lockout policy</Heading>
          <Text>Trialflare can automatically lock user accounts after a number of unsuccessful login attempts. The attempt count will reset after a successful login. Leave the field blank to disable this policy.</Text>
          <InputGroup mt={3}>
            <InputLeftAddon children='Lock user accounts after' />
            <NumberInput width='100px' value={team.allowedLoginAttempts || ''}
              onChange={e => updateTeam({allowedLoginAttempts: parseInt(e)})}
            >
              <NumberInputField />
            </NumberInput>
            <InputRightAddon children='attempts' />
          </InputGroup>
          <Button colorScheme='primary' mt={5} onClick={savePasswordPolicy}>Save lockout policy</Button>
        </Box>
      </Box>

      <Box p={3} mt={5} rounded='md' borderWidth='1px'>
        <Heading as='h3' size='md'>User sign-up notifications</Heading>
        <Text>Trialflare can automatically send a notification email when new users are created within your team. To enable this, enter an email address below to receive the notifications, or leave blank to disable this feature.</Text>
        <Input mt={5} placeholder='Email address' value={userCreationNotificationEmail} onChange={e => setUserCreationNotificationEmail(e.target.value)} /> 
        <Button colorScheme='primary' mt={5} onClick={saveUserNotificationEmail}>Save notification email address</Button>
      </Box>
    </>:
      <Box>
        <Heading>Page unavailable</Heading>
        <Text>This page is only viewable by users that manage the team</Text>
      </Box>
    }
  </>);
}

export default TeamSettings;
