import React, { useState, useEffect } from 'react';
import { useToast, Grid, Stack, Input, InputGroup, InputRightElement, Box, Button, Heading, Text, Alert, AlertIcon, AlertDescription, AlertTitle, StatGroup, Stat, StatLabel, StatNumber, StatHelpText, List, ListItem, ListIcon, Link } from '@chakra-ui/react';
import { CheckCircleIcon, WarningIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import { FaApple, FaAndroid } from 'react-icons/fa';
import { Link as RouterLink, useParams } from 'react-router-dom';
import api from '../../api';
import utils from '../../utils/utils';
import useStore from '../../store';
import useTitle from '../../hooks/useTitle';
import StepsImage from '../../images/steps.png';
import SimpleTable from '../includes/SimpleTable';

export default function Home() {
  const toast = useToast();
  const { user, trial } = useStore();
  useTitle('Overview', trial);

  const trialReady = trial && trial.name && trial.description && trial.code && trial.dataTypeCount && trial.stageCount;

  const copyUrl = (url) => {
    navigator.clipboard.writeText(url || 'https://join.trialflare.com').then(
      () => toast({title: 'URL copied to clipboard', status: 'success'}),
      err => toast({title: 'Error copying', status: 'error'})
    );
  };
  const copyCode = () => {
    navigator.clipboard.writeText(trial.code).then(
      () => toast({title: 'Code copied to clipboard', status: 'success'}),
      err => toast({title: 'Error copying', status: 'error'})
    );
  };
  
  const consentUrl = `https://app.trialflare.com/consent/${trial?.code}`;

  if (utils.canReadTrial(user, trial)) {
    return (
      <div>
        {trialReady ?
          <Box display={{md: 'flex'}}>
            <Box width={{md:'50%'}} borderWidth='1px' p={3} rounded='md'>
              <Alert status="success" variant="left-accent">
                <AlertIcon />
                <Box flexDirection="column">
                <AlertTitle mr={2}>Your trial is ready to launch</AlertTitle>
                <AlertDescription>You have configured enough information for this trial to start. You can still add extra data types and stages to finish building the trial to your needs.</AlertDescription></Box>
              </Alert>
              <Heading size='md' as='h3' mt={5}>Next, get your participants started</Heading>
              <Text>When you are happy with your trial, the next step is to get participants to begin supplying data.</Text>
              
              {trial?.eConsentEnabled &&
                <Alert colorScheme='grey' variant='left-accent' mt={5}>
                  <Box>
                    <Heading size='sm'>eConsent is enabled for this trial</Heading>
                    <Text fontSize='xs'>Participants must eConsent before they can take part in the trial. Participants can eConsent at the URL shown below.</Text>
                    <InputGroup size="sm" mt={2}>
                     <Input bg='white' size='sm' readonly value={consentUrl} />
                      <InputRightElement width="4.5rem">
                        <Button h="1.5rem" size="xs" colorScheme='blue' onClick={() => copyUrl(consentUrl)}>Copy</Button>
                      </InputRightElement>
                    </InputGroup>
                  </Box>
                </Alert>
              }

              <Box display='flex' mt={10}>
                <Box w={50} textAlign='center'>
                  <Text fontSize={40} color='darkcyan'>1</Text>
                </Box>
                <Box ml={5} flex='1'>
                  <Heading size='sm'>Tell participants where to go</Heading>
                  <Text>Give participants the trial URL or ask them to download the official Trialflare app.</Text>
                  <Heading as='h4' size='sm' fontWeight='semiBold' mt={5} mb={3}>Using the official app (search "Trialflare" in your app store)</Heading>
                  <Button colorScheme='blue' mr={2} mb={2} size='sm' as={'a'} href='https://play.google.com/store/apps/details?id=app.trilo' target='_blank' icon='android'><Box as={FaAndroid} mr={2} /> Android app</Button>
                  <Button colorScheme='blue' mb={2} size='sm' as={'a'} href='https://itunes.apple.com/gb/app/trilo/id1460738681' target='_blank' icon='apple'><Box as={FaApple} mr={2} /> iPhone &amp; iPad app</Button>
                  <Heading as='h4' size='sm' fontWeight='semiBold' mt={5} mb={3}>Using the participant web portal</Heading>
                  <InputGroup size="md">
                    <Input pr={4} readOnly value='https://join.trialflare.com' />
                    <InputRightElement width="4.5rem">
                      <Button h="1.75rem" size="sm" colorScheme='blue' onClick={() => copyUrl()}>Copy</Button>
                    </InputRightElement>
                  </InputGroup>
                </Box>
              </Box>

              <Box display='flex' mt={10}>
                <Box w={50} textAlign='center'>
                  <Text fontSize={40} color='darkcyan'>2</Text>
                </Box>
                <Box ml={5} flex='1'>
                  <Heading size='sm'>Share the trial code</Heading>
                  <Text mb={3}>Participants use this code to access the trial using the website or app.</Text>
                  {trial.code ?
                    <InputGroup size='md'>
                      <Input pr={4} readOnly value={trial.code} />
                      <InputRightElement width="4.5rem">
                        <Button h="1.75rem" size="sm" colorScheme='blue' onClick={copyCode}>Copy</Button>
                      </InputRightElement>
                    </InputGroup>
                  :
                    <Alert status='warning' variant='left-accent'>No code has been set yet.</Alert>
                  }
                  {utils.canManageTrial(user, trial) &&
                    <Button mt={3} as={RouterLink} to={`/trials/${trial._id}/info`} size='sm' colorScheme='blue' variant='outline'>Edit trial code</Button>
                  }
                </Box>
              </Box>

              <Box display='flex' mt={10}>
                <Box w={50} textAlign='center'>
                  <Text fontSize={40} color='darkcyan'>3</Text>
                </Box>
                <Box ml={5} flex='1'>
                  <Heading size='sm'>Participant logs in with an ID</Heading>
                  <Text>Each participant must use a unique ID along with the Trial Code in order to access the trial. Usually this is an ID that you provide to them.</Text>
                  {utils.canManageTrial(user, trial) && <>
                    <Button mt={3} as={RouterLink} to={`/trials/${trial._id}/settings`} size='sm' colorScheme='blue' variant='outline'>Manage joining settings</Button>
                    <Button mt={3} ml={3} as={RouterLink} to={`/trials/${trial._id}/participants`} size='sm' colorScheme='blue' variant='outline'>Pre-register participants</Button>
                  </>}
                </Box>
              </Box>

            </Box>

            {utils.canReadTrial(user, trial) &&
            <Box w={{md: '50%'}} p={5}>
              <Heading size='lg' mb={5}>Key information</Heading>
              <Box p={3} bg='gray.50'>
                <StatGroup>
                  <Stat>
                    <StatLabel>Registered participants</StatLabel>
                    <StatNumber>{trial.participantCount || 0}</StatNumber>
                    <Button mt={3} as={RouterLink} to={`/trials/${trial._id}/participants`} size='sm' colorScheme='blue' variant='outline'>Manage participants</Button>
                  </Stat>
                  <Stat>
                    <StatLabel>Participant groups</StatLabel>
                    <StatNumber>{trial.participantGroupCount || 0}</StatNumber>
                    <Button mt={3} as={RouterLink} to={`/trials/${trial._id}/participants`} size='sm' colorScheme='blue' variant='outline'>Manage groups</Button>
                  </Stat>
                </StatGroup>
              </Box>

              <Box p={3} bg='gray.50' mt={3}>
                <Stat>
                  <StatLabel>Responses submitted</StatLabel>
                  <StatNumber>{trial.responseCount || 0}</StatNumber>
                  <Button mt={3} as={RouterLink} to={`/trials/${trial._id}/results`} size='sm' colorScheme='blue' variant='outline'>View results</Button>
                </Stat>
              </Box>

              <Heading size='lg' mt={5} mb={5}>Useful links</Heading>
              <List spacing={3}>
                {utils.canManageTrial(user, trial) &&
                  <ListItem>
                    <ListIcon as={ArrowForwardIcon} color='green.500' />
                    <Link color='teal' as={RouterLink} to={`/trials/${trial._id}/settings`}>Manage collaborators</Link> to control who has access to your trial.
                  </ListItem>
                }
                {utils.canSupportTrial(user, trial) &&
                  <ListItem>
                    <ListIcon as={ArrowForwardIcon} color='green.500' />
                    Provide support to participants via the <Link color='teal' as={RouterLink} to={`/trials/${trial._id}/messages`}>participant messenger</Link>.
                  </ListItem>
                }
                {utils.canManageTrial(user, trial) &&
                  <ListItem>
                    <ListIcon as={ArrowForwardIcon} color='green.500' />
                    <Link color='teal' as={RouterLink} to={`/trials/${trial._id}/results`}>Set-up an integration</Link> to get easy access to data.
                  </ListItem>
                }
              </List>

            </Box>
            }

          </Box>
        :
            <>
            <Box display={{md: null}} textAlign='center'>
              <Box><img alt='Steps' src={StepsImage} style={{width: 300, display: 'block', margin: '0px auto'}} /></Box>
              <Box mt={1}>
                <Heading as='h2' size='lg' mb={3}>Let's get {trial.name} up and running</Heading>
                <Text mb={10}>Complete the following steps to finish setting-up your trial.</Text>
              </Box>
            </Box>

            <Grid templateColumns={{md: 'repeat(3, 1fr)'}} gap={4} mt={5} className='joyride-trial-steps'>
              <GuideStep title='1. Trial information'
                description="Name and describe your trial so participants know what it's about."
                buttonText='Set-up'
                buttonLink={`/trials/${trial._id}/info`}
                isComplete={trial.name && trial.description && trial.code}
                className='joyride-trial-steps-info'
              />

              <GuideStep title='2. Data types'
                description={<Text>Specify <strong>what</strong> data types you want your trial to collect.</Text>}
                buttonText='Set-up'
                buttonLink={`/trials/${trial._id}/data`}
                isComplete={trial?.dataTypeCount > 0}
              />

              <GuideStep title='3. Stages'
                description={<Text>Build the trial stages that will describe <strong>how</strong> to collect your data.</Text>}
                buttonText='Set-up'
                buttonLink={`/trials/${trial._id}/stages`}
                isComplete={trial?.stageCount > 0}
              />
            </Grid>
          </>
        }
      </div>
    );
  }
      
  else if (utils.canAccessTrial(user, trial)) {
    return <NonAdminHome user={user} trial={trial} />;
  }
}

function GuideStep({ title, description, buttonText, buttonLink, isComplete, className }) {
  return (
    <Box rounded='md' borderWidth='1px' textAlign='center' p={3}
      bg={isComplete ? 'green.50' : 'primary.50'}
      boxShadow='sm' className={className} transition='0.25s'
      _hover={{shadow: 'lg'}}>
      {isComplete ?
          <CheckCircleIcon color='green.400' />
        :
          <WarningIcon color='orange.400' />
      }
      <Heading as='h2' size='md' mt={5} mb={5}>{title}</Heading>
      <Text>{description}</Text>
      <Button mt={3} colorScheme='primary' variant={isComplete ? 'outline' : 'solid'} as={RouterLink} to={buttonLink}>{buttonText}</Button>
    </Box>
  );
}

function NonAdminHome({ user, trial }) {
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    api.trials.getParticipants(trial._id, 1, null, null, null, false, 'lastSeenAt', 'desc', ({ participants, total }) => {
      setParticipants(participants);
    });
  }, [trial._id]);

  return (
    <Box>
      <Heading size='md'>Welcome to {trial.name}</Heading>
      <Stack mt={5} spacing={5} direction={{base: 'column', md: 'row'}}>
        <Box p={3} rounded='md' bg='gray.50'>
          <Heading size='sm'>Quick actions</Heading>
          <List spacing={3} mt={5}>
            <ListItem>
              <ListIcon as={ArrowForwardIcon} color='green.500' />
              <Link color='primary.500' as={RouterLink} to={`/trials/${trial._id}/info`}>View information about this trial</Link>
            </ListItem>
            <ListItem>
              <ListIcon as={ArrowForwardIcon} color='green.500' />
              <Link color='primary.500' as={RouterLink} to={`/trials/${trial._id}/participants`}>View your participants</Link>
            </ListItem>
            <ListItem>
              <ListIcon as={ArrowForwardIcon} color='green.500' />
              <Link color='primary.500' as={RouterLink} to={`/settings`}>Manage your account</Link>
            </ListItem>
          </List>
        </Box>

        <Box p={3} rounded='md' bg='gray.50' flex={1}>
          {participants.length > 0 &&
            <Box>
              <Box display='flex' justifyContent='space-between'>
                <Heading size='sm' mb={5}>Recent participants</Heading>
                <Button colorScheme='purple' variant='outline' size='sm' as={RouterLink} to={`/trials/${trial._id}/participants`}>View all</Button>
              </Box>
              <SimpleTable
                headers={['ID', 'Last seen', 'Actions']}
                rows={participants.map(p => [
                  p.id,
                  p.lastSeenAt ? new Date(p.lastSeenAt).toLocaleString() : 'Never',
                  <Button size='xs' colorScheme='purple' as={RouterLink} to={`/trials/${trial._id}/participants/${p._id}`}>Manage</Button>
                ])}
              />
            </Box>
          }
        </Box>
      </Stack>
    </Box>
  );
}
