import React, { Component } from 'react';
import { Box, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';

import useStore from '../../../store';
import useTitle from '../../../hooks/useTitle';
import ParticipantMessaging from './Messages';
import AutomaticMessages from './Automatic';

export default function Messages() {
  const { trial } = useStore();
  useTitle('Messages', trial);
  return (
    <Box pl={10} pr={10}>
      <Tabs>
        <TabList>
          <Tab>Participant messenger</Tab>
          <Tab>Automatic messages</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <ParticipantMessaging />
          </TabPanel>
          <TabPanel>
            <AutomaticMessages />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
