import React from 'react';
import { Box, Heading, Text, Alert, Input, Textarea, Badge } from '@chakra-ui/react';

const Terms = ({ trial, data, updateData}) => {
  return (
    <Box>
      <Box mb={10} rounded='md' shadow='md' bg='gray.50' borderWidth='1px' p={3}>
        <Heading as='h3' size='md'>Terms and privacy conditions statement</Heading>
        <Text>Participants will be asked to read and agree to this statement before taking part in the trial. You can use this to describe your ethics, your approach to data protection, and/or to include links to any other relevant policies you may have.</Text>
        <Alert status='info' variant='left-accent' flexDirection='column' mt={5} mb={5}>
          <Text fontSize='sm'>Please note that Trialflare does not collect personally-identifiable information from participants as part of its standard use. If you use Trialflare to collect personal and/or sensitive information from participants then you should indicate this in your conditions statement, and explain how you will keep it secure and comply with relevant Data Protection regulations. Trialflare will securely store and process participant data on your behalf but does not act as Data Controller for such data.</Text>
        </Alert>
        <Textarea bg='white' h={60} rows={15} value={data.conditions} onChange={e => updateData({ conditions: e.target.value })} placeholder='Terms and privacy conditions statement...'/>
      </Box>
    </Box>
  );
};

export default Terms;
