import React from 'react';
import { Popover, PopoverTrigger, Button, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody } from '@chakra-ui/react';

export default function SimplePopover({ on, closeOnBlur, contentProps, trigger, header, body }) {
  return (
    <Popover closeOnBlur={closeOnBlur} trigger={on || 'click'}>
      <PopoverTrigger>{trigger}</PopoverTrigger>
      <PopoverContent {...contentProps}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>{header}</PopoverHeader>
        <PopoverBody>{body}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
