import React, { useState, useEffect } from 'react';
import { Tabs, TabList, Tab, TabPanels, TabPanel, Button, InputGroup, Input, InputRightAddon, Box, Table, Tbody, Tr, Td, Text, Badge, Alert } from '@chakra-ui/react';
import { FaHamburger, FaCheckCircle } from 'react-icons/fa';
import api from '../../api';

import { nutritionTypes } from '../trials/NewData';

function FoodChooser({ forId, requiredNutritionFields, value, onComplete }) {
  const [searchAttempted, setSearchAttempted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [amountConsumed, setAmountConsumed] = useState(0);
  const [customName, setCustomName] = useState('');
  const [customNutrition, setCustomNutrition] = useState({});
  const [chosenItem, setChosenItem] = useState();

  useEffect(() => {
    if (value?.amountConsumed) {
      setChosenItem(value);
      setAmountConsumed(value.amountConsumed);
      if (value.source === 'manual') {
        setCustomNutrition(value.nutrients || {});
        setCustomName(value.name || '');
      }
    }
  }, [value]);

  const search = () => {
    setLoading(true);
    setSearchAttempted(false);
    api.nutrition.search(forId, searchTerm, ({ results }) => {
      setSearchResults(results);
      setLoading(false);
      setSearchAttempted(true);
    }, err => {
      setLoading(false);
    });
  };

  const updateFieldNutrition = (resultId, field, value) => {
    const newResults = Object.assign([], searchResults).map(r => {
      if (r.id === resultId) {
        r.nutrients[field.key] = value.replace(/[^\d.]/g, '');
      }
      return r;
    });
    setSearchResults(newResults);
  };

  const choose = (selectedFood) => {
    selectedFood.amountConsumed = parseFloat(amountConsumed);
    let error = false;
    (requiredNutritionFields || []).forEach(n => {
      const recorded = selectedFood.nutrients[n];
      if (recorded === null || recorded === undefined || recorded === '') error = true;
      selectedFood.nutrients[n] = parseFloat(recorded);
    });
    if (error) return alert('Please ensure the required nutritional fields are completed.');
    setChosenItem(selectedFood);
    onComplete(selectedFood);
  };

  const nutritionFields = (requiredNutritionFields || []).map(key => nutritionTypes.find(n => n.key === key));

  return (
    <>
    {chosenItem ?
        <Box bg='green.200' p={3} display='flex' alignItems='center' justifyContent='space-between'>
          <Box display='flex' alignItems='center'>
            <Box as={FaCheckCircle} color='green.700' />
            <img src={chosenItem.photo} style={{maxHeight: 40, marginLeft: 10}} />
            <Text ml={3}>{amountConsumed}g of {chosenItem.name}</Text>
          </Box>
          <Button size='sm' onClick={e => setChosenItem(null)}>Choose a different item</Button>
        </Box>
    : <>
      <Tabs>
        <TabList>
          <Tab>Search for food</Tab>
          <Tab>Enter food manually</Tab>
        </TabList>
        <TabPanels pt={5}>
          <TabPanel>
            <Box display='flex'>
              <Input maxWidth={350} size='sm' bg='white' type="text" placeholder='Enter a barcode number or search term...' onChange={e => setSearchTerm(e.target.value)} value={searchTerm} />
              <Button onClick={search} isLoading={loading} ml={2} size='sm' variant='outline' colorScheme='primary'><Box as={FaHamburger} mr={2} /> Search food</Button>
            </Box>

            {!chosenItem && searchResults?.length > 0 &&
              <>
              <Text mt={2} mb={2} fontSize={10} color='gray.800'>Nutritional values are per 100g/ml</Text>
              <Table size='sm'>
                <Tbody>
                  {searchResults.map(result =>
                    <Tr key={result.id}>
                      <Td display='flex' flexDirection='column' alignItems='center'>
                        <Text mb={2} fontWeight='bold' fontSize={14}>{result.name}</Text>
                        <img style={{maxHeight:'60px'}} src={result.imageUrl} /></Td>
                      <Td>
                        {nutritionFields.map(field =>
                          <InputGroup mb={1} size='xs' key={field.key}>
                            <Input placeholder='Per 100g food' value={result.nutrients[field.key]} onChange={e => updateFieldNutrition(result.id, field, e.target.value)} />
                            <InputRightAddon children={`${field.unit} ${field.name || ''}`} />
                          </InputGroup>
                        )}
                      </Td>
                      <Td bg='green.100'>
                        <Box display='flex' flexDirection='column' alignItems='center'>
                          <Text fontSize={10}>Amount consumed</Text>
                          <InputGroup size='sm'>
                            <Input bg='white' placeholder='100' value={amountConsumed} onChange={e => setAmountConsumed(e.target.value.replace(/[^\d]/g, ''))} />
                            <InputRightAddon children='g or ml' />
                          </InputGroup>
                          <Button mt={2} size='sm' onClick={e => choose(result)} colorScheme='green'>Select</Button>
                        </Box>
                      </Td>
                    </Tr>
                  )}
                </Tbody>
              </Table>
              </>
            }
            {searchAttempted && searchResults && searchResults.length === 0 &&
              <Alert mt={2} status='warning'>No results were found for your search term. You can add the entries in manually instead.</Alert>
            }
          </TabPanel>

          <TabPanel>
            <Text fontSize={12} fontWeight='bold'>Enter a name for the item</Text>
            <Input bg='white' placeholder='Food entry name...' value={customName} onChange={e => setCustomName(e.target.value)}/>

            <Text mt={3} fontSize={12} fontWeight='bold'>Enter the required nutritional values per 100g of food</Text>
            {nutritionFields.map(field =>
              <InputGroup mb={1} size='sm' key={field.key}>
                <Input bg='white' placeholder='Per 100g food' value={customNutrition[field.key] || ''} onChange={e => {
                  const newCustomValues = Object.assign({}, customNutrition);
                  newCustomValues[field.key] = e.target.value.replace(/[^\d.]/g, '');
                  setCustomNutrition(newCustomValues);
                }} />
                <InputRightAddon children={`${field.unit} ${field.name || ''}`} />
              </InputGroup>
            )}

            <Text mt={3} fontSize={12} fontWeight='bold'>Enter the number of grams consumed</Text>
            <InputGroup mb={1}>
              <Input bg='white' placeholder='300' value={amountConsumed} onChange={e => setAmountConsumed(e.target.value.replace(/[^\d]/g, ''))}/>
              <InputRightAddon children='g' />
            </InputGroup>

            <Button mt={3} colorScheme='green' onClick={e => {
              choose({
                source: 'manual',
                name: customName,
                nutrients: customNutrition,
              });
            }}>Add entry</Button>
          </TabPanel>

        </TabPanels>
      </Tabs>
      </>}
    </>
  );
}

export default FoodChooser;
