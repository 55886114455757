import React, { useState, useEffect } from 'react';
import { useToast, Box, Heading, Text, Alert, AlertTitle, AlertDescription, Button } from '@chakra-ui/react';
import { useBlocker } from 'react-router-dom';
import useStore from '../../../store';
import useTitle from '../../../hooks/useTitle';
import api from '../../../api';
import utils from '../../../utils/utils';
import SimpleTabs from '../../includes/SimpleTabs';
import UnsavedModal from '../../includes/UnsavedModal';

import Overview from './Overview';
import Code from './Code';
import Sponsor from './Sponsor'
import Name from './Name';
import Terms from './Terms';
import Approval from './Approval';

const FIELDS = [
  { section: 'code', label: 'Trial code', type: 'text', key: 'code', required: true, placeholder: 'ACMETrial1' },
  { section: 'sponsor', label: 'Trial sponsor', type: 'text', key: 'sponsor', placeholder: 'ACME Inc.' },
  { section: 'name', label: 'Trial name (public title)', type: 'text', key: 'name', required: true, placeholder: 'ACME Sleep Quality Trial' },
  { section: 'name', label: 'Scientific title', type: 'text', key: 'scientificName' },
  { section: 'name', label: 'Trial description', type: 'textarea', key: 'description', required: true, placeholder: 'This trial is designed to measure the quality of sleep in participants over a 30-day period...' },
  { section: 'name', label: 'Study acronym', type: 'text', key: 'studyAcronym', placeholder: 'ACMESQT' },
  { section: 'name', label: 'EudraCT number', type: 'text', key: 'eudractNumber' },
  { section: 'name', label: 'CTIS number', type: 'text', key: 'ctisNumber' },
  { section: 'name', label: 'ClinicalTrials.gov number', type: 'text', key: 'clinicalTrialsGovNumber', placeholder: 'NCT012345' },
  { section: 'name', label: 'Protocol or serial number', type: 'text', key: 'protocolNumber'},
];

export default function Info() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [data, setData] = useState({});
  const [saving, setSaving] = useState(false);
  const [unsaved, setUnsaved] = useState(false);
  const [error, setError] = useState();
  const toast = useToast();
  const { user, trial, updateTrial } = useStore();
  useTitle('About', trial);

  const blocker = useBlocker(({ currentLocation, nextLocation }) =>
    unsaved &&
    currentLocation.pathname !== nextLocation.pathname
  );

  useEffect(() => {
    setData({
      code: trial.code,
      name: trial.name,
      sponsor: trial.sponsor,
      scientificName: trial.scientificName,
      description: trial.description,
      studyAcronym: trial.studyAcronym,
      eudractNumber: trial.eudractNumber,
      ctisNumber: trial.ctisNumber,
      clinicalTrialsGovNumber: trial.clinicalTrialsGovNumber,
      protocolNumber: trial.protocolNumber,
      conditions: trial.conditions,
      approvalNeeded: trial.approvalNeeded || false,
      approvals: trial.approvals || [],
    });
  }, [trial]);

  function updateData(newData) {
    setData({...data, ...newData});
    setUnsaved(true);
  }

  function save() {
    if (!data.code || !data.name) {
      return setError('Code and name are required fields.')
    }
    setSaving(true);
    setError();
    api.trials.update(trial._id, data, t => {
      updateTrial(t._id, t);
      toast({title: 'Information saved', status: 'success'});
      setSaving(false);
      setUnsaved(false);
    }, err => {
      setSaving(false);
      setError(err.message);
    });
  }

  const TABS = [
    {
      title: 'Overview',
      content: <Overview fields={FIELDS} trial={trial} data={data} setTab={key => setSelectedTabIndex(TABS.findIndex(t => t.key === key))} />,
    }
  ];
  if (utils.canManageTrial(user, trial)) {
    TABS.push(...[
      {
        title: 'Code',
        key: 'code',
        content: <Code fields={FIELDS.filter(f => f.section === 'code')} trial={trial} data={data} updateData={updateData} />,
      },
      {
        title: 'Sponsor',
        key: 'sponsor',
        content: <Sponsor fields={FIELDS.filter(f => f.section === 'sponsor')} trial={trial} data={data} updateData={updateData} />,
      },
      {
        title: 'Identity',
        key: 'name',
        content: <Name fields={FIELDS.filter(f => f.section === 'name')} trial={trial} data={data} updateData={updateData} />,
      },
      {
        title: 'Privacy',
        content: <Terms trial={trial} data={data} updateData={updateData} />,
      },
      {
        title: 'Approval',
        key: 'approval',
        content: <Approval trial={trial} data={data} updateData={updateData} />,
      }
    ]);
  }

  return (
    <Box>
      <UnsavedModal blocker={blocker} />
      <Heading as='h2' size='lg' mb={15}>About your trial</Heading>

      <SimpleTabs
        tabProps={{index: selectedTabIndex, onChange: setSelectedTabIndex}}
        tabs={TABS}
      />
      
      {utils.canManageTrial(user, trial) &&
        <Box mt={10} ml={10} mr={10} position='sticky' style={{bottom: 10}} boxShadow='lg' p={3} rounded='md' bg='primary.50'>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Box>
              {error ?
                <Alert mr={3} status='error' variant='left-accent'>
                  <Box>
                    <AlertTitle>There was a problem saving your trial details</AlertTitle>
                    <AlertDescription>{error} -  Please fix these issues and try again.</AlertDescription>
                  </Box>
                </Alert>
              : <Box />
              }
              {unsaved ?
                <Alert mr={3} status='info' variant='left-accent'>
                  <Box>
                    <AlertTitle>You have unsaved changes</AlertTitle>
                    <AlertDescription>Remember to save before leaving.</AlertDescription>
                  </Box>
                </Alert>
              : <Box />
              }
            </Box>
            <Button colorScheme='primary' onClick={save} isLoading={saving}>Save changes</Button>    
          </Box>
        </Box>
      }        
    </Box>
  );
}
