import React from 'react';
import { Box, Text, Button, Slider, SliderTrack, SliderFilledTrack, SliderThumb } from '@chakra-ui/react';

export default function SimpleSlider({ value, onChange, onChangeEnd, minValue, maxValue }) {
  const defaultValue = ((maxValue ?? 10) + (minValue ?? 1)) / 2;
  const hasValue = value !== null && value !== undefined;
  const colourScheme = hasValue ? 'blue' : 'gray';
  return (
    <Box display="flex" alignItems="center" opacity={hasValue ? 1 : 0.5}>
      <Text color='gray.400' mr={5}>{minValue}</Text>
      <Slider defaultValue={defaultValue} onChange={onChange} onChangeEnd={onChangeEnd} min={minValue} max={maxValue} value={value ?? defaultValue}>
        <SliderTrack>
          <SliderFilledTrack bg={`${colourScheme}.300`} />
        </SliderTrack>
        <SliderThumb boxSize={6} bg={`${colourScheme}.500`}>
          <Text color='white'>{value ?? ''}</Text>
        </SliderThumb>
      </Slider>
      <Text color='gray.400' ml={5}>{maxValue}</Text>
      <Button colorScheme='gray' variant='outline' size='sm' ml={5} onClick={() => onChange(null)}>Reset</Button>
    </Box>
  );
}
