import React, { useState, useEffect } from 'react';
import { Heading, Text, Box, Stack, CircularProgress, Badge } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import moment from 'moment';

import EmptyBox from '../../includes/EmptyBox';
import SimpleStat from '../../includes/SimpleStat';
import SimpleTabs from '../../includes/SimpleTabs';
import api from '../../../api';
import DataImage from '../../../images/dataProcessing.png';

export default function HardwareResponses({ trialId, participantId }) {
  const [hardwareResponses, setHardwareResponses] = useState([]);
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    api.trials.getParticipantHardwareResponses(trialId, participantId, d => {
      setLoading(false);
      setHardwareResponses(d.hardwareResponses);
      setActivities(d.activities);
    })
  }, [participantId]);

  const SLEEP_SCORE_COLOURS = {
    'EXCELLENT': 'green',
    'GOOD': 'green',
    'FAIR': 'yellow',
    'POOR': 'red',
  };

  const dailies = hardwareResponses?.filter(h => h.type === 'daily');
  const sleeps = hardwareResponses?.filter(h => h.type === 'sleep');

  return (
    <Box>
      {loading && <Box textAlign='center'>
        <CircularProgress isIndeterminate />
      </Box>}
      

      {!loading && (
        <SimpleTabs
        tabProps={{ variant:'soft-rounded', colorScheme:'blue' }}
        tabs={[
          {
            title: 'Summary',
            content: (
              <Box>
                {dailies?.length === 0 && <EmptyBox 
                  image={DataImage}
                  title='No summaries are available yet for this participant' 
                  description='Data from wearables will automatically show here once received by Trialflare.'
                /> }
                {dailies?.map((response, index) => (
                  <Box key={response._id} mb={4} p={4} borderWidth='1px' borderRadius='md' bg='gray.50'>
                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                      <Heading size='sm'>{moment(response?.entry?.calendarDate).format('LL')}</Heading>
                      {response?.source && <Badge>Source: {response.source}</Badge>}
                    </Box>
                    <Stack direction='row' mt={4} textAlign='center'>
                      {response?.entry?.activeKilocalories &&
                        <SimpleStat
                          name='Active Calories'
                          number={`${response.entry.activeKilocalories} kCal`}
                        />
                      }
                      {response?.entry?.bmrKilocalories &&
                        <SimpleStat
                          name='Total Calories'
                          number={`${response.entry.bmrKilocalories} kCal`}
                        />
                      }
                    </Stack>
                    <Stack direction='row' mt={4} textAlign='center'>
                      {response?.entry?.steps &&
                        <SimpleStat
                          name='Steps'
                          number={`${response.entry.steps}`}
                        />
                      }
                      {response?.entry?.activeTimeInSeconds &&
                        <SimpleStat
                          name='Active Time'
                          number={`${response.entry.activeTimeInSeconds} secs`}
                        />
                      }
                      {response?.entry?.distanceInMeters &&
                        <SimpleStat
                          name='Distance'
                          number={`${response.entry.distanceInMeters} m`}
                        />
                      }
                    </Stack>
                    
                  </Box>
                ))}
              </Box>
            )
          },
          {
            title: 'Sleep',
            content: (
              <Box>
                {sleeps?.length === 0 && <EmptyBox 
                  image={DataImage}
                  title='No sleeps are available yet for this participant' 
                  description='Data from wearables will automatically show here once received by Trialflare.'
                /> }
                {sleeps?.map((response, index) => (
                  <Box key={response._id} mb={4} p={4} borderWidth='1px' borderRadius='md' bg='gray.50'>
                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                      <Heading size='sm'>{moment(response?.entry?.calendarDate).format('LL')}</Heading>
                      {response?.entry?.overallSleepScore && <Badge colorScheme={SLEEP_SCORE_COLOURS[response.entry.overallSleepScore.qualifierKey]}>Score: {response.entry.overallSleepScore?.value} ({response.entry.overallSleepScore?.qualifierKey})</Badge>}
                      {response?.source && <Badge>Source: {response.source}</Badge>}
                    </Box>
                    <Stack direction='row' mt={4} textAlign='center'>
                      {response?.entry?.durationInSeconds &&
                        <SimpleStat
                          name='Asleep Time'
                          number={`${response.entry.durationInSeconds} seconds`}
                        />
                      }
                      {response?.entry?.awakeDurationInSeconds &&
                        <SimpleStat
                          name='Awake Time'
                          number={`${response.entry.awakeDurationInSeconds} seconds`}
                        />
                      }
                    </Stack>
                    <Stack direction='row' mt={4} textAlign='center'>
                      {response?.entry?.deepSleepDurationInSeconds &&
                        <SimpleStat
                          name='Deep Sleep'
                          number={`${response.entry.deepSleepDurationInSeconds} seconds`}
                        />
                      }
                      {response?.entry?.remSleepInSeconds &&
                        <SimpleStat
                          name='REM Sleep'
                          number={`${response.entry.remSleepInSeconds} seconds`}
                        />
                      }
                      {response?.entry?.lightSleepDurationInSeconds &&
                        <SimpleStat
                          name='Light Sleep'
                          number={`${response.entry.lightSleepDurationInSeconds} seconds`}
                        />
                      }
                    </Stack>
                  </Box>
                ))}
              </Box>
            )
          },
          {
            title: 'Activity',
            content: (
              <Box>
                {activities?.length === 0 && <EmptyBox 
                  image={DataImage}
                  title='No activities are available yet for this participant' 
                  description='Data from wearables will automatically show here once received by Trialflare.'
                /> }
                {activities?.map((activity, index) => (
                  <Box key={activity._id} mb={4} p={4} borderWidth='1px' borderRadius='md' bg='gray.50'>
                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                      <Heading size='sm'>{activity.entry?.activityName} ({moment(activity?.entry?.createdAt).format('LL')})</Heading>
                      {activity?.source && <Badge>Source: {activity.source}</Badge>}
                    </Box>
                    <Stack direction='row' mt={4} textAlign='center'>
                      {activity?.entry?.durationInSeconds &&
                        <SimpleStat
                          name='Duration'
                          number={`${activity.entry.durationInSeconds} seconds`}
                        />
                      }
                      {activity?.entry?.averageHeartRateInBeatsPerMinute &&
                        <SimpleStat
                          name='Average Heart Rate'
                          number={`${activity.entry?.averageHeartRateInBeatsPerMinute} bpm`}
                        />
                      }
                      {activity?.entry?.activeKilocalories &&
                        <SimpleStat
                          name='Calories'
                          number={`${activity.entry?.activeKilocalories} kCal`}
                        />
                      }
                    </Stack>
                    <Stack direction='row' mt={4} textAlign='center'>
                      {activity?.entry?.averageSpeedInMetersPerSecond &&
                        <SimpleStat
                          name='Average speed'
                          number={`${activity.entry?.averageSpeedInMetersPerSecond} m/s`}
                        />
                      }
                      {activity?.entry?.averagePaceInMinutesPerKilometer &&
                        <SimpleStat
                          name='Average pace'
                          number={`${activity.entry?.averagePaceInMinutesPerKilometer} min/km`}
                        />
                      }
                      {activity?.entry?.distanceInMeters &&
                        <SimpleStat
                          name='Distance'
                          number={`${activity.entry?.distanceInMeters} m`}
                        />
                      }
                    </Stack>
                  </Box>
                ))}
              </Box>
            )
          }
        ]} />
      )}
          
      

    </Box>
  );
}
