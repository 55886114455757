import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast, Box, Button, Heading, Text } from '@chakra-ui/react';
import api from '../../../api';
import useStore from '../../../store';

import ConfirmButton from '../../includes/ConfirmButton';

export default function DeleteSettings({ trial }) {
  const toast = useToast();
  const navigate = useNavigate();
  const { setTrial } = useStore();

  function deleteTrial() {
    api.trials.delete(trial._id, () => {
      toast({status: 'success', title: 'Trial deleted', description: 'We\'ve deleted the trial and its data.'});
      setTrial(null);
      navigate('/trials');
    }, err => toast({status:'error', title:err.message}));
  }
  return (
    <Box rounded='md' borderWidth='1px' p={3} borderColor='red.200' bg='red.50' shadow='lg'>
      <Heading as='h3' size='md'>Delete trial</Heading>
      <Text>Deleting the trial will also de-register all participants and delete all responses and data.</Text>
      <ConfirmButton
        trigger={<Button mt={2} colorScheme='red'>Delete trial</Button>}
        header='Really delete this trial?'
        body='All associated data will be removed.'
        footer='This action is non-reversible.'
        action={deleteTrial}
        actionColor='red'
      />
    </Box>
  );
}
