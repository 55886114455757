import React, { useRef, useState, useEffect } from 'react';
import { useToast, HStack, VStack, Stack, Box,  Heading, Text, FormControl, FormLabel, Input, InputGroup, InputRightElement, Textarea, Checkbox, Button, Select, Alert, AlertTitle, AlertIcon, AlertDescription, Table, Thead, Tbody, Tr, Th, Td, Tooltip, Modal, ModalBody, ModalContent, ModalOverlay, ModalHeader, ModalCloseButton, ModalFooter, Badge, Tag, Icon, SimpleGrid, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import { ArrowLeftIcon, DeleteIcon, WarningTwoIcon, AddIcon, CheckIcon } from '@chakra-ui/icons';
import { FaArrowRight } from 'react-icons/fa';
import { GrDrag } from 'react-icons/gr';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useBlocker } from 'react-router';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import uuid from 'uuid';
import utils from '../../../utils/utils';
import api from '../../../api';
import useStore from '../../../store';
import useTitle from '../../../hooks/useTitle';

import RichText from '../../includes/RichText';
import ConfirmButton from '../../includes/ConfirmButton';
import RadioHeader from '../../includes/RadioHeader';
import UnsavedModal from '../../includes/UnsavedModal';
import DataTypeEditor from '../NewData';
import PollModeEditor from './Poll';

import { POLL_FIELDS } from '../../Poll';

function NewStage({ }){
  const [stage, setStage] = useState();
  const [stages, setStages] = useState([]);
  const [dataTypes, setDataTypes] = useState([]);
  const [sites, setSites] = useState([]);
  const [error, setError] = useState();
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [postSubmissionText, setPostSubmissionText] = useState('');
  const [showPostSubmissionEditor, setShowPostSubmissionEditor] = useState(false);
  const [isRecurring, setIsRecurring] = useState(false);
  const [recurringFrequency, setRecurringFrequency] = useState(0);
  const [recurringDuration, setRecurringDuration] = useState(0);
  const [adHoc, setAdHoc] = useState(false);
  const [reminders, setReminders] = useState([]);
  const [availableToSites, setAvailableToSites] = useState();
  const [enabled, setEnabled] = useState(true);
  const [enableAfter, setEnableAfter] = useState();
  const [components, setComponents] = useState([]);
  const [selectedComponent, setSelectedComponent] = useState();
  const [searchDataType, setSearchDataType] = useState('');
  const [isPoll, setIsPoll] = useState(false);
  const [customLinks, setCustomLinks] = useState([]);
  const [unsaved, setUnsaved] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const { id, stageId } = useParams();
  const { trial, updateTrial, team } = useStore();
  useTitle('Edit Stage', trial);
  const isEditing = !!stageId;

  useEffect(() => {
    setUnsaved(true);
  }, [name, description, postSubmissionText, isRecurring, recurringFrequency, recurringDuration, adHoc, availableToSites, enabled, enableAfter, reminders, components, isPoll, customLinks])

  useEffect(() => {
    api.trials.getStages(id, d => setStages(d.stages));
    api.trials.getDataTypes(id, d => setDataTypes(d.dataTypes));
    api.sites.getForTrial(id, d => setSites(d.sites));
    if (stageId) api.stages.get(stageId, s => {
      setName(s.name || '');
      setDescription(s.description || '');
      setPostSubmissionText(s.postSubmissionText || '');
      setComponents(s.components || []);
      setIsRecurring(s.isRecurring || false);
      setRecurringFrequency(s.recurringFrequency || 0);
      setRecurringDuration(s.recurringDuration || 0);
      setAdHoc(s.adHoc || false);
      setReminders(s.reminders || []);
      setAvailableToSites(s.availableToSites || null);
      setEnabled(s.enabled || false);
      setEnableAfter(s.enableAfter || null);
      setIsPoll(s.isPoll || false);
      setCustomLinks(s.customLinks || []);
      setStage(s);
      setShowPostSubmissionEditor(true);
      setTimeout(() => setUnsaved(false), 1000); // Delay to ensure other useEffects have run
    });
    if (!stageId) {
      setShowPostSubmissionEditor(true);
      setTimeout(() => setUnsaved(false), 1000); // Delay to ensure other useEffects have run
    }
  }, [stageId]);

  const blocker = useBlocker(({ currentLocation, nextLocation }) =>
    unsaved &&
    currentLocation.pathname !== nextLocation.pathname
  );

  function create() {
    setError();
    const data = { name, description, postSubmissionText, isRecurring, recurringFrequency, recurringDuration, adHoc, availableToSites, enabled, enableAfter, reminders, components, isPoll, customLinks};
    if (isEditing) {
      api.stages.update(stageId, data, stage => {
        setUnsaved(false);
        toast({title:'Stage updated', status:'success'});
        setTimeout(() => navigate(`/trials/${trial._id}/stages`), 50);
      }, err => setError(err.message));
    } else {
      api.trials.createStage(trial._id, data, stage => {
        setUnsaved(false);
        updateTrial(trial._id, { stageCount: (trial.stageCount ?? 0) + 1 });
        const navTarget = stages?.length === 0 ? 'manage' : 'stages';
        toast({title: 'Stage created', status: 'success'});
        setTimeout(() => navigate(`/trials/${trial._id}/${navTarget}`), 50); // Delay to ensure state is set
      }, err => setError(err.message));
    }
  }

  function updateEnableAfter(data) {
    if (!!data.delay) {
      data.delay = parseInt(data.delay);
    }
    if (data.stage) {
      if (data.stage.indexOf(':') > -1) {
        const components = data.stage.split(':');
        data.stage = components[0];
        data.stageRecurrence = parseInt(components[1]);
      } else {
        data.stageRecurrence = null;
      }
    }
    const newEnableAfter = Object.assign({}, enableAfter, data);
    setEnableAfter(newEnableAfter);
  }

  function selectSchedule(val) {
    if (val === 'none') {
      setIsRecurring(false);
      setAdHoc(false);
      setRecurringFrequency(0);
      setRecurringDuration(0);
    }
    if (val === 'recur') {
      setIsRecurring(true);
      setAdHoc(false);
    }
    if (val === 'adHoc') {
      setIsRecurring(false);
      setAdHoc(true);
      setRecurringFrequency(0);
      setRecurringDuration(0);
    }
  }

  function addReminder() {
    let newReminders = Object.assign([], reminders);
    let hourToAdd = 8;
    const existingHours = newReminders.map(r => r.hour);
    for (let i = 9; i < 23; i++) {
      if (existingHours.indexOf(i) === -1) {
        hourToAdd = i;
        break;
      }
    }
    newReminders.push({ hour: hourToAdd, title: stage?.name || name || 'Trialflare reminder', body: 'Please remember to submit data today.' });
    newReminders = newReminders.sort((a, b) => a.hour >= b.hour);
    setReminders(newReminders);
  }
  function removeReminder(hour) {
    let newReminders = Object.assign([], reminders);
    const hourToRemove = parseInt(hour);
    setReminders(newReminders.filter(r => r.hour !== hourToRemove));
  }
  function updateReminder(index, update) {
    let newReminders = Object.assign([], reminders);
    if (update.hour) {
      update.hour = parseInt(update.hour);
      const existingHours = newReminders.map(r => r.hour);
      if (existingHours.indexOf(update.hour) > -1) {
        return alert('You can\'t send two reminders in the same hour.');
      }
    }
    const reminder = Object.assign({}, newReminders[index], update);
    newReminders[index] = reminder;
    newReminders = newReminders.sort((a, b) => a.hour >= b.hour);
    setReminders(newReminders);
  }

  function addComponent(beforeIndex, dataType, type) {
    const newComponents = Object.assign([], components);
    const newComponent = {
      id: uuid.v4(),
      title: '',
      description: '',
      dataType: dataType ?? '',
      type: type ?? undefined, // For handling built-in (title, etc.)
    };
    if (beforeIndex === null || beforeIndex === undefined) newComponents.push(newComponent);
    else newComponents.splice(beforeIndex, 0, newComponent);
    setComponents(newComponents);
    // setSelectedComponent(newComponent);
  }
  function deleteComponent(componentId) {
    const newComponents = Object.assign([], components);
    const index = components.map(c => c.id).indexOf(componentId);
    newComponents.splice(index, 1);
    setComponents(newComponents);
  }
  function updateEntireComponent(componentId, data) {
    const newComponents = Object.assign([], components).map(c => {
      let component = c;
      if (component.id === componentId) {
        component = Object.assign({}, c, data);
      }
      return component;
    });
    setComponents(newComponents);
  }
  function moveComponent(componentId, newIndex) {
    const newComponents = Object.assign([], components.filter(c => c.id !== componentId));
    const component = Object.assign({}, components.filter(c => c.id === componentId)[0]);
    newComponents.splice(newIndex, 0, component);
    setComponents(newComponents);
  }

  const usedDataTypes = components.map(c => c.dataType);
  const availableDataTypes = dataTypes?.filter(d => usedDataTypes.indexOf(d._id) === -1);
  const filteredAvailableDataTypes = searchDataType ? availableDataTypes?.filter(d => d.name.toLowerCase().indexOf(searchDataType.toLowerCase()) > -1) : availableDataTypes;

  const warnings = [];
  if (isPoll) {
    components?.forEach(c => {
      const dataType = dataTypes?.filter(d => d._id === c.dataType)[0];
      if (!Object.keys(POLL_FIELDS).includes(dataType?.type)) {
        warnings.push(`The field "${(c.title || dataType?.name) ?? 'title or section'}" is not available in polls.`);
      }
      if (c.condition) {
        warnings.push(`The field "${(c.title || dataType?.name) ?? 'title or section'}" has a condition. Polls do not support conditional fields.`);
      }
    });
    if (isRecurring) {
      warnings.push('Enabling poll mode for recurring stages will allow participants to submit to the same stage multiple times.');
    }
  }

  return (
    <>
      <UnsavedModal blocker={blocker} />

      <Button mb={5} as={Link} to={`/trials/${trial._id}/stages`} variant='link' colorScheme='primary'><ArrowLeftIcon mr={3} /> Back to stages</Button>
      <Heading as='h2' size='lg'>{isEditing ? `Edit stage ${stage?.name}` : `Create a new stage`}</Heading>

      {warnings.length > 0 &&
        <Alert status='warning' variant='left-accent' mt={5} flexDirection='column' alignItems='start'>
          <Heading size='sm'>This stage has {warnings.length} issue{warnings.length > 1 && 's'}</Heading>
          <Box ml={5}>
            <ul>
              {warnings.map((w, i) => <li key={i}>{w}</li>)}
            </ul>
          </Box>
        </Alert>
      }

      <Tabs mt={5}>
        <TabList>
          <Tab>Stage editor</Tab>
          <Tab>Push reminders</Tab>
          <Tab>Settings</Tab>
          {utils.hasFeature(team, 'pollMode') && <Tab>Poll mode</Tab>}
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box p={3} mt={15} borderWidth='1px' rounded='md' bg='gray.50'>
              <Heading as='h3' size='md' mb={5}>About your stage</Heading>

              <Heading size='sm' as='h4'>Name</Heading>
              <Text color='gray.500'>Give your stage an identifier. This will be shown to participants in order for them to access the stage and fill in the required data.</Text>
              <Input bg='white' mt={1} placeholder='Stage 1' value={name} onChange={e => setName(e.target.value)}/>

              <Heading as='h4' size='sm' mt={5}>Description</Heading>
              <Text color='gray.500'>Describe your stage. This will also be shown to participants, and is useful for giving an overview of what they might be expected to do during this stage.</Text>
              <Textarea bg='white' mt={1} placeholder='The following questions will...' value={description} onChange={e => setDescription(e.target.value )}/>
            </Box>

            <DndProvider backend={HTML5Backend}>
              <Box p={3} mt={15} borderWidth='1px' rounded='md' bg='gray.50'>
                <Heading as='h3' size='md'>Form builder</Heading>
                <Text color='gray.500' mb={10}>Set-up the questions that participants will answer in order to provide their data.</Text>

                <Box display='flex'>
                  <Box w={300} mt={3}>
                    <Box mb={5} display='flex' alignItems='center' justifyContent='end'>
                      <Text textColor='grey' fontSize='xs'>Drag fields in to create your form</Text>
                      <Icon color='gray' as={FaArrowRight} ml={2} />
                    </Box>

                    <Box bg='gray.200' p={2} rounded='md'>
                      <Text mb={3} color='gray' fontWeight='bold' textTransform='uppercase' fontSize='xs'>Your data types</Text>
                      {dataTypes?.length === 0 && <Alert fontSize='sm'>You don't have any datatypes yet</Alert>}
                      {availableDataTypes?.length > 0 && <>
                        <Input bg='white' mb={2} size='xs' placeholder='Filter data types...' value={searchDataType} onChange={e => setSearchDataType(e.target.value)} />
                        <Box overflowY='scroll' maxH={350}>
                          {filteredAvailableDataTypes?.map(d => {
                            return <ComponentMenuItem key={d._id} name={d.name} typeId={d._id} addComponent={addComponent} />
                          })}
                        </Box>
                      </>}
                      {dataTypes?.length > 0 && availableDataTypes?.length === 0 &&
                        <Alert variant='left-accent' fontSize='sm'>All of your data types are now in use.</Alert>
                      }
                      <DataTypeEditor trial={trial}
                        onComplete={newDataType => {
                          const newDataTypes = Object.assign([], dataTypes);
                          newDataTypes.push(newDataType);
                          setDataTypes(newDataTypes);
                        }}
                        trigger={
                          <Button size='xs' width='100%' mt={2} colorScheme='blue' variant='outline'><AddIcon mr={2} /> Create a new data type</Button>
                        } />
                    </Box>

                    <Box mt={5} bg='gray.200' p={2} rounded='md'>
                      <Text mb={3} color='gray' fontWeight='bold' textTransform='uppercase' fontSize='xs'>Other fields</Text>
                      <ComponentMenuItem name='Title area' type='title' addComponent={addComponent} />
                      <ComponentMenuItem name='Section divider' type='sectionDivider' addComponent={addComponent} />
                    </Box>
                  </Box>
                  <ComponentFieldArea components={components} dataTypes={dataTypes} moveComponent={moveComponent} setSelectedComponent={setSelectedComponent} deleteComponent={deleteComponent} addComponent={addComponent} />
                </Box>
                {selectedComponent &&
                  <ComponentEditor component={selectedComponent} updateComponent={updateEntireComponent} dataTypes={dataTypes} trial={trial} onClose={() => setSelectedComponent(null)} />
                }

                <Box mt={5} />

                {(dataTypes?.length > 0) ?
                  (availableDataTypes?.length === 0 &&
                    <Alert variant='left-accent'>You have no more data types to collect.</Alert>
                  )
                :
                  <Alert status='warning'>
                    <AlertIcon />
                    <Box>
                      <Text><strong>Before you create a component you need to create at least one data type.</strong></Text>
                      <Button as={Link} to={`/trials/${trial._id}/data`}>Go to data types</Button>
                    </Box>
                  </Alert>
                }
              </Box>
            </DndProvider>
          </TabPanel>

          <TabPanel>
            <Box p={3} mt={15} borderWidth='1px' rounded='md' bg='gray.50'>
              <Heading as='h3' size='md'>Reminders</Heading>
              <Text color='gray.500' mb={5}>Send push notifications to participants to remind them to complete this stage.</Text>

              <Alert status="warning">
                <AlertIcon /> Reminders can only be sent to participants that have enabled push notifications on their device.
              </Alert>

              <Text mt={3} mb={3}>Trialflare will try to send a push notification to participants for each day this stage is valid at the following hours:</Text>
              {reminders?.length > 0 ?
                <Table variant="striped">
                  <Thead>
                    <Tr>
                      <Th>Send at (UTC)</Th>
                      <Th>Reminder title</Th>
                      <Th>Reminder body</Th>
                      <Th></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {reminders.map((reminder, i) =>
                      <Tr key={reminder.hour}>
                        <Td>
                          <Select w='200px' placeholder="Select an hour of the day" value={reminder.hour}
                            onChange={e => updateReminder(i, { hour: e.target.value })}
                          >
                            {Array.from({length: 24}, (_, i) => i).map(hour =>
                              <option key={hour} value={hour}>{hour < 10 && '0'}{hour}:00</option>
                            )}
                          </Select>
                        </Td>
                        <Td><Input bg='white' value={reminder.title} onChange={e => updateReminder(i, { title: e.target.value })} placeholder='Notification title'/></Td>
                        <Td><Input bg='white' value={reminder.body} onChange={e => updateReminder(i, { body: e.target.value })} placeholder='Notification body'/></Td>
                        <Td><Button size='xs' colorScheme='red' onClick={e => removeReminder(reminder.hour)}>Remove</Button></Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              : <Text color='gray.500' fontSize='sm'>No reminders have been set yet.</Text>}

              <Button colorScheme='blue' mt={5} onClick={addReminder}>Add a reminder</Button>
            </Box>
          </TabPanel>

          <TabPanel>
            <Box p={3} mt={15} borderWidth='1px' rounded='md' bg='gray.50'>
              <Heading as='h3' size='md'>Who has access to this stage</Heading>
              <Text color='gray.500' mb={5}>Specify the sites with access to this stage. For example, you might use different languages for different sites.</Text>
              <SimpleGrid columns={2} spacing={3}>
                <Box rounded='md' shadow='sm' bg='white' p={3}>
                  <RadioHeader checked={!availableToSites} name='availableToSites' value='none' title='Available to all participants'
                    onChange={e => setAvailableToSites(null)}
                    content={
                      <>
                        <Text color='gray.500' mb={5}>This stage will be shown to all participants in the trial, subject to the settings below.</Text>
                      </>
                    }
                  />
                </Box>
                <Box rounded='md' shadow='sm' bg='white' p={3}>
                  <RadioHeader checked={availableToSites} name='availableToSites' value='none' title='Available to specific sites only'
                    onChange={e => {if (!availableToSites) setAvailableToSites([])}}
                    content={
                      <>
                        <Text color='gray.500' mb={5}>This stage will only be shown to the sites selected below.</Text>
                        {availableToSites &&
                          <Table size='xs'>
                            <Tbody>
                              {sites.map(site =>
                                <Tr key={site._id}>
                                  <Td>
                                    <Checkbox
                                      isChecked={availableToSites.indexOf(site._id) > -1}
                                      onChange={e => {
                                        const index = availableToSites.indexOf(site._id);
                                        let newSites = Object.assign([], availableToSites);
                                        if (index === -1) newSites.push(site._id);
                                        else newSites.splice(index, 1);
                                        setAvailableToSites(newSites);
                                      }}
                                    />
                                  </Td>
                                  <Td>{site.name}</Td>
                                </Tr>
                              )}
                            </Tbody>
                          </Table>
                        }
                      </>
                    }
                  />
                </Box>
              </SimpleGrid>
            </Box>

            <Box p={3} mt={15} borderWidth='1px' rounded='md' bg='gray.50'>
              <Heading as='h3' size='md'>When to enable your stage</Heading>
              <Text color='gray.500' mb={5}>Choose when your stage will become available to participants during the trial.</Text>

              {isPoll &&
                <Alert status='info' variant='left-accent' mb={5}>
                  Since poll mode is enabled for this stage, viewers with the link to the poll will be able to view and submit responses to the poll at any time. The settings below only apply to full trial participants using the participant portal or mobile apps.
                </Alert>
              }

              <SimpleGrid columns={3} spacing={3}>
                <Box rounded='md' shadow='sm' bg='white' p={3}>
                  <RadioHeader checked={!enabled && !enableAfter} name='enable' value='none' title='Not available to participants'
                    onChange={e => {setEnabled(false); setEnableAfter(null)}}
                    content={
                      <>
                        <Text color='gray.500' mb={5}>This stage is not available to participants. Select this option if, for example, staff will complete this data on behalf of the participants.</Text>
                      </>
                    }
                  />
                </Box>

                <Box rounded='md' shadow='sm' bg='white' p={3}>
                  <RadioHeader checked={enabled && !enableAfter} name='enable' value='true' title='Available to participants'
                    onChange={e => {setEnabled(true); setEnableAfter(null)}}
                    content={
                      <>
                        <Text color='gray.500' mb={5}>The stage is available for completion by participants. This is useful if you want participants to be able to access this stage from "Day 0" or for controlled availability.</Text>
                      </>
                    }
                  />
                </Box>

                <Box rounded='md' shadow='sm' bg='white' p={3}>
                  <RadioHeader checked={!!enableAfter} name='enable' value='after' title='Make available after...'
                    onChange={e => {setEnabled(false); setEnableAfter({stage: null, delay: 0})}}
                    content={
                      <>
                        <Text color='gray.500' mb={5}>Enable this stage for participants after another stage has been completed. Please note that this means that this stage might become available to each participant on different dates, depending on when they completed the other stages.</Text>
                        {!!enableAfter &&
                          <VStack>
                            <HStack alignItems='center' justifyContent='center'>
                              <Text fontSize='sm'>Enable</Text>
                              <Input size='xs' placeholder='2' bg='white' w='50px' value={enableAfter?.delay} onChange={e => updateEnableAfter({ delay: e.target.value })} />
                              <Text fontSize='sm'>days after</Text>
                            </HStack>
                            <Text fontSize='sm'>the paticipant completes</Text>
                            <Select size='xs' bg='white' placeholder='Select another stage' w='100%'
                              value={`${enableAfter?.stage}${enableAfter?.stageRecurrence != null ? `:${enableAfter?.stageRecurrence}` : ''}`}
                              onChange={e => updateEnableAfter({ stage: e.target.value })}
                            >
                              {stages?.filter(s => s._id !== stage?._id).map(s => {
                                return s.isRecurring ?
                                  Array.from({length: s.recurringDuration}, (_, i) => i).map(recurrence =>
                                    <option value={`${s._id}:${recurrence}`} key={`${s._id}:${recurrence + 1}`}>{s.name} ({recurrence + 1})</option>)
                                :
                                  <option value={s._id} key={s._id}>{s.name}</option>
                              })}
                            </Select>
                          </VStack>
                        }
                      </>
                    }
                  />
                </Box>
              </SimpleGrid>
            </Box>

            <Box p={3} mt={15} borderWidth='1px' rounded='md' bg='gray.50'>
              <Heading as='h3' size='md'>Schedule your stage</Heading>
              <Text color='gray.500' mb={5}>Choose how participants will interact with your stage.</Text>

              <SimpleGrid columns={3} spacing={3}>
                <Box rounded='md' shadow='sm' bg='white' p={3}>
                  <RadioHeader checked={!isRecurring && !adHoc} name='schedule' value='none' title='This stage will not repeat'
                  onChange={e => selectSchedule(e.target.value)}
                  content={
                    <>
                      <Text color='gray.500' mb={5}>The stage can only be completed once.</Text>
                    </>
                  }
                  />
                </Box>

                <Box rounded='md' shadow='sm' bg='white' p={3}>
                <RadioHeader checked={isRecurring} name='schedule' value='recur' title='This stage will repeat'
                    onChange={e => selectSchedule(e.target.value)}
                    content={
                      <Box>
                        <Text color='gray.500' mb={5}>The trial will dynamically create versions of this stage based on your rules.</Text>
                        {isRecurring && <>
                          <Stack spacing={2} direction='row' justifyContent='center' alignItems='center'>
                            <Text fontSize='sm'>Repeat every</Text>
                            <Input size='xs' bg='white' w='50px' isDisabled={!isRecurring} placeholder='Days' type='number' value={recurringFrequency} onChange={e => setRecurringFrequency(e.target.value)}/>
                            <Text fontSize='sm'>days</Text>
                          </Stack>
                          <Stack mt={1} spacing={2} direction='row' justifyContent='center' alignItems='center'>
                            <Text fontSize='sm'>for</Text>
                            <Input size='xs' bg='white' w='50px' isDisabled={!isRecurring} placeholder='Number' type='number' value={recurringDuration} onChange={e => setRecurringDuration(e.target.value)}/>
                            <Text fontSize='sm'>occurrences</Text>
                          </Stack>
                        </>}
                      </Box>
                    }
                  />
                </Box>

                <Box rounded='md' shadow='sm' bg='white' p={3}>
                  <RadioHeader checked={adHoc} name='schedule' value='adHoc' title='Ad-hoc input'
                    onChange={e => selectSchedule(e.target.value)}
                    content={
                      <>
                        <Text color='gray.500' mb={5}>The trial will allow participants to complete this stage on an ad-hoc basis. This means that participants can repeatedly complete this stage at any time.</Text>
                      </>
                    }
                  />
                </Box>
              </SimpleGrid>
            </Box>
          
            {showPostSubmissionEditor && // Delay until stage is loaded
              <Box p={3} mt={15} borderWidth='1px' rounded='md' bg='gray.50'>
                <Heading as='h3' size='md'>Post-submission text</Heading>
                <Text color='gray.500' mb={5}>This text will be displayed to participants after they complete this stage.</Text>
                <RichText value={postSubmissionText} onChange={e => setPostSubmissionText(e)} forType='trial' forId={trial?._id} allowImages />
              </Box>
            }
          </TabPanel>

          {utils.hasFeature(team, 'pollMode') && <TabPanel>
            <PollModeEditor stage={stage} isPoll={isPoll} setIsPoll={setIsPoll} customLinks={customLinks} setCustomLinks={setCustomLinks} />
          </TabPanel>}
        </TabPanels>
      </Tabs>

      <Box mt={10} ml={10} mr={10} position='sticky' style={{bottom: 10}} boxShadow='lg' p={3} rounded='md' bg='primary.50'>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          {error ?
            <Alert mr={3} status='error' variant='left-accent'>
              <Box>
                <AlertTitle>There was a problem saving your stage</AlertTitle>
                <AlertDescription>{error}. Please fix these issues and try again.</AlertDescription>
              </Box>
            </Alert>
          : <Box />
          }
          <Button size='lg' colorScheme='primary' onClick={create}>{isEditing ? 'Save stage' : 'Create stage'}</Button>
        </Box>
      </Box>
    </>
  );
}

function ComponentFieldArea({ components, dataTypes, moveComponent, setSelectedComponent, deleteComponent, addComponent}) {
  const [{ handlerId }, drop] = useDrop({
    accept: 'COMPONENT',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    drop(item, monitor) {
      if (!monitor.didDrop()){
        if (item.typeId) addComponent(null, item.typeId, null); // data type
        if (item.type) addComponent(null, null, item.type); // built-in
      }
    },
  })

  return (
    <Box ref={drop} flex={1} ml={3} p={2} rounded='md' bg='gray.300' boxShadow='inner' maxH='700px' overflowY='scroll'>
      {components?.length === 0 &&
        <Box textAlign='center' mt={5}>
          <Heading size='md'>Your form doesn't have any fields yet</Heading>
          <Text mt={3}>Add fields to your form by dragging (or tapping) them in.</Text>
        </Box>
      }
      {components?.map((component, i) =>
        <ComponentItem key={component.id} component={component} index={i} components={components} dataTypes={dataTypes} moveComponent={moveComponent} setSelectedComponent={setSelectedComponent} deleteComponent={deleteComponent} addComponent={addComponent} />
      )}
    </Box>
  );
}

function ComponentMenuItem({ name, type, typeId, addComponent }) {
  const [{ isDragging }, drag] = useDrag({
    type: 'COMPONENT',
    item: () => {
      return { type, typeId }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  return (
    <Box ref={drag} bg='white' p={1} rounded='md' mr={2} mb={2} display='inline-block' cursor='grab' shadow='sm' transition='0.2s' _hover={{shadow: 'lg'}} onClick={e => addComponent(null, typeId)}>
      <Box display='flex' alignItems='center'>
        <Icon as={GrDrag} w={2} h={2} />
        <Text fontSize='sm' ml={1}>{name}</Text>
      </Box>
    </Box>
  );
}

function ComponentItem({ component, dataTypes, components, index, moveComponent, setSelectedComponent, deleteComponent, addComponent }) {
  const [isHoveringGrab, setIsHoveringGrab] = useState(false);
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: 'COMPONENT',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) return;
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) return
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return
      if (!item.typeId && !item.type) {
        moveComponent(component.id, dragIndex)
      }
      item.index = hoverIndex
    },
    drop(item) {
      if (item.typeId) addComponent(item.index, item.typeId, null); // data type
      if (item.type) addComponent(item.index, null, item.type); // built-in
    },
  })
  const [{ isDragging }, drag, preview] = useDrag({
    type: 'COMPONENT',
    item: () => {
      return { id: component.id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref));

  const clashedDataType = components?.filter(c => c.id !== component.id).map(c => c.dataType).indexOf(component.dataType) > -1;
  const dataType = dataTypes?.find(d => d._id === component.dataType);
  const conditionDataType = dataTypes?.find(d => d._id === component.condition?.dataType);
  return (
    <Box ref={ref} style={{ opacity }} bg={component.type ? null : 'white'} mb={component.type === 'sectionDivider' ? 4 : 2} mt={component.type === 'sectionDivider' ? 4 : null} p={3} position='relative' display='flex' justifyContent='space-between' alignItems='center' transition='0.25s' shadow={isHoveringGrab ? 'xl': null} _hover={{bg: component.type ? 'gray.400' : 'gray.100'}}>
      <Box w={30} display='flex' alignItems='center'>
        <Icon as={GrDrag} w={5} h={5} cursor='grab' onMouseEnter={e => setIsHoveringGrab(true)} onMouseLeave={e => setIsHoveringGrab(false)} />
      </Box>

      {component.type ?
        <Box mr={1} display='flex' flexDirection='column' alignItems='start' flex={1} cursor='pointer' onClick={() => setSelectedComponent(component)}>
          <Box display='flex'>
            <Heading size='sm'>
              {component.title || 'Untitled'}
            </Heading>
            {component.type === 'title' && <Tag ml={2} size='sm' colorScheme='blue'>Title</Tag>}
            {component.type === 'sectionDivider' && <Tag ml={2} size='sm' colorScheme='blue'>Section divider</Tag>}
          </Box>
        </Box>
      :
        <Box mr={1} display='flex' flexDirection='column' alignItems='start' flex={1} cursor='pointer' onClick={() => setSelectedComponent(component)}>
          <Box mb={2} display='flex' alignItems='center'>
            {dataType && <Tag mr={1} size='sm'>{dataType.name}</Tag>}
            {component.isRequired && <Badge colorScheme='red' fontSize={9} mr={2}>required</Badge>}
            {conditionDataType && <Badge size='xs' colorScheme='orange' fontSize={9} mr={2}>depends on {conditionDataType.name}</Badge>}
          </Box>
          <Box display='flex' alignItems='center'>
            <Heading size='sm'>{component.title || 'Untitled question'}</Heading>
            {clashedDataType && <Tooltip label='The chosen datatype is being used multiple times in this stage'><WarningTwoIcon ml={2} color='red.400' /></Tooltip>}
            {(!dataType || !component.title) && <Tooltip label='This component is not completed'><WarningTwoIcon ml={2} color='orange.400' /></Tooltip>}
          </Box>
        </Box>
      }

      <Box>
        <ConfirmButton
          trigger={<Button size='xs' ml={3} colorScheme='gray' variant='outline'><DeleteIcon /></Button>}
          header='Really delete this component?'
          footer='Any data collected so far for this component may be lost.'
          action={e => deleteComponent(component.id)}
          actionColor='red'
        />
      </Box>
    </Box>
  );
}

function ComponentEditor({ component, dataTypes, trial, updateComponent, onClose }) {
  const [isOpen, setIsOpen] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [componentDataType, setComponentDataType] = useState();
  const [componentIsRequired, setComponentIsRequired] = useState(false);
  const [componentTitle, setComponentTitle] = useState('');
  const [componentDescription, setComponentDescription] = useState('');
  const [conditional, setConditional] = useState(false);
  const [conditionDataType, setConditionDataType] = useState(null);
  const [conditionOperator, setConditionOperator] = useState('equal');
  const [conditionValue, setConditionValue] = useState('');

  useEffect(() => {
    if (component) {
      setComponentDataType(component.dataType);
      setComponentIsRequired(component.isRequired);
      setComponentTitle(component.title);
      setComponentDescription(component.description);
      setConditional(!!component.condition?.dataType);
      setConditionDataType(component.condition?.dataType);
      setConditionOperator(component.condition?.operator);
      setConditionValue(component.condition?.value);
    }
    setIsOpen(!!component);
    setChangesMade(false);
  }, [component]);

  useEffect(() => {
    if (isOpen && (component.dataType !== componentDataType ||
      component.isRequired !== componentIsRequired ||
      component.title !== componentTitle ||
      component.description !== componentDescription ||
      component.condition?.dataType !== componentDataType ||
      component.condition?.operator !== conditionOperator ||
      component.condition?.value !== conditionValue
    )) {
      setChangesMade(true);
    }
  }, [componentDataType, componentIsRequired, componentTitle, componentDescription])

  const keepChanges = () => {
    const newUpdate = { dataType: componentDataType, isRequired: componentIsRequired, title: componentTitle, description: componentDescription, condition: null };
    if (conditional) {
      let val = conditionValue;
      const conditionDataTypeObject = dataTypes?.find(d => d._id === conditionDataType);
      if (conditionDataTypeObject?.type === 'float') val = parseFloat(conditionValue);
      if (['integer', 'slider'].indexOf(conditionDataTypeObject?.type) > -1) val = parseInt(conditionValue);
      newUpdate.condition = {
        dataType: conditionDataType,
        operator: conditionOperator,
        value: val,
      };
    }
    updateComponent(component.id, newUpdate);
    setIsOpen(false);
  };

  const checkOnClose = () => {
    if (changesMade) {
      if (!window.confirm('You may have made changes to this component. Are you sure you want to close it?')) return;
    }
    setIsOpen(false);
    onClose();
  };

  const conditionDataTypeObject = dataTypes.find(d => d._id === conditionDataType);
  return (
    <Modal isOpen={isOpen} onClose={checkOnClose} size='3xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit field</ModalHeader>
        <ModalCloseButton />

        {component.type ?
          <ModalBody>
            {component.type === 'sectionDivider' && <>
              <FormControl isRequired mt={5}>
                <FormLabel>Section title</FormLabel>
                <Input value={componentTitle} onChange={e => setComponentTitle(e.target.value)}/>
              </FormControl>

              <FormControl mt={3}>
                <FormLabel>Section description</FormLabel>
                <RichText value={componentDescription} onChange={e => setComponentDescription(e)} forType='trial' forId={trial?._id} allowImages />
              </FormControl>
            </>}
            {component.type === 'title' && <>
              <FormControl isRequired mt={5}>
                <FormLabel>Title text</FormLabel>
                <Input value={componentTitle} onChange={e => setComponentTitle(e.target.value)}/>
              </FormControl>
            </>}
          </ModalBody>
        :
          <ModalBody>
            <FormControl isRequired>
              <FormLabel>Data type</FormLabel>
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Box display='flex' alignItems='center'>
                  <Select label='Data type to collect' placeholder='Select a data type' value={componentDataType} onChange={e => setComponentDataType(e.target.value)} maxW={300}>
                    {dataTypes?.map(d =>
                      <option key={d._id} value={d._id}>{d.name} ({d.type})</option>
                    )}
                  </Select>
                </Box>
              </Box>
            </FormControl>

            <FormControl mt={3} p={2} bg='gray.50'>
              <Checkbox isChecked={componentIsRequired} onChange={e => setComponentIsRequired(e.target.checked)}>Require that this component is completed when submitting data</Checkbox>
            </FormControl>

            <Box mt={5} p={2} bg='gray.50'>
              <Checkbox isChecked={conditional} onChange={e => setConditional(e.target.checked)}>Conditionally show this component</Checkbox>
              {conditional && <Box mt={3} display='flex' alignItems='center'>
                <Text fontSize='sm'>Show if</Text>
                <Select bg='white' ml={1} maxWidth={200} size='sm' placeholder='Choose a data type...'
                value={conditionDataType} onChange={e => {
                  const obj = dataTypes.find(d => d._id === e.target.value);
                  setConditionOperator('');
                  setConditionValue(obj.type === 'choice' ? [] : '');
                  setConditionDataType(e.target.value);
                }} >
                  {dataTypes?.filter(d => d._id !== component.dataType).map(d =>
                    <option key={d._id} value={d._id}>{d.name}</option>
                  )}
                </Select>
                <Select bg='white' ml={1} maxWidth={150} size='sm' value={conditionOperator} onChange={e => setConditionOperator(e.target.value)} placeholder='Choose a condition...'>
                  <option key='exists' value='exists'>exists</option>
                  {(['integer', 'slider', 'float', 'text', 'paragraph', 'choice'].indexOf(conditionDataTypeObject?.type) > -1) && <>
                    <option key='equal' value='equal'>is equal to</option>
                    <option key='notequal' value='notequal'>is not equal to</option>
                    {(['integer', 'slider', 'float'].indexOf(conditionDataTypeObject?.type) > -1) && <>
                      <option key='more' value='more'>is more than</option>
                      <option key='less' value='less'>is less than</option>
                    </>}
                  </>}
                </Select>
                {['equal', 'notequal', 'more', 'less'].indexOf(conditionOperator) > -1 && <>
                  {conditionDataTypeObject?.type === 'choice' && <Box>
                    <Text ml={2} fontSize='sm'>One of</Text>
                    {conditionDataTypeObject?.options?.map(option => {
                      const isSelected = conditionValue?.indexOf(option) > -1;
                      return (<Tag key={option} ml={1} mt={1} cursor='pointer' size='sm'
                        colorScheme={isSelected ? 'blue' : 'gray'}
                        onClick={e => {
                          const index = conditionValue?.indexOf(option);
                          const newCV = Object.assign([], conditionValue);
                          if (index === -1) newCV.push(option);
                          else newCV.splice(index, 1);
                          setConditionValue(newCV);
                        }}
                      >{isSelected ? <CheckIcon mr={1} /> : <AddIcon mr={1} />} {option}</Tag>);
                    })}
                  </Box>}
                  {['integer', 'float', 'text', 'paragraph', 'slider'].indexOf(conditionDataTypeObject?.type) > -1 &&
                    <Input bg='white' ml={1} maxWidth={200} size='sm' placeholder='Enter a value...' value={conditionValue} onChange={e => {
                      const val = e.target.value;
                      if (['text', 'paragraph'].indexOf(conditionDataTypeObject.type) > -1)
                        setConditionValue(val);
                      else if (['integer', 'slider'].indexOf(conditionDataTypeObject.type) > -1)
                        setConditionValue(val === '' ? '' : val.replace(/[^\d]/g, ''));
                      else if (['float'].indexOf(conditionDataTypeObject.type) > -1)
                        setConditionValue(val === '' ? '' : val.replace(/[^\d.]/g, ''));
                    }} />
                  }
                </>}
              </Box>}
            </Box>

            <FormControl isRequired mt={5}>
              <FormLabel>Question title</FormLabel>
              <Input value={componentTitle} onChange={e => setComponentTitle(e.target.value)}/>
            </FormControl>

            <FormControl mt={3}>
              <FormLabel>Question description or instructions</FormLabel>
              <RichText value={componentDescription} onChange={e => setComponentDescription(e)} forType='trial' forId={trial?._id} allowImages />
            </FormControl>
          </ModalBody>
        }
        <ModalFooter>
          <Button variant='ghost' mr={3} onClick={checkOnClose}>Cancel</Button>
          <Button colorScheme='blue' onClick={keepChanges}>Keep changes</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default NewStage;
