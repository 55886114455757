import React from 'react';
import { Box, Heading, Badge, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';

import useStore from '../../../store';
import useTitle from '../../../hooks/useTitle';
import Dashboards from './Dashboards';
import Explore from './Explore';
import Export from './Export';
import Integrations from './Integrations';

export default function Results() {
  const { trial } = useStore();
  useTitle('Results', trial);
  return (
    <Box>
      <Heading as='h2' size='lg' mb={5}>Trial results</Heading>
      <Tabs>
        <TabList>
          <Tab>Dashboards <Badge ml={2} colorScheme='yellow'>Beta</Badge></Tab>
          <Tab>Explore</Tab>
          <Tab>Export</Tab>
          <Tab>Integrations</Tab>
        </TabList>
        <TabPanels pt={5}>
          <TabPanel>
            <Dashboards />
          </TabPanel>
          <TabPanel>
            <Explore />
          </TabPanel>
          <TabPanel>
            <Export />
          </TabPanel>
          <TabPanel>
            <Integrations />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
