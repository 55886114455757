import React from 'react';
import { Box, Stack, Badge, FormControl, FormLabel, FormHelperText, Input } from '@chakra-ui/react';

export default function ShortText({ dataType, value, onChange }) {
  
  return (
    <Box>
      <FormControl>
        <Input bg='white' value={value || ''} onChange={e => onChange(e.target.value)} />
        <FormHelperText>
          {value &&
            <Stack spacing={2} direction='row'>
              {dataType?.minimumLength && <Badge colorScheme={value.length < dataType.minimumLength ? 'red' : 'green'}>Min length: {dataType.minimumLength}</Badge>}
              {dataType?.maximumLength && <Badge colorScheme={value.length > dataType.maximumLength ? 'red' : 'green'}>Max length: {dataType.maximumLength}</Badge>}
            </Stack>
          }
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
