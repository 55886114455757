import React, { useState, useEffect } from 'react';
import { Box, Icon, Button, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody, Alert, CircularProgress, Heading, Text } from '@chakra-ui/react';
import { FaPlayCircle, FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import api from '../../api';
import ConfirmButton from './ConfirmButton';

export default function PrivateVideo({ forType, forObject, name, withPreview, thumb, isProcessing, hasError, onRemove }) {
  const [url, setUrl] = useState();
  const [thumbUrl, setThumbUrl] = useState();
  const [openFull, setOpenFull] = useState(false);
  const forId = forObject?._id;

  useEffect(() => {
    if (forType && forId && name) {
      api.uploads.generateFileDownloadRequest({ forType, forId, name }, ({ request }) => {
        setUrl(request);
      });
    }
  }, [forType, forId, name]);

  useEffect(() => {
    if (forType && forId && thumb) {
      api.uploads.generateFileDownloadRequest({ forType, forId, name: thumb }, ({ request }) => {
        setThumbUrl(request);
      });
    }
  }, [forType, forId, thumb]);

  if (!url) return null;
  const isMp4 = url?.toLowerCase()?.indexOf('.mp4') > -1
  return (<>
    {withPreview ?
      <Box>
        <Box h='100px' w='100px' bg='gray.300' rounded='md' cursor='pointer' display='flex' justifyContent='center' alignItems='center'
        backgroundImage={thumbUrl} backgroundSize='cover' backgroundPosition='center center'
        transition='0.25s' _hover={{shadow: 'lg'}}
          onClick={() => setOpenFull(true)}
        >
          <Icon as={FaPlayCircle} color='blue.600' w='50px' h='50px' />
        </Box>

        <Modal size='5xl' isOpen={openFull} onClose={() => setOpenFull(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            {!isMp4 && isProcessing && <VideoLoading url={url} />}
            {isMp4 &&
              <video autoPlay controls style={{maxwidth: '100%', maxHeight:'500px'}}>
                <source src={url} />
              </video>
            }
          </ModalContent>
        </Modal>
      </Box>
    :
      <Box display='flex' justifyContent='center' mt={5}>
        <Box p={3} bg='white' minWidth='400px' maxWidth='80%' rounded='md' shadow='md'>
          {onRemove &&
            <Box mb={3} display='flex' justifyContent='end'>
              <ConfirmButton
                header='Really remove this video?'
                trigger={<Button variant='ghost'><Icon as={FaTimes} mr={2} /> Remove</Button>}
                action={onRemove}
              />
            </Box>
          }
          {!isMp4 && !hasError && isProcessing && <VideoLoading url={url} />}
          {hasError &&
            <Box mb={3}>
              <Alert size='xs' variant='left-accent' status='warning'>
                <Box>
                  <Text>There was an error processing this video. Please try again.</Text>
                  <Button mt={3} size='sm' colorScheme='blue' as='a' target='_blank' rel='noopener noreferrer' href={url}>Download unprocessed video</Button>
                </Box>
              </Alert>
            </Box>
          }
          {isMp4 &&
            <video controls style={{width: '100%', maxHeight: '500px'}}>
              <source src={url} />
            </video>
          }
        </Box>
      </Box>
    }
  </>);
}

function VideoLoading({ url }) {
  return (
    <Alert size='xs' variant='left-accent'>
      <CircularProgress size='40px' isIndeterminate mr={5} />
      <Box>
        <Heading size='sm'>Trialflare is processing this video in the background</Heading>
        <Text>It is safe to continue and save or submit your changes. Please come back later to view the video.</Text>
        <Button mt={5} size='sm' colorScheme='blue' variant='outline' as='a' target='_blank' rel='noopener noreferrer' href={url}>Download non-processed version</Button>
      </Box>
    </Alert>
  )
}
