import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { createStandaloneToast, Box } from '@chakra-ui/react'
import api from '../api';
import useStore from '../store';

import NavBar from './includes/NavBar';
import Footer from './includes/Footer';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  }
`;

const { ToastContainer } = createStandaloneToast()

export default function App() {
  const location = useLocation();
  const { user, completeLogin, setTeam } = useStore();
  const teamId = user?.team;

  useEffect(() => {
    api.accounts.autoLogin(token => completeLogin(token));
  }, []);

  useEffect(() => {
    if (teamId) api.teams.get(teamId, t => setTeam(t));
  }, [teamId]);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location]);

  return (<>
    <GlobalStyle />
    <Box display='flex' flexDirection='column' style={{minHeight: '100vh'}}>
      <NavBar />
      <Box flex={1}>
        <Outlet />
        <ToastContainer />
      </Box>
      <Footer />
    </Box>
  </>);
}
