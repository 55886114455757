import React, { useState, useEffect } from 'react';
import { useToast, Box, Heading, Text, Button, FormControl, FormLabel, Input } from '@chakra-ui/react';
import api from '../../api';
import useStore from '../../store';
import utils from '../../utils/utils.js';

function TeamIntegrations({  }) {
  const [loading, setLoading] = useState(false)
  const [nutriticsUsername, setNutriticsUsername] = useState('');
  const [nutriticsPassword, setNutriticsPassword] = useState('');
  const toast = useToast();
  const { user, team, updateTeam } = useStore();

  useEffect(() => {
    if (team) {
      setNutriticsUsername(team.services?.nutritics?.username || '');
      setNutriticsPassword(team.services?.nutritics?.password || '');
    }
  }, [team]);

  function updateNutriticsDetails() {
    setLoading(true);
    api.teams.updateNutriticsDetails(team._id, { username: nutriticsUsername, password: nutriticsPassword }, newDetails => {
      toast({title: 'Details updated', status: 'success'});
      setLoading(false);
      const newTeam = Object.assign({}, team, {services: {nutritics : newDetails}});
      updateTeam(newTeam);
    }, err => {
      toast({title: 'Unable to update details', description: err.message, status: 'error'});
      setLoading(false);
    });
  }

  return (
    <>
      {utils.hasPermission(user, 'team.manage', team?._id) ?
        <Box p={3} rounded='md' borderWidth='1px' m={1}>
          <Heading as='h3' size='md'>Nutritics</Heading>
          <Text color='gray.700'>Trialflare can use Nutritics as a database source when using the "Food" data type in a trial. If your organisation already possesses a Nutritics license, you can use your own credentials here. Alternatively, you can subscribe to a Trialflare plan that includes Nutritics access.</Text>
  
          <Box display='flex' mt={5}>
            <FormControl>
              <FormLabel>Nutritics username (email address)</FormLabel>
              <Input type='email' value={nutriticsUsername} onChange={e => setNutriticsUsername(e.target.value)}/>
            </FormControl>
  
            <FormControl ml={5}>
              <FormLabel>Nutritics password</FormLabel>
              <Input type='password' value={nutriticsPassword} onChange={e => setNutriticsPassword(e.target.value)} />
            </FormControl>
          </Box>
          <Button loading={loading} onClick={updateNutriticsDetails} colorScheme='primary' mt={5}>Save Nutritics details</Button>
        </Box>
      :
        <Box>
          <Heading>Page unavailable</Heading>
          <Text>This page is only viewable by users that manage the team</Text>
        </Box>
      }
    </>
  );
}

export default TeamIntegrations;
