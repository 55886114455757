import api from '.';

export const groups = {
  update(id, data, success, fail) {
    api.authenticatedRequest('PUT', `/groups/${id}`, data, success, fail);
  },
  delete(id, success, fail) {
    api.authenticatedRequest('DELETE', `/groups/${id}`, null, success, fail);
  },
  addMember(id, participantId, success, fail) {
    api.authenticatedRequest('PUT', `/groups/${id}/memberships/${participantId}`, null, success, fail);
  },
  removeMember(id, participantId, success, fail) {
    api.authenticatedRequest('DELETE', `/groups/${id}/memberships/${participantId}`, null, success, fail);
  },
};
