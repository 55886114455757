import React, { useState } from 'react';
import { Box, Heading, Text, Button, Select, Input, Checkbox, Alert, AlertTitle, AlertDescription, List, ListItem, ListIcon, FormControl, FormLabel, FormHelperText, Link as ChakraLink } from '@chakra-ui/react';
import { CheckIcon, CheckCircleIcon } from '@chakra-ui/icons';
import { FaStar } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { api } from '../../api';
import useStore from '../../store';

import EntryView from '../includes/EntryView';
import Terms from '../includes/Terms';

export default function Signup() {
  const [currentScreen, setCurrentScreen] = useState(0);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [teamName, setTeamName] = useState('');
  const [teamType, setTeamType] = useState('limitedCompany');
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const store = useStore();

  const createAccount = (cb) => {
    if (!email || !password || !firstName || !lastName || !termsAgreed) return setErrorMessage('Please complete all of the fields.');
    setErrorMessage('');
    setLoading(true);
    api.accounts.create(email, password, firstName, lastName, termsAgreed, (data) => {
      setLoading(false);
      store.completeLogin(data.token);
      cb(data);
    }, (err) => {
      setLoading(false);
      setErrorMessage(err.message);
    });
  }

  const createTeam = () => {
    if (!teamName || !teamType) return setErrorMessage('Please enter information about your team.');
    createAccount((newUser) => {
      setLoading(true);
      setErrorMessage('');
      api.teams.create({ name: teamName, type: teamType, researchAreas: [] }, team => {
        api.users.me(u => store.updateUser(u._id, u)); // Refresh user to get permissions on the UI
        navigate('/setup');
      }, err => {
        setLoading(false);
        setErrorMessage(err.message);
      });
    });
  }

  return (
    <EntryView
      left={
        <div>
          <Heading size='md' mb={2}>Already got a Trialflare account?</Heading>
          <Button as={Link} to='/' colorScheme='green'>Login</Button>
        </div>
      }
      right={
        <Box>
          {currentScreen === 0 && <Box>
            <Heading size='lg'>Welcome to Trialflare</Heading>
            <Text>You're joining the other happy organisations around the world that we already help to collect meaningful data as part of clinical trials, blinded surveys, and more.</Text>

            <Button mt={5} size='lg' colorScheme='primary' onClick={e => setCurrentScreen(1)}>Get started</Button>

            <Heading size='md' mt={5}>About your new account</Heading>
            <Text>You're signing up for a free team account.</Text>
            <List spacing={3} mt={3}>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color='green.500' /> No credit card or payment is required.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color='green.500' /> Get access to the participant mobile app.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color='green.500' /> Single sign-on for your team.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color='green.500' /> Two-factor authentication & robust logging.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color='green.500' /> Group participants by arm or treatment.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color='green.500' /> Export collected data to CSV or Excel.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color='green.500' /> 1 trial limit.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color='green.500' /> 1 team seat.
              </ListItem>
              <ListItem>
                <ListIcon as={CheckCircleIcon} color='green.500' /> 5 participants.
              </ListItem>
            </List>

            <Box rounded='md' bg='gray.100' mt={5} p={3}>
            <Heading size='sm'>You can choose to upgrade your account at any time to get extra features. The following are not included in the free account.</Heading>
            <List spacing={3} mt={3}>
              <ListItem>
                <ListIcon as={FaStar} color='yellow.500' /> Dedicated account manager, full training for your team, and ongoing support.
              </ListItem>
              <ListItem>
                <ListIcon as={FaStar} color='yellow.500' /> Up to unlimited trials, team seats, and participants.
              </ListItem>
              <ListItem>
                <ListIcon as={FaStar} color='yellow.500' /> Multi-site access and strict role-based access control.
              </ListItem>
              <ListItem>
                <ListIcon as={FaStar} color='yellow.500' /> Export data to SPSS, Graphpad, and more.
              </ListItem>
              <ListItem>
                <ListIcon as={FaStar} color='yellow.500' /> Integrations with Microsoft Office365, Google Drive, Dropbox, and more.
              </ListItem>
              <ListItem>
                <ListIcon as={FaStar} color='yellow.500' /> Advanced data capture, such as images and food diaries (including barcode scanning).
              </ListItem>
              <ListItem>
                <ListIcon as={FaStar} color='yellow.500' /> Cognitive testing suite (including colour and memory tests).
              </ListItem>
            </List>
            </Box>

            <Alert mt={5} status='success' variant='left-accent' flexDirection="column" alignItems='flex-start'>
              <AlertTitle mb={2}>About the data you collect</AlertTitle>
              <AlertDescription>Data you collect through trials and surveys on Trialflare is owned by you and your team - we just securely look it after it for you. You can view or download it at any time and we never give it to third parties.</AlertDescription>
            </Alert>

            <Button mt={10} size='lg' colorScheme='primary' onClick={e => setCurrentScreen(1)}>Get started</Button>
          </Box>}

          {currentScreen === 1 && <Box>
            <Heading size='xl' mb={3}>Sign-up</Heading>
            <Text>Creating an account takes less than one minute.</Text>

            <Box mt={5} p={3} bg='gray.100' rounded='md'>
              <Heading mb={3} size='md'><strong>Step 1 of 2:</strong> About you</Heading>
              <Heading size='sm' mb={5}>To begin, please tell us a bit about yourself.</Heading>

              <FormControl mt={5}>
                <FormLabel htmlFor="password">First name</FormLabel>
                <Input autoFocus bg='white' size='lg' placeholder='Jane' onChange={e => setFirstName(e.target.value)} value={firstName}/>
                <FormHelperText id="password-helper-text">
                  Your name allows other people in your team to identify you.
                </FormHelperText>
              </FormControl>

              <FormControl mt={5}>
                <FormLabel htmlFor="password">Last name</FormLabel>
                <Input bg='white' size='lg' placeholder='Doe' onChange={e => setLastName(e.target.value)} value={lastName}/>
              </FormControl>

              <FormControl mt={5}>
                <FormLabel htmlFor="password">Your <strong>work</strong> email address</FormLabel>
                <Input bg='white' size="lg" type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder='jane@company.com'/>
              </FormControl>

              <FormControl mt={5}>
                <FormLabel htmlFor="password">Enter a strong and unique password</FormLabel>
                <Input bg='white' size='lg' id='password' placeholder='Enter a secure password...' type="password" value={password} onChange={e => setPassword(e.target.value)} aria-describedby="password-helper-text"/>
                <FormHelperText id="password-helper-text">
                  This needs to be at least 8 characters long.
                </FormHelperText>
              </FormControl>
            </Box>

            {firstName.length > 1 && email.length > 4 && password.length > 1 && <>
              <Box mt={8} p={3} bg='gray.100' rounded='md'>
                <Heading size='md' mb={3}>Step 2 of 2: Create your team</Heading>
                <Heading size='sm' mb={1}>Thanks, {firstName}. Now please tell us a bit about the team you are creating</Heading>
                <Alert status='info' variant='left-accent' mt={3} mb={3}>Once created we'll add you as the owner of your team.</Alert>
                <Text>Team name</Text>
                <Input bg='white' size='lg' placeholder='Organisation or department name' value={teamName} onChange={e => setTeamName(e.target.value)}/>
                <Text mt={3}>What type of organisation are you a part of?</Text>
                <Select bg='white' size='lg' placeholder='Select a type...' value={teamType} onChange={e => setTeamType(e.target.value)}>
                  <option value='soleTrader'>Sole trader</option>
                  <option value='llp'>LLP</option>
                  <option value='limitedCompany'>Limited company</option>
                  <option value='publicCompany'>Public company</option>
                  <option value='companyDepartment'>Company department or division</option>
                  <option value='school'>School or college</option>
                  <option value='university'>University</option>
                  <option value='charity'>Charity</option>
                  <option value='otherResearch'>Research organisation</option>
                  <option value='other'>Other</option>
                </Select>
              </Box>

              <Terms value={termsAgreed} onChange={setTermsAgreed} />

              {errorMessage &&
                <Alert status="warning" variant="left-accent" mt={3}>
                  <AlertTitle>{errorMessage}</AlertTitle>
                </Alert>
              }

              <Button size='lg' mt={8} colorScheme="primary" onClick={createTeam} isLoading={loading}><CheckIcon mr={2} /> Continue</Button>

              </>}
            </Box>
            }
          </Box>
        } />
  );
}
