import React, { useState, useEffect, useCallback } from 'react';
import { useToast, Input, Textarea, Heading, Text, Stack, Badge, Tag, Button, Box, Progress, CircularProgress, CircularProgressLabel, Table, Thead, Tr, Th, Tbody, Td, Alert, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Icon } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import { FaTimesCircle, FaImage, FaVideo, FaFile } from 'react-icons/fa';
import moment from 'moment';
import api from '../../../api';
import useStore from '../../../store';
import utils from '../../../utils/utils';

import PrivateFile from '../../includes/PrivateFile';
import PrivateImage from '../../includes/PrivateImage';
import PrivateVideo from '../../includes/PrivateVideo';
import SimplePopover from '../../includes/SimplePopover';
import SimpleTable from '../../includes/SimpleTable';
import SimpleStat from '../../includes/SimpleStat';
import BMITag from '../../includes/BMITag';
import EmptyBox from '../../includes/EmptyBox';
import ResponseEditor from './ResponseEditor';
import DataImage from '../../../images/dataProcessing.png';

export default function ResultsParticipant({ trialId, participantId }) {
  const [loading, setLoading] = useState(false);
  const [dataTypes, setDataTypes] = useState([]);
  const [stages, setStages] = useState([]);
  const [drafts, setDrafts] = useState();
  const [results, setResults] = useState();
  const [participant, setParticipant] = useState();
  const [inputDataStage, setInputDataStage] = useState();
  const toast = useToast();
  const { user, trial, updateTrial } = useStore();

  useEffect(() => {
    api.trials.getDataTypes(trialId, d => setDataTypes(d.dataTypes));
    api.trials.getStages(trialId, d => setStages(d.stages));
    fetchResponses();
  }, [trialId, participantId]);

  const fetchResponses = () => {
    setLoading(true);
    api.trials.getParticipantResponses(trialId, participantId, ({ drafts, responses, participant }) => {
      setLoading(false);
      setDrafts(drafts);
      setResults(responses);
      setParticipant(participant);
    }, err => setLoading(false));
  };

  return (
    <>
      {results && participant &&
        <>
          <Box display='flex' justifyContent='end' alignItems='center' mb={5}>
            {utils.canReadTrial(user, trial) &&
              <Button as={Link} colorScheme='primary' size='sm' to={`/trials/${trialId}/results`}>Export results</Button>
            }
          </Box>

          {stages.map(stage => {
            const stageResponses = results?.filter(r => r.stage === stage._id);
            return (
              <Box key={stage._id} p={3} mb={8} borderWidth='2px' borderColor='gray.200' rounded='md'>
                <Box display='flex' justifyContent='space-between' mb={3}>
                  <Heading as='h3' size='md'>{stage.name}</Heading>

                  {utils.canWriteParticipant(user, trial, participant) &&
                    <Button size='sm' colorScheme='teal' onClick={e => setInputDataStage(stage)}>
                      {(stage.isRecurring || stage.adHoc) && <span>Add data for this stage</span>}
                      {!stage.adHoc && !stage.isRecurring && !stageResponses.length && <span>Set data for this stage</span>}
                      {!stage.adHoc && !stage.isRecurring && stageResponses.length > 0 && <span>Change the data for this stage</span>}
                    </Button>
                  }
                </Box>

                {!(stage.enabled || stage.enableAfter) && <Alert mb={3} variant='left-accent'>This stage is not available to participants to complete themselves.</Alert>}

                {stageResponses.length > 0 ?
                  <>
                    {stageResponses.map(stageResponse =>
                      <div key={stageResponse._id}>
                        {stage.isRecurring &&
                          <Heading size='sm' mt={4} mb={2}>Recurrence: {stageResponse.stageRecurrence ? (stageResponse.stageRecurrence + 1) : 1}</Heading>
                        }
                        <Table variant='striped' size='sm'>
                        <Thead>
                          <Tr>
                            <Th width={190}>Date</Th>
                            <Th>Note</Th>
                            <Th>Data type</Th>
                            <Th>Value</Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {dataTypes?.map(dataType => {
                            if (!dataType) return null;
                            const dataResponse = stageResponse?.response && stageResponse.response[dataType?._id];
                            let value = dataResponse?.value;
                            if (value === null || value === undefined) return null;

                            if (dataType.type === 'choice' && (typeof value === 'string' || value instanceof String)) {
                              value = value.split(',');
                            }

                            return (<Tr key={dataType._id}>
                              <Td>{moment(dataResponse.createdAt).format('DD/MM/YYYY LTS')}</Td>
                              <Td>{dataResponse?.updateNote || ''}</Td>
                              <Td>{dataType.name} ({dataType.type})</Td>
                              <Td>
                                {['text', 'paragraph', 'integer', 'float', 'slider'].indexOf(dataType.type) > -1 &&
                                  <Text>{value.toString()}</Text>
                                }
                                {dataType.type === 'choice' && value.map(chosen => <Badge key={chosen} mr={1} colorScheme='blue' variant='outline'>{chosen}</Badge>)}
                                {dataType.type === 'date' && <Text>{moment(value).format('L')}</Text>}
                                {dataType.type === 'datetime' && <Text>{moment(value).format('L LT')}</Text>}
                                {dataType.type === 'temperature' && <Text>{parseFloat(value)?.toFixed(2)} °C</Text>}
                                {dataType.type === 'weight' && <Text>{parseFloat(value)?.toFixed(2)} kg</Text>}
                                {dataType.type === 'height' && <Text>{parseFloat(value)?.toFixed(2)} cm</Text>}
                                {dataType.type === 'file' && <PrivateFile forType='participant' forObject={participant} name={value.fileName} displayName={value.name} preview />}
                                {dataType.type === 'image' && <PrivateImage forType='participant' forObject={participant} name={value.fileName} withPreview />}
                                {dataType.type === 'video' && <PrivateVideo forType='participant' forObject={participant} name={value.fileName} thumb={value.thumb} withPreview isProcessing={value.needsVideoProcess}/>}
                                {dataType.type === 'food' && <Text>{value.amountConsumed}g {value.name}</Text>}
                                {dataType.type === 'bmi' && <BMITag bmi={value?.bmi} />}
                                {dataType.type === 'stroop' &&
                                  <SimplePopover
                                    contentProps={{w: '500px'}}
                                    trigger={<Button size='xs' colorScheme='blue' variant='outline'>View results</Button>}
                                    header='Stroop test results'
                                    body={
                                      <Box maxH='400px' overflowY='scroll'>
                                        <Stack direction='row' mb={5} p={2} bg='gray.50'>
                                          <SimpleStat name='Attempts' number={value?.length} helpText={`${(value?.length / 60).toFixed(2)} per second`} />
                                          <SimpleStat name='Correct guesses' number={value?.filter(v => v.selectedOption.toLowerCase() === v.displayedColourName.toLowerCase()).length} total={value?.length} helpTextAsPercentage />
                                        </Stack>
                                        <SimpleTable
                                          headers={['Displayed', 'Selected', 'Time elapsed (ms)']}
                                          rows={value?.map((stroopResult, j) => [
                                            <span style={{color: stroopResult.displayedColour}}>{stroopResult.displayedOption}</span>,
                                            <Text>
                                              {stroopResult.selectedOption}
                                              {stroopResult.selectedOption.toLowerCase() === stroopResult.displayedColourName.toLowerCase() ? <CheckCircleIcon ml={2} color='green.500' /> : <Box as={FaTimesCircle} ml={2} color='red.500' display='inline-block'/>}
                                            </Text>,
                                            stroopResult.timeElapsed,
                                          ])}
                                        />
                                      </Box>
                                    }
                                  />
                                }
                                {dataType.type === 'pal' &&
                                  <SimplePopover
                                    contentProps={{w: '500px'}}
                                    trigger={<Button size='xs' colorScheme='blue' variant='outline'>View results</Button>}
                                    header='Paired Associates Learning test results'
                                    body={
                                      <Box maxH='400px' overflowY='scroll'>
                                        <Stack direction='row' mb={5} p={2} bg='gray.50'>
                                          <SimpleStat name='Correct answers' number={value?.filter(r => r.userAnsweredCorrectly)?.length} total={value?.length} helpTextAsPercentage />
                                        </Stack>
                                        <SimpleTable
                                          headers={['Prompt', 'Answer', 'Related', 'Correct']}
                                          rows={value?.map((result, j) => [
                                            <Text>{result.question}</Text>,
                                            <Text>{result.userAnswer}</Text>,
                                            <Text>{result.promptIsRelated ? 'True' : 'False'}</Text>,
                                            <Text>{result.userAnsweredCorrectly ? <CheckCircleIcon color='green.500' /> : <Icon as ={FaTimesCircle} color='red.500' /> }</Text>,
                                          ])}
                                        />
                                      </Box>
                                    }
                                  /> 
                                }
                                {dataType.type === 'ravlt' &&
                                  <SimplePopover
                                    contentProps={{w: '500px'}}
                                    trigger={<Button size='xs' colorScheme='blue' variant='outline'>View results</Button>}
                                    header='Rey Auditory Verbal Learning Test results'
                                    body={
                                      <Box maxH='400px' overflowY='scroll'>
                                        <Stack direction='row' mb={5} p={2} bg='gray.50'>
                                          <SimpleStat name='First test recall' number={value?.rememberedTestSets[0]?.length} total={15} helpTextAsPercentage />
                                          <SimpleStat name='Final test recall' number={value?.rememberedTestSets[value?.rememberedTestSets?.length - 1]?.length} total={15} helpTextAsPercentage />
                                        </Stack>
                                        <SimpleTable
                                          headers={['Test set', 'Recalled words', 'Recall']}
                                          rows={[
                                            ...value?.rememberedTestSets?.map((testSet, i) => [
                                              `Test ${i + 1}`,
                                              testSet?.map(word => <Text key={word} fontSize='xs'>{word}</Text>),
                                              `${parseInt(((testSet?.length || 0) / 15) * 100)}%`,
                                            ]),
                                            [
                                              'Interruption set',
                                              value?.rememberedInterruptionSet?.map(word => <Text key={word} fontSize='xs'>{word}</Text>),
                                              `${parseInt(((value?.rememberedInterruptionSet?.length || 0) / 15) * 100)}%`,
                                            ]
                                          ]}
                                        />
                                      </Box>
                                    }
                                  />
                                }
                              </Td>

                            </Tr>);
                          })}
                        </Tbody>
                        </Table>
                      </div>
                    )}
                  </>
                  :
                    <EmptyBox
                      image={DataImage}
                      title='No data has been recorded for this stage yet'
                      description='Once you or participants add data, it will be displayed here.'
                    />
                  }

              </Box>
            );
          })}
        </>
      }

      <ResponseEditor dataTypes={dataTypes} participant={participant} stage={inputDataStage} responses={results} drafts={drafts} onClose={() => setInputDataStage(null)} fetchResponses={fetchResponses} />
    </>
  );
}
