import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Table, Thead, Tbody, Tr, Td, Th, Menu, MenuButton, MenuList, MenuItem, Button, IconButton, Tag, Text, Select, Alert, Input } from '@chakra-ui/react';
import { CloseIcon, CheckIcon } from '@chakra-ui/icons';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import api from '../../../api';
import EmptyBox from '../../includes/EmptyBox';

import NotFoundImage from '../../../images/notFound.png';
import PrivateImage from '../../includes/PrivateImage';
import BMITag from '../../includes/BMITag';

export default function Explore() {
  const [stages, setStages] = useState([]);
  const [dataTypes, setDataTypes] = useState([]);
  const [dataTypeFilter, setDataTypeFilter] = useState('');
  const [results, setResults] = useState([]);
  const [columns, setColumns] = useState([]);
  const [availableGroups, setAvailableGroups] = useState([]);
  const [group, setGroup] = useState('');
  const [stage, setStage] = useState('');
  const [stageRecurrence, setStageRecurrence] = useState();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const trialId = id;

  useEffect(() => {
    if (!trialId || !stage) return;
    setLoading(true);
    api.trials.getResponsesExplore(trialId, { columns, group, stage }, r => {
      setResults(r.results);
      setLoading(false);
    });
  }, [trialId, columns, group, stage]);

  // Fetch groups, stages, dataTypes to show in the selector
  useEffect(() => {
    if (!trialId) return;
    api.trials.getGroups(trialId, r => setAvailableGroups(r.groups));
    api.trials.getStages(trialId, d => setStages(d.stages));
    api.trials.getDataTypes(trialId, d => setDataTypes(d.dataTypes));
  }, [trialId]);

  // Auto-select the first stage in the list
  useEffect(() => {
    if (stage) return;
    if (stages?.length) {
      setStage(stages[0]._id);
    }
  }, [stages]);

  // Auto-select first few data types
  useEffect(() => {
    if (dataTypes?.length === 0) return;
    if (columns?.length > 0) return;
    const newColumns = [];
    dataTypes.slice(0, 4)?.map(d => newColumns.push(d._id));
    setColumns(newColumns);
  }, [dataTypes]);

  // Build list of columns
  const availableColumns = [
    {key: 'createdAt', name: 'Signed-up', format: v => moment(v).fromNow()},
  ];
  dataTypes?.forEach(d => {
    const col = {key: d._id, name: d.name};
    if (d.type === 'text' || d.type === 'paragraph' || d.type === 'integer' || d.type === 'float' || d.type === 'slider') {
      col.format = r => <Text>{r?.value}</Text>;
    }
    else if (d.type === 'choice') {
      col.format = r => {
        const rVal = Array.isArray(r?.value) ? r?.value : [r?.value];
        return rVal?.map(v => <Tag colorScheme='blue' mr={1} mb={1}>{v}</Tag>);
      };
    }
    else if (d.type === 'date') {
      col.format = r => <Text>{r?.value && moment(r.value).format('L')}</Text>;
    }
    else if (d.type === 'datetime') {
      col.format = r => <Text>{r?.value && moment(r.value).format('LT')}</Text>;
    }
    else if (d.type === 'food') {
      col.format = r => <Text>{r?.value?.amountConsumed}g {r?.value?.name}</Text>;
    }
    else if (d.type === 'image') {
      col.format = (r, p) => <PrivateImage forType='participant' forObject={p} name={r?.value?.fileName} withPreview />;
    }
    else if (d.type === 'temperature') {
      col.format = r => <Text>{!isNaN(r?.value) ? `${parseFloat(r?.value)?.toFixed(2)} °C` : ''}</Text>
    }
    else if (d.type === 'weight') {
      col.format = r => <Text>{!isNaN(r?.value) ? `${parseFloat(r?.value)?.toFixed(2)} kg` : ''}</Text>
    }
    else if (d.type === 'height') {
      col.format = r => <Text>{!isNaN(r?.value) ? `${parseFloat(r?.value)?.toFixed(2)} cm` : ''}</Text>
    }
    else if (d.type === 'bmi') {
      col.format = r => <BMITag bmi={r?.value?.bmi} />;
    }
    else return; // Otherwise don't include in column list
    availableColumns.push(col);
  });

  const removeColumn = key => {
    const index = columns.indexOf(key);
    if (index > -1) {
      const newColumns = Object.assign([], columns);
      newColumns.splice(index, 1);
      setColumns(newColumns);
    }
  };
  const addColumn = key => {
    const index = columns.indexOf(key);
    if (index === -1) {
      const newColumns = Object.assign([], columns);
      newColumns.push(key);
      setColumns(newColumns);
    }
  };


  const filteredAvailableColumns = dataTypeFilter ?
    availableColumns?.filter(c => c.name.toLowerCase().indexOf(dataTypeFilter.toLowerCase()) > -1)
  : availableColumns;

  return (
    <Box>
      <Box display='flex'>
        {availableGroups?.length > 0 &&
          <Select mr={2} placeholder='Show all participants' maxW={200} value={group} size='sm'
            onChange={e => setGroup(e.target.value)}
          >
            {availableGroups?.map(g => <option value={g._id} key={g._id}>Show {g.name}</option>)}
          </Select>
        }
        {stages?.length > 0 &&
          <Select placeholder='Select a stage...' w={300} size='sm'
              value={`${stage}${stageRecurrence ? `::${stageRecurrence}` : ''}`}
              onChange={e => setStage(e.target.value)}>
            {stages?.map(s => {
              return s.isRecurring ?
                Array.from({length: s.recurringDuration}, (_, i) => i)?.map(recurrence =>
                  <option value={`${s._id}::${recurrence}`} key={`${s._id}::${recurrence}`}>{s.name} ({recurrence + 1})</option>)
              :
                <option value={s._id} key={s._id}>{s.name}</option>
            })}
          </Select>
        }
      </Box>
      {loading && <Box m={5} textAlign='center'><CircularProgress isIndeterminate /></Box>}
      {!loading && !results?.length &&
        <EmptyBox image={NotFoundImage} title={`There isn't any data here yet`}
        description={`Data will appear here when participants begin to respond to your trial.`} />
      }
      {!loading && results?.length > 0 &&
        <Box>
          {columns?.length > 4 &&
            <Alert mt={3} fontSize='sm' variant='left-accent' colorScheme='gray'>If you want to view lots of columns, we recommend exporting your trial data and analysing it with a dedicated tool, such as Excel.</Alert>
          }
          <Table mt={3} variant='striped' colorScheme='gray' size='sm'>
            <Thead>
              <Tr>
                <Th>Participant</Th>
                {columns?.map(col =>
                  <Th key={col}>
                    <Box display='flex' alignItems='center'>
                    {availableColumns?.find(c => c.key === col)?.name}
                    <IconButton colorScheme='blue' size='xs' variant='ghost' onClick={() => removeColumn(col)} icon={<CloseIcon />} />
                    </Box>
                  </Th>
                )}
                <Td>
                  <Box display='flex' justifyContent='end'>
                    <Menu>
                      <MenuButton colorScheme='blue' variant='ghost' as={Button} size='sm'>Add columns...</MenuButton>

                      <MenuList>
                        <Box p={2}><Input value={dataTypeFilter} onChange={e => setDataTypeFilter(e.target.value)} autoFocus size='xs' placeholder='Search data types...'/></Box>
                        <Box maxH='300px' overflowY='scroll'>
                        {filteredAvailableColumns?.map(col => {
                          const alreadySelected = columns.indexOf(col.key) > -1;
                          const action = alreadySelected ? removeColumn : addColumn;
                          return (
                            <MenuItem key={col.key} onClick={e => action(col.key)}>
                              {alreadySelected && <CheckIcon mr={2} />}
                              {col.name}
                            </MenuItem>
                          );
                        })}
                        </Box>
                      </MenuList>
                    </Menu>
                  </Box>
                </Td>
              </Tr>
            </Thead>
            <Tbody>
              {results?.map(result => {
                return (
                  <Tr key={result.participantId}>
                    <Td>{result.participant?.id}</Td>
                    {columns?.map(col => {
                      const definition = availableColumns?.find(c => c.key === col);
                      const val = result[col];
                      const formatted = definition?.format ? definition.format(val, result.participant) : JSON.stringify(val);
                      return (
                        <Td key={col}>{formatted}</Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      }
    </Box>
  );
}
