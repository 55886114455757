import React, { useState, useRef } from 'react';
import { Stack, Box, Icon, Button } from '@chakra-ui/react'
import { BoldExtension, ItalicExtension, UnderlineExtension, ImageExtension, LinkExtension } from 'remirror/extensions';
import { Remirror, useRemirror, EditorComponent, useActive, useCommands } from '@remirror/react';
import { FaBold, FaItalic, FaUnderline, FaImage } from 'react-icons/fa';
import styled from 'styled-components';
import api from '../../api';

const RichContainer = styled(Box)`
  background-color: white;
  white-space: pre-wrap;
  img {
    display: block;
    max-width: 90%;
    margin: 20px auto;
  }
  a {
    color: blue;
    text-decoration: underline;
  }
  .remirror-editor {
    padding: 5px;
  }
`;

const Toolbar = ({ forType, forId, allowImages }) => {
  const { bold, italic, underline } = useActive();
  const { toggleBold, toggleItalic, toggleUnderline, insertImage } = useCommands();
  const inputRef = useRef();
  const [uploading, setUploading] = useState(false);

  const handleFileChosen = (e) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      setUploading(true);
      api.uploads.generateFileUploadRequest({
        forType, forId, name: file.name, size: file.size, type: file.type
      }, (response) => {
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', response.signedRequest);
        xhr.setRequestHeader('Content-Type', file.type);
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            setUploading(false);
            if (xhr.status === 200) {
              insertImage({ src: response.expectedUrl });
            } else {
              setUploading(false);
              onError && onError('Unable to upload file');
            }
          }
        };
        xhr.send(file);
      }, (err) => {
        setUploading(false);
        if (onError) onError(err.message || 'Unable to upload file');
      });
    }
  };

  return (
    <Stack direction='row' alignItems='center' spacing={1} bg='gray.100' p={2}>
      <Button size='xs' onClick={() => toggleBold()} colorScheme='blue' variant={bold() ? 'solid' : 'outline'}><Icon as={FaBold} /></Button>
      <Button size='xs' onClick={() => toggleItalic()} colorScheme='blue' variant={italic() ? 'solid' : 'outline'}><Icon as={FaItalic} /></Button>
      <Button size='xs' onClick={() => toggleUnderline()} colorScheme='blue' variant={underline() ? 'solid' : 'outline'}><Icon as={FaUnderline} /></Button>
      {allowImages && <Box pl={5}>
        <Button size='xs' isLoading={uploading} onClick={() => inputRef.current.click()} colorScheme='blue' variant='outline'><Icon as={FaImage} /></Button>
      </Box>}
      <input type='file' style={{display: 'none'}} ref={inputRef} onChange={handleFileChosen} accept='image/*' />
    </Stack>
  );
};

const Editor = ({ value, onChange, forType, forId, allowImages }) => {

  const { manager, state } = useRemirror({
    extensions: () => [new BoldExtension(), new ItalicExtension(), new UnderlineExtension(), new ImageExtension(), new LinkExtension({ autoLink: true })],
    content: value,
    stringHandler: 'html',
    selection: 'end',
  });

  const onUpdate = d => {
    const html = d.helpers?.getHTML();
    onChange(html);
  };

  return (
    <Box borderColor='grey.100' borderWidth='1px' rounded='md'>
      <Remirror manager={manager} initialContent={state} onChange={onUpdate}>
        <Toolbar forType={forType} forId={forId} allowImages={allowImages} />
        <RichContainer p={2}>
          <EditorComponent />
        </RichContainer>
      </Remirror>
    </Box>
  );
};

export default Editor;