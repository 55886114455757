import React, { useState, useEffect, useCallback } from 'react';
import { useToast, Input, Textarea, Heading, Text, Grid, Badge, Tag, Button, Box, Progress, CircularProgress, CircularProgressLabel, Popover, PopoverTrigger, PopoverArrow, PopoverCloseButton, PopoverContent, PopoverBody, PopoverHeader, PopoverFooter, Alert, AlertTitle, AlertDescription, AlertIcon, Modal, ModalBody, ModalContent, ModalOverlay, ModalHeader, ModalCloseButton, ModalFooter, FormControl, FormLabel, Checkbox, Slider, SliderTrack, SliderFilledTrack, SliderThumb, Select, Divider, Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, UnorderedList, ListItem } from '@chakra-ui/react';
import { AddIcon, CheckIcon, EditIcon, CheckCircleIcon, WarningIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import { FaTimesCircle, FaImage, FaVideo, FaFile } from 'react-icons/fa';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import api from '../../../api';
import useStore from '../../../store';
import utils from '../../../utils/utils';

import ConfirmButton from '../../includes/ConfirmButton';
import RichTextViewer from '../../includes/RichTextViewer';
import FileChooser from '../../includes/FileChooser';
import FoodChooser from '../../includes/FoodChooser';
import PrivateFile from '../../includes/PrivateFile';
import PrivateImage from '../../includes/PrivateImage';
import PrivateVideo from '../../includes/PrivateVideo';
import BMIEditor from '../../includes/BMIEditor';
import BMITag from '../../includes/BMITag';
import TemperatureEditor from '../../includes/TemperatureEditor';
import WeightEditor from '../../includes/WeightEditor';
import HeightEditor from '../../includes/HeightEditor';
import RAVLTEditor from '../../includes/RAVLTEditor';

export default function ResponseEditor({ dataTypes, participant, stage, drafts, responses, onClose, fetchResponses }) {
  const [loadingComponents, setLoadingComponents] = useState(false);
  const [components, setComponents] = useState([]);
  const [response, setResponse] = useState({});
  const [updateNote, setUpdateNote] = useState('');
  const [existingResponse, setExistingResponse] = useState({});
  const [recurrence, setRecurrence] = useState(0);
  const [changesMade, setChangesMade] = useState(false);
  const [lastDraftSavedAt, setLastDraftSavedAt] = useState();
  const [error, setError] = useState();
  const [errorField, setErrorField] = useState();
  const toast = useToast();
  const stageId = stage?._id;
  const trialId = stage?.trial;
  const isAdHoc = stage?.adHoc;
  const isRecurring = !isAdHoc && stage?.isRecurring;

  useEffect(() => {
    if (stage) {
      setLoadingComponents(true);
      api.stages.get(stage._id, d => {
        setComponents(d.components)
        setLoadingComponents(false);
      });
    }
  }, [stage]);

  // Here we try and pull through existing data (if any) to help improve the UX
  useEffect(() => {
    if (isRecurring) { // Recurring stage. Grab the response with stage and recurrence
      const stageResponsesForRecurrence = responses?.filter(r => r.stage === stageId && ((r.stageRecurrence || 0) === (recurrence || 0)));
      if (stageResponsesForRecurrence?.length && stageResponsesForRecurrence[0]?.response) setExistingResponse(stageResponsesForRecurrence[0]?.response);
      else setExistingResponse({});
    }
    if (!isRecurring && !isAdHoc) { // One-off stage. Grab the response matching the stage
      const stageResponses = responses?.filter(r => r.stage === stageId && !r.stageRecurrence);
      if (stageResponses?.length && stageResponses[0]?.response) setExistingResponse(stageResponses[0]?.response);
      else setExistingResponse({});
    }
    if (isAdHoc) setExistingResponse({}); // Ad-hoc stage. Create a new response.

    const stageDrafts = drafts?.filter(r => r.stage === stageId);
    const stageDraftsForRecurrence = stageDrafts?.filter(r => (r.stageRecurrence || 0) === (recurrence || 0));
    const stageDraftResponse = stageDraftsForRecurrence && stageDraftsForRecurrence[0]?.response;
    setResponse(stageDraftResponse ?? {});
  }, [recurrence, stageId, isAdHoc, isRecurring, drafts, responses]);

  const saveDraft = useCallback(utils.debounce((resp) => {
    setError();
    const stageId = `${stage._id}${recurrence > 0 ? `::${recurrence}` : ''}`;
    api.trials.updateParticipantResponseDraft(trialId, participant._id, stageId, { response: resp }, () => {
      fetchResponses();
      setChangesMade(false);
      setLastDraftSavedAt(new Date());
    }, err => {
      setError(err.message);
    });
  }), [stage, recurrence]);

  const revertDraft = () => {
    const stageId = `${stage._id}${recurrence > 0 ? `::${recurrence}` : ''}`;
    api.trials.deleteParticipantResponseDraft(trialId, participant._id, stageId, () => {
      fetchResponses();
      setResponse({});
      setError();
    }, err => {
      toast({title: 'Unable to delete the draft', description: err.message, status:'error'});
      setError(err.message);
    });
  };

  const submit = () => {
    setError();
    setErrorField();
    const stageId = `${stage._id}${recurrence > 0 ? `::${recurrence}` : ''}`;
    api.trials.updateParticipantResponse(trialId, participant._id, stageId, { response, updateNote }, () => {
      setUpdateNote('');
      onClose();
      toast({title: 'Done', description: 'The participant\'s data has been updated', status:'success'});
      fetchResponses();
    }, err => {
      if (err.message.indexOf('[') > -1) {
        const match = err.message.match(/\[([a-z0-9\-]+)\]/i);
        if (match?.length > 1) {
          setErrorField(match[1]);
          err.message = err.message.replace('[' + match[1] + ']', '');
          document.getElementById(`field-entry-${match[1]}`).scrollIntoView({behavior: "smooth"});
        }
      }
      setError(err.message);
    });
  };

  const updateResponse = (dataType, value) => {
    const newResponse = Object.assign({}, response);
    let newResponseEntry = Object.assign({}, newResponse[dataType._id], {
      createdAt: new Date(),
    });
    if (dataType.type === 'float') {
      // Rather than parse float, we use regex (since as user types a "." it removes it)
      newResponseEntry.value = (value === '' ? '' : value.replace(/[^\d.]/g, ''));
    }
    else if (dataType.type === 'integer') newResponseEntry.value = (value === '' ? '' : parseInt(value));
    else if (dataType.type === 'slider') newResponseEntry.value = parseInt(value);
    else if (dataType.type === 'date') newResponseEntry.value = (value ? value.toISOString() : '');
    else if (dataType.type === 'datetime') newResponseEntry.value = (value ? value.toISOString() : '');
    else if (dataType.type === 'text') newResponseEntry.value = value;
    else if (dataType.type === 'paragraph') newResponseEntry.value = value;
    else if (dataType.type === 'choice') {
      if (!newResponseEntry.value) newResponseEntry.value = [];
      if (dataType.multiple) {
        if (newResponseEntry?.value?.indexOf(value) > -1)
          newResponseEntry.value.splice(newResponseEntry.value.indexOf(value), 1);
        else {
          if (value === 'none') newResponseEntry.value = [value];
          else {
            newResponseEntry.value?.push(value);
            if (newResponseEntry.value.indexOf('none') > -1)
              newResponseEntry.value.splice(newResponseEntry.value.indexOf('none'), 1);
          }
        }
      } else {
        if (newResponseEntry?.value?.indexOf(value) === -1)
          newResponseEntry.value = [value];
        else
          newResponseEntry.value = [];
      }
    }
    else if (dataType.type === 'file') newResponseEntry.value = value;
    else if (dataType.type === 'image') newResponseEntry.value = value;
    else if (dataType.type === 'video') newResponseEntry.value = value;
    else if (dataType.type === 'food') newResponseEntry.value = value;
    else if (dataType.type === 'temperature') newResponseEntry.value = value;
    else if (dataType.type === 'weight') newResponseEntry.value = value;
    else if (dataType.type === 'height') newResponseEntry.value = value;
    else if (dataType.type === 'bmi') newResponseEntry.value = value;
    else if (dataType.type === 'ravlt') newResponseEntry.value = value;

    newResponse[dataType._id] = newResponseEntry;
    setResponse(newResponse);
    saveDraft(newResponse);
    setChangesMade(true);
  };

  function fieldHasValue(dataTypeId) {
    const value = response[dataTypeId]?.value || existingResponse[dataTypeId]?.value;
    if (!value) return false;
    if (Array.isArray(value)) return value.length > 0;
    return true;
  }

  function conditionIsMet(c) {
    const conditionResp = response[c.condition.dataType]?.value ?? existingResponse[c.condition.dataType]?.value;

    const exists =
      conditionResp !== null && conditionResp !== undefined && conditionResp !== '' &&
      (Array.isArray(conditionResp) ? (conditionResp?.length > 0) : true);

    const isEqual =
      conditionResp?.toString()?.toLowerCase()?.trim() == c.condition.value?.toString()?.toLowerCase()?.trim() ||
      (Array.isArray(c.condition?.value) && c.condition?.value.indexOf(conditionResp) > -1) ||
      (Array.isArray(conditionResp) && conditionResp.indexOf(c.condition?.value) > -1) ||
      (Array.isArray(c.condition?.value) && Array.isArray(conditionResp) && c.condition.value.filter(x => conditionResp?.indexOf(x) !== -1)?.length > 0);

    return (
      (c.condition.operator === 'exists' && exists) ||
      (c.condition.operator === 'equal' && isEqual) ||
      (c.condition.operator === 'notequal' && !isEqual) ||
      (c.condition.operator === 'more' && conditionResp > c.condition.value) ||
      (c.condition.operator === 'less' && conditionResp < c.condition.value)
    );
  }

  if (!stage) return null;

  // Extract some useful objects for this stage
  const stageResponses = responses?.filter(r => r.stage === stage._id);
  const stageResponsesForRecurrence = stageResponses?.filter(r => (r.stageRecurrence || 0) === (recurrence || 0));

  // Process sections separated by sectionDivider components
  const sections = [];
  components?.forEach((c, i) => {
    if (c.type === 'sectionDivider') {
      // Calculate percentage complete
      let sectionFields = 0;
      let completedSectionFields = 0;
      for (let j = i + 1; j < components.length; j++) {
        const c2 = components[j];
        if (!c2 || c2.type === 'sectionDivider') break;
        else if (c2.dataType && !c2.condition) {
          sectionFields += 1;
          if (fieldHasValue(c2.dataType)) completedSectionFields += 1;
        }
      }
      sections.push({title: c.title, id: c.id, amountCompleted: sectionFields > 0 ? (completedSectionFields / sectionFields) : 1});
    }
  });

  // Calculate percentage of the total form completed
  let totalFields = 0;
  let completedFields = 0;
  components.forEach(c => {
    if (c.dataType && !c.condition) {
      totalFields += 1;
      if (fieldHasValue(c.dataType)) completedFields += 1;
    }
  });
  const amountCompleted = totalFields > 0 ? (completedFields / totalFields) : 1;
  const noteRequired = !isAdHoc && ((!isRecurring && stageResponses?.length > 0) || (isRecurring && stageResponsesForRecurrence?.length > 0));
  return (
    <Modal isOpen={!!stage} onClose={onClose} size='7xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add data for {stage?.name}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>

          {isAdHoc ?
            <Alert mb={3} status='info' variant='left-accent'>This stage is ad-hoc. You can submit as many responses to it as needed.</Alert>
          : <>
            {!isRecurring && stageResponses?.length > 0 &&
              <Alert mb={3} status='warning' variant='left-accent'>This stage is not recurring and already has sumbmitted data. This means that any data you submit here will overwrite existing submitted data for this stage.</Alert>
            }
            {isRecurring && <Box mb={5}>
              <Heading mb={1} size='sm'>Since this is a repeating (recurring) stage, choose a recurrence to add data for</Heading>
              <Select placeholder='Select a recurrence' value={recurrence} onChange={e => setRecurrence(parseInt(e.target.value) || 0)}>
                {[...Array(stage.recurringDuration || 0)].map((i, index) =>
                  <option value={index} key={index}>
                    {index + 1}
                    {stageResponses?.filter(r => (r.stageRecurrence || 0) === (index || 0))?.length > 0 && ' (data has been submitted)'}
                  </option>
                )}
              </Select>
              {stageResponsesForRecurrence?.length > 0 &&
                <Alert mt={3} mb={3} status='warning' variant='left-accent' fontSize='sm'>Data has already been submitted for this recurrence of this stage. Any changes you submit below will overwrite the existing submitted data for this recurrence.</Alert>
              }
            </Box>}
          </>}

          <Divider mt={5} mb={5}/>

          <Box position='relative'>
            <Box display='flex'>

              {sections?.length > 0 &&
                <Box minW={250} maxW={350} mr={2} position='relative'>
                  <Box shadow='md' bg='gray.100' rounded='md' position='sticky' style={{top: 30}} overflow='hidden'>
                    <Box display='flex' alignItems='center' mt={3} mb={5}>
                      <Heading ml={3} size='xs' textTransform='uppercase' textColor='gray'>Sections</Heading>
                      <Progress flex={1} ml={3} mr={3} bg='white' colorScheme={amountCompleted === 1 ? 'green': 'orange'} value={amountCompleted * 100} size='xs' />
                    </Box>
                    <Box maxH='550px' overflowY='scroll'>
                      {sections.map(section =>
                        <Box p={3} mt={2} bg='gray.200' _hover={{bg: 'gray.300'}} cursor='pointer'
                          display='flex' justifyContent='space-between' alignItems='center'
                          onClick={e => document.querySelector(`#component-${section.id}`)?.scrollIntoView({behavior: "smooth"})}
                        >
                          <Text mr={2}>{section.title}</Text>
                          <CircularProgress value={section.amountCompleted * 100} size='30px' thickness='4px'
                            trackColor='white' color={section.amountCompleted === 1 ? 'green': 'orange'}>
                              {section.amountCompleted === 1 && <CircularProgressLabel><CheckCircleIcon height='20px' width='20px' color='green.500'/></CircularProgressLabel>}
                              {section.amountCompleted === 0 && <CircularProgressLabel><WarningIcon height='20px' width='20px' color='orange.500'/></CircularProgressLabel>}
                          </CircularProgress>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Box>
              }

              <Box flex={1}>
              {loadingComponents && <Box textAlign='center'><CircularProgress isIndeterminate /></Box>}
              {components?.map(c => {
                if (c.type) {
                  if (c.type === 'title') {
                    return <Box p={3} mt={10} mb={10} id={`component-${c.id}`}>
                      <Heading size='md'>{c.title}</Heading>
                    </Box>;
                  }
                  if (c.type === 'sectionDivider') {
                    return <Box bg='blue.100' rounded='md' shadow='sm' p={3} mt={10} mb={10} id={`component-${c.id}`}>
                      <Heading size='lg'>{c.title}</Heading>
                      <Box mt={2}>
                        <RichTextViewer content={c.description} />
                      </Box>
                    </Box>;
                  }
                }
                else {
                  const dataType = dataTypes.filter(d => d._id === c.dataType)[0];
                  const resp = response[dataType._id]?.value ?? existingResponse[dataType._id]?.value;
                  const hasChangedValue = response[dataType._id]?.value ?? null;
                  const isError = c.id === errorField;
                  const hasValue = fieldHasValue(c.dataType);
                  if (c.condition?.dataType) {
                    if (!conditionIsMet(c)) return null;
                  }
                  return (
                    <FormControl mb={8} key={c._id} bg={isError ? 'red.200' : 'blue.50'} p={3} rounded='md'>
                      <Box id={`field-entry-${c.id}`}>
                        <Box display='flex' justifyContent='end' mb={3}>
                          {hasChangedValue !== null ?
                            <Badge ml={3} colorScheme='green' size='sm'><CheckIcon /> This change will be included when submitted</Badge>
                          : <Badge ml={3} colorScheme='blue' size='sm'>No changes made</Badge>
                          }
                          {c.isRequired && <Badge ml={3} colorScheme={hasValue ? 'green' : 'red'} size='sm'>{hasValue && <CheckCircleIcon color='green' />} Required field</Badge>}
                        </Box>
                        <Heading size='md' mb={2}>{c.title}</Heading>
                      </Box>

                      <Box mb={4}>
                        <RichTextViewer content={c.description} />
                      </Box>
                      <Divider mt={2} mb={2} />

                      {['text'].indexOf(dataType.type) > -1 &&
                        <Input bg='white' placeholder={c.title} value={resp || ''} onChange={e => updateResponse(dataType, e.target.value)}/>
                      }
                      {['paragraph'].indexOf(dataType.type) > -1 &&
                        <Textarea bg='white' placeholder={c.title} value={resp || ''} onChange={e => updateResponse(dataType, e.target.value)}/>
                      }
                      {['text', 'paragraph'].indexOf(dataType.type) > -1 &&
                        <Box display='flex' fontSize={10}>
                          {dataType.minimumLength && <Text mr={4} color={(resp || '').length < dataType.minimumLength ? 'red' : 'black'}>Minimum length: {dataType.minimumLength}</Text>}
                          {dataType.maximumLength && <Text color={(resp || '').length > dataType.maximumLength ? 'red' : 'black'}>Maximum length: {dataType.maximumLength}</Text>}
                        </Box>
                      }
                      {['integer', 'float'].indexOf(dataType.type) > -1 &&
                        <Box>
                          <Input bg='white' type='text' placeholder={c.title} value={resp?.toString().replace(/^0.+/, '') ?? 0} onChange={e => updateResponse(dataType, e.target.value)}/>
                          <Box display='flex' fontSize={10}>
                            {dataType.minimumValue ? <Text mr={4} color={resp < dataType.minimumValue ? 'red' : 'black'}>Minimum value: {dataType.minimumValue}</Text> : null}
                            {dataType.maximumValue ? <Text color={resp > dataType.maximumValue ? 'red' : 'black'}>Maximum value: {dataType.maximumValue}</Text> : null}
                          </Box>
                        </Box>
                      }
                      {['date'].indexOf(dataType.type) > -1 &&
                        <Box display='flex' justifyContent='start' alignItems='center'>
                          <Box maxW='300px'>
                            <DatePicker
                              placeholderText='Tap to select a date...'
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              todayButton="Today"
                              dateFormat="dd/MM/yyyy"
                              selected={resp ? new Date(resp) : null}
                              onChange={(date) => updateResponse(dataType, date)}
                              customInput={<Input bg='white' />}
                            />
                          </Box>
                          {resp && <Button ml={2} onClick={e => updateResponse(dataType, null)}>Clear date</Button>}
                        </Box>
                      }
                      {['datetime'].indexOf(dataType.type) > -1 &&
                        <Box display='flex' justifyContent='start' alignItems='center'>
                          <Box maxW='300px'>
                            <DatePicker
                              placeholderText='Tap to select a date & time...'
                              showTimeSelect
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              todayButton="Today"
                              dateFormat="dd/MM/yyyy HH:mm"
                              selected={resp ? new Date(resp) : null}
                              onChange={(date) => updateResponse(dataType, date)}
                              customInput={<Input bg='white' />}
                            />
                          </Box>
                          {resp && <Button ml={2} onClick={e => updateResponse(dataType, null)}>Clear date & time</Button>}
                        </Box>
                      }
                      {['date', 'datetime'].indexOf(dataType.type) > -1 &&
                        <Box display='flex' fontSize={10}>
                          {dataType.earliestDate && <Text mr={4} color={resp < dataType.earliestDate ? 'red' : 'black'}>Earliest allowed: {dataType.earliestDate}</Text>}
                          {dataType.latestDate && <Text color={resp > dataType.latestDate ? 'red' : 'black'}>Latest allowed: {dataType.latestDate}</Text>}
                        </Box>
                      }
                      {['choice'].indexOf(dataType.type) > -1 &&
                        <Box>
                          {dataType.options.map(option =>
                            <Checkbox size='lg' mr={5} mb={5} isChecked={resp?.indexOf(option) > -1} onChange={e => updateResponse(dataType, option)}>{option}</Checkbox>
                          )}
                          {dataType.includeNone &&
                            <Checkbox color='orange.600' size='lg' mr={5} mb={5} isChecked={resp?.indexOf('none') > -1} onChange={e => updateResponse(dataType, 'none')}>None</Checkbox>
                          }
                        </Box>
                      }
                      {['slider'].indexOf(dataType.type) > -1 &&
                        <Box display='flex' alignItems='center'>
                          <Text fontSize={20} mr={5}>{resp || 0}</Text>
                          <Slider defaultValue={dataType.minimum} min={dataType.minimum} max={dataType.maximum} value={resp} onChange={n => updateResponse(dataType, n)}>
                            <SliderTrack>
                              <SliderFilledTrack />
                            </SliderTrack>
                            <SliderThumb />
                          </Slider>
                        </Box>
                      }
                      {dataType.type === 'file' &&
                        <Box>
                          {!resp?.fileName &&
                            <FileChooser accept='.doc,.docx,application/msword,.pdf,.xls,.xlsx,.ppt,.pptx,.pages,.numbers,.key,.txt'
                              forType='participant' forObject={participant}
                              onComplete={({ storedName, name }) => updateResponse(dataType, { name, fileName: storedName })}
                              trigger={<Button size='sm' variant='outline' colorScheme='primary'><Box as={FaFile} mr={2} /> Choose a file</Button>}
                            />
                          }
                          {resp?.fileName &&
                            <PrivateFile forType='participant' forObject={participant} name={resp.fileName} displayName={resp.name} onRemove={() => updateResponse(dataType, '')} />
                          }
                        </Box>
                      }
                      {dataType.type === 'image' &&
                        <Box>
                          {!resp?.fileName &&
                            <FileChooser accept='image/*'
                              forType='participant' forObject={participant}
                              onComplete={({ storedName }) => updateResponse(dataType, { fileName: storedName })}
                              trigger={<Button size='sm' variant='outline' colorScheme='primary'><Box as={FaImage} mr={2} /> Choose an image</Button>}
                            />
                          }
                          {resp?.fileName &&
                            <PrivateImage forType='participant' forObject={participant} name={resp.fileName} onRemove={() => updateResponse(dataType, '')} />
                          }
                        </Box>
                      }
                      {dataType.type === 'video' &&
                        <Box>
                          {!resp?.fileName &&
                            <FileChooser accept='.mp4,.mkv,.avi,.mov' fileType='video'
                              forType='participant' forObject={participant}
                              onComplete={({ storedName }) => updateResponse(dataType, { fileName: storedName, needsVideoProcess: true })}
                              trigger={<Button size='sm' variant='outline' colorScheme='primary'><Box as={FaVideo} mr={2} /> Choose a video</Button>}
                            />
                          }
                          {resp?.fileName &&
                            <PrivateVideo forType='participant' forObject={participant} name={resp.fileName} onRemove={() => updateResponse(dataType, '')} isProcessing={resp.needsVideoProcess} hasError={resp.processError} />
                          }
                        </Box>
                      }
                      {dataType.type === 'food' &&
                        <Box>
                          <FoodChooser
                            forId={dataType._id}
                            requiredNutritionFields={dataType.requiredFoodFields || []}
                            value={resp}
                            onComplete={(foodData) => updateResponse(dataType, foodData)}
                          />
                        </Box>
                      }
                      {dataType.type === 'temperature' && <TemperatureEditor value={resp} onChange={val => updateResponse(dataType, val)} />}
                      {dataType.type === 'weight' && <WeightEditor value={resp} onChange={val => updateResponse(dataType, val)} />}
                      {dataType.type === 'height' && <HeightEditor value={resp} onChange={val => updateResponse(dataType, val)} />}
                      {dataType.type === 'bmi' && <BMIEditor value={resp} onChange={val => updateResponse(dataType, val)} />}
                      {dataType.type === 'ravlt' && <RAVLTEditor value={resp} dataType={dataType} onChange={val => updateResponse(dataType, val)} />}
                      {['text', 'paragraph', 'integer', 'float', 'date', 'datetime', 'choice', 'slider', 'file', 'image', 'video', 'food', 'temperature', 'weight', 'height', 'bmi', 'ravlt'].indexOf(dataType.type) === -1 &&
                        <Alert variant='left-accent' fontSize='sm' mt={3}>This type of data cannot be edited by you at this time.</Alert>
                      }
                    </FormControl>
                  );
                }
              })}
              </Box>
            </Box>

            <Box position='sticky' style={{bottom: 10}} boxShadow='md' p={3} rounded='md' bg='white'>
            
              <Accordion allowToggle mb={2}>
                <AccordionItem>
                    <AccordionButton>
                      <Heading size='xs'>Sign-off note
                        {noteRequired && !updateNote && <Badge ml={3} colorScheme='red' size='sm'>Required</Badge>}
                        {updateNote && <Badge ml={3} colorScheme='green' size='sm'><CheckIcon />Added</Badge>}
                      </Heading>
                      <AccordionIcon />
                    </AccordionButton>
                  <AccordionPanel>
                    <Textarea size='sm' rows={1} placeholder='Write a note, justification, or information about this update...' value={updateNote} onChange={e => setUpdateNote(e.target.value)} />
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            
              {error &&
                <Alert mb={3} status='error' variant='left-accent'>
                  <Box>
                    <AlertTitle>There was a problem storing the stage data</AlertTitle>
                    <AlertDescription>{error}</AlertDescription>
                  </Box>
                </Alert>
              }
              <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Box display='flex' alignItems='center'>
                  {changesMade && <>
                    <CircularProgress color='orange.400' isIndeterminate size='18px' mr={2} />
                    <Text color='gray.500' fontSize='sm'> Saving draft...</Text>
                  </>}
                  {!changesMade && lastDraftSavedAt && <>
                    <CheckCircleIcon color='green' mr={2} />
                    <Text color='gray.500' fontSize='sm'> Draft saved</Text>
                  </>}
                  
                </Box>
                <Box>
                  <Button size='sm' variant='ghost' onClick={onClose}>Close without submitting</Button>
                  {Object.keys(response || {}).length > 0 &&
                    <Popover>
                      <PopoverTrigger>
                        <Button ml={2} size='sm' variant='ghost' colorScheme='blue'>View {Object.keys(response || {}).length} data change(s)</Button>
                      </PopoverTrigger>
                      <PopoverContent>
                        <PopoverHeader size='sm'>The following fields will be included in your next submission</PopoverHeader>
                        <PopoverBody maxH={200} overflowY='scroll'>
                          <UnorderedList>
                            {Object.keys(response || {}).map(key => {
                              const fc = components.filter(c => c.dataType === key);
                              if (fc) return <ListItem key={key}>{fc[0]?.title}</ListItem>;
                            })}
                          </UnorderedList>
                        </PopoverBody>
                        <PopoverFooter>
                          <ConfirmButton
                            header='Really revert your changes?'
                            body='Your progress will be lost and the form will be reset to the last submitted state.'
                            trigger={<Button ml={2} size='sm' variant='ghost' colorScheme='red'>Reset changes</Button>}
                            action={revertDraft}
                          />
                        </PopoverFooter>
                      </PopoverContent>
                    </Popover>
                  }
                  <ConfirmButton
                    header='Ready to submit?'
                    body='The updated data will be recorded for this participant.'
                    trigger={<Button size='sm' ml={2} colorScheme='blue'>Submit data</Button>}
                    action={submit}
                  />
                </Box>
              </Box>
            </Box>

          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
