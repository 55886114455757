import React, { useState, useEffect } from 'react';
import { Heading, Text, Box, CircularProgress, Table, Thead, Tbody, Th, Tr, Td, SimpleGrid, Alert, Select } from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';

import EmptyBox from '../../includes/EmptyBox';
import moment from 'moment';
import api from '../../../api';

export default function Timeline({ trialId, participantId }) {
  const [stages, setStages] = useState([])
  const [ratioComplete, setRatioComplete] = useState(0);
  const [filter, setFilter] = useState('all');

  useEffect(() => {
    api.participants.listStages(trialId, participantId, d => {
      setStages(d.stages)
      setRatioComplete(d.ratioComplete);
    })
  }, [participantId]);

  const adHocs = stages.filter(s => s.adHoc);
  const timepoints = stages.filter(s => !s.adHoc);
  let filteredTimepoints = timepoints;
  if (filter === 'complete') filteredTimepoints = timepoints.filter(s => s.completed);
  if (filter === 'incomplete') filteredTimepoints = timepoints.filter(s => !s.completed);

  const dateFormat = 'DD/MM/YYYY HH:mm';

  return (
    <Box>
      <Alert mb={5} status='info'>
        <Text>This tab shows the timeline of stages available to the participant via the mobile app or participant portal.</Text>
      </Alert>

      {stages.length === 0 && <EmptyBox title='No stages are currently available for this participant.' />}

      {stages.length > 0 &&
        <Box>

          {adHocs.length > 0 &&
            <Box mb={5}>
              <Heading size='md' mb={3}>Ad-hoc stages</Heading>
              <SimpleGrid columns={{base: 2, sm: 3, md: 4}} spacing={5}>
                {adHocs.map((stage, index) => (
                  <Box key={index} p={3} borderRadius='md' borderWidth='1px' borderColor='gray.200'>
                    <Text fontSize='md'>{stage.name}</Text>
                    {stage.lastRecordedAt && <Text fontSize='sm' color='gray.500'>Last logged: {moment(stage.lastRecordedAt).format(dateFormat)}</Text>}
                  </Box>
                ))}
              </SimpleGrid>
            </Box>
          }

          {timepoints.length > 0 &&
            <Box>
              <Box display='flex' justifyContent='space-between' alignItems='center' mb={3}>
                <Heading size='md' mt={5} mb={3}>Timepoints {ratioComplete ? <small>({Math.ceil(ratioComplete * 100)}% complete)</small> : ''}</Heading>
                <Select size='sm' value={filter} onChange={e => setFilter(e.target.value)} w='auto' variant='filled'>
                  <option value='all'>View all</option>
                  <option value='complete'>View completed stages</option>
                  <option value='incomplete'>View incomplete stages</option>
                </Select>
              </Box>
              <Table size='sm' variant='basic'>
                <Thead>
                  <Tr>
                    <Th>Stage</Th>
                    <Th>Status</Th>
                    <Th>Available at</Th>
                    <Th>Completed at</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {filteredTimepoints.map((stage, index) => (
                    <Tr key={index} bg={stage.completedAt && 'green.50'}>
                      <Td>{stage.name}</Td>
                      <Td>
                        {stage.completed ?
                          <Text color='green.500'>Completed <CheckCircleIcon ml={1} /></Text> :
                          (stage.enabled ?
                            <Text color='blue.500'>Available <CheckCircleIcon ml={1} /></Text> :
                            <Text color='gray.500'>Not available</Text>
                          )
                        }
                      </Td>
                      <Td>{stage.enableAt ? moment(stage.enableAt).format(dateFormat) : '-'}</Td>
                      <Td>{stage.completedAt ? moment(stage.completedAt).format(dateFormat) : <Text color='gray.500'>Not completed</Text>}</Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          }
        </Box>
      }
      
      
    </Box>
  );
}
