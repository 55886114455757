import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import logo from '../../images/logo/logo_grey.png';

export default function Footer() {
  return (
    <Box bg='gray.100' p={4} display={{md:'flex'}} alignItems='center'>
      <Box width={{md:'50%'}}>
        <Text fontSize='xs' color='gray.400' mb={1}>Powered by</Text>
        <img alt="Trialflare logo" src={logo} style={{ width: '100px', opacity: 0.4 }} />
        <Text fontSize='xs' mt={2} color='gray.400'>&copy; {new Date().getFullYear()} Seastorm Limited</Text>
      </Box>
      <Box width={{md:'50%'}} textAlign='right' pt={1} fontSize='sm' color='gray.700'>
        <Text mb={2}><a href='https://support.seastorm.co/hc/help-center/en' target='_blank' rel='noopener noreferrer'>Help Center</a></Text>
        <Text mb={2}><a href='https://trialflare.com/legal' target='_blank' rel='noopener noreferrer'>Privacy Policy</a></Text>
        <Text mb={2}><a href="https://trialflare.com/legal" target='_blank' rel='noopener noreferrer'>Terms of Use</a></Text>
      </Box>
    </Box>
  );
}
