import logo from '../images/logo/logo.png';
import { createStandaloneToast } from '@chakra-ui/react';

const { toast } = createStandaloneToast()

export const TEAM_ROLES = [
  { key: 'users.create', description: 'Create and invite users' },
  { key: 'users.delete', description: 'Delete users' },
  { key: 'users.manage', description: 'Update users and manage permissions' },
  { key: 'trials.create', description: 'Create trials' },
  { key: 'trials.manage', description: 'Manage any trial' },
  { key: 'team.access', description: 'Access the team account pages' },
  { key: 'team.manage', description: 'Manage the team account' },
];

const utils = {
  hasFeature(team, feature) {
    return team?.enabledFeatures?.indexOf(feature) > -1;
  },
  copyToClipboard(text) {
    navigator.clipboard.writeText(text).then(
      () => toast({title: 'Copied to clipboard', status: 'success'}),
      err => toast({title: 'Error copying', status: 'error'})
    );
  },
  hasPermission(user, permission, scope) {
    return user?.permissions && (
      user.permissions.global?.indexOf('root') > -1 ||
      user.permissions.global?.indexOf(permission) > -1 ||
      (user.permissions[scope] && user.permissions[scope].indexOf(permission) > -1)
    );
  },
  canManageTeam(user, team) {
    return utils.hasPermission(user, 'team.manage', team?._id);
  },
  canManageTrial(user, trial) {
    return utils.hasPermission(user, 'trials.manage', trial.team) || utils.hasPermission(user, 'trial.admin', trial._id);
  },
  canWriteTrial(user, trial) {
    return utils.canManageTrial(user, trial) || utils.hasPermission(user, 'trial.write', trial._id);
  },
  canReadTrial(user, trial) {
    return utils.canWriteTrial(user, trial) || utils.hasPermission(user, 'trial.read', trial._id);
  },
  canAccessTrial(user, trial) {
    return utils.canReadTrial(user, trial) || utils.hasPermission(user, 'trial.access', trial._id);
  },
  canSupportTrial(user, trial) {
    return utils.canManageTrial(user, trial) || utils.hasPermission(user, 'trial.support', trial._id);
  },
  canReadFiles(user, trial) {
    return utils.canReadTrial(user, trial) || utils.hasPermission(user, 'trial.readFiles', trial._id);
  },
  canWriteFiles(user, trial) {
    return utils.canManageTrial(user, trial) || utils.hasPermission(user, 'trial.writeFiles', trial._id);
  },
  canDeleteFiles(user, trial) {
    return utils.canManageTrial(user, trial) || utils.hasPermission(user, 'trial.deleteFiles', trial._id);
  },
  canWriteGroup(user, group) {
    return utils.hasPermission(user, 'group.write', group?._id);
  },
  canReadGroup(user, group) {
    return utils.canWriteGroup(user, group) || utils.hasPermission(user, 'group.read', group?._id);
  },
  canWriteSite(user, site) {
    return utils.hasPermission(user, 'site.write', site?._id);
  },
  canReadSite(user, site) {
    return utils.canWriteSite(user, site) || utils.hasPermission(user, 'site.read', site?._id);
  },
  canWriteParticipant(user, trial, participant) {
    if (!user || !trial || !participant) return false;
    if (utils.canWriteTrial(user, trial)) return true;
    for (const group of (participant.groups ?? [])) {
      if (utils.canWriteGroup(user, {_id: group})) return true;
    }
    for (const site of (participant.sites ?? [])) {
      if (utils.canWriteSite(user, {_id: site})) return true;
    }
    return false;
  },
  canReadParticipant(user, trial, participant) {
    if (canWriteParticipant(user, trial, participant)) return true;
    if (!user || !trial || !participant) return false;
    if (utils.canReadTrial(user, trial)) return true;
    for (const group of (participant.groups ?? [])) {
      if (utils.canReadGroup(user, {_id: group})) return true;
    }
    for (const site of (participant.sites ?? [])) {
      if (utils.canReadSite(user, {_id: site})) return true;
    }
    return false;
  },
  randomAlphaNumeric(length) {
    const o = '3456789ABCDEFGJKLMNPRSTUVWXY';
    let s = '';
    while (s.length < length) s += o[Math.floor(Math.random() * o.length)];
    return s;
  },
  ensureHttp(s) {
    if (s && s.toLowerCase().indexOf('http') === -1) return `http://${s}`;
    return s;
  },
  activePath(pattern) {
    const match = window.location.pathname.match(new RegExp(pattern, 'i'));
    return match && match.length > 0;
  },
  absoluteUrl(path) {
    return window.location.protocol + '//' + window.location.host + path;
  },
  hasEmailSubscription(user, sub) {
    return user && user.subscriptions && user.subscriptions.email && user.subscriptions.email.indexOf(sub) > -1;
  },
  teamLogo(team) {
    return (team && team.logoUrl) || logo;
  },
  debounce(callback, delay) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => callback(...args), delay || 2000);
    };
  },
  kToLbs(pK) {
    const nearExact = parseFloat(pK)/0.45359237;
    const lbs = Math.floor(nearExact);
    const oz = (nearExact - lbs) * 16;
    return {
        pounds: lbs,
        ounces: oz
    };
  },
  lbToK(pounds, ounces) {
    const totalPounds = parseFloat(pounds) + parseFloat(ounces) / 16;
    return totalPounds * 0.45359237;
  },
  cmToFeet(n) {
    const realFeet = ((n*0.393700) / 12);
    const feet = Math.floor(realFeet);
    return {
      feet: feet,
      inches: Math.round((realFeet - feet) * 12)
    };
  },
  feetToCm(feet, inches) {
    return parseInt(feet) * 30.48 + parseFloat(inches) * 2.54;
  },
  celsiusToFahrenheit(c) {
    if (isNaN(parseFloat(c))) return;
    return (parseFloat(c) * (9/5)) + 32;
  },
  fahrenheitToCelsius(f) {
    if (isNaN(parseFloat(f))) return;
    return (parseFloat(f) - 32) * (5/9);
  },
  shuffleArray(a) {
    for (let i = a.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
  },
  downloadFile(url, fileName) {
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', fileName);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  },
  loginWithGoogle() {
    const url = 'https://accounts.google.com/o/oauth2/v2/auth';
    const redirectUri = `${window.location.protocol}//${window.location.host}/login/oauth/google`;
    const scope = 'email profile';
    const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
    window.location = `${url}?client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}&response_type=code`;
  },
  loginWithMicrosoft() {
    const url = 'https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize';
    const redirectUri = `${window.location.protocol}//${window.location.host}/login/oauth/microsoft`;
    const scope = 'openid email offline_access User.Read';
    const clientId = import.meta.env.VITE_MICROSOFT_CLIENT_ID;
    window.location = `${url}?client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}&response_type=code`;
  },
  connectToGoogle(scope, returnTo, serviceName) {
    const url = 'https://accounts.google.com/o/oauth2/v2/auth';
    const redirectUri = `${window.location.protocol}//${window.location.host}/connect/google`;
    const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
    const callback = returnTo || window.location.pathname;
    const state = JSON.stringify({ service: serviceName || 'google', scope, callback });
    window.location = `${url}?client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}&response_type=code&state=${btoa(state)}&prompt=consent&access_type=offline`;
  },
  connectToMicrosoft(scope, returnTo, serviceName) {
    const url = 'https://login.microsoftonline.com/organizations/oauth2/v2.0/authorize';
    const redirectUri = `${window.location.protocol}//${window.location.host}/connect/microsoft`;
    const clientId = import.meta.env.VITE_MICROSOFT_CLIENT_ID;
    const callback = returnTo || window.location.pathname;
    const state = JSON.stringify({ service: serviceName || 'microsoft', scope, callback });
    window.location = `${url}?client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}&response_type=code&state=${btoa(state)}&prompt=consent&access_type=offline`;
  },
  connectToDropbox(scope, returnTo, serviceName) {
    const url = 'https://www.dropbox.com/oauth2/authorize';
    const redirectUri = `${window.location.protocol}//${window.location.host}/connect/dropbox`;
    const clientId = import.meta.env.VITE_DROPBOX_CLIENT_ID;
    const callback = returnTo || window.location.pathname;
    const state = JSON.stringify({ service: serviceName || 'dropbox', scope, callback });
    window.location = `${url}?client_id=${clientId}&scope=${scope}&redirect_uri=${redirectUri}&response_type=code&state=${btoa(state)}&token_access_type=offline`;
  },

};

export default utils;
