import React, { useState } from 'react';
import { useToast, Heading, Button, Input, Container } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import api from '../../api';

import EntryView from '../includes/EntryView';

export default function ResetPassword({ }) {
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  function resetPassword() {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    setLoading(true);
    api.accounts.updatePasswordWithToken(token, password, () => {
      setLoading(false);
      toast({title: 'Password set successfully.', status: 'success'});
      navigate("/");
    }, (err) => {
      setLoading(false);
      toast({title: 'Unable to reset password', description: err.message, status:'error'});
    });
  }

  return (
    <Container mt={5} mb={5}>
      <Heading size='lg' mb={5}>Reset password</Heading>
      <Heading size='sm' mb={4}>Enter a new password for your account below.</Heading>
      <Input placeholder='Type a password...' size='lg' type="password" value={password} onChange={e => setPassword(e.target.value)} autoFocus />
      <Button isLoading={loading} mt={5} colorScheme="primary" onClick={resetPassword}>Set Password</Button>
    </Container>
  );
}
