import useStore from '../store';
import { root } from './root.js';
import { marketing } from './marketing.js';
import { accounts } from './accounts.js';
import { users } from './users.js';
import { uploads } from './uploads.js';
import { teams } from './teams.js';
import { trials } from './trials.js';
import { participants } from './participants.js';
import { participantNotes } from './participantNotes.js';
import { dataTypes } from './dataTypes.js';
import { stages } from './stages.js';
import { groups } from './groups.js';
import { sites } from './sites.js';
import { files } from './files.js';
import { nutrition } from './nutrition.js';
import { dashboards } from './dashboards.js';
import { webhooks } from './webhooks.js';

export const api = {

  req(method, path, data, success, fail, withAuth, options) {
    const xhr = new XMLHttpRequest();
    xhr.open(method, `${import.meta.env.VITE_API_URL}${path}`);
    xhr.setRequestHeader('Content-Type', 'application/json');
    const token = useStore.getState().token;
    if (withAuth && token) {
      xhr.setRequestHeader('Authorization', `Bearer ${token}`);
    }
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          let response;
          if (options && options.plain) response = xhr.responseText;
          else {
            try { response = JSON.parse(xhr.responseText); } catch (err) { console.log(err); }
          }
          if (success) success(response);
        } else {
          if (xhr.status === 401) {
            if (token) {
              accounts.localLogout(() => useStore.getState().completeLogout());
            }
            return fail && fail({ status: 401, message: 'Authorisation is needed' });
          }
          let message;
          try { message = JSON.parse(xhr.responseText).message; } catch (err) { if (fail) fail({ status: xhr.status, message: 'There was a problem with this request' }); }
          if (fail) fail({ status: xhr.status, message });
        }
      }
    };
    xhr.send(data && JSON.stringify(data));
  },

  unauthenticatedRequest(method, path, data, success, fail, options) {
    api.req(method, path, data, success, fail, false, options);
  },

  authenticatedRequest(method, path, data, success, fail, options ) {
    api.req(method, path, data, success, fail, true, options);
  },

  root, marketing, accounts, users, uploads, teams, trials, participants, participantNotes, dataTypes, stages, groups, sites, files, nutrition, dashboards, webhooks
};

export default api;
