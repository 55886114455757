import React from 'react';
import { Box, Heading, Text, Alert, Input, Textarea, Badge } from '@chakra-ui/react';

import FormField from '../../includes/FormField';

const Sponsor = ({ trial, fields, data, updateData}) => {
  return (
    <Box>
      <Box rounded='md' shadow='md' bg='gray.50' borderWidth='1px' p={3}>
        <Heading as='h3' size='md'>Trial sponsor</Heading>
        <Text>If this is set, the name of the trial sponsor will be displayed instead of the team name on the participant portal and app.</Text>

        <Box mt={5}>
          {fields.map(field =>
            <FormField key={field.name} label={field.label} type={field.type} value={data[field.key]} onChange={e => updateData({ [field.key]: e.target.value })} />  
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Sponsor;
