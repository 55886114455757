import { useRef, useEffect } from 'react'

export default function useDocumentTitle(title, trial = null, prevailOnUnmount = false) {
  const defaultTitle = useRef(document.title);

  useEffect(() => {
    document.title = `${title} ${trial ? ` | ${trial.name}` : ''} | Trialflare`;
  }, [title]);

  useEffect(() => () => {
    if (!prevailOnUnmount) {
      document.title = defaultTitle.current;
    }
  }, [])
}
