import React, { useState, useRef } from 'react';
import { useToast, Box, Input, Heading, Text, Button, Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverHeader, PopoverFooter, Alert, AlertTitle, AlertDescription } from '@chakra-ui/react';
import QRCode from 'qrcode';
import api from '../../api';
import useStore from '../../store';

function AccountPassword({ }) {
  const [ existingPassword, setExistingPassword] = useState('');
  const [ newPassword, setNewPassword] = useState('');
  const [ otpCode, setOtpCode] = useState('');
  const [ disableOtpPassword, setDisableOtpPassword] = useState('');
  const toast = useToast();
  const qrCanvas = useRef(null);

  const { user, updateUser } = useStore();

  const updatePassword = () => {
    api.accounts.updatePassword(existingPassword, newPassword, () => {
      setExistingPassword('');
      setNewPassword('');
      toast({
        position: "bottom-left",
        title: "Success",
        description: "We've updated your account password.",
        status: "success",
        duration: 9000,
        isClosable: true,
      })
    }, err => toast({
        position: "bottom-left",
        title: "Unable to update your password",
        description: err.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      })
    );
  }

  const getOtpUri = () => {
    api.accounts.getOtpUri(data => {
      QRCode.toCanvas(qrCanvas.current, data.uri, { width: 200, height: 200 })
    }, err => toast({status: 'error', description: err.message}));
  }
  const enableMfa = () => {
    api.accounts.enableMfa(otpCode, data => {
      updateUser(user._id, { otpEnabled: true });
    }, err => toast({status: 'error', description: err.message}));
  }
  const disableMfa = () => {
    api.accounts.disableMfa(disableOtpPassword, data => {
      updateUser(user._id, { otpEnabled: false });
    }, err => toast({status: 'error', description: err.message}));
  }

  return (
    <>
      <Box rounded='lg' borderWidth='1px' p={3} mt={5}>
        <Heading size='md' as='h3' mb={3}>Change account password</Heading>
        <Text mb={10}>Update your account password to one that is strong and hard to guess.</Text>

        {user?.hasPassword ? <>
          <Text>Current password</Text>
          <Input type="password" value={existingPassword} onChange={e => setExistingPassword(e.target.value)} />
          <Text mt={5}>New password</Text>
          <Input label="New password" type="password" value={newPassword} onChange={e => setNewPassword(e.target.value)} />

          <Button mt={10} colorScheme="primary" onClick={updatePassword}>Update password</Button>
        </> :
          <Alert status='warning' mt={5}>
            <AlertTitle>Your account password is managed by your organisation. You can't change it here.</AlertTitle>
          </Alert>
        }
      </Box>

      <Box rounded='lg' borderWidth='1px' p={3} mt={5}>
        <Heading size='md' as='h3' mb={3}>Two-factor authentication</Heading>
        <Text mb={10}>Enabling two-factor authentication (2FA) on your account adds an extra layer of security in case anyone guesses or discovers your password.</Text>
        {user?.hasPassword ? <>
          {user?.otpEnabled ? <>
            <Alert status='success' variant='left-accent' flexDirection='column' alignItems='start'>
              <AlertTitle>2FA is enabled for your account</AlertTitle>
              <AlertDescription>You will be asked for a temporary password generated by your 2FA app each time you login to Trialflare.</AlertDescription>
            </Alert>
            <Popover>
              <PopoverTrigger>
                <Button colorScheme='red' mt={5}>Turn off 2FA</Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverHeader>
                  <Heading size='md' as='h4'>Turn off 2FA for your account</Heading>
                  <Text>We recommend keeping 2FA turned on for your account, but if you want to turn it off (or re-enable it), enter your account password below to confirm.</Text>
                </PopoverHeader>
                <PopoverBody>
                  <Input type='password' placeholder='Your password' value={disableOtpPassword} onChange={e => setDisableOtpPassword(e.target.value)} />
                </PopoverBody>
                <PopoverFooter>
                  <Button colorScheme='red' onClick={disableMfa}>Turn off 2FA</Button>
                </PopoverFooter>
              </PopoverContent>
            </Popover>
          </> :
            <Popover>
              <PopoverTrigger>
                <Button colorScheme='primary' onClick={getOtpUri}>Set-up 2FA</Button>
              </PopoverTrigger>
              <PopoverContent>
                <PopoverHeader>
                  <Heading size='md' as='h4'>Set-up 2FA</Heading>
                  <Text>Using your 2FA app (we recommend Authy or Google Authenticator), scan the QR code below.</Text>
                </PopoverHeader>
                <PopoverBody display='flex' justifyContent='center'>
                  <canvas ref={qrCanvas} />
                </PopoverBody>
                <PopoverBody>
                  <Text mb={5}>Now, enter the six-digit code generated by your 2FA app to verify the set-up.</Text>
                  <Input autoFocus placeholder='123456' value={otpCode} onChange={e => setOtpCode(e.target.value)}/>
                </PopoverBody>
                <PopoverFooter align='right'>
                  <Button colorScheme='green' onClick={enableMfa}>Complete setup</Button>
                </PopoverFooter>
              </PopoverContent>
            </Popover>
          }
        </> :
          <Alert status='warning' mt={5}>
            <AlertTitle>Your account password is managed by your organisation. You can't enable 2FA here.</AlertTitle>
          </Alert>
        }
      </Box>
    </>
  );
}

export default AccountPassword;
