import { Bar } from "react-chartjs-2";

const PASTEL_COLOURS = ['#f5b7b1', '#d7bde2', '#a9cce3', '#a3e4d7', '#a9dfbf', '#f9e79f', '#f5cba7'];

export default function SimpleBarChart({ labels, datasets, options }) {
  datasets.forEach((dataset, i) => {
    dataset.backgroundColor = PASTEL_COLOURS[i % PASTEL_COLOURS.length];
    dataset.borderColor = dataset.backgroundColor;
    dataset.borderWidth = 1;
  });
  return (
    <Bar options={options}
      data={{
        labels,
        datasets,
      }}
    />
  );
}
