import React, { useState } from 'react';
import { useToast, Container, Box, Heading, Text, Button, Input, Stack } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../../api';

import useTitle from '../../hooks/useTitle';

export default function ForgottenPassword() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  useTitle('Forgotten Password');

  function sendEmail() {
    setLoading(true);
    api.accounts.sendPasswordResetEmail(email, () => {
      setLoading(false);
      toast({title: 'OK, done', description:'If your account exists, a password email has been sent', status:'success'});
      navigate('/');
    }, (err) => {
      setLoading(false);
      toast({title: 'Unable to reset your password', description: err.message, status: 'error'});
    });
  }

  return (
    <Container mt={5} mb={5}>
      <Heading size='lg' mb={5}>Password reset</Heading>
      <Box p={4} bg='gray.50' rounded='md'>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Box>
            <Heading size='sm' mb={2}>Remembered your password?</Heading>
            <Text fontSize='sm'>If you've remembered your password, you can use it to login.</Text>
            </Box>
          <Button colorScheme='primary' variant='outline' as={Link} to='/'>Login</Button>
        </Stack>
      </Box>

      <Heading size='sm' mb={4} mt={5}>Type your email address below, and we'll send you a password-reset email.</Heading>
      <Input size='lg' type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="mary@example.com" autoFocus />
      <Button mt={4} colorScheme="primary" onClick={sendEmail} isLoading={loading}>Send Email</Button>
    </Container>
  );
}
