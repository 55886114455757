import React, { useState, useRef, useEffect } from 'react';
import { useToast, Badge, Heading, Text, Box, Button, Icon, Table, Thead, Tbody, Th, Tr, Td } from '@chakra-ui/react';
import { Link, useParams } from 'react-router-dom';
import api from '../../api';
import utils from '../../utils/utils';
import useStore from '../../store';
import useTitle from '../../hooks/useTitle';

import EmptyBox from '../includes/EmptyBox';
import ConfirmButton from '../includes/ConfirmButton';
import SitesImage from '../../images/sites.png';

export default function Sites({ }){
  const [sites, setSites] = useState([]);
  const toast = useToast();
  const { id } = useParams();
  const { user, trial, updateTrial } = useStore();
  useTitle('Sites', trial);

  useEffect(() => {
    api.sites.getForTrial(id, d => setSites(d.sites));
  }, [id]);

  function deleteSite(siteId) {
    api.sites.delete(siteId, () => {
      toast({title:'Site deleted', status:'success'});
      setSites(sites?.filter(s => s._id !== siteId));
      updateTrial(trial._id, { siteCount: (trial.siteCount ?? 1) - 1 });
    }, err => toast({title: 'Unable to delete site', description: err.message, status: 'error'}));
  }

  return (
    <>
      <Heading as='h2' size='lg'>Trial sites</Heading>
      <Button style={{float: 'right'}} as={Link} to={`/trials/${trial._id}/sites/new`} colorScheme='primary'>Create a new site</Button>
      <Text mb={15}>Specify any physical or virtual locations for your trial participants.</Text>
      <div style={{clear:'both'}} />

      {(sites?.length > 0) ?
        <Table variant='striped'>
          <Thead>
            <Tr>
              <Th />
              <Th>Name</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {sites.map(site =>
              <Tr key={site._id}>
                <Td>
                  <Link to={`/trials/${id}/sites/${site._id}`}>
                    {site.imageUrl &&
                      <img src={site.imageUrl} style={{maxHeight: 50, maxWidth: 200}} alt={`Logo for ${site.name}`} />
                    }
                  </Link>
                </Td>
                <Td><Link to={`/trials/${id}/sites/${site._id}`}>{site.name}</Link></Td>
                <Td>
                  {utils.canManageTrial(user, trial) &&
                    <ConfirmButton
                      trigger={<Button size='sm' ml={3} colorScheme='red' variant='outline'>Delete</Button>}
                      header='Really delete this site?'
                      footer='Any participants assigned to this site will be removed from the site.'
                      action={e => deleteSite(site._id)}
                    />
                  }
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      :
        <EmptyBox image={SitesImage} title='Create your first site' description='Sites allow you to separate participants by geographic (or virtual) location. For example, for distributed trials you might work with a number of clinics or centres to which your participants will attend.' action={<Button colorScheme='primary' as={Link} to={`/trials/${trial._id}/sites/new`}>Create your first site</Button>} />
      }
    </>
  );
}

