import React, { useState, useEffect } from 'react';
import { useToast, Alert, AlertIcon, Box, Button, Input, Heading, Text, Select } from '@chakra-ui/react';
import api from '../../api';
import useStore from '../../store';
import utils from '../../utils/utils.js';
import FileChooser from '../includes/FileChooser';
import QrCode from '../includes/QrCode';
import ColourChooser from '../includes/ColourChooser';

export default function TeamBranding({ }) {
  const [name, setName] = useState('');
  const toast = useToast();
  const { user, team, updateTeam } = useStore();

  useEffect(() => {
    if (team) {
      setName(team.name);
    }
  }, [team]);

  function saveName() {
    api.teams.update(team._id, { name }, team => {
      updateTeam(team);
      toast({title: 'Team updated', status: 'success'});
    }, err => toast({title: 'Unable to update team', description: err.message, status: 'error'}));
  }

  function updateName(e) {
    const newTeam = Object.assign({}, team);
    newTeam.name = e.target.value;
    updateTeam(newTeam);
  }

  function updateLogo({ storedName }) {
    api.teams.update(team._id, { logo: storedName }, team => {
      if (!storedName) { 
        team.logo = null;
        team.logoUrl = null;
      }
      updateTeam(team);
      toast({title: 'Team updated', status: 'success'});
    }, err => toast({title: 'Unable to update team', description: err.message, status: 'error'}));
  }

  function updateQrIcon({ storedName }) {
    api.teams.update(team._id, { qrIcon: storedName }, team => {
      if (!storedName) { 
        team.qrIcon = null;
        team.qrIconUrl = null;
      }
      updateTeam(team);
      toast({title: 'Team updated', status: 'success'});
    }, err => toast({title: 'Unable to update team', description: err.message, status: 'error'}));
  }

  function updateQrValue(valName, value) {
    api.teams.update(team._id, { [valName]: value }, team => {
      updateTeam(team);
    }, err => toast({title: 'Unable to update team', description: err.message, status: 'error'}));
  }

  const canEdit = utils.hasPermission(user, 'team.manage', user.team);
  
  return (<>
    {utils.hasPermission(user, 'team.manage', team?._id) ? <>
      <Box p={3} rounded='md' borderWidth='1px' m={1}>
        <Heading as='h3' size='md'>Team identity</Heading>
        <Text color='gray.500'>Choose how other users in {team?.name} and your trial participants see your team.</Text>
        <Box display={{md:'flex'}} mt={10}>
          <Box>
            <Heading as='h3' size='sm'>Team name</Heading>
            <Text color='gray.700'>Change the name of your team. This name will be shown to participants of your trials.</Text>
            <Input readOnly={!canEdit} mt={5} mb={5} value={name} onChange={e => setName(e.target.value)} />
            {canEdit &&
              <Button colorScheme='primary' onClick={saveName}>Save name</Button>
            }
          </Box>
  
          <Box ml={5}>
            <Heading as='h3' size='sm'>Team logo</Heading>
            <Text color='gray.700'>Add a logo to your team. This will be displayed on trials published by {team?.name}.</Text>
            {team.logoUrl ?
              <img src={team.logoUrl} style={{width: 200, marginTop: 15}} />
            :
              <Alert mt={5} variant='left-accent'>No logo is currently set.</Alert>
            }
            <Box display='flex' mt={5}>
              <FileChooser accept='image/*'
                forType='team' forObject={team}
                onComplete={updateLogo}
                trigger={<Button colorScheme='primary'>Update logo</Button>}
              />
              <Button ml={2} onClick={e => updateLogo({ storedName: null })}>Remove logo</Button>
            </Box>
          </Box>
        </Box>
      </Box>
  
      <Box p={3} rounded='md' borderWidth='1px' mt={5}>
        <Heading as='h3' size='md'>QR code branding</Heading>
        <Text color='gray.500'>Customise the appearance of QR codes generated within Trialflare.</Text>
        <Box display='flex' mt={5}>
          <Box border='1px solid #ddd' p={3} rounded='md'>
            <Heading size='sm' mb={5}>Preview</Heading>
            <QrCode name='trialflare' url='https://trialflare.com' size={200} />
          </Box>
          <Box ml={5}>
            <Heading size='sm'>Icon</Heading>
            <Text mb={3} color='gray.700'>Add an icon to the center of the QR code.</Text>
            <FileChooser accept='image/*'
              forType='team' forObject={team}
              onComplete={updateQrIcon}
              trigger={<Button colorScheme='primary' size='sm'>Update icon</Button>}
            />
            {team.qrIconUrl &&
              <Button ml={2} onClick={e => updateQrIcon({ storedName: null })} size='sm'>Remove icon</Button>
            }

            <Heading mt={5} size='sm'>Style</Heading>
            <Select mt={3} value={team.qrDotStyle} onChange={e => updateQrValue('qrDotStyle', e.target.value)}>
              <option value='squares'>Squares</option>
              <option value='dots'>Dots</option>
            </Select>

            <Heading mt={5} size='sm'>Corner style</Heading>
            <Select mt={3} value={team.qrCornerSquareStyle} onChange={e => updateQrValue('qrCornerSquareStyle', e.target.value)}>
              <option value='dot'>Dot</option>
              <option value='extra-rounded'>Extra rounded</option>
              <option value='square'>Square</option>
            </Select>
          </Box>

          <Box ml={5}>
            <Heading size='sm'>Background</Heading>
            <Text mb={3} color='gray.700'>Choose a background colour for the QR code.</Text>
            <ColourChooser value={team.qrBackgroundColour} onChange={value => updateQrValue('qrBackgroundColour', value)} />
            {team.qrBackgroundColour &&
              <Button onClick={e => updateQrValue('qrBackgroundColour', null)} ml={2} size='sm'>Remove background colour</Button>
            }

            <Heading mt={5} size='sm'>Foreground</Heading>
            <Text mb={3} color='gray.700'>Choose a colour for the QR code.</Text>
            <ColourChooser value={team.qrColour} onChange={value => updateQrValue('qrColour', value)} />
            {team.qrColour &&
              <Button onClick={e => updateQrValue('qrColour', null)} ml={2} size='sm'>Remove foreground colour</Button>
            }
          </Box>
        </Box>
      </Box>
  
    </>:
      <Box>
        <Heading>Page unavailable</Heading>
        <Text>This page is only viewable by users that manage the team</Text>
      </Box>
    }
  </>);
}
