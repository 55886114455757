import React, { useState, useEffect, useRef } from 'react';
import { Box, Button } from '@chakra-ui/react';
import { DownloadIcon } from '@chakra-ui/icons';
import QRCodeStyling from 'qr-code-styling';
import useStore from '../../store';

const DEFAULTS = {
  dotsOptions: {
    gradient: { type: 'radial', colorStops: [{ offset: 0, color: 'black' }, { offset: 1, color: '#805AD5' }] },
    type: 'dots'
  },
  cornersSquareOptions: { type: 'extra-rounded' },
  backgroundOptions: { color: '#ffffff' },
};

export default function QrCode({ name, url, size, withDownload }) {
  const [qrCode, setQrCode] = useState(null);
  const qrCodeRef = useRef();
  const { team } = useStore();

  useEffect(() => {
    if (qrCodeRef.current) {
      const qrCode = new QRCodeStyling({
        width: 200,
        height: 200,
        data: `${url}`,
        image: team?.qrIconUrl || '/icon.png',
        dotsOptions: {
          color: team?.qrColour || '#805AD5',
          gradient: team?.qrColour ? null : {
            type: 'radial',
            colorStops: [{ offset: 0, color: 'black' }, { offset: 1, color: '#805AD5' }]
          },
          type: team?.qrDotStyle || 'dots'
        },
        cornersSquareOptions: {
          type: team?.qrCornerSquareStyle || 'extra-rounded',
        },
        backgroundOptions: {
          color: team?.qrBackgroundColour || '#ffffff'
        },
        imageOptions: {
          crossOrigin: 'anonymous',
          margin: 2
        }
      });
      qrCodeRef.current.innerHTML = '';
      qrCode.append(qrCodeRef.current);
      setQrCode(qrCode);
    }
  }, [url, team]);

  const download = () => {
    if (!qrCode) return;
    qrCode.download({
      name: name || 'qr-code',
      extension: 'png'
    });
  };

  return (
    <Box>
      <Box display='flex' justifyContent='center' alignItems='center'>
        <div ref={qrCodeRef} />
      </Box>
      {withDownload &&
        <Button onClick={download} w='100%' colorScheme='blue' mt={5}><DownloadIcon mr={2} /> Download QR Code</Button>
      }
    </Box>
  );
}
