import React, { useState, useEffect } from 'react';
import { useToast, Heading, Box, Text, Button, Input, CircularProgress, Alert, AlertDescription, Textarea, Badge } from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon, CheckIcon } from '@chakra-ui/icons';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import api from '../../../api';
import useStore from '../../../store';

import Empty from '../../../images/empty.png';
import EmptyBox from '../../includes/EmptyBox';
import ParticipantMessenger from '../../includes/ParticipantMessenger';

function Messages({ }){
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [participants, setParticipants] = useState([]);
  const [nextPageAvailable, setNextPageAvailable] = useState(false);
  const [prevPageAvailable, setPrevPageAvailable] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [onlyUnreads, setOnlyUnreads] = useState(false);
  const [participant, setParticipant] = useState();

  const { id, participantId } = useParams();
  const { user, trial, updateTrial } = useStore();
  const subscriptionTopic = `trial.${trial._id}.participantMessages`
  const [hasSubscription, setHasSubscription] = useState(user?.subscriptions?.email?.includes(subscriptionTopic))

  const toast = useToast();
  const trialId = trial?._id;

  useEffect(() => {
    getParticipants();
  }, [trialId, page, searchTerm, onlyUnreads]);
  
  useEffect(() => {
    setParticipant(participants?.find(p => p._id === participantId));
  }, [participantId, participants]);

  useEffect(() => {
    const interval = setInterval(getParticipants, 60000);
    return () => clearInterval(interval);
  }, []);

  function getParticipants() {
    setLoading(true);
    api.trials.getParticipants(trialId, page, searchTerm, null, null, onlyUnreads, null, null, ({ participants, nextPageAvailable, prevPageAvailable, total }) => {
      setLoading(false);
      setParticipants(participants);
      setNextPageAvailable(nextPageAvailable);
      setPrevPageAvailable(prevPageAvailable);
    }, err => setLoading(false));
  }

  function subscribe(enabled) {
    const f = enabled ? api.users.createEmailSubscription : api.users.deleteEmailSubscription;
    f(user._id, subscriptionTopic, subData => {
      toast({status: 'success', title: 'Successfully updated subscription'}); 
      setHasSubscription(subData?.subscriptions?.email?.includes(subscriptionTopic));
    }, err => {
      toast({status: 'error', title: err.message});
    })
  }

  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box>
          <Heading mt={5} as='h2' size='lg'>Participant messaging</Heading>
          <Text>Provide support to your participants using the messenger.</Text>
        </Box>
        <Box>
          {hasSubscription
            ?
              <Button onClick={() => subscribe(false)} size='sm' colorScheme='primary' variant='outline'>Unubscribe</Button>
            :
              <Button onClick={() => subscribe(true)} size='sm' colorScheme='primary'>Subscribe</Button>
          }
        </Box>
      </Box>

      <Box display={{md:'flex'}} justifyContent='space-between' mt={5}>
        <Box width={{md: '40%'}} bg='gray.100' p={5} borderRadius={3}>
          <Box display='flex' alignItems='baseline' justifyContent='space-between'>
            <Heading as='h3' size='sm'>Participant list</Heading>
            <Button size='xs' colorScheme='yellow' variant={onlyUnreads ? 'solid' : 'outline'} onClick={e => setOnlyUnreads(!onlyUnreads)}>{onlyUnreads && <CheckIcon mr={1} />} Only show unreads</Button>
          </Box>

          <Input bg='white' mt={2} mb={2} placeholder='Search for a participant...' value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />

          {!loading && !participants?.length && <EmptyBox image={Empty} title='Participants will appear here once they join the trial' description='Need help getting your participants joined-up?' action={<Button colorScheme='primary' as={Link} to={`/trials/${trial._id}/manage`}>See instructions</Button>} />}
          {loading && <CircularProgress isIndeterminate />}
          {participants?.map(p =>
            <Box key={p._id} as={Link} to={`/trials/${trial._id}/messages/${p._id}`} display='flex' justifyContent='space-between' bg={participantId === p._id ? 'white' : 'grey.50'} alignItems='baseline' p={2} mt={2} mr={-5} borderRadius={2}>
              <Text>{p.id}</Text>
              {p.unreadMessageCount > 0 && <Badge colorScheme='yellow' size='sm'>{p.unreadMessageCount} unread</Badge>}
              {p.lastSeenAt && <Text color='gray.400'>Last seen {moment(p.lastSeenAt).fromNow()}</Text>}
            </Box>
          )}
          {prevPageAvailable && <Button onClick={e => setPage(page - 1)}><ArrowLeftIcon /></Button>}
          {nextPageAvailable && <Button onClick={e => setPage(page + 1)}><ArrowRightIcon /></Button>}
        </Box>
        <Box width={{md: '60%'}} ml={10}>
          <ParticipantMessenger trial={trial} participant={participant} />
        </Box>
      </Box>
    </>
  );
}

export default Messages;
