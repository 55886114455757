import React, { useEffect } from 'react';
import { Heading, Text, Box, CircularProgress, Button, Alert, Stat, StatLabel, StatNumber } from '@chakra-ui/react';
import { DownloadIcon, CheckCircleIcon } from '@chakra-ui/icons';

import EmptyBox from '../../includes/EmptyBox';
import moment from 'moment';
import useStore from '../../../store';
import utils from '../../../utils/utils';
import ConsentImage from '../../../images/consent.png';

export default function Consent({ trialId, participantId, participant }) {
  const consent = participant?.trialConsentObject;
  const consentVerification = participant?.trialConsentVerificationObject;
  
  if (!participant) {
    return (
      <Box>
        <CircularProgress isIndeterminate />
      </Box>
    );
  }

  return (
    <Box>
      
      {consent ?
        <Box>
          <Alert variant='left-accent' status='success' mt={5}><CheckCircleIcon mr={2} /> The participant has completed consent verification for this trial.</Alert>
          
          <Box bg='gray.50' rounded='md' p={3} mt={5}>
            <Box display='flex' justifyContent='space-between'>
              <Heading size='md'>Consent details</Heading>
              {consent.fileUrl &&
                <Button size='sm' colorScheme='primary' as='a' target='_blank' href={consent.fileUrl}><DownloadIcon mr={2} /> Download consent</Button>
              }
            </Box>
            <Text>Before joining the trial, the participant consented to take part.</Text>
            
            <Stat mt={5}>
              <StatLabel>Consent recorded at</StatLabel>
              <StatNumber>{moment(consent.createdAt).format('DD/MM/YYYY HH:mm')}</StatNumber>
            </Stat>
            {consent.phone &&
              <Stat mt={5}>
                <StatLabel>Consent phone number</StatLabel>
                <StatNumber>{consent.phone}</StatNumber>
              </Stat>
            }
            {consent.email &&
              <Stat mt={5}>
                <StatLabel>Consent email address</StatLabel>
                <StatNumber>{consent.email}</StatNumber>
              </Stat>
            }
          </Box>
          
          {consentVerification &&
            <Box bg='green.50' rounded='md' p={3} mt={5}>
              <Heading size='md'>Consent verification</Heading>
              <Text>When joining the trial, the participant verified their consent via a two-factor code sent to one of their chosen contact methods.</Text>
              {consentVerification?.consumedAt &&
                <Stat mt={5}>
                  <StatLabel>Consent verified at</StatLabel>
                  <StatNumber>{moment(consentVerification.consumedAt).format('DD/MM/YYYY HH:mm')}</StatNumber>
                </Stat>
              }
              {consentVerification?.phone &&
                <Stat mt={5}>
                  <StatLabel>Consent verified via</StatLabel>
                  <StatNumber>SMS to: {consentVerification.phone}</StatNumber>
                </Stat>
              }
              {consentVerification?.email &&
                <Stat mt={5}>
                  <StatLabel>Consent verified via</StatLabel>
                  <StatNumber>Email to: {consentVerification.email}</StatNumber>
                </Stat>
              }
            </Box>
          }
        </Box>
      :
        <EmptyBox
          image={ConsentImage}
          title='Participant has not yet consented' description={'If the trial has "Consent" enabled, the participant\'s consent information will appear here when the participant joins.'} />
      }
      
    </Box>
  );
}
