import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Avatar, Button, Menu, MenuList, MenuButton, MenuItem, MenuDivider, Box, Heading, Text, Stack } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { FaCog } from 'react-icons/fa';
import api from '../../api';
import useStore from '../../store';
import utils from '../../utils/utils.js';

import LogoLight from '../../images/logo/logo.png';

const LogoLink = ({ user, team }) => {
  const imageStyle = { maxWidth: '100%', maxHeight: '40px' };
  return (
    <Box as={Link} textAlign='center' to={user ? '/trials' : '/'} display='flex' justifyContent='center'>
      {team?.logoUrl ?
        <img src={team.logoUrl} style={imageStyle} alt={`${team?.name} logo`} />
      :
        <img src={LogoLight} style={imageStyle} alt='Trialflare icon' />
      }
    </Box>
  );
}

export default function NavBar({ }) {
  const navigate = useNavigate();
  const { user, team, trial, completeLogout } = useStore();

  function logout() {
    api.accounts.logout(() => {
      completeLogout();
      navigate('/');
    });
  }

  return (
    <div style={{height: 60, width:'100%', padding: '10px 10px', borderBottomColor:'gray.50', borderBottomStyle: 'solid', borderBottomWidth: 3}}>
      <Box display='flex' justifyContent='space-between' width='100%' style={{overflow:'hidden'}} alignItems='center'>
        <Box>
          <LogoLink user={user} team={team} />
        </Box>
        <Stack ml={5} direction='row' alignItems='center'>
          {user && <Stack ml={5} display={{base: 'none', md: 'inherit'}} direction='row' alignItems='center'>
            <NavLink to={`/trials`}>Trials</NavLink>
            {(utils.hasPermission(user, 'team.access', team?._id) || utils.hasPermission(user, 'team.manage', team?._id)) &&
              <NavLink  to={`/teams/${team && team._id}`}>Team settings</NavLink>
            }
            <NavLink to='https://support.seastorm.co/hc/help-center/en' target='_blank'>Help Center</NavLink>
          </Stack>}
          
          {user &&
            <Menu>
              <MenuButton><Avatar size='sm' name={user?.firstName} /></MenuButton>
              <MenuList placement='bottom-left'>
                {user &&
                  <Box p={[2,4,2,4]} mb={2} fontSize={12} bg='gray.50'>
                    <Text>{user.firstName} {user.lastName}</Text>
                    <Text color='gray.500'>{user.email}</Text>
                  </Box>
                }
                <MenuItem as={Link} to="/settings">My account</MenuItem>
                <MenuItem onClick={logout}>Logout</MenuItem>
                <MenuDivider />
                {team &&
                  <Box p={[2,4,2,4]} mb={2} fontSize={12} bg='gray.50'>
                    <Text>{team.name}</Text>
                    <Text color='gray.500'>Team member</Text>
                  </Box>
                }
                {utils.hasPermission(user, 'team.manage', team?._id) && <>
                  <MenuItem as={Link} to={`/teams/${team?._id}`}>Team account</MenuItem>
                  <MenuDivider />
                  <MenuItem as='a' href='mailto:hello@seastorm.co'>Feedback</MenuItem>
                </>}
                {utils.hasPermission(user, 'root') &&
                  <MenuItem as={Link} to='/root'>Root</MenuItem>
                }

              </MenuList>
            </Menu>
          }
        </Stack>
      </Box>
    </div>
  );
}

function NavLink({ to, target, children }) {
  const location = useLocation();
  const isActive = location?.pathname === to || (to !== '/' && location?.pathname.indexOf(to) > -1);
  return (
    <Button as={Link} to={to} target={target} isActive={isActive} variant={false ? 'outline' : 'ghost'} colorScheme='gray'>
      {children}
    </Button>
  );
}
