import React, { useEffect, useState } from 'react';
import { useToast, Alert, Button, InputGroup, InputLeftElement, Input, Heading, Text, Box, Table, Thead, Tbody, Tr, Th, Td, CircularProgress } from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons'
import { useParams } from 'react-router-dom';
import Papa from 'papaparse';
import useStore from '../../store';
import api from '../../api';

import EmptyBox from '../includes/EmptyBox';
import ConfirmButton from '../includes/ConfirmButton';
import CSVChooser from '../includes/CSVChooser';
import useTitle from '../../hooks/useTitle';
import DataTypeEditor from './NewData';
import DataImage from '../../images/dataTypes.png';
import { categories } from '../trials/NewData';

function Data() {
  const [filter, setFilter] = useState('');
  const [dataTypes, setDataTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const { id } = useParams();
  const { trial, updateTrial } = useStore();
  useTitle('Data Types', trial);

  useEffect(() => {
    setLoading(true);
    api.trials.getDataTypes(id, d => {
      setDataTypes(d.dataTypes);
      setLoading(false);
    });
  }, [id]);
  
  function onCreated(newDataType) {
    const newDataTypes = Object.assign([], dataTypes);
    newDataTypes.push(newDataType);
    setDataTypes(newDataTypes);
  }

  function deleteDataType(id) {
    api.dataTypes.delete(id, () => {
      toast({title: 'Data type deleted', status:'success'});
      const newTypes = dataTypes?.filter(d => d._id !== id);
      setDataTypes(newTypes);
      updateTrial(trial._id, { dataTypeCount: (trial.dataTypeCount ?? 1) - 1 });
    }, err => toast({title: 'Unable to delete data type', description: err.message, status:'error'}));
  }
  
  function clone(d) {
    api.trials.createDataType(trial._id, { copyFrom: d._id }, dataType => {
      toast({title: 'Data type created', status: 'success'});
      const newTypes = Object.assign([], dataTypes);
      const originalIndex = dataTypes.map(d2 => d2._id).indexOf(d._id);
      newTypes.splice(originalIndex + 1, 0, dataType);
      updateTrial(trial._id, { dataTypeCount: (trial.dataTypeCount ?? 0) + 1 });
      setDataTypes(newTypes);
    }, err => toast({title: 'Unable to clone data type', description: err.message, status: 'error'}));
  }

  function csvFormatter(text) {
    const types = [];
    categories.forEach(c => c.types.forEach(t => types.push(t.key)));
    const dat = Papa.parse(text, {skipEmptyLines: true, header: true, transformHeader: h => h.toLowerCase(), transform: v => v.trim()});
    return [['name', 'type'], dat.data.map(({ type, name }) => {
      const row = { type, name };
      if (types.indexOf(type) === -1) row.error = 'Unknown data type';
      return row;
    })];
  }
  function bulkCreateDataTypes(types) {
    api.trials.createDataType(trial._id, {'dataTypes': types.filter(t => !t.error)}, ({ dataTypes }) => {
      const newTypes = Object.assign([], dataTypes);
      dataTypes.forEach(d => newTypes.push(d));
      setDataTypes(newTypes);
      updateTrial(trial._id, { dataTypeCount: (trial.dataTypeCount ?? 0) + dataTypes.length });
      toast({title: 'Imported successfully', status: 'success'});
    }, err => {
      toast({title: 'Unable to import file', description: err.message, status: 'error'});
    });
  }

  const filteredData = filter ? dataTypes.filter(d => d.name.toLowerCase().includes(filter)) : dataTypes;

  return (
    <>
      <Box display={{md: 'flex'}} justifyContent='space-between'>
        <Heading as='h2' size='lg'>Data types</Heading>
        <Box>
          <CSVChooser
            trigger={<Button colorScheme='primary' variant='outline' mr={2}>Bulk create data types</Button>}
            title='Bulk create data types for this trial'
            instructions='Upload a CSV file to create lots of data types in one go. Your CSV must include a "name" and "type" column.'
            formatFunction={csvFormatter}
            onComplete={bulkCreateDataTypes}
          />
          <DataTypeEditor trial={trial}
            onComplete={onCreated}
            trigger={
              <Button colorScheme='primary'>Create a new data type</Button>
            }
          />
        </Box>
      </Box>
      <Text mb={15}>Configure the data types you'd like to collect as part of this trial.</Text>

      {loading && <Box textAlign='center'><CircularProgress isIndeterminate /></Box>}
      {dataTypes?.length > 0 &&
        <Box mt={10}>

          <InputGroup size='sm' maxWidth={300} mb={5}>
            <InputLeftElement
              children={<SearchIcon color='gray.500' />}
            />
            <Input size='sm' placeholder='Type to search your data types...' value={filter} onChange={e => setFilter(e.target.value.toLowerCase())} />
          </InputGroup>

          {filteredData?.length > 0 ?
            <Table variant='striped' size='sm'>
              <Thead>
                <Tr>
                  <Th maxWidth={300}>Name</Th>
                  <Th>Type</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {filteredData.map(d =>
                  <Tr key={d._id}>
                    <Td maxWidth={300}>{d.name}</Td>
                    <Td>{d.type}</Td>
                    <Td isNumeric>
                      <DataTypeEditor trial={trial} dataTypeId={d._id}
                        onComplete={newDataType => {
                          setDataTypes(dataTypes.map(d2 => {
                            if (d2._id === newDataType._id) return newDataType;
                            return d2;
                          }));
                        }}
                        trigger={
                          <Button size='sm' colorScheme='blue'>Edit</Button>
                        }
                      />
                      <Button size='sm' colorScheme='blue' variant='outline' ml={3} onClick={e => clone(d)}>Clone</Button>
                      <ConfirmButton
                        trigger={<Button size='sm' ml={3} colorScheme='red' variant='outline'>Delete</Button>}
                          header='Really delete this data type?'
                          footer='You cannot delete a data type if it is being used by a stage.'
                          action={e => deleteDataType(d._id)}
                          actionColor='red'
                        />
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          :
            <Alert>Your search returned no results. Please try changing your search term.</Alert>
          }
        </Box>
      }
      {!loading && !dataTypes?.length &&
        <EmptyBox image={DataImage} title='Create your first data type' description="Data types describe the different sets of data that you want to collect in your trial. Once defined, you can see how a data type changes across your trial's stages"
          action={
            <DataTypeEditor trial={trial}
              onComplete={onCreated}
              trigger={
                <Button colorScheme='primary'>Define a new data type</Button>
              }
            />
            }
          
        />
      }
    </>
  );
}

export default Data;
