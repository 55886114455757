import React from 'react';
import { Box, Heading, Text, Button, Stack, Badge } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

import FormField from '../../includes/FormField';
import SimpleAccordion from '../../includes/SimpleAccordion';
import ConfirmButton from '../../includes/ConfirmButton';

export const APPROVAL_TYPES = [
  { value: null, label: 'Select an approval type...'},
  { value: 'rec', label: 'Research Ethics Committee (REC)' },
  { value: 'irb', label: 'Institutional Review Board IRB)' },
  { value: 'arsac', label: 'Administration of Radioactive Substances Advisory Committee (ARSAC)' },
  { value: 'cag', label: 'Confidentiality Advisory Group (CAG)' },
  { value: 'gtac', label: 'Gene Therapy Advisory Committee (GTAC)' },
  { value: 'hra', label: 'Health Research Authority (HRA)' },
  { value: 'hcrw', label: 'Health and Care Research Wales (HCRW)' },
  { value: 'mhra', label: 'Medicines and Healthcare products Regulatory Agency (MHRA)' },
  { value: 'nhs', label: 'NHS / HSC R&D offices' },
  { value: 'nhsrec', label: 'NHS / HSC Research Ethics Committees (REC)' },
  { value: 'hmpps', label: 'HM Prison and Probation Service (HMPPS)' },
  { value: 'screc', label: 'Social Care Research Ethics Committee' },
]

const Approvals = ({ trial, data, updateData}) => {

  const addApproval = () => {
    const newApproval = { id: uuidv4() };
    const existingApprovals = data?.approvals || [];
    updateData({ approvals: [...existingApprovals, newApproval] });
  };

  const updateApproval = (id, newData) => {
    const existingApprovals = data?.approvals || [];
    const updatedApprovals = existingApprovals.map(approval => approval.id === id ? { ...approval, ...newData } : approval);
    updateData({ approvals: updatedApprovals });
  };

  return (
    <Box>
      <Box mb={10} rounded='md' shadow='md' bg='gray.50' borderWidth='1px' p={3}>
        <Heading as='h3' size='md'>Approvals</Heading>
        <Text mb={5}>Some trials require some form of approval process before they can begin. Use this section to describe your approvals, if relevant.</Text>

        <FormField label='Approval needed' type='switch' fieldProps={{ display: 'flex', alignItems: 'baseline' }}
          isChecked={data.approvalNeeded} onChange={e => updateData({ approvalNeeded: e.target.checked })} />

        {data.approvalNeeded &&
          <Box>
            <Box display='flex' justifyContent='center'>
              <Button colorScheme='primary' variant='outline' onClick={addApproval}>
                <AddIcon mr={2} /> Add new approval
              </Button>
            </Box>

            <Stack spacing={3} mt={5}>
              <SimpleAccordion
                items={data?.approvals?.map(approval =>
                  ({
                    key: approval.id,
                    label: (
                      <Box display='flex' alignItems='center'>
                        {(!approval.status || approval.status === 'notSubmitted') && <Badge colorScheme='gray' mr={3}>Not Submitted</Badge>}
                        {approval.status === 'submitted' && <Badge colorScheme='yellow' mr={3}>Submitted</Badge>}
                        {approval.status === 'approved' && <Badge colorScheme='green' mr={3}>Approved</Badge>}
                        <Heading size='xs'>{approval.type ? APPROVAL_TYPES.find(t => t.value === approval.type)?.label : 'Unknown approval type'}</Heading>
                      </Box>
                    ),
                    content: (
                      <Box bg='gray.200' p={3} rounded='md'>
                        <Box mb={3} display='flex' justifyContent='flex-end'>
                          <ConfirmButton
                            trigger={<Button size='sm' colorScheme='red' variant='outline'>Remove</Button>}
                            action={() => updateData({ approvals: data.approvals.filter(a => a.id !== approval.id) })}
                            header='Really remove this approval?' body='This action cannot be undone.' />
                        </Box>
                        <Stack spacing={2}>
                          <FormField label='Approval type' type='select' value={approval.type} onChange={e => updateApproval(approval.id, { type: e.target.value })} options={APPROVAL_TYPES} />
                          <FormField label='Approval status' type='select' value={approval.status} onChange={e => updateApproval(approval.id, { status: e.target.value })} options={[
                            { value: 'notSubmitted', label: 'Not yet submitted' },
                            { value: 'submitted', label: 'Submitted' },
                            { value: 'approved', label: 'Approved' },
                          ]} />
                          {(approval.status === 'submitted' || approval.status === 'approved') &&
                            <FormField label='Submission date' type='date' value={approval.submissionDate} onChange={e => updateApproval(approval.id, { submissionDate: e ? moment(e).format() : null })} />
                          }
                          {approval.status === 'approved' &&
                            <FormField label='Approval date' type='date' value={approval.approvalDate} onChange={e => updateApproval(approval.id, { approvalDate: e ? moment(e).format() : null })} />
                          }
                          <FormField label='Reference number' type='text' value={approval.reference} onChange={e => updateApproval(approval.id, { reference: e.target.value })} />

                          <Heading size='sm' mt={5}>Address</Heading>
                          <FormField label='Address line 1' type='text' value={approval.address1} onChange={e => updateApproval(approval.id, { address1: e.target.value })} />
                          <FormField label='Address line 2' type='text' value={approval.address2} onChange={e => updateApproval(approval.id, { address2: e.target.value })} />
                          <FormField label='City' type='text' value={approval.addressCity} onChange={e => updateApproval(approval.id, { addressCity: e.target.value })} />
                          <FormField label='Country' type='text' value={approval.addressCountry} onChange={e => updateApproval(approval.id, { addressCountry: e.target.value })} />
                          <FormField label='Post code or ZIP' type='text' value={approval.addressPostCode} onChange={e => updateApproval(approval.id, { addressPostCode: e.target.value })} />

                          <FormField label='Telephone' type='text' value={approval.telephone} onChange={e => updateApproval(approval.id, { telephone: e.target.value })} />
                          <FormField label='Email' type='email' value={approval.email} onChange={e => updateApproval(approval.id, { email: e.target.value || null })} />
                        </Stack>
                      </Box> 
                    )
                  })
                ) || []}                 
              />
            </Stack>
          </Box>
        }
      </Box>
    </Box>
  );
};

export default Approvals;
