import api from '.';

export const teams = {
  create(data, success, fail) {
    api.authenticatedRequest('POST', '/teams', data, success, fail);
  },
  getAll(success, fail) {
    api.authenticatedRequest('GET', '/teams', null, success, fail);
  },
  get(id, success, fail) {
    api.authenticatedRequest('GET', `/teams/${id}`, null, success, fail);
  },
  update(id, data, success, fail) {
    api.authenticatedRequest('PUT', `/teams/${id}`, data, success, fail);
  },
  createUser(id, data, success, fail) {
    api.authenticatedRequest('POST', `/teams/${id}/users`, data, success, fail);
  },
  getUsers(id, page, term, sort, sortOrder, success, fail) {
    api.authenticatedRequest('GET', `/teams/${id}/users?page=${page || ''}&term=${term || ''}&sort=${sort || ''}&sortOrder=${sortOrder || ''}`, null, success, fail);
  },
  searchUsers(id, term, success, fail) {
    api.authenticatedRequest('GET', `/teams/${id}/users/search?term=${term}`, null, success, fail);
  },
  createTrial(id, data, success, fail) {
    api.authenticatedRequest('POST', `/teams/${id}/trials`, data, success, fail);
  },
  getTrials(id, success, fail) {
    api.authenticatedRequest('GET', `/teams/${id}/trials`, null, success, fail);
  },
  getLogs(id, page, success, fail) {
    api.authenticatedRequest('GET', `/teams/${id}/logs?page=${page}`, null, success, fail);
  },
  updateNutriticsDetails(id, data, success, fail) {
    api.authenticatedRequest('PUT', `/teams/${id}/nutritics`, data, success, fail);
  }
};
