import React, { Component } from 'react';
import { Box, Heading, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';

import useTitle from '../../hooks/useTitle';
import Profile from './Profile';
import Notifications from './Notifications';
import Account from './Account';
import Password from './Password';

export default function Settings() {
  useTitle('Settings');
  return (
    <Box p={10} width={{md:'75%'}}>
      <Heading as='h2' size='lg' mb={5}>Manage your account</Heading>
      <Tabs>
        <TabList>
          <Tab>Profile</Tab>
          <Tab>Notifications</Tab>
          <Tab>Account</Tab>
          <Tab>Password & security</Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Profile />
          </TabPanel>
          <TabPanel>
            <Notifications />
          </TabPanel>
          <TabPanel>
            <Account />
          </TabPanel>
          <TabPanel>
            <Password />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
