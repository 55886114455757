import React, { useEffect } from 'react';
import { Box, CircularProgress } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import useStore from '../store';
import Login from './entry/Login';

export default function Home() {
  const navigate = useNavigate();
  const { user, loadingUser } = useStore();

  useEffect(() => {
    if (user) navigate('/trials');
  }, [user]);

  return (
    <Box>
      {!user && loadingUser && <Box mt={5} display='flex' justifyContent='center'>
        <CircularProgress isIndeterminate color="primary.300" size="100px" thickness="4px" />
      </Box>}
      {!user && !loadingUser &&
        <Login />
      }
    </Box>
  )
};
