import React, { useEffect, useState } from 'react';
import { Box, Select, FormControl, FormLabel, InputGroup, InputRightAddon, Input } from '@chakra-ui/react';
import utils from '../../utils/utils';

export default function TemperatureEditor({ value, onChange }) {
  const [measurement, setMeasurement] = useState('metric');
  const [temperature, setTemperature] = useState();
  const [temperatureFahrenheit, setTemperatureFahrenheit] = useState();

  useEffect(() => {
    const val = value?.toString().replace(/^0.+/, '') || '';
    setTemperature(val);
    setTemperatureFahrenheit(utils.celsiusToFahrenheit(val));
  }, [value])

  const updateMetricTemperature = c => {
    setTemperature(c);
    if (c === '' || parseFloat(c)) {
      onChange(c);
      const f = utils.celsiusToFahrenheit(c);
      setTemperatureFahrenheit(f);
    }
  };

  const updateImperialTemperatire = f => {
    setTemperatureFahrenheit(f);
    if (f === '' || parseFloat(f)) {
      const c = utils.fahrenheitToCelsius(f);
      setTemperature(c);
      onChange(c);
    }
  };

  return (
    <Box>
      <Box>
        <Select w='200px' placeholder='Measurement type...' size='xs' mr={2} bg='white'
          value={measurement} onChange={e => setMeasurement(e.target.value || 'metric')}>
          <option value='metric'>Metric (Celsius)</option>
          <option value='imperial'>Imperial (Fahrenheit)</option>
        </Select>
      </Box>

      <Box display={{md: 'flex'}} mt={2}>
        {measurement === 'metric' && <>
          <FormControl bg='blue.100' rounded='md' p={2} w='150px'>
            <FormLabel fontSize='xs'><strong>Temperature</strong></FormLabel>
            <InputGroup size='sm'>
              <Input width='80px' bg='white' size='sm' value={temperature} onChange={e => updateMetricTemperature(e.target.value.replace(/[^\d.-]/g, ''))} />
              <InputRightAddon children='°C' />
            </InputGroup>
          </FormControl>
        </>}

        {measurement === 'imperial' && <>
          <FormControl bg='blue.100' rounded='md' p={2} w='150px'>
            <FormLabel fontSize='xs'><strong>Temperature</strong></FormLabel>
            <InputGroup size='sm'>
              <Input width='80px' bg='white' size='sm' value={temperatureFahrenheit} onChange={e => updateImperialTemperatire(e.target.value.replace(/[^\d.-]/g, ''))} />
              <InputRightAddon children='°F' />
            </InputGroup>
          </FormControl>
        </>}
      </Box>
    </Box>
  );
}