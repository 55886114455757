import React, { useState } from 'react';
import { useParams} from 'react-router-dom';
import { useToast, Box, Button, Heading, Text, Icon, Alert } from '@chakra-ui/react';
import { FaFileCsv, FaFileExcel, FaFilePdf } from 'react-icons/fa';
import api from '../../../api';
import useStore from '../../../store';
import utils from '../../../utils/utils';

import ExportFilesImage from '../../../images/exportFiles.png';
import GraphpadImage from '../../../images/graphpad.png';
import SpssImage from '../../../images/spss.png';

function ResultsExport({ }) {
  const toast = useToast();
  const [exporting, setExporting] = useState(false);
  const { id } = useParams();
  const { team, trial } = useStore();

  function downloadFile(url, fileName) {
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', fileName);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  function exportExcel() {
    setExporting(true);
    api.trials.getResponses(trial._id, 'excel', ({ request }) => {
      downloadFile(request, `${trial.name.replace(/ /g, '_')}.xlsx`);
      toast({status:'success', title:'Excel file generated successfully', description: 'It should now be in your downloads folder.'});
      setExporting(false);
    }, err => {
      toast({status:'error', title:err.message});
      setExporting(false);
    });
  }

  function exportCSV() {
    setExporting(true);
    api.trials.getResponses(trial._id, 'csv', ({ csv }) => {
      downloadFile(`data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`, `${trial.name.replace(/ /g, '_')}.csv`);
      toast({status:'success', title:'CSV file generated successfully', description: 'It should now be in your downloads folder.'});
      setExporting(false);
    }, err => {
      toast({status:'error', title:err.message});
      setExporting(false);
    });
  }

  function exportSpss() {
    setExporting(true);
    api.trials.getResponses(trial._id, 'spss', ({ request }) => {
      downloadFile(request, `${trial.name.replace(/ /g, '_')}.sav`);
      toast({status:'success', title:'SPSS SAV file generated successfully', description: 'It should now be in your downloads folder.'});
      setExporting(false);
    }, err => {
      toast({status:'error', title:err.message});
      setExporting(false);
    });
  }

  function exportGradphPad() {
    setExporting(true);
    api.trials.getResponses(trial._id, 'graphpad', ({ request }) => {
      downloadFile(request, `${trial.name.replace(/ /g, '_')}.sav`);
      toast({status:'success', title:'GraphPad Prism PZFX file generated successfully', description: 'It should now be in your downloads folder.'});
      setExporting(false);
    }, err => {
      toast({status:'error', title:err.message});
      setExporting(false);
    });
  }

  return (
    <Box>

      <Box borderWidth='1px' p={3} mb={10} rounded='md' display='flex' alignItems='center'>
        <Box w={200}>
          <img src={ExportFilesImage} />
        </Box>
        <Box ml={10}>
          <Heading size='md'><Icon as={FaFileCsv} /> Export data as CSV</Heading>
          <Text mb={5}>CSV files can be imported into spreadsheet apps (such as Excel), stats tools (such as SPSS), loaded into your R or Python programs, or given directly to your data analysts.</Text>
          <Button colorScheme='primary' isLoading={exporting} onClick={exportCSV}>Download results as CSV</Button>

          <Heading mt={10} size='md'><Icon as={FaFileExcel} /> Export data in Excel format</Heading>
          <Text mb={5}>Trialflare can create an Excel file that can be natively imported into Microsoft Excel.</Text>
          <Button colorScheme='primary' isLoading={exporting} onClick={exportExcel}>Download results as an Excel workbook</Button>
        </Box>
      </Box>

      <Box borderWidth='1px' p={3} mb={10} rounded='md' display='flex' alignItems='center'>
        <Box w={200}>
          <img src={SpssImage} />
        </Box>
        <Box ml={10}>
          <Heading size='md'>Export data in SPSS <code>sav</code> format</Heading>
          <Text mb={5}>Trialflare can create a file that you can directly import into IBM SPSS.</Text>
          {utils.hasFeature(team, 'advancedExport') ?
            <Button colorScheme='primary' isLoading={exporting} onClick={exportSpss}>Download results as a SPSS data file</Button>
          :
            <Alert variant='left-accent'>Your team plan does not include this type of export.</Alert>
          }
        </Box>
      </Box>

      <Box borderWidth='1px' p={3} mb={10} rounded='md' display='flex' alignItems='center'>
        <Box w={200}>
          <img src={GraphpadImage} />
        </Box>
        <Box ml={10}>
          <Heading size='md'>Export data in GraphPad format</Heading>
          <Text mb={5}>Trialflare can create a file that you can directly import into GraphPad Prism.</Text>
          {utils.hasFeature(team, 'advancedExport') ?
            <Button colorScheme='primary' isLoading={exporting} onClick={exportGradphPad}>Download results as a PZFX data file</Button>
          :
            <Alert variant='left-accent'>Your team plan does not include this type of export.</Alert>
          }
        </Box>
      </Box>

    </Box>
  );
}

export default ResultsExport;
