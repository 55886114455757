import React, { useEffect, useState } from 'react';
import { Container, Box, Heading, Text, Button, Alert, FormControl, Input, FormLabel, FormHelperText, Switch, Checkbox, InputGroup, InputLeftAddon, Select, Textarea } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import api from '../api';

import RichTextViewer from './includes/RichTextViewer';
import Terms from './includes/Terms';
import countryCodes from '../utils/country_codes.json';

export default function Consent() {
  const [trial, setTrial] = useState();
  const [consentDocument, setConsentDocument] = useState();
  const [error, setError] = useState();
  const [email, setEmail] = useState('');
  const [dialCode, setDialCode] = useState('GB');
  const [phone, setPhone] = useState('');
  const [fullName, setFullName] = useState('');
  const [eligbilityCriteriaConfirmed, setEligbilityCriteriaConfirmed] = useState(false);
  const [eligbilityCriteriaInitials, setEligbilityCriteriaInitials] = useState('');
  const [conditionConfirmations, setConditionConfirmations] = useState({});
  const [conditionInitials, setConditionInitials] = useState({});
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [conditionsConsentGiven, setConditionsConsentGiven] = useState(false);
  const [consentGiven, setConsentGiven] = useState(false);
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  
  const { trialId } = useParams();
  
  useEffect(() => {
    if (!trialId) setError('No trial could be found.');
    else {
      api.trials.getActiveConsentDocument(trialId, data => {
        setTrial(data?.trial);
        setConsentDocument(data?.document);
      }, err => {
        setError('There was an error fetching the consent document for this trial.')
      });
    }
  }, [trialId]);
  
  function consent(e) {
    e.preventDefault();
    if (!termsAgreed) return setError('You must agree to the Trialflare terms before continuing.');
    const selectedCountry = countryCodes.find(c => c.code === dialCode);
    if (!selectedCountry) return setError('Ensure you have selected a valid country.');
    setLoading(true);
    setError('');
    api.trials.submitConsent(trial?._id, consentDocument?._id, {
      phone: `${selectedCountry.dial_code}${phone}`.replace(' ', '').replace('+4407', '+447'),
      email: email ? email : null,
      fullName,
      consentGiven,
      trialConditionsConsentGiven: conditionsConsentGiven,
      eligbilityCriteriaConfirmed, eligbilityCriteriaInitials,
      conditionConfirmations, conditionInitials
    }, data => {
      setLoading(false);
      setSuccess('Your consent has been successfully recorded. You can now close this browser tab.');
    }, err => {
      setLoading(false);
      setError(err.message);
    });
  }

  return (
    <Container mt={5} mb={5}>
      
        <Heading size='lg'>Welcome</Heading>
        <Heading size='sm' color='gray.500'>Trial eConsent</Heading>
        
        <Box mt={5} bg='blue.50' p={3}>
          <Text>Please read this page carefully and follow the instructions to confirm your consent.</Text>
        </Box>
        
        {trial && <>
          <Heading mt={5} size='sm'>Name of the study:</Heading>
          <Text mt={2}>{trial.name}</Text>
        </>}
        {consentDocument && <>
          {consentDocument.eligibilityCriteria && <>
            <Heading mt={10} size='sm'>Eligibility criteria:</Heading>
            <Box mt={2} bg='gray.50' rounded='md' p={3}>
              <RichTextViewer content={consentDocument.eligibilityCriteria} />
            </Box>
            
            <Box mt={3} bg='blue.50' p={3}>
              <Checkbox
                isChecked={eligbilityCriteriaConfirmed}
                onChange={e => setEligbilityCriteriaConfirmed(e.target.checked)}
              >I confirm that I am eligible to take part in this trial.</Checkbox>
              {eligbilityCriteriaConfirmed && <Box mt={2}>
                <Text fontSize='sm'>Please add your initials below to confirm.</Text>
                <Input autoFocus bg='white' size='sm' placeholder='A.B.'
                  value={eligbilityCriteriaInitials}
                  onChange={e => setEligbilityCriteriaInitials(e.target.value)}
                />
              </Box>}
            </Box>
          </>}
          
          <Heading mt={5} size='sm'>Participant Information Sheet (PIS):</Heading>
          <Box mt={2} bg='gray.50' rounded='md' p={3}>
            <RichTextViewer content={consentDocument.document} />
          </Box>
          
          {consentDocument.conditions?.length > 0 && <>
            <Heading mt={10} size='sm'>Conditions:</Heading>
            <Box mt={2} bg='gray.50' rounded='md' p={3}>
              {consentDocument.conditions.map((condition, i) =>
                <Box key={i} mb={5}>
                  <Heading size='sm'>{condition.title}</Heading>
                  <Text fontSize='sm'>{condition.description}</Text>
                  <Box mt={2} bg='blue.50' p={3}>
                    <Checkbox
                      isChecked={conditionConfirmations[condition.id]}
                      onChange={e => {
                        const newConditionConfirmations = Object.assign({}, conditionConfirmations);
                        newConditionConfirmations[condition.id] = e.target.checked;
                        setConditionConfirmations(newConditionConfirmations);
                      }}
                    >I confirm that I agree to this.</Checkbox>
                    {conditionConfirmations[condition.id] === true && <Box mt={2}>
                      <Text fontSize='sm'>Please add your initials below to confirm.</Text>
                      <Input autoFocus bg='white' size='sm' placeholder='A.B.'
                        value={conditionInitials[condition.id]}
                        onChange={e => {
                          const newConditionInitials = Object.assign({}, conditionInitials);
                          newConditionInitials[condition.id] = e.target.value;
                          setConditionInitials(newConditionInitials);
                        }}
                      />
                    </Box>}
                  </Box>
                </Box>
              )}
            </Box>
          </>}
        </>}
        
        {error &&
          <Alert variant='left-accent' mt={5} status='error'>{error}</Alert>
        }
        {success &&
          <Alert variant='left-accent' mt={5} status='success'>{success}</Alert>
        }
        
        {consentDocument && !success && <form onSubmit={consent}>
          <Box bg='blue.50' p={3} mt={10}>
            <Heading size='md'>Consent to joining this trial</Heading>
            <Text>To indicate your consent to this trial, please complete the form below using a phone number, and an optional email address.</Text>

              <FormControl mt={5}>
                <FormLabel htmlFor="fullName">Full name (required)</FormLabel>
                <Input bg='white' type="text" id="fullName" placeholder='Jane Smith' value={fullName} onChange={e => setFullName(e.target.value)}/>
              </FormControl>
            
              <FormControl mt={5}>
                <FormLabel htmlFor="phone">Mobile phone number (required)</FormLabel>
                <InputGroup>
                  <InputLeftAddon children={
                    <Select maxW='200px' variant='unstyled' value={dialCode} onChange={e => setDialCode(e.target.value)}>
                      {countryCodes.map(country =>
                        <option value={country.code}>
                          {country.name} {country.dial_code}
                        </option>
                      )}
                    </Select>
                  } />
                  <Input bg='white' type="text" id="phone" placeholder='7123456789' value={phone} onChange={e => setPhone(e.target.value?.replace(/[^0-9]/, ''))}/>
                </InputGroup>
                <FormHelperText>
                 This will be verified when joining the trial, so ensure you use a phone number that you can access and that can receive text messages.
                </FormHelperText>
              </FormControl>
              
              <FormControl mt={5}>
                <FormLabel htmlFor="email">Email address (to receive a copy of your eConsent)</FormLabel>
                <Input bg='white' type="email" id="email" placeholder='jane@company.com' value={email} onChange={e => setEmail(e.target.value)}/>
                <FormHelperText>
                 We'll send a copy of your consent to this email address for your records.
                </FormHelperText>
                <FormHelperText>
                 Your email can also be used for verification when joining the trial.
                </FormHelperText>
              </FormControl>
              
              <Box mt={10}>
                <Terms value={termsAgreed} onChange={a => setTermsAgreed(a)}/>
              </Box>

              <Box mt={10} p={3} bg='green.50' rounded='md'>
                <Heading size='sm'>Trial terms & privacy</Heading>
                <Text>Additional terms and privacy information has been set out by the trial sponsor. Please ensure you have read and understood these before continuing.</Text>
                <Box bg='gray.50' p={3} mt={2} whiteSpace='pre-line'>{trial?.conditions}</Box>
                <Checkbox iconSize="lg" colorScheme="green" mt={5} isChecked={conditionsConsentGiven} onChange={e => setConditionsConsentGiven(e.target.checked)}>
                  I have read and agree to the trial terms
                </Checkbox>
              </Box>
              
              <FormControl mt={10} display='flex' alignItems='center'>
                <Switch id='consent' isChecked={consentGiven} onChange={e => setConsentGiven(e.target.checked)} />
                <FormLabel ml={3} htmlFor='consent' mb='0'>
                  I have read and understood all of the information above, and I consent to taking part in this trial.
                </FormLabel>
              </FormControl>
            </Box>
            
            {error &&
              <Alert variant='left-accent' mt={5} status='error'>{error}</Alert>
            }
            
            <Button w='100%' mt={10} colorScheme='primary' type='submit' isLoading={loading}>Submit consent</Button>
        </form>}

    </Container>
  )
};
