import React from 'react';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';

export default function SimpleTabs({ tabs, tabProps }) {
  return (
    <Tabs {...tabProps}>
      <TabList>
        {tabs?.map(tab => <Tab key={tab.title} justifyContent='start' textAlign='left'>{tab.title}</Tab>)}
      </TabList>
    
      <TabPanels>
        {tabs?.map(tab =>
          <TabPanel key={tab.title}>
            {tab.content}
          </TabPanel>
        )}
      </TabPanels>
    </Tabs>
  );
}