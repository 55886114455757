import api from '.';

export const trials = {
  get(id, success, fail) {
    api.authenticatedRequest('GET', `/trials/${id}`, null, success, fail);
  },
  update(id, data, success, fail) {
    api.authenticatedRequest('PUT', `/trials/${id}`, data, success, fail);
  },
  delete(id, success, fail) {
    api.authenticatedRequest('DELETE', `/trials/${id}`, null, success, fail);
  },
  getLogs(id, page, userType, success, fail) {
    api.authenticatedRequest('GET', `/trials/${id}/logs?page=${page}&userType=${userType}`, null, success, fail);
  },
  getCollaborators(id, success, fail) {
    api.authenticatedRequest('GET', `/trials/${id}/collaborators`, null, success, fail);
  },
  getConnectedServices(id, success, fail) {
    api.authenticatedRequest('GET', `/trials/${id}/connections`, null, success, fail);
  },
  connectToService(id, service, scope, code, success, fail) {
    api.authenticatedRequest('POST', `/trials/${id}/connections/${service}`, { scope, code }, success, fail);
  },
  disconnectFromService(id, service, success, fail) {
    api.authenticatedRequest('DELETE', `/trials/${id}/connections/${service}`, null, success, fail);
  },
  createDataType(id, data, success, fail) {
    api.authenticatedRequest('POST', `/trials/${id}/dataTypes`, data, success, fail);
  },
  getDataTypes(id, success, fail) {
    api.authenticatedRequest('GET', `/trials/${id}/dataTypes`, null, success, fail);
  },
  createStage(id, data, success, fail) {
    api.authenticatedRequest('POST', `/trials/${id}/stages`, data, success, fail);
  },
  getStages(id, success, fail) {
    api.authenticatedRequest('GET', `/trials/${id}/stages`, null, success, fail);
  },
  createGroup(id, data, success, fail) {
    api.authenticatedRequest('POST', `/trials/${id}/groups`, data, success, fail);
  },
  getGroups(id, success, fail) {
    api.authenticatedRequest('GET', `/trials/${id}/groups`, null, success, fail);
  },
  createParticipant(id, data, success, fail) {
    api.authenticatedRequest('POST', `/trials/${id}/participants`, data, success, fail);
  },
  getParticipants(id, page, term, group, site, unread, sort, sortOrder, success, fail) {
    api.authenticatedRequest('GET', `/trials/${id}/participants?page=${page || 1}&term=${term || ''}&group=${group || ''}&site=${site || ''}${unread ? `&unread=${unread}` : ''}&sort=${sort || ''}&sortOrder=${sortOrder || ''}`, null, success, fail);
  },
  getParticipantsCsv(id, success, fail) {
    api.authenticatedRequest('GET', `/trials/${id}/participants?format=csv`, null, success, fail);
  },
  deleteParticipant(id, participantId, success, fail) {
    api.authenticatedRequest('DELETE', `/trials/${id}/participants/${participantId}`, null, success, fail);
  },
  updateParticipant(id, participantId, data, success, fail) {
    api.authenticatedRequest('PUT', `/trials/${id}/participants/${participantId}`, data, success, fail);
  },
  deleteParticipants(id, data, success, fail) {
    api.authenticatedRequest('DELETE', `/trials/${id}/participants`, data, success, fail);
  },
  getParticipantMessages(id, participantId, success, fail) {
    api.authenticatedRequest('GET', `/trials/${id}/participants/${participantId}/messages`, null, success, fail);
  },
  createParticipantMessage(id, participantId, data, success, fail) {
    api.authenticatedRequest('POST', `/trials/${id}/participants/${participantId}/messages`, data, success, fail);
  },
  readParticipantMessages(id, participantId, success, fail) {
    api.authenticatedRequest('PUT', `/trials/${id}/participants/${participantId}/messages`, null, success, fail);
  },
  createParticipantNotifications(id, data, success, fail) {
    api.authenticatedRequest('POST', `/trials/${id}/participants/notifications`, data, success, fail);
  },
  createConditionsAgreement(id, agreed, success, fail) {
    api.authenticatedRequest('POST', `/trials/${id}/conditionAgreements`, { agreed }, success, fail);
  },
  createResponse(id, stageId, data, success, fail) {
    api.authenticatedRequest('POST', `/trials/${id}/stages/${stageId}/responses`, data, success, fail);
  },
  getResponses(id, type, success, fail) {
    api.authenticatedRequest('GET', `/trials/${id}/responses?type=${type || 'json'}`, null, success, fail);
  },
  getResponsesExplore(id, args, success, fail) {
    args.type = 'explore';
    const searchParams = new URLSearchParams(args);
    api.authenticatedRequest('GET', `/trials/${id}/responses?${searchParams}`, null, success, fail);
  },
  getParticipantResponses(id, participantId, success, fail) {
    api.authenticatedRequest('GET', `/trials/${id}/participants/${participantId}/responses`, null, success, fail);
  },
  getParticipantHardwareResponses(id, participantId, success, fail) {
    api.authenticatedRequest('GET', `/trials/${id}/participants/${participantId}/hardwareResponses`, null, success, fail);
  },
  updateParticipantResponse(id, participantId, stageId, data, success, fail) {
    api.authenticatedRequest('PUT', `/trials/${id}/participants/${participantId}/responses/${stageId}`, data, success, fail);
  },
  updateParticipantResponseDraft(id, participantId, stageId, data, success, fail) {
    api.authenticatedRequest('PUT', `/trials/${id}/participants/${participantId}/responses/${stageId}?draft=true`, data, success, fail);
  },
  deleteParticipantResponseDraft(id, participantId, stageId, success, fail) {
    api.authenticatedRequest('DELETE', `/trials/${id}/participants/${participantId}/responses/${stageId}?draft=true`, null, success, fail);
  },
  getAutomaticMessages(id, success, fail) {
    api.authenticatedRequest('GET', `/trials/${id}/automaticMessages`, null, success, fail);
  },
  createAutomaticMessage(id, data, success, fail) {
    api.authenticatedRequest('POST', `/trials/${id}/automaticMessages`, data, success, fail);
  },
  deleteAutomaticMessage(id, message_id, success, fail) {
    api.authenticatedRequest('DELETE', `/trials/${id}/automaticMessages/${message_id}`, null, success, fail);
  },
  getConsentDocuments(id, success, fail) {
    api.authenticatedRequest('GET', `/trials/${id}/consentDocuments`, null, success, fail);
  },
  createConsentDocument(id, data, success, fail) {
    api.authenticatedRequest('POST', `/trials/${id}/consentDocuments`, data, success, fail);
  },
  getActiveConsentDocument(id, success, fail) {
    api.unauthenticatedRequest('GET', `/trials/${id}/consentDocument`, null, success, fail);
  },
  submitConsent(id, documentId, data, success, fail) {
    api.authenticatedRequest('POST', `/trials/${id}/consentDocuments/${documentId}/consents`, data, success, fail);
  },
  listConsentsForTrial(id, success, fail) {
    api.authenticatedRequest('GET', `/trials/${id}/consents`, null, success, fail);
  },
};
