import React, { useState, useEffect } from 'react';
import { useToast, Heading, Text, Stack, Button, Input, Grid, Radio, Checkbox, Box, Popover, PopoverTrigger, PopoverContent, PopoverBody, Select, Badge, CloseButton, Alert, AlertTitle, AlertIcon, AlertDescription,Accordion, AccordionItem, AccordionIcon, AccordionButton, AccordionPanel, Tag, TagRightIcon, TagLabel, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react';
import { ArrowLeftIcon, LockIcon } from '@chakra-ui/icons';
import DatePicker from 'react-datepicker';
import { ChromePicker } from 'react-color';
import { FaBrain, FaTasks, FaStar } from 'react-icons/fa';
import styled from 'styled-components';
import SimpleTable from '../includes/SimpleTable';
import utils from '../../utils/utils';
import api from '../../api';
import useStore from '../../store';

const StyledSaveBar = styled(Box)`
  position: sticky;
  bottom: 10px;
`;

export const categories = [
  {
    name: 'Essential',
    description: 'Basic data inputs, including text, numeric, sliders, and multiple choice.',
    icon: FaTasks,
    feature: 'basicDataTypes',
    types: [
      { key: 'text', mobile: true, label: 'Short text', description: 'For collecting a short piece of text (e.g. a word, phrase or a sentence).' },
      { key: 'paragraph', mobile: true, label: 'Long text', description: 'For collecting a paragraph or more of text.' },
      { key: 'choice', mobile: true, label: 'Choice', description: 'Allow participants to select one or more options from a selection.' },
      { key: 'integer', mobile: true, label: 'Integer', description: 'For collecting a single integer value (e.g. 42).' },
      { key: 'float', mobile: true, label: 'Decimal number', description: 'For collecting a single floating point number (e.g. 276.42). We refer to this internally as a "float".' },
      { key: 'slider', mobile: true, label: 'Slider', description: 'For collecting a single integer value on a range between a specified minimum and maximum (e.g. between 1 and 10).' },
      { key: 'date', mobile: true, label: 'Date', description: 'For collecting a point in time represented by a date (e.g. a date of birth). You do not need to record the date of submission since Trialflare will handle this for you.' },
      { key: 'datetime', mobile: true, label: 'Date & time', description: 'For collecting a point in time represented by a date and time (e.g. the time the participant used a product). You do not need to record the date of submission since Trialflare will handle this for you.'},
    ]
  },
  {
    name: 'Advanced',
    description: 'More complex data types.',
    icon: FaStar,
    types: [
      { key: 'temperature', mobile: true, label: 'Temperature', description: 'For collecting a temperature measurement.', feature: 'basicDataTypes'},
      { key: 'weight', mobile: true, label: 'Weight', description: 'For collecting a weight measurement.', feature: 'basicDataTypes'},
      { key: 'height', mobile: true, label: 'Height', description: 'For collecting a height measurement.', feature: 'basicDataTypes'},
      { key: 'bmi', mobile: true, label: 'BMI', description: 'For collecting a person\'s height and weight and calculating their body-mass index.', feature: 'basicDataTypes'},
      { key: 'file', mobile: false, label: 'File', description: 'For allowing the upload and collection of files, such as PDFs, spreadsheets, documents, and more.', feature: 'fileDataType'},
      { key: 'image', mobile: true, label: 'Image', description: 'For allowing the upload and collection of image files. On mobile, this allows the participant to use the camera.', feature: 'imageDataType'},
      { key: 'video', mobile: false, label: 'Video', description: 'For allowing the upload and collection of video files.', feature: 'videoDataType'},
      { key: 'food', mobile: true, label: 'Nutrition input', description: 'Enter a food or drink item or scan a barcode.', feature: 'foodDataType'},
    ]
  },
  {
    name: 'Cognition',
    description: 'A suite of tests for measuring various cognitive capabilities.',
    icon: FaBrain,
    feature: 'cognitionDataTypes',
    types: [
      { key: 'stroop', mobile: true, label: 'Stroop', description: 'For running and collecting data from a Stroop test.' },
      { key: 'pal', mobile: true, label: 'PAL', description: 'For running and collecting data from a paired associates learning test.' },
      { key: 'ravlt', mobile: false, label: 'RAVLT', description: 'For running and collecting data from a Rey Auditory Verbal Learning Test.' },
    ],
  },
];

export const times = [
  {key: '10', value: 10, text: '10 seconds'},
  {key: '30', value: 30, text: '30 seconds'},
  {key: '45', value: 45, text: '45 seconds'},
  {key: '60', value: 60, text: '1 minute' },
  {key: '120', value: 120, text: '2 minutes'}
];

export const nutritionTypes = [
  {key: 'carbohydrate', name: 'Carbohydrate', unit: 'g'},
  {key: 'fat', name: 'Fat', unit: 'g'},
  {key: 'saturatedFat', name: 'Saturated fat', unit: 'g'},
  {key: 'fibre', name: 'Fibre', unit: 'g'},
  {key: 'protein', name: 'Protein', unit: 'g'},
  {key: 'salt', name: 'Salt', unit: 'g'},
  {key: 'sugars', name: 'Sugars', unit: 'g'},
  {key: 'energyKcal', name: 'Energy (Kcal)', unit: 'Kcal'},
];

function NewData({ trigger, trial, dataTypeId, onComplete }) {
  const [open, setOpen] = useState(false);
  const [dataType, setDataType] = useState();
  const [error, setError] = useState();
  const [selectedType, setSelectedType] = useState('text');
  const [name, setName] = useState('');
  const [textMin, setTextMin] = useState(0);
  const [textMax, setTextMax] = useState(0);
  const [minValue, setMinValue] = useState(0);
  const [maxValue, setMaxValue] = useState(0);
  const [sliderMin, setSliderMin] = useState(1);
  const [sliderMax, setSliderMax] = useState(10);
  const [dateMin, setDateMin] = useState();
  const [dateMax, setDateMax] = useState();
  const [choiceOptions, setChoiceOptions] = useState([]);
  const [newChoiceOption, setNewChoiceOption] = useState('');
  const [choiceMultipleSelection, setChoiceMultipleSelection] = useState(false);
  const [choiceIncludeNone, setChoiceIncludeNone] = useState(false);
  const [stroopOptions, setStroopOptions] = useState([]);
  const [newStroopOption, setNewStroopOption] = useState('');
  const [newStroopColour, setNewStroopColour] = useState('');
  const [stroopTimeLimit, setStroopTimeLimit] = useState(60);
  const [palWordPairs, setPalWordPairs] = useState([]);
  const [palPairTimeout, setPalPairTimeout] = useState(3);
  const [palAnswerTimeout, setPalAnswerTimeout] = useState(180);
  const [palDisplayAnswerTimer, setPalDisplayAnswerTimer] = useState(true);
  const [palDisplayCorrectResult, setPalDisplayCorrectResult] = useState(true);
  const [ravltTestSet, setRavltTestSet] = useState(Array(15).fill(''));
  const [ravltInterruptionSet, setRavltInterruptionSet] = useState(Array(15).fill(''));
  const [ravltPreInterruptionTests, setRavltPreInterruptionTests] = useState(3);
  const [ravltPostInterruptionTests, setRavltPostInterruptionTests] = useState(1);
  const [foodSource, setFoodSource] = useState('openFoodFacts');
  const [requiredFoodFields, setRequiredFoodFields] = useState(['protein', 'carbohydrate', 'fat', 'energyKcal'])
  const toast = useToast();
  const id = trial?._id;
  const { team, updateTrial } = useStore();
  const isEditing = !!dataTypeId;

  useEffect(() => {
    if (dataTypeId) api.dataTypes.get(dataTypeId, setDataType);
  }, [dataTypeId])

  useEffect(() => {
    if (dataType?.name) {
      setName(dataType.name);
      setSelectedType(dataType.type);
      setTextMin(dataType.minimumLength || 0);
      setTextMax(dataType.maximumLength || 0);
      setMinValue(dataType.minimumValue || 0);
      setMaxValue(dataType.maximumValue || 0);
      setSliderMin(dataType.minimum || 1);
      setSliderMax(dataType.maximum || 10);
      setChoiceOptions(dataType.options || []);
      setChoiceMultipleSelection(dataType.multiple || false);
      setChoiceIncludeNone(dataType.includeNone || false);
      setDateMin(dataType.earliestDate ? new Date(dataType.earliestDate) : null);
      setDateMax(dataType.latestDate ? new Date(dataType.latestDate) : null);
      setStroopOptions(dataType.options || []);
      setStroopTimeLimit(dataType.timeLimit || 60);
      setPalWordPairs(dataType.wordPairs || []);
      setPalPairTimeout(dataType.pairTimeout || 3);
      setPalAnswerTimeout(dataType.answerTimeout ?? 180);
      setPalDisplayAnswerTimer(dataType.displayAnswerTimer);
      setPalDisplayCorrectResult(dataType.displayCorrectResult);
      setRavltTestSet(dataType.testSet || Array(15).fill(''));
      setRavltInterruptionSet(dataType.interruptionSet || Array(15).fill(''));
      setRavltPreInterruptionTests(dataType.preInterruptionTests || 3);
      setRavltPostInterruptionTests(dataType.postInterruptionTests || 1);
      setFoodSource(dataType.foodSource || 'openFoodFacts');
      setRequiredFoodFields(dataType.requiredFoodFields || ['protein', 'carbohydrate', 'fat', 'energyKcal']);
    }
  }, [dataType]);
  
  function complete(dataType) {
    if (!dataTypeId) {
      setName('');
      setTextMin(0);
      setTextMax(0);
      setMinValue(0);
      setMaxValue(0);
      setSliderMin(1);
      setSliderMax(10);
      setChoiceOptions([]);
      setChoiceMultipleSelection(false);
      setChoiceIncludeNone(false);
      setDateMin(null);
      setDateMax(null);
      setStroopOptions([]);
      setStroopTimeLimit(60);
      setPalWordPairs([]);
      setPalPairTimeout(3);
      setPalAnswerTimeout(180);
      setPalDisplayAnswerTimer(true);
      setPalDisplayCorrectResult(true);
      setRavltTestSet(Array(15).fill(''));
      setRavltInterruptionSet(Array(15).fill(''));
      setRavltPreInterruptionTests(3);
      setRavltPostInterruptionTests(1);
    }
    setOpen(false);
    if (onComplete) onComplete(dataType);
  }

  function create() {
    setError();
    const data = { name };
    if (['text', 'paragraph'].indexOf(selectedType) > -1) {
      data.minimumLength = textMin || 0;
      data.maximumLength = textMax || 0;
      if (textMin < 0 || textMax < 0) return setError('Minimum and maximum values must be above 0')
      if (textMin && textMax && textMax < textMin) return setError('Maximum text length cannot be lower than the minimum')
    }
    if (['integer', 'float'].indexOf(selectedType) > -1) {
      data.minimumValue = minValue || 0;
      data.maximumValue = maxValue || 0;
      if (minValue < 0 || maxValue < 0) return setError('Minimum and maximum values must be above 0')
      if (minValue && maxValue && maxValue < minValue) return setError('Maximum value cannot be lower than the minimum')
    }
    if (selectedType === 'slider') {
      data.minimum = sliderMin;
      data.maximum = sliderMax;
      if (!data.minimum || !data.maximum) return setError('You must choose a minimum and maximum slider value');
    }
    if (selectedType === 'choice') {
      data.options = choiceOptions;
      data.multiple = choiceMultipleSelection;
      data.includeNone = choiceIncludeNone;
      if (!data.options || !data.options.length) return setError('You must provide at least one option');
    }
    if (['date', 'datetime'].indexOf(selectedType) > -1) {
      data.earliestDate = dateMin || null;
      data.latestDate = dateMax || null;
      if (dateMin && dateMax && dateMax < dateMin) return setError('Latest date cannot be earlier than the earliest date')
    }
    if (selectedType === 'stroop') {
      data.options = stroopOptions;
      data.timeLimit = stroopTimeLimit;
      if (!data.options || !data.options.length) return setError('You must provide at least one colour test');
    }
    if (selectedType === 'pal') {
      data.wordPairs = palWordPairs;
      data.pairTimeout = palPairTimeout;
      data.answerTimeout = palAnswerTimeout || 0;
      data.displayAnswerTimer = palDisplayAnswerTimer;
      data.displayCorrectResult = palDisplayCorrectResult;
      if (!data.wordPairs || !data.wordPairs.length) return setError('You must provide at least one word pair');
    }
    if (selectedType === 'ravlt') {
      data.testSet = ravltTestSet;
      data.interruptionSet = ravltInterruptionSet;
      data.preInterruptionTests = ravltPreInterruptionTests;
      data.postInterruptionTests = ravltPostInterruptionTests;
    }
    if (selectedType === 'food') {
      data.foodSource = foodSource;
      data.requiredFoodFields = requiredFoodFields;
    }
    if (isEditing) {
      api.dataTypes.update(dataType._id, data, dataType => {
        toast({title:'Data type updated', status:'success'});
        complete(dataType);
      }, err => setError(err.message));
    } else {
      data.type = selectedType;
      api.trials.createDataType(trial._id, data, dataType => {
        updateTrial(trial._id, { dataTypeCount: (trial.dataTypeCount ?? 1) + 1 });
        toast({title:'Data type created', status:'success'});
        complete(dataType);
      }, err => setError(err.message));
    }
  }

  function addStroopOption() {
    if (!newStroopColour || !newStroopOption) return;
    stroopOptions.push({
      colour: newStroopColour,
      text: newStroopOption,
    });
    setStroopOptions(stroopOptions);
    setNewStroopOption('');
    setNewStroopColour('');
  }
  function removeStroopOption(index) {
    const options = Object.assign([], stroopOptions);
    options.splice(index, 1);
    setStroopOptions(options);
  }

  function addPalWordPair() {
    setPalWordPairs([...palWordPairs, {
      words: ['',''],
      related: false,
    }]);
  }
  function removePalWordPair(index) {
    const newPairs = palWordPairs.filter((pair, i) => i !== index);
    setPalWordPairs(newPairs);
  }
  function updatePalWordPairRelated(index, related) {
    const newPairs = palWordPairs.map((pair, i) => {
      if (i === index) {
        pair.related = related;
      }
      return pair;
    });
    setPalWordPairs(newPairs);
  }
  function updatePalWordPair(index, pairIndex, newWord) {
    const newPairs = palWordPairs.map((pair, i) => {
      if (i === index) {
        pair.words[pairIndex] = newWord;
      }
      return pair;
    });
    setPalWordPairs(newPairs);
  }

  function addChoiceOption() {
    if (!newChoiceOption) return;
    choiceOptions.push(newChoiceOption);
    setChoiceOptions(choiceOptions);
    setNewChoiceOption('');
  }
  function removeChoiceOption(i) {
    const options = Object.assign([], choiceOptions);
    options.splice(i, 1);
    setChoiceOptions(options);
  }

  return (
    <>
      {trigger
        ? React.cloneElement(trigger, { onClick: () => setOpen(true) })
        : <Button colorScheme="blue" onClick={() => setOpen(true)}>Create new data type</Button>
      }
      <Modal isOpen={open} onClose={() => setOpen(false)} size='4xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isEditing ? `Edit your data type` : `Create a new data type`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
          <Text mb={15}>You can use this data type when building your trial stages.</Text>
    
          <Box rounded='md' p={5} mt={15} borderWidth='2px' bg='gray.50'>
            <Heading as='h3' size='md'>1. Name your data type</Heading>
            <Text mb={5}>This is just to help you identify the data type and will not be shown to participants. For example, "Weight", "Height", "Feeling".</Text>
            <Input bg='white' placeholder='My data type' type='text' value={name} onChange={e => setName(e.target.value)}/>
          </Box>
    
          <Box rounded='md' p={5} mt={15} borderWidth='2px' bg='gray.50'>
            <Heading as='h3' size='md'>2. Choose a format</Heading>
            <Text>Select a measurement from the data type library.</Text>
            {isEditing ?
              <>
                <Alert status='info' mt={5} mb={5} variant='left-accent'>Once created the data type format cannot be changed.</Alert>
                <Text><b>Data type format:</b> {selectedType}</Text>
              </>
            :
              <Accordion mt={5} allowToggle defaultIndex={[0]}>
              {categories.map(c =>
                <AccordionItem>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Heading as='h4' size='sm' color='primary.500'><Box as={c.icon} mr={2} display='inline-block'/> {c.name}</Heading>
                      <Text mt={3}>{c.description}</Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Grid templateColumns={{md: 'repeat(2, 1fr)', lg:'repeat(3, 1fr)'}} gap={2} mt={5} mb={5}>
                      {c.types.map(t => {
                        const allowed = utils.hasFeature(team, t.feature || c.feature);
                        return (
                          <Box key={t.key} p={3} borderWidth='1px' bg={selectedType === t.key ? 'primary.100' : 'white'} onClick={(e, r) => !isEditing && setSelectedType(t.key)} cursor='pointer'>
                            {!allowed && <Box><Badge mb={2} colorScheme='orange' size='sm'><LockIcon size='xs' /> Not available in your team</Badge></Box>}
                            <Radio isDisabled={!allowed || isEditing} name='type' isChecked={selectedType === t.key}>
                              <Heading as='h4' size='sm' fontWeight='bold'>{t.label}</Heading>
                            </Radio>
                            <Box>
                              {t.mobile && <Badge mt={1} colorScheme='green' size='sm'>Participant compatible</Badge>}
                              <Text mt={1} fontSize='xs'>{t.description}</Text>
                            </Box>
                          </Box>
                        );
                      })}
                    </Grid>
                  </AccordionPanel>
                </AccordionItem>
              )}
              </Accordion>
            }
          </Box>
          
          {['text', 'paragraph'].indexOf(selectedType) > -1 &&
            <Box rounded='md' p={5} mt={15} borderWidth='2px' bg='gray.50'>
              <Heading as='h3' size='md'>3. Configure this data type</Heading>
              <Text mb={5}>Choose extra settings for this field.</Text>
              <Text>Minimum allowed text length <small>(Leave as 0 or blank for no minimum)</small></Text>
              <Input mb={5} bg='white' placeholder='1' type='number' value={textMin} onChange={e => setTextMin(parseInt(e.target.value))} />
              <Text>Maximum allowed text length <small>(Leave as 0 or blank for no maximum)</small></Text>
              <Input bg='white' placeholder='1' type='number' value={textMax} onChange={e => setTextMax(parseInt(e.target.value))} />
            </Box>
          }
          
          {['integer', 'float'].indexOf(selectedType) > -1 &&
            <Box rounded='md' p={5} mt={15} borderWidth='2px' bg='gray.50'>
              <Heading as='h3' size='md'>3. Configure this data type</Heading>
              <Text mb={5}>Choose extra settings for this field.</Text>
              <Text>Minimum allowed value <small>(Leave as 0 or blank for no minimum)</small></Text>
              <Input mb={5} bg='white' placeholder='1' type='number' value={minValue} onChange={e => setMinValue(parseInt(e.target.value))} />
              <Text>Maximum allowed value <small>(Leave as 0 or blank for no maximum)</small></Text>
              <Input bg='white' placeholder='1' type='number' value={maxValue} onChange={e => setMaxValue(parseInt(e.target.value))} />
            </Box>
          }
    
          {selectedType === 'slider' &&
            <Box rounded='md' p={5} mt={15} borderWidth='2px' bg='gray.50'>
              <Heading as='h3' size='md'>3. Slider settings</Heading>
              <Text mb={5}>Allow participants to select a number between the following minimum and maximum.</Text>
              <Text>Minimum value</Text>
              <Input bg='white' placeholder='1' type='number' value={sliderMin} onChange={e => setSliderMin(parseInt(e.target.value))} />
              <Text mt={5}>Maximum value</Text>
              <Input bg='white' placeholder='10' type='number' value={sliderMax} onChange={e => setSliderMax(parseInt(e.target.value))} />
            </Box>
          }
    
          {selectedType === 'choice' &&
            <Box rounded='md' p={5} mt={15} borderWidth='2px' bg='gray.50'>
              <Heading as='h4' size='md'>3. Choice settings</Heading>
              <Text mb={5}>Manage the options for participants to select from.</Text>
              
              <Text>New option</Text>
              <Input bg='white' value={newChoiceOption} onChange={e => setNewChoiceOption(e.target.value)} />
              <Button colorScheme='blue' mb={5} mt={2} onClick={addChoiceOption}>Add option</Button>
              <Box>
                {choiceOptions && choiceOptions.map((c,i) =>
                  <Tag size='lg' key={c} variant='subtle' colorScheme='cyan' mr={2}>
                    <TagLabel>{c}</TagLabel>
                    <TagRightIcon boxSize='12px' as={CloseButton} onClick={e => removeChoiceOption(i)}/>
                  </Tag>
                )}
              </Box>
              <Box mt={5}>
                <Checkbox isChecked={choiceMultipleSelection} onChange={e => setChoiceMultipleSelection(e.target.checked)}>Allow participants to select more than one option</Checkbox>
              </Box>
              <Box mt={5}>
                <Checkbox isChecked={choiceIncludeNone} onChange={e => setChoiceIncludeNone(e.target.checked)}>Include an additional "None" option <small>(not available on mobile)</small></Checkbox>
              </Box>
            </Box>
          }
          
          {['date', 'datetime'].indexOf(selectedType) > -1 &&
            <Box rounded='md' p={5} mt={15} borderWidth='2px' bg='gray.50'>
              <Heading as='h3' size='md'>3. Date settings</Heading>
              <Text mb={5}>Define the earliest and latest date available for this data type. Leave blank to leave the field as unrestricted.</Text>
              <Text>Earliest available date</Text>
              <Box maxW='300px' display='flex'>
                <DatePicker
                  placeholderText='Tap to select a date...'
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  todayButton="Today"
                  dateFormat="dd/MM/yyyy"
                  selected={dateMin}
                  onChange={(date) => setDateMin(date)}
                  customInput={<Input bg='white' />}
                />
                <Button ml={5} onClick={() => setDateMin(null)}>Unset</Button>
              </Box>
              
              <Text mt={5}>Latest available date</Text>
              <Box maxW='300px' display='flex'>
                <DatePicker
                  placeholderText='Tap to select a date...'
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  todayButton="Today"
                  dateFormat="dd/MM/yyyy"
                  selected={dateMax}
                  onChange={(date) => setDateMax(date)}
                  customInput={<Input bg='white' />}
                />
                <Button ml={5} onClick={() => setDateMax(null)}>Unset</Button>
              </Box>
            </Box>
          }
    
          {selectedType === 'stroop' &&
            <Box rounded='md' p={5} mt={15} borderWidth='2px' bg='gray.50'>
              <Heading as='h3' size='md'>3. Configure this Stroop test</Heading>
              <Text>In the Stroop test, participants will be shown a series of "colour tests" (the name of a colour written in another colour), and will be asked to select the name of the colour the colour test is written in.</Text>
              <Text mb={10}>Add text and colour combinations below for the Stroop test.</Text>
    
              <Box display={{md:'flex'}}>
                <Box w={{md:'50%'}} m={2}>
                  <Text>Text colour</Text>
                  <Popover>
                    <PopoverTrigger>
                      <Input bg='white' value={newStroopColour} label='Colour' placeholder='#441f1f' readOnly/>
                    </PopoverTrigger>
                    <PopoverContent zIndex={4} bg='white'>
                      <PopoverBody>
                        <ChromePicker color={newStroopColour} onChange={({hex}) => setNewStroopColour(hex)}/>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Box>
                <Box w={{md:'50%'}} m={2}>
                  <Text>Colour name</Text>
                  <Input bg='white' value={newStroopOption} onChange={e => setNewStroopOption(e.target.value)} placeholder='RED'/>
                </Box>
              </Box>
              <Button colorScheme='blue' mt={3} onClick={addStroopOption}>Add option</Button>
    
              <Box mt={3}>
              {stroopOptions && stroopOptions.length > 0 &&
                <>
                  {stroopOptions.map((o, i) =>
                    <Tag size='lg' key={o.text} variant='subtle' color='white' bg={o.colour} mr={2}>
                      <TagLabel>{o.text}</TagLabel>
                      <TagRightIcon boxSize='12px' as={CloseButton} onClick={e => removeStroopOption(i)}/>
                    </Tag>
    
                  )}
                </>
              }
              </Box>
              {/*<Badge color='white' fontSize='lg' bg={o.colour} m={3} key={o.text}>{o.text} <CloseButton onClick={e => removeStroopOption(i)}/></Badge>*/}
              <Text mt={10}>How long should the test keep generating new combinations for?</Text>
              <Select value={stroopTimeLimit} onChange={e => setStroopTimeLimit(e.target.value)}>
                {times.map(o => <option key={o.key} value={o.value}>{o.text}</option>)}
              </Select>
            </Box>
          }

          {selectedType === 'pal' &&
            <Box rounded='md' p={5} mt={15} borderWidth='2px' bg='gray.50'>
              <Heading as='h3' size='md'>3. Configure this PAL test</Heading>
              <Text>In the PAL test, participants will be shown a series of word pair combinations, and are then asked to remember the correct pair for each given word.</Text>
              <Text mb={5}>Add word pairs below for the PAL test.</Text>
    
              <Box display={{md:'flex'}}>
                {palWordPairs.length > 0 &&
                  <SimpleTable headers={['Left word', 'Right word', 'Related', '']} rows={palWordPairs.map((p, i) => [
                    <Input bg='white' value={p.words[0]} onChange={e => updatePalWordPair(i, 0, e.target.value)}/>,
                    <Input bg='white' value={p.words[1]} onChange={e => updatePalWordPair(i, 1, e.target.value)}/>,
                    <Checkbox isChecked={p.related} onChange={e => updatePalWordPairRelated(i, e.target.checked)}/>,
                    <Button colorScheme='red' onClick={e => removePalWordPair(i)}>Remove</Button>
                  ])} />
                }
              </Box>
              <Button colorScheme='blue' mt={3} onClick={addPalWordPair}>Add word pair</Button>

              <Box mt={5}>
                <Text>For how many seconds should participants be shown each pair?</Text>
                <Input bg='white' type='number' value={palPairTimeout} onChange={e => setPalPairTimeout(parseInt(e.target.value) || '')} w='100px' />
                <Text mt={4}>For how many seconds should participants be allowed to select matches for? (Leave 0 or blank for no limit)</Text>
                <Input bg='white' type='number' placeholder='0' value={palAnswerTimeout} onChange={e => setPalAnswerTimeout(parseInt(e.target.value) || '')} w='100px' />
                <Text mt={4}>Should the participant see a timer counting down when selecting pairs?</Text>
                <Checkbox isChecked={palDisplayAnswerTimer} onChange={e => setPalDisplayAnswerTimer(e.target.checked)}>Display answer timer</Checkbox>
                <Text mt={4}>Should the participant see if they selected the correct pair after selecting?</Text>
                <Checkbox isChecked={palDisplayCorrectResult} onChange={e => setPalDisplayCorrectResult(e.target.checked)}>Display correct result</Checkbox>
              </Box>
            </Box>
          }

          {selectedType === 'ravlt' &&
            <Box rounded='md' p={5} mt={15} borderWidth='2px' bg='gray.50'>
              <Heading as='h3' size='md'>3. Configure this RAVLT test</Heading>
              <Text mb={5}>In the Rey Auditory Verbal Learning Test (RAVLT), participants will be shown or told a series of words and asked to recall them after a delay.</Text>
              <Alert status='info' mb={5} variant='left-accent'>Please note that this test must be conducted by an administrator who fills-in the results on the participant page.</Alert>
              <Text mb={10}>Provide two sets of 15 words. The first set is the "test" set and the second set is the "interruption" set.</Text>

              <Stack direction='row' spacing={5} mb={5}>
                <Box>
                  <Text>Number of pre-interruption tests</Text>
                  <Select bg='white' value={ravltPreInterruptionTests} onChange={e => setRavltPreInterruptionTests(e.target.value)}>
                    {[1,2,3,4,5].map(o => <option key={o} value={o}>{o}</option>)}
                  </Select>
                </Box>
                <Box>
                  <Text>Number of post-interruption tests</Text>
                  <Select bg='white' value={ravltPostInterruptionTests} onChange={e => setRavltPostInterruptionTests(e.target.value)}>
                    {[1,2,3,4,5].map(o => <option key={o} value={o}>{o}</option>)}
                  </Select>
                </Box>
              </Stack>
              
              <SimpleTable headers={['Test set', 'Interruption set']} rows={ravltTestSet.map((w, i) => [
                <Input bg='white' placeholder={`Test word ${i+1}`} value={ravltTestSet[i]} onChange={e => setRavltTestSet(ravltTestSet.map((w, j) => i === j ? e.target.value : w))} />,
                <Input bg='white' placeholder={`Interruption word ${i+1}`}  value={ravltInterruptionSet[i]} onChange={e => setRavltInterruptionSet(ravltInterruptionSet.map((w, j) => i === j ? e.target.value : w))} />,
              ])} />
            </Box>
          }
    
          {selectedType === 'food' &&
            <Box rounded='md' p={5} mt={15} borderWidth='2px' bg='gray.50'>
              <Heading as='h3' size='md'>3. Configure the food input type</Heading>
    
              <Heading size='sm' mt={10}>Nutrition data source</Heading>
              <Text>Trialflare supports different food nutrition sources for this type of data.</Text>
              <Select mt={5} bg='white' placeholder='Choose a food nutrition data source...' value={foodSource} onChange={e => setFoodSource(e.target.value)}>
                <option value='openFoodFacts'>Open Food Facts</option>
                <option value='nutritics'>Nutritics</option>
              </Select>
    
              <Heading size='sm' mt={10}>Nutritional information required</Heading>
              <Text>Trialflare will try to automatically determine nutritional values from the data source. However, if these are unavailable for a particular item, you can specify the fields required to be completed manually.</Text>
              {nutritionTypes.map(n =>
                <Box key={n.key} p={2} bg='white' display='inline-block' m={2}>
                  <Checkbox key={n.key} isChecked={requiredFoodFields.indexOf(n.key) > -1}
                    onChange={e => {
                      const index = requiredFoodFields.indexOf(n.key);
                      const newRequiredFoodFields = Object.assign([], requiredFoodFields);
                      if (index === -1) newRequiredFoodFields.push(n.key);
                      else newRequiredFoodFields.splice(index, 1);
                      setRequiredFoodFields(newRequiredFoodFields);
                    }}>
                    {n.name}
                  </Checkbox>
                </Box>
              )}
            </Box>
          }

        </ModalBody>
    
        <StyledSaveBar bg='primary.50' p={3} display='flex' m={2} shadow='lg' rounded='md' justifyContent='space-between' alignItems='center'>
          <Box>
            {error &&
              <Alert mr={3} status='error' variant='left-accent'>
                <Box>
                  <AlertTitle>There was a problem saving your data type</AlertTitle>
                  <AlertDescription>{error}. Please fix these issues and try again.</AlertDescription>
                </Box>
              </Alert>
            }
          </Box>
          <Box>
            <Button variant='ghost' mr={3} onClick={() => setOpen(false)}>Cancel</Button>
            <Button colorScheme='primary' onClick={create}>{isEditing ? `Save data type` : `Create data type`}</Button>
          </Box>
        </StyledSaveBar>
      </ModalContent>
    </Modal>
    </>
  );
}

export default NewData;
