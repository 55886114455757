import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

const EmptyBox = props => {
  return (
    <Box rounded='lg' p={10} m={5} bg='gray.50' textAlign='center' {...props}>
      {props.image &&
        <img alt={props.title} src={props.image} style={{height: 250, display: 'block', margin: '10px auto'}} />
      }
      <Heading as='h3' size='md' mb={4}>{props.title}</Heading>
      <Text mb={10}>{props.description}</Text>
      {props.action}
    </Box>
  );
};

export default EmptyBox;
