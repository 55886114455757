import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useToast, Stack, Box, Select, Tooltip, Badge, Tag, TagLabel, TagRightIcon, CloseButton, Button, Heading, Text, Popover, PopoverContent, PopoverHeader, PopoverBody, PopoverTrigger, Input, Alert, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import api from '../../../api';
import useStore from '../../../store';
import utils from '../../../utils/utils';

import ConfirmButton from '../../includes/ConfirmButton';

const DEFAULT_PERMISSION = 'trial.access';

export default function CollaborationSettings({ user, trial }) {
  const toast = useToast();
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [groups, setGroups] = useState([]);
  const [sites, setSites] = useState([]);
  const [manageCollaborator, setManageCollaborator] = useState();
  const [collaborators, setCollaborators] = useState([]);
  const trialId = trial?._id;
  const teamId = trial?.team;
  const { team } = useStore();

  useEffect(() => {
    api.trials.getCollaborators(trialId, d => setCollaborators(d.collaborators));
    api.trials.getGroups(trialId, d => setGroups(d.groups));
    api.sites.getForTrial(trialId, d => setSites(d.sites));
  }, [trialId]);

  useEffect(() => {
    if (!searchTerm || searchTerm.length < 3) return;
    api.teams.searchUsers(teamId, searchTerm, ({ users }) => setSearchResults(users));
  }, [teamId, searchTerm]);

  const addCollaborator = (newCollaborator) => {
    api.users.updatePermissions(newCollaborator._id, trial._id, [DEFAULT_PERMISSION], data => {
      newCollaborator.permissions = Object.assign({}, {[trial._id]: data.permissions});
      const newCollaborators = Object.assign([], collaborators);
      newCollaborators.push(newCollaborator);
      setCollaborators(newCollaborators);
      setSearchTerm('');
      setSearchResults([]);
    }, err => {
      toast({title: 'Unable to update this user', desciption: err.message, status: 'error'});
    });
  }

  const removeCollaborator = c => {
    api.users.updatePermissions(c._id, trial?._id, [], data => {
      setCollaborators(Object.assign([], collaborators).filter(u => u._id !== c._id));
    });
  };

  const updateCollaboratorPermissions = (collaboratorId, permissions) => {
    setCollaborators(collaborators.map(c => {
      if (c._id === collaboratorId) return Object.assign({}, c, { permissions });
      return c;
    }));
  };

  const canModifyPermission = c => {
    if (!utils.canManageTrial(user, trial)) return false;
    if (utils.hasPermission(c, 'trials.manage', trial.team)) return false;
    return true;
  };

  return (
    <>
      <Heading size='md'>Collaborators on this trial</Heading>
      <Text mb={5}>See how others in your team can view and manage {trial?.name}.</Text>

      <Alert mb={5} display='flex' justifyContent='space-between' variant='left-accent'>
        <Text mr={2} fontSize='sm'>You can only add existing registered users in {team?.name} as collaborators to this trial.</Text>

        {utils.hasPermission(user, 'team.manage', trial?.team) &&
          <Button size='sm' as={Link} to={`/teams/${trial?.team}`} colorScheme='blue'>Manage team members</Button>
        }
      </Alert>

      {utils.hasPermission(user, 'team.manage', trial?.team) &&
        <Box display='flex' justifyContent='end' mb={5}>
          <Popover>
            <PopoverTrigger>
              <Button colorScheme='primary'>Add a collaborator</Button>
            </PopoverTrigger>
            <PopoverContent bg='gray.100' placement='left' w={400}>
              <PopoverHeader>
                <Heading size='md' as='h4'>Add someone to help manage {trial?.name}</Heading>
                <Text>Search for a member of your team to add them to your trial. You can search by name or email address.</Text>
              </PopoverHeader>
              <PopoverBody>
                <Input bg='white' placeholder="Begin typing..." value={searchTerm} onChange={e => setSearchTerm(e.target.value)} />
                {searchTerm?.length > 2 && searchResults?.length === 0 &&
                  <Alert status='warning' variant='left-accent' mt={3} size='xs'>No users match your search</Alert>
                }
                {searchResults?.length > 0 &&
                  <Table size='xs' mt={3}>
                    {searchResults.map(res =>
                      <Tr key={res._id}>
                        <Td>{res.firstName} {res.lastName}</Td>
                        <Td>{res.email}</Td>
                        <Td>{utils.canAccessTrial(res, trial) ? <Badge colorScheme="green">Added</Badge> : <Button colorScheme='primary' size='xs' onClick={() => addCollaborator(res)}><AddIcon mr={2} /> Add</Button>}</Td>
                      </Tr>
                    )}
                  </Table>
                }
              </PopoverBody>
            </PopoverContent>
          </Popover>
        </Box>
      }

      <Table size='sm'>
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Permissions</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {collaborators?.map(c =>
            <Tr key={c._id}>
              <Td>{(c.firstName || c.lastName) ? <span>{c.firstName} {c.lastName}</span> : <span>{c.email}</span>}</Td>
              <Td>
                {c.permissions && c.permissions[trial._id]?.map(p => {
                  if (p === DEFAULT_PERMISSION) return null;
                  return <Tag colorScheme='blue' size='sm' mr={1} mb={1}>{p}</Tag>;
                })}
                {groups?.map(g => {
                  const count = (c.permissions && c.permissions[g._id]?.length) || 0;
                  if (count > 0) return <Tag bg={g.colour} size='sm' mr={1} mb={1}>{g.name} ({count} permissions)</Tag>;
                })}
                {sites?.map(s => {
                  const count = (c.permissions && c.permissions[s._id]?.length) || 0;
                  if (count > 0) return <Tag bg='gray.200' size='sm' mr={1} mb={1}>{s.name} ({count} permissions)</Tag>;
                })}

                {utils.hasPermission(c, 'trials.manage', trial?.team) && <Tooltip placement='top' label='This user can manage all trials'><Badge colorScheme="primary">Team admin</Badge></Tooltip>}
                {utils.hasPermission(c, 'root', 'global') && <Tooltip placement='top' label='This user is your team account manager'><Badge size='xs' ml={5} colorScheme='blue'>Account manager</Badge></Tooltip>}
              </Td>
              <Td isNumeric>
                {canModifyPermission(c) &&
                  <>
                    <Button colorScheme='blue' size='sm' onClick={() => setManageCollaborator(c)}>Manage permissions</Button>
                    <ConfirmButton
                      trigger={<Button colorScheme='red' variant='ghost' size='xs' ml={2}><DeleteIcon mr={1} /> Remove</Button>}
                      header={`Really remove ${c.firstName || 'this user'} from the trial?`}
                      footer={`To give the user access again a team admin will need to re-add them as a collaborator.`}
                      action={() => removeCollaborator(c)}
                    />
                  </>
                }
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>

      <CollaboratorEditor collaborator={manageCollaborator} onUpdatePermissions={updateCollaboratorPermissions} onClose={() => setManageCollaborator(null)} trial={trial} groups={groups} sites={sites} />
    </>
  );
}

function CollaboratorEditor({ collaborator, onUpdatePermissions, onClose, trial, groups, sites }) {
  const [newPermissionScope, setNewPermissionScope] = useState('');
  const [newPermissionName, setNewPermissionName] = useState('');
  const [permissions, setPermissions] = useState({});

  useEffect(() => {
    if (collaborator) {
      setPermissions(collaborator.permissions || {});
    }
  }, [collaborator]);

  const getGroup = (groupId) => {
    return groups?.filter(g => g._id === groupId)[0];
  };

  const addNewPermission = () => {
    const newPermission = Object.assign([], permissions[newPermissionScope] || []);
    if (newPermission.indexOf(newPermissionName) > -1) return;
    newPermission.push(newPermissionName);
    api.users.updatePermissions(collaborator._id, newPermissionScope, newPermission, data => {
      setNewPermissionName('');
      setNewPermissionScope('');
      setPermissions({...permissions, [newPermissionScope]: data.permissions });
      onUpdatePermissions(collaborator._id, {...permissions, [newPermissionScope]: data.permissions });
    });
  };

  const removePermission = (scope, name) => {
    const permission = permissions[scope] || [];
    const index = permission.indexOf(name);
    if (index === -1) return;
    permission.splice(index, 1);
    api.users.updatePermissions(collaborator._id, scope, permission, data => {
      setPermissions({ ...permissions, [newPermissionScope]: data.permissions });
      onUpdatePermissions(collaborator._id, {...permissions, [newPermissionScope]: data.permissions });
    });
  };

  if (!collaborator) return null;
  const newPermissionScopeType = newPermissionScope === trial._id ? 'trial' : groups.filter(g => g._id === newPermissionScope)[0] ? 'group' : 'site';

  return (
    <Modal isOpen={!!collaborator} onClose={null} size='xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Manage {collaborator.firstName} {collaborator.lastName}'s permissions for {trial.name}</ModalHeader>
        <ModalBody>
          <Box p={3} bg='blue.50'>
            <Heading size='sm'>Add a new permission</Heading>
            <Stack direction='row' mt={5}>

              <Select bg='white' size='sm' placeholder="Add permission for..." value={newPermissionScope}
                onChange={e => {
                  setNewPermissionScope(e.target.value);
                  setNewPermissionName(null);
                }}
              >
                <option value={trial._id}>Trial: {trial.name}</option>
                {groups.map(group =>
                  <option key={group._id} value={group._id}>Group: {group.name}</option>
                )}
                {sites.map(site =>
                  <option key={site._id} value={site._id}>Site: {site.name}</option>
                )}
              </Select>

              {newPermissionScope &&
                <Select bg='white' size='sm' placeholder="Select a permission..." value={newPermissionName}
                  onChange={e => {
                    setNewPermissionName(e.target.value);
                  }}
                >
                  {newPermissionScopeType === 'trial' &&
                    <>
                      {!utils.canReadTrial(collaborator, trial) && <option value='trial.read'>(trial.read) Read trial data, settings, and files</option>}
                      {!utils.canWriteTrial(collaborator, trial) && <option value='trial.write'>(trial.write) Access files and view and change trial response data</option>}
                      {!utils.canReadFiles(collaborator, trial) && <option value='trial.readFiles'>(trial.readFiles) Access files in the eTMF</option>}
                      {!utils.canWriteFiles(collaborator, trial) && <option value='trial.writeFiles'>(trial.writeFiles) Create new files and versions in the eTMF</option>}
                      {!utils.canDeleteFiles(collaborator, trial) && <option value='trial.deleteFiles'>(trial.deleteFiles) Delete files and versions in the eTMF</option>}
                      {!utils.canSupportTrial(collaborator, trial) && <option value='trial.support'>(trial.support) Send & receive support messages</option>}
                      {!utils.canManageTrial(collaborator, trial) && <option value='trial.admin'>(trial.admin) Trial admin (read & change all settings & data)</option>}
                    </>
                  }
                  {newPermissionScopeType === 'group' &&
                    <>
                      {!utils.canReadGroup(collaborator, getGroup(newPermissionScope)) && <option value='group.read'>(group.read) Read group participants & data</option>}
                      {!utils.canWriteGroup(collaborator, getGroup(newPermissionScope)) && <option value='group.write'>(group.write) Read & change group participant data</option>}
                    </>
                  }
                  {newPermissionScopeType === 'site' &&
                    <>
                      {!utils.canReadSite(collaborator, sites.filter(s => s._id === newPermissionScope)[0]) && <option value='site.read'>(site.read) Read site participants & data</option>}
                      {!utils.canWriteSite(collaborator, sites.filter(s => s._id === newPermissionScope)[0]) && <option value='site.write'>(site.write) Read & change site participant data</option>}
                    </>
                  }
                </Select>
              }

              {newPermissionScope && newPermissionName &&
                <Button size='sm' colorScheme='primary' onClick={addNewPermission}>Add</Button>
              }

            </Stack>
          </Box>

          <Box p={3} bg='gray.50' mt={10}>
            <Heading size='sm'>Existing permissions</Heading>
            <Box mt={5}>
              <Heading size='xs' mb={3}>Permissions for the trial as a whole</Heading>
              {permissions[trial._id]?.map(p => {
                if (p === DEFAULT_PERMISSION) return null;
                return (
                  <Tag mr={2} mb={2} key={p} colorScheme='blue'>
                    <TagLabel>{p}</TagLabel>
                    <TagRightIcon as={CloseButton} onClick={e => removePermission(trial._id, p)}/>
                  </Tag>
                );
              })}
            </Box>

            {groups?.map(g => {
              if (!permissions[g._id] || permissions[g._id].length === 0) return null;
              return permissions[g._id] &&
                <Box key={g._id} mt={5}>
                  <Heading size='xs' mb={3}>Permissions for the group {g.name}</Heading>
                  {permissions[g._id]?.map(p =>
                    <Tag mr={2} mb={2} key={`${g._id}{p}`} bg={g.colour}>
                      <TagLabel>{p}</TagLabel>
                      <TagRightIcon as={CloseButton} onClick={e => removePermission(g._id, p)}/>
                    </Tag>
                  )}
                </Box>
            })}

            {sites?.map(s => {
              if (!permissions[s._id] || permissions[s._id].length === 0) return null;
              return permissions[s._id] &&
                <Box key={s._id} mt={5}>
                  <Heading size='xs' mb={3}>Permissions for the site {s.name}</Heading>
                  {permissions[s._id]?.map(p =>
                    <Tag mr={2} mb={2} key={`${s._id}{p}`} bg='gray.200'>
                      <TagLabel>{p}</TagLabel>
                      <TagRightIcon as={CloseButton} onClick={e => removePermission(s._id, p)}/>
                    </Tag>
                  )}
                </Box>
            })}
          </Box>

        </ModalBody>

        <ModalFooter>
          <Button colorScheme='blue' variant='solid' onClick={onClose}>Finish</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
