import React, { useState, useRef, useEffect } from 'react';
import { useToast, Badge, Heading, Text, Box, Button, Icon } from '@chakra-ui/react';
import { Link, useParams } from 'react-router-dom';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { GrDrag } from 'react-icons/gr';
import { FaFilePdf } from "react-icons/fa";

import api from '../../api';
import utils from '../../utils/utils';
import useStore from '../../store';
import useTitle from '../../hooks/useTitle';

import EmptyBox from '../includes/EmptyBox';
import ConfirmButton from '../includes/ConfirmButton';
import StagesImage from '../../images/stages.png';

export default function Stages({ }){
  const [stages, setStages] = useState([]);
  const toast = useToast();
  const { id } = useParams();
  const { user, trial, updateTrial } = useStore();
  useTitle('Stages', trial);

  useEffect(() => {
    api.trials.getStages(id, d => setStages(d.stages));
  }, [id]);

  function deleteStage(id) {
    api.stages.delete(id, () => {
      toast({title:'Stage deleted', status:'success'});
      setStages(stages?.filter(s => s._id !== id));
      updateTrial(trial._id, { stageCount: (trial.stageCount ?? 1) - 1 });
    }, err => toast({title: 'Unable to delete stage', description: err.message, status: 'error'}));
  }

  function clone(s) {
    api.trials.createStage(trial._id, { copyFrom: s._id }, stage => {
      toast({title: 'Stage created', status: 'success'});
      const newStages = Object.assign([], stages);
      newStages.push(stage);
      updateTrial(trial._id, { stageCount: (trial.stageCount ?? 0) + 1 });
      setStages(newStages);
    }, err => toast({title: 'Unable to clone stage', description: err.message, status: 'error'}));
  }

  function move(s, newIndex, onlyCommit) {
    if (newIndex < 0 || newIndex >= stages.length) return;
    if (!onlyCommit) {
      const stage = Object.assign({}, s);
      stage.index = newIndex;
      const newStages = Object.assign([], stages);
      newStages.splice(stages.indexOf(s), 1);
      newStages.splice(newIndex, 0, stage);
      setStages(newStages);
    }
    if (onlyCommit) api.stages.update(s._id, { index: newIndex });
  }

  return (
    <>
      <Heading as='h2' size='lg'>Trial stages</Heading>
      <Button style={{float: 'right'}} as={Link} to={`/trials/${trial._id}/stages/new`} colorScheme='primary'>Create a new stage</Button>
      <Text mb={15}>Define the stages that you will use to collect your data.</Text>
      <div style={{clear:'both'}} />

      {(stages?.length > 0) ?
        <DndProvider backend={HTML5Backend}>
          <Box pt={15}>
            {stages.map((s, i) =>
              <StageCard user={user} trial={trial} stage={s} key={s._id} index={i} moveStage={move} cloneStage={clone} deleteStage={deleteStage} />
            )}
          </Box>
        </DndProvider>
      :
        <EmptyBox image={StagesImage} title='Create your first stage' description='Stages define how your trial will collect your data types, allowing you to see how data types change over time.' action={<Button colorScheme='primary' as={Link} to={`/trials/${trial._id}/stages/new`}>Create your first stage</Button>} />
      }
    </>
  );
}

function StageCard({ user, trial, stage, index, moveStage, cloneStage, deleteStage }) {
  const [exporting, setExporting] = useState(false);
  const [isHoveringGrab, setIsHoveringGrab] = useState(false);
  const toast = useToast();
  const ref = useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: 'STAGE',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item, monitor) {
      if (!ref.current) return
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) return
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return
      moveStage(stage, dragIndex, false)
      item.index = hoverIndex
    },
    drop(item, monitor) {
      moveStage(stage, item.index, true)
    },
  })
  const [{ isDragging }, drag, preview] = useDrag({
    type: 'STAGE',
    item: () => {
      return { id: stage._id, index }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  function exportStage(id) {
    setExporting(true);
    api.stages.getPdf(id, pdf => {
      const link = document.createElement('a');
      link.href = pdf?.url;
      link.setAttribute('download', `${stage.name}.pdf`);
      document.body.appendChild(link);
      link.click();
      setExporting(false);
    }, err => {
      toast({title: 'Unable to export stage', description: err.message, status: 'error'});
      setExporting(false);
    });
  }

  const opacity = isDragging ? 0 : 1
  utils.canManageTrial(user, trial) && drag(drop(ref));
  const s = stage;

  return (
    <Box ref={ref} style={{ opacity }} key={s._id} rounded='md' mb={5} p={3} borderWidth='1px' display={{md: 'flex'}} justifyContent='space-between' shadow={isHoveringGrab ? 'xl' : null} transition='0.5s'>
      {utils.canManageTrial(user, trial) &&
        <Box w={50} display='flex' alignItems='center'>
          <Icon as={GrDrag} w={6} h={6} cursor='grab' onMouseEnter={e => setIsHoveringGrab(true)} onMouseLeave={e => setIsHoveringGrab(false)} />
        </Box>
      }
      <Box flex={1}>
        <Heading as='h4' size='md' mb={2}>{s.name}</Heading>
        <Badge colorScheme='blue'>Collects {s.dataTypeCount} data {s.dataTypeCount === 1 ? 'type' : 'types'}</Badge>
        {s.isRecurring && s.recurringFrequency > 0 && <Badge ml={2} colorScheme='primary'>Recurs every {s.recurringFrequency} {s.recurringFrequency === 1 ? 'day' : 'days'} ({s.recurringDuration || 0} {s.recurringDuration === 1 ? 'time' : 'times'})</Badge>}
        {s.adHoc && <Badge ml={2} colorScheme='primary'>Ad-hoc</Badge>}
        {s.reminderCount > 0 && <Badge ml={2} colorScheme='blue'>{s.reminderCount} reminders</Badge>}
        {s.isPoll && <Badge ml={2} colorScheme='yellow'>Poll mode</Badge>}
      </Box>
      {utils.canManageTrial(user, trial) &&
        <Box display='flex' alignItems='center'>
          <Button size='sm' as={Link} colorScheme='blue' to={`${s._id}`}>Edit</Button>
          <Button size='sm' onClick={() => exportStage(s._id)} ml={2} isLoading={exporting}><Icon as={FaFilePdf} /></Button>
          <Button size='sm' colorScheme='blue' variant='outline' ml={2} onClick={e => cloneStage(s)}>Clone</Button>
          <ConfirmButton
            trigger={<Button size='sm' ml={2} colorScheme='red' variant='outline'>Delete</Button>}
            header='Really delete this stage?'
            footer='Any data collected by participants for this stage will be lost.'
            action={e => deleteStage(s._id)}
          />
        </Box>
      }
    </Box>
  )
}
