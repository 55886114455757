import React, { useState, useEffect } from 'react';
import { useToast, Alert, Heading, Text, Stack, Box, Switch } from '@chakra-ui/react';
import api from '../../../api';
import useStore from '../../../store';
import utils from '../../../utils/utils';

import FitbitImage from '../../../images/fitbit.png';
import GarminImage from '../../../images/garmin.png';

export default function IntegrationsSettings({ user, trial }) {
  const toast = useToast();
  const trialId = trial?._id;
  const { updateTrial, team } = useStore();

  function toggleFitbitIntegration(e) {
    api.trials.update(trialId, { fitbitEnabled: e.target.checked }, newTrial => {
      updateTrial(newTrial._id, newTrial);
    }, err => {
      toast({
        title: 'Error updating Fitbit integration',
        description: err.message,
        status: 'error',
        isClosable: true,
      });
    });
  }

  function toggleGarminIntegration(e) {
    api.trials.update(trialId, { garminEnabled: e.target.checked }, newTrial => {
      updateTrial(newTrial._id, newTrial);
    }, err => {
      toast({
        title: 'Error updating Garmin integration',
        description: err.message,
        status: 'error',
        isClosable: true,
      });
    });
  }

  return (
    <>
      <Heading size='md'>Wearables &amp; integrations</Heading>
      <Text mb={5}>Configure integrations for this trial.</Text>

      <Box p={5} borderWidth='1px' borderRadius='md' bg='gray.50' display='flex' alignItems='center'> 
        <Box>
          <img src={FitbitImage} alt='Fitbit' width={100} />
        </Box>
        <Box ml={5}>
          <Heading size='sm'>Fitbit</Heading>
          <Text mb={5}>Allow Trialflare to connect to the Fitbit accounts of trial participants.</Text>
          {utils.hasFeature(team, 'fitbit') ?
            <Stack alignItems='center' direction='row' spacing={4}>
              <Switch
                isChecked={!!trial?.fitbitEnabled}
                onChange={toggleFitbitIntegration}
               />
              <Text>Enable Fitbit integration</Text>
            </Stack>
          :
            <Alert status='info'>
              The Fitbit integration is not enabled for this team. Contact your account manager for more information.
            </Alert>
          }
        </Box>
      </Box>

      <Box mt={10} p={5} borderWidth='1px' borderRadius='md' bg='gray.50' display='flex' alignItems='center'>
        <Box>
          <img src={GarminImage} alt='Garmin' width={100} />
        </Box>
        <Box ml={5}>
          <Heading size='sm'>Garmin</Heading>
          <Text mb={5}>Allow Trialflare to connect to the Garmin accounts of trial participants.</Text>
          {utils.hasFeature(team, 'garmin') ?
            <Stack alignItems='center' direction='row' spacing={4}>
              <Switch
                isChecked={!!trial?.garminEnabled}
                onChange={toggleGarminIntegration}
               />
              <Text>Enable Garmin integration</Text>
            </Stack>
          :
            <Alert status='info'>
              The Garmin integration is not enabled for this team. Contact your account manager for more information.
            </Alert>
          }
        </Box>
      </Box>
    </>
  );
}
