import React, { useState, useEffect } from 'react';
import { Box, Button, Icon, Modal, ModalOverlay, ModalContent, ModalCloseButton, ModalBody } from '@chakra-ui/react';
import { FaTimes } from 'react-icons/fa';
import styled from 'styled-components';
import api from '../../api';
import ConfirmButton from './ConfirmButton';

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 400px;
  margin: 10px auto;
  display: block;
`;

const StyledModalImage = styled.img`
  max-width: 1000px;
  max-height: 800px;
  margin: 10px auto;
  display: block;
`;

const StyledPreview = styled(Box)`
  background-position: center center;
  background-size: cover;
  background-image: url(${props => props.src});
`;

function PrivateImage({ forType, forObject, name, withPreview, onRemove }) {
  const [url, setUrl] = useState();
  const [openFull, setOpenFull] = useState(false);
  const forId = forObject?._id;

  useEffect(() => {
    if (forType && forId && name) {
      api.uploads.generateFileDownloadRequest({ forType, forId, name }, ({ request }) => {
        setUrl(request);
      });
    }
  }, [forType, forId, name]);

  if (!url) return null;
  return (<>
    {withPreview ?
      <Box>
        <Box p={2}>
          <StyledPreview src={url} onClick={() => setOpenFull(true)}
            shadow='md' rounded='md' cursor='pointer' w='100px' h='100px'
            transition='0.25s' _hover={{shadow: 'lg'}}
          />
        </Box>

        <Modal size='5xl' isOpen={openFull} onClose={() => setOpenFull(false)}>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
              <StyledModalImage src={url} />
          </ModalContent>
        </Modal>
      </Box>
    :
      <Box display='flex' justifyContent='center' mt={5}>
        <Box p={3} bg='white' minWidth='400px' maxWidth='80%' rounded='md' shadow='md'>
          {onRemove &&
            <Box mb={3} display='flex' justifyContent='end'>
              <ConfirmButton
                header='Really remove this image?'
                trigger={<Button variant='ghost'><Icon as={FaTimes} mr={2} /> Remove</Button>}
                action={onRemove}
              />
            </Box>
          }
          <StyledImage src={url} />
        </Box>
      </Box>
    }
  </>);
}

export default PrivateImage;