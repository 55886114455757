import React from 'react';
import { Box, Heading, Text, Checkbox, Link } from '@chakra-ui/react';

export default function Terms({ value, onChange }) {
  return (
    <Box p={5} mt={5} mb={5} rounded='lg' bg='green.50'>
      <Heading as='h4' size='sm'>We take data protection seriously</Heading>
      <Text>In order to provide this service Trialflare will securely process and store your data in accordance with our Privacy Policy. To continue, please indicate that you have read and understand our Terms of Use and Privacy Policy, which can both be found <Link color='primary.500' href='https://trialflare.com/legal' target='_blank' rel='noopener noreferrer'>by clicking here</Link>.</Text>
      <Checkbox iconSize="lg" colorScheme="green" mt={5} isChecked={value} onChange={e => onChange(e.target.checked)}>
        I have read and agree to the Trialflare Terms of Use and Privacy Policy
      </Checkbox>
    </Box>
  );
}
