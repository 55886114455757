import React, { useState, useEffect } from 'react';
import { useToast, Box, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button, Input, Select, Text, Heading, Alert } from '@chakra-ui/react';
import { AddIcon, CheckCircleIcon } from '@chakra-ui/icons';
import utils from '../../utils/utils';
import api from '../../api';
import ConfirmButton from './ConfirmButton';

const groupColours = ['#f5b7b1', '#d7bde2', '#a9cce3', '#a3e4d7', '#a9dfbf', '#f9e79f', '#f5cba7'];

export default function GroupCreator({ trigger, trial, group, onCreated, onUpdated, onDeleted }) {
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState('');
  const [colour, setColour] = useState('#f5b7b1');
  const [creating, setCreating] = useState(false);
  const toast = useToast();
  const groupId = group?._id;
  
  useEffect(() => {
    if (group?._id) {
      setName(group.name);
      setColour(group.colour);
      setIsEditing(true);
    }
  }, [group, groupId]);

  function createGroup() {
    if (!name || !colour) return;
    const data = { name, colour };
    if (isEditing) {
      api.groups.update(group._id, data, newGroup => {
        toast({title: 'Group saved', status:'success'});
        onUpdated(newGroup);
        setOpen(false);
      }, err => toast({title:'Unable to save group', description: err.message, status:'error'}));
    } else {
      api.trials.createGroup(trial._id, data, newGroup => {
        toast({title: 'Group created', status:'success'});
        onCreated(newGroup);
        setOpen(false);
      }, err => toast({title:'Unable to create group', description:err.message, status:'error'}));
    }
  }

  function deleteGroup(id) {
    api.groups.delete(id, () => {
      toast({title: 'Group deleted', status:'success'});
      onDeleted(id);
      setOpen(false);
    }, err => toast({title: 'Unable to delete group', description:err.message, status:'error'}));
  }
  
  // Note: this function is not currently in-use
  function bulkDeleteGroup() {
    const group = selectedGroup;
    if (!group) alert('No group selected');
    api.trials.deleteParticipants(trial._id, { groups: [group._id] }, ({ deletedParticipants }) => {
      toast({title: 'Participants removed', status: 'success'});
      const newParticipants = Object.assign([], participants).filter(p => deletedParticipants.indexOf(p._id) === -1);
      setParticipants(newParticipants);
      setSelectionParticipants([]);
    }, err => toast({title: 'Unable to delete participants', description:err.message, status: 'error'}));
  }

  return (
    <>
      {trigger
        ? React.cloneElement(trigger, { onClick: () => setOpen(true) })
        : <Button colorScheme="blue" onClick={() => setOpen(true)}><AddIcon mr={2} /> Create a group</Button>
      }
      <Modal isOpen={open} onClose={() => setOpen(false)} size='lg'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{isEditing ? 'Update group' : 'Create a participant group'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Participant groups allow you to specify different arms, conditions, treatments, and more for your trial. For example, you may want a control group and a treatment group in order to compare the effect of a treatment.</Text>
            
            <Heading mt={5} size='sm'>Group name</Heading>
            <Text fontSize='sm'>Choose a name for your group. Participants cannot see their group(s).</Text>
            <Input bg='white' mt={3} autoFocus type='text' placeholder='Group name' value={name} onChange={e => setName(e.target.value)} />
            
            <Heading mt={5} size='sm'>Group colour</Heading>
            <Text fontSize='sm'>A colour scheme helps you to identify groups of participants in the list.</Text>
            <Box display='flex' mt={3}>
              {groupColours.map(c =>
                <Box rounded='md' cursor='pointer' mr={2} w={10} h={10} key={c} bg={c} position='relative' onClick={() => setColour(c)}>
                  {c === colour &&
                    <CheckCircleIcon position='absolute' style={{top: -5, right: -5}} />
                  }
                </Box>
              )}
            </Box>
            
          </ModalBody>
          <ModalFooter display='flex' justifyContent='space-between'>
            <Box>
              {isEditing &&
                <ConfirmButton
                  trigger={<Button colorScheme='red' variant='outline'>Delete group</Button>}
                  header='Really delete this group?'
                  footer='Participants will be removed from the group, but the participants themselves will not be deleted.'
                  action={e => deleteGroup(group?._id)}
                />
              }
            </Box>
            <Box>
              <Button mr={5} onClick={() => setOpen(false)}>Cancel</Button>
              <Button colorScheme='blue' onClick={createGroup}>{isEditing ? 'Save group' : 'Add group'}</Button>
            </Box>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}