import React, { useState, useEffect } from 'react';
import { Alert, AlertIcon, Box, Heading, Text, Icon, Button, Table, Thead, Tr, Th, Tbody, Td, Select, Badge, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverHeader, PopoverBody } from '@chakra-ui/react';
import { ArrowRightIcon, ArrowLeftIcon } from '@chakra-ui/icons';
import moment from 'moment';
import useStore from '../../../store';
import api from '../../../api';

function TrialEvents({ trial }) {
  const [page, setPage] = useState(1);
  const [userType, setUserType] = useState('user');
  const [logs, setLogs] = useState([]);
  const { team } = useStore();

  useEffect(() => {
    api.trials.getLogs(trial._id, page, userType, (data) => setLogs(data.logs));
  }, [trial._id, page, userType]);
  useEffect(() => {
    if (page > 1) setPage(1);
  }, [userType]);

  return (
    <>
      <Heading as='h3' size='md'>{trial.name} events</Heading>
      <Text>For audit purposes, Trialflare logs actions traken by collaborators and participants of your trial.</Text>

      <Alert mt={5} variant='left-accent'><AlertIcon /> You can find the event logs relating to the {team?.name} team on the team's settings page.</Alert>

      <Select mt={5} onChange={e => setUserType(e.target.value)} value={userType}>
        <option value='user'>Show events from team members</option>
        <option value='participant'>Show events from trial participants</option>
      </Select>

      <Box mt={15}>
        <Table variant='simple'>
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>User</Th>
              <Th>Event</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {logs.map(log =>
              <Tr key={log._id}>
                <Td>{moment(log.createdAt).format('L LTS')}</Td>
                <Td>{log.userEmail || log.participantId}</Td>
                <Td>{log.type}</Td>
                <Td>
                  <Popover trigger='hover'>
                    <PopoverTrigger>
                      <Badge>Client</Badge>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverHeader>Client information</PopoverHeader>
                      <PopoverBody fontSize='2xs'>
                        <pre>{JSON.stringify(log.client, null, 2)}</pre>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                  
                  <Popover trigger='hover'>
                    <PopoverTrigger>
                      <Badge ml={2} colorScheme='primary' variant='outline'>Data</Badge>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverHeader>Data</PopoverHeader>
                      <PopoverBody fontSize='2xs'>
                        <pre>{JSON.stringify(log.event, null, 2)}</pre>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>

      <Box mt={5} display='flex' justifyContent='space-between'>
        <Box>{page > 1 && <Button onClick={e => setPage(page - 1)}><ArrowLeftIcon mr={3} /> Previous page</Button>}</Box>
        <Box>{logs.length > 0 &&<Button onClick={e => setPage(page + 1)}><ArrowRightIcon mr={3} /> Next page</Button>}</Box>
      </Box>
    </>
  );
}

export default TrialEvents;
