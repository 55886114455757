import React, { useState } from 'react';
import { useToast, Box, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, Tabs, TabList, Tab, TabPanels, TabPanel, Button, Input, Select, Text, Heading, Checkbox, Alert } from '@chakra-ui/react';
import Papa from 'papaparse';
import { AddIcon } from '@chakra-ui/icons';
import utils from '../../utils/utils';
import animals from '../../utils/animals';
import colours from '../../utils/colours';
import adjectives from '../../utils/adjectives';
import api from '../../api';

import CSVChooser from '../includes/CSVChooser';

export default function ParticipantCreator({ trigger, trial, groups = [], onCreated }) {
  const [open, setOpen] = useState(false);
  const [newParticipantId, setNewParticipantId] = useState('');
  const [newParticipantPassword, setNewParticipantPassword] = useState('');
  const [newParticipantGroup, setNewParticipantGroup] = useState('');
  const [newParticipantCount, setNewParticipantCount] = useState('');
  const [newParticipantNameStrategy, setNewParticipantNameStrategy] = useState('');
  const [newParticipantNamePattern, setNewParticipantNamePattern] = useState('p{n}');
  const [newParticipantNameLength, setNewParticipantNameLength] = useState(5);
  const [newParticipantGroupStrategy, setNewParticipantGroupStrategy] = useState('');
  const [newParticipantGeneratePassword, setNewParticipantGeneratePassword] = useState(false);
  const [creating, setCreating] = useState(false);
  const toast = useToast();

  function createParticipant() {
    const data = { participantId: newParticipantId, password: newParticipantPassword || null, group: newParticipantGroup || null };
    setCreating(true);
    api.trials.createParticipant(trial._id, data, participant => {
      toast({title: 'Participant registered', description: 'We\'ve added the participant to the trial', status: 'success'});
      setNewParticipantId('');
      setNewParticipantPassword('');
      setCreating(false);
      onCreated([participant]);
      setOpen(false);
    }, err => {
      toast({title: 'Unable to create participant', description: err.message, status: 'error'});
      setCreating(false);
    });
  }

  function csvFormatter(text) {
    const dat = Papa.parse(text, {skipEmptyLines: true, header: true, transform: v => v.trim()});
    return [['participantId', 'group', 'groupId', 'password'], dat.data.map(({ participantId, group, password }) => {
      const row = { participantId, group, password };
      if (!participantId?.length) row.error = 'Invalid ID';
      if (group) {
        groups.forEach(g => {
          if (g.name.toLowerCase() === group.trim().toLowerCase()) row.groupId = g._id;
        });
        if (!row.groupId) row.error = 'Invalid group name';
      }
      return row;
    })];
  }

  function bulkCreateParticipants(rows) {
    rows.forEach(row => {
      row.group = row.groupId ?? null; // PI expects groupId to be 'group'
      row.groupId = undefined; // API doesn't want a groupID param
    });
    setCreating(true);
    api.trials.createParticipant(trial._id, { participants: rows }, ({ participants: newlyCreated }) => {
      toast({title: 'Participants registered', description: 'We\'ve added the participants to the trial', status: 'success'});
      setCreating(false);
      onCreated(newlyCreated);
      setOpen(false);
    }, err => {
      toast({title: 'Unable to create participants', description: err.message, status: 'error'});
      setCreating(false);
    });
  }

  function bulkCreateParticipantsWithStrategy() {
    const count = parseInt(newParticipantCount);
    if (!count || count < 0 || count > 2000) {
      return toast({title: 'Unable to create participants', description: 'Your number is invalid or too high.', status: 'error'});
    }
    const newParticipants = [];
    for (let i = 0; i < count; i++) {
      let newId;
      if (newParticipantNameStrategy === 'phrase') {
        const colour = colours[Math.floor(Math.random()*colours.length)];
        const adjective = adjectives[Math.floor(Math.random()*adjectives.length)];
        const animal = animals[Math.floor(Math.random()*animals.length)];
        const phrase = `${adjective}-${colour}-${animal}`.toLowerCase();
        const pattern = newParticipantNamePattern?.toLowerCase() || '{phrase}';
        if (pattern?.indexOf('{phrase}') === -1) {
          return toast({title: 'Unable to create participants', description: 'Your ID pattern is invalid', status: 'error'});
        }
        newId = pattern.replace('{phrase}', phrase);
      }
      else if (newParticipantNameStrategy === 'sequential') {
        if (!newParticipantNamePattern || newParticipantNamePattern.indexOf('{n}') === -1) {
          return toast({title: 'Unable to create participants', description: 'Your ID pattern is invalid', status: 'error'});
        }
        newId = newParticipantNamePattern.toLowerCase().replace('{n}', trial.participantCount + i + 1);
      }
      else if (newParticipantNameStrategy === 'alphanumeric') {
        const length = newParticipantNameLength || 5;
        if (length < 3 || length > 10) {
          return toast({title: 'Unable to create participants', description: 'ID length must be between 3 and 10', status: 'error'});
        }
        newId = utils.randomAlphaNumeric(length).toUpperCase();
      }
      else {
        return toast({title: 'Unable to create participants', description: 'A ID strategy must be selected', status: 'error'});
      }
      if (newId) {
        const newParticipant = { participantId: newId };
        if (newParticipantGeneratePassword) {
          const adjective = adjectives[Math.floor(Math.random()*adjectives.length)];
          const animal = animals[Math.floor(Math.random()*animals.length)];
          newParticipant.password = `${adjective}-${animal}`.toLowerCase();
        }
        newParticipants.push(newParticipant);
      }
    }

    if (newParticipantGroupStrategy && groups?.length > 0) {

      let shuffledGroups = [];
      if (newParticipantGroupStrategy === 'random') {
        // Build random list of IDs for use in random strategy
        while (shuffledGroups.length < newParticipants.length) {
          shuffledGroups.push(groups[shuffledGroups.length % groups.length]._id);
        }
        shuffledGroups = utils.shuffleArray(shuffledGroups);
      }
      else if (newParticipantGroupStrategy.indexOf('block-') === 0) {
        // Build random list of IDs for use in block strategy
        const blockSize = parseInt(newParticipantGroupStrategy.replace('block-', '')) * groups.length;
        let counter = 0;
        while (shuffledGroups.length < newParticipants.length) {
          const block = [];
          while (block.length < blockSize) {
            block.push(groups[counter % groups.length]._id);
            counter++;
          }
          utils.shuffleArray(block).forEach(g => shuffledGroups.push(g));
        }
      }
      newParticipants.forEach((p, i) => {
        if (newParticipantGroupStrategy === 'roundRobin') {
          p.groupId = groups[i % groups.length]._id;
        }
        else if (newParticipantGroupStrategy === 'random' || newParticipantGroupStrategy.indexOf('block-') === 0) {
          p.groupId = shuffledGroups[i];
        }
        else { // Assume a group ID
          p.groupId = newParticipantGroupStrategy;
        }
      });
    }

    // Download a CSV of IDs and passwords if password is set.
    if (newParticipantGeneratePassword) {
      let csvData = `ParticipantID,password`;
      newParticipants.forEach(p => csvData += `%0A${p.participantId}%2C${p.password}`);
      const element = document.createElement('a');
      element.setAttribute('href', `data:text/csv;charset=utf-8,${csvData}`);
      element.setAttribute('download', 'participants.csv');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }

    // Proceed to create them on the API.
    bulkCreateParticipants(newParticipants);
  }

  return (
    <>
      {trigger
        ? React.cloneElement(trigger, { onClick: () => setOpen(true) })
        : <Button colorScheme="blue" onClick={() => setOpen(true)}><AddIcon mr={2} /> Create participants</Button>
      }
      <Modal isOpen={open} onClose={() => setOpen(false)} size='lg'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Register participants</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs>
              <TabList>
                <Tab>Single</Tab>
                <Tab>Multiple</Tab>
                <Tab>CSV upload</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Heading size='sm'>Create a new participant ID that can be used to join the trial</Heading>
                  <Text mt={2}>Participant ID</Text>
                  <Input placeholder='p1' bg='white' value={newParticipantId} onChange={e => setNewParticipantId(e.target.value)} />
                  <Text mt={2}>Participant password</Text>
                  <Text mb={1}><small>Optional. If not set the trial password will be used. If set, make a note of it since Trialflare will not be able to reveal it later.</small></Text>
                  <Input placeholder='Type a password...' type='password' bg='white' value={newParticipantPassword} onChange={e => setNewParticipantPassword(e.target.value)} />
                  {groups.length > 0 && <>
                    <Text mt={2}>Participant group (optional)</Text>
                    <Select bg='white' label='Participant group (optional)' value={newParticipantGroup} onChange={e => setNewParticipantGroup(e.target.value)} placeholder='Choose a group'>
                      {groups.map(g => <option key={g._id} value={g._id}>{g.name}</option>)}
                    </Select>
                  </>}
                  <Button isLoading={creating} mt={5} colorScheme='blue' onClick={createParticipant}>Create participant</Button>
                </TabPanel>

                <TabPanel>
                  <Heading size='sm' mb={3}>Trialflare can create several participants for you in one go</Heading>
                  <Text mb={1}>Number of participants to create</Text>
                  <Input placeholder='0' mb={3} bg='white' value={newParticipantCount} onChange={e => setNewParticipantCount(e.target.value.replace(/[^\d]/g, ''))} />

                  <Box mb={3} p={2} bg='gray.50'>
                    <Text mb={1}>ID naming strategy</Text>
                    <Select mb={3} bg='white' value={newParticipantNameStrategy} onChange={e => {
                      setNewParticipantNameStrategy(e.target.value);
                      setNewParticipantNamePattern(e.target.value === 'phrase' ? '{phrase}' : 'p{n}');
                    }} placeholder='Select a strategy...'>
                      <option value='phrase'>Random three-word phrase</option>
                      <option value='sequential'>Sequential pattern</option>
                      <option value='alphanumeric'>Random sequence of alphanumeric characters</option>
                    </Select>

                    {newParticipantNameStrategy === 'phrase' && <>
                      <Text>Phrase naming pattern</Text>
                      <Text mb={1}><small>"{`{phrase}`}" will be replaced with the generated phrase.</small></Text>
                      <Input mb={3} bg='white' value={newParticipantNamePattern} onChange={e => setNewParticipantNamePattern(e.target.value.replace(/\s/g, ''))} />
                    </>}

                    {newParticipantNameStrategy === 'sequential' && <>
                      <Text>Sequential naming pattern</Text>
                      <Text mb={1}><small>"{`{n}`}" will be replaced with a sequential participant number starting from the current participant count.</small></Text>
                      <Input mb={3} bg='white' value={newParticipantNamePattern} onChange={e => setNewParticipantNamePattern(e.target.value.replace(/\s/g, ''))} />
                    </>}

                    {newParticipantNameStrategy === 'alphanumeric' && <>
                      <Text>ID length</Text>
                      <Text mb={1}><small>Number of characters to generate for each ID (default: 5)</small></Text>
                      <Input mb={3} bg='white' placeholder='5' value={newParticipantNameLength} onChange={e => setNewParticipantNameLength(parseInt(e.target.value) || '')} />
                    </>}
                  </Box>

                  {groups?.length > 0 && <Box mb={3} p={2} bg='gray.50'>
                    <Text mb={1}>Grouping strategy</Text>
                    <Select mb={3} bg='white' value={newParticipantGroupStrategy} onChange={e => {
                      setNewParticipantGroupStrategy(e.target.value);
                    }} placeholder='Select a strategy...'>
                      {groups.map(group =>
                        <option key={group._id} value={group._id}>Add all to {group.name}</option>
                      )}
                      <option value='roundRobin'>Round robin ("A", "B", "C", "A", "B", ...)</option>
                      <option value='random'>Random uniform allocation</option>
                      <option value='block-1'>Random blocks of {groups.length * 1}</option>
                      <option value='block-2'>Random blocks of {groups.length * 2}</option>
                      <option value='block-3'>Random blocks of {groups.length * 3}</option>
                      <option value='block-4'>Random blocks of {groups.length * 4}</option>
                      <option value='block-5'>Random blocks of {groups.length * 5}</option>
                    </Select>
                  </Box>}

                  <Box mb={3} p={2} bg='gray.50'>
                    <Checkbox mb={3} isChecked={newParticipantGeneratePassword} onChange={e => setNewParticipantGeneratePassword(e.target.checked)}>
                      <Text>Generate random passwords</Text>
                      <Text><small>Optional. If not selected, the trial password, if set, will be in effect.</small></Text>
                    </Checkbox>

                    {newParticipantGeneratePassword && <Alert status='info' variant='left-accent' fontSize='sm'>After creation, a CSV file containing participant data will be downloaded for you. Keep this safe, as Trialflare will not be able to reveal participant passwords again later.</Alert>}
                  </Box>

                  <Text mt={3}>Please note that duplicate participant IDs will be ignored.</Text>

                  <Button isLoading={creating} mt={3} colorScheme='blue' onClick={bulkCreateParticipantsWithStrategy}>Create {newParticipantCount ?? ''} participants</Button>
                </TabPanel>

                <TabPanel>
                  <Heading size='sm' mb={3}>Upload a CSV file to create several participants in one go</Heading>
                  <CSVChooser
                    trigger={<Button colorScheme='blue'>Choose a CSV file</Button>}
                    title='Bulk register participants for this trial'
                    instructions='Upload a CSV file to create lots of participants in one go. Your CSV must include a "participantId" and may also include "group" and "password" columns. Please note that duplicate participant IDs will be ignored. Please note: Trialflare passwords are hashed, and so it is important that you keep a record of them if used.'
                    formatFunction={csvFormatter}
                    onComplete={bulkCreateParticipants}
                  />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
