import React from 'react';
import { Box, Stack, Badge, FormControl, FormHelperText, Input } from '@chakra-ui/react';

export default function Float({ dataType, value, onChange }) {

  const localOnChange = e => {
    const re = /^[0-9\b\.]+$/;
    if (e.target.value === '') {
      onChange(null);
    }
    else if (re.test(e.target.value)) {
      onChange(e.target.value); // We don't parse float because as user types a "." it will be invalid
    }
  }
  
  return (
    <Box>
      <FormControl>
        <Input bg='white' value={value ?? ''} onChange={localOnChange} />
        <FormHelperText>
          {value &&
            <Stack spacing={2} direction='row'>
              {dataType?.minimumValue && <Badge colorScheme={value < dataType.minimumValue ? 'red' : 'green'}>Min: {dataType.minimumValue}</Badge>}
              {dataType?.maximumValue && <Badge colorScheme={value > dataType.maximumValue ? 'red' : 'green'}>Max: {dataType.maximumValue}</Badge>}
            </Stack>
          }
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
