export default [
  "Aqua",
  "Aquamarine",
  "Azure",
  "Beige",
  "Bisque",
  "Black",
  "Blue",
  "Brown",
  "Chocolate",
  "Coral",
  "Cornsilk",
  "Crimson",
  "Cyan",
  "Fuchsia",
  "Gold",
  "GoldenRod",
  "Grey",
  "Green",
  "Indigo",
  "Ivory",
  "Khaki",
  "Lavender",
  "Lime",
  "Linen",
  "Magenta",
  "Maroon",
  "Moccasin",
  "Navy",
  "Olive",
  "Orange",
  "Orchid",
  "Peru",
  "Pink",
  "Plum",
  "Powder",
  "Purple",
  "Red",
  "Salmon",
  "Sienna",
  "Silver",
  "Snow",
  "Tan",
  "Teal",
  "Thistle",
  "Tomato",
  "Turquoise",
  "Violet",
  "Wheat",
  "White",
  "Yellow",
];