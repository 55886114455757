import React from 'react';
import { Box, Heading, Text, Table, Tbody, Tr, Td, Button, Badge, Stack } from '@chakra-ui/react';
import { WarningTwoIcon, EditIcon } from '@chakra-ui/icons';
import moment from 'moment';
import useStore from '../../../store';
import utils from '../../../utils/utils';

import { APPROVAL_TYPES } from './Approval';

const Overview = ({ trial, fields, data, setTab }) => {
  const { user } = useStore();
  return (
    <Box>
      <Table variant='simple'>
        <Tbody>
          {fields.filter(f => data[f.key] || f.required).map(field =>
            <Tr key={field.key}>
              <Td>{field.label}</Td>
              <Td>
                {field.required && !data[field.key] &&
                  <WarningTwoIcon color='orange.400' />
                }
                <Text fontSize={field.type === 'textarea' ? 'sm': 'md'}>
                  {data[field.key]}
                </Text>
              </Td>
              {utils.canManageTrial(user, trial) &&
                <Td>
                  <Button size='sm' variant='ghost' colorScheme='blue' onClick={e => setTab(field.section)}>
                    <EditIcon mr={2} /> Edit
                  </Button>
                </Td>
              }
            </Tr>
          )}
          {data.approvals?.map(approval =>
            <Tr key={approval.key}>
              <Td>
                <Heading size='sm'>Approval</Heading>
                {APPROVAL_TYPES.find(t => t.value === approval.type)?.label || 'Unknown approval type'}
              </Td>
              <Td>
                {(!approval.status || approval.status === 'notSubmitted') && <Badge colorScheme='gray' mr={3}>Not Submitted</Badge>}
                {approval.status === 'submitted' && <Badge colorScheme='yellow' mr={3}>Submitted</Badge>}
                {approval.status === 'approved' && <Badge colorScheme='green' mr={3}>Approved</Badge>}

                <Stack spacing={1} mt={2}>
                  {approval.submissionDate && <Text>Submitted: {moment(approval.submissionDate).format('L')}</Text>}
                  {approval.approvalDate && <Text>Approved: {moment(approval.approvalDate).format('L')}</Text>}
                  {approval.reference && <Text>Reference: {approval.reference}</Text>}
                </Stack>
              </Td>
              {utils.canManageTrial(user, trial) &&
                <Td>
                  <Button size='sm' variant='ghost' colorScheme='blue' onClick={e => setTab('approval')}>
                    <EditIcon mr={2} /> Edit
                  </Button>
                </Td>
              }
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );
};

export default Overview;
