import React from 'react';
import { Box, Heading, Stack } from '@chakra-ui/react';

import FormField from '../../includes/FormField';

const Name = ({ trial, fields, data, updateData}) => {
  return (
    <Box>
      <Box mb={10} rounded='md' shadow='md' bg='gray.50' borderWidth='1px' p={3}>
        <Heading as='h3' size='md' mb={10}>Name and description</Heading>

        <Stack spacing={5}>
          {fields.map(field =>
            <FormField key={field.name} placeholder={field.placeholder} required={field.required} label={field.label} type={field.type} value={data[field.key]} onChange={e => updateData({ [field.key]: e.target.value })} />  
          )}
        </Stack>
      </Box>
    </Box>
  );
};

export default Name;
