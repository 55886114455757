import api from '.';

export const stages = {
  get(id, success, fail) {
    api.authenticatedRequest('GET', `/stages/${id}`, null, success, fail);
  },
  getPdf(id, success, fail) {
    api.authenticatedRequest('GET', `/stages/${id}?format=pdf`, null, success, fail);
  },
  getPoll(id, success, fail) {
    api.unauthenticatedRequest('GET', `/participants/stages/${id}/public`, null, success, fail);
  },
  submitPollData(id, data, success, fail) {
    api.authenticatedRequest('POST', `/participants/stages/${id}/public/responses`, data, success, fail);
  },
  update(id, data, success, fail) {
    api.authenticatedRequest('PUT', `/stages/${id}`, data, success, fail);
  },
  delete(id, success, fail) {
    api.authenticatedRequest('DELETE', `/stages/${id}`, null, success, fail);
  },
  getForParticipant(id, success, fail) {
    api.authenticatedRequest('GET', `/stages/${id}/participate`, null, success, fail);
  },
};
