import React, { useState, useEffect } from 'react';
import { useToast, HStack, VStack, Stack, Box,  Heading, Text, FormControl, FormLabel, Input, InputGroup, InputRightElement, Textarea, Checkbox, Button, Select, Alert, AlertTitle, AlertIcon, AlertDescription, Table, Thead, Tbody, Tr, Th, Td, Tooltip, Modal, ModalBody, ModalContent, ModalOverlay, ModalHeader, ModalCloseButton, ModalFooter, Badge, Tag, Icon, SimpleGrid, Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/react';
import uuid from 'uuid';
import utils from '../../../utils/utils';
import SimpleModal from '../../includes/SimpleModal';
import ConfirmButton from '../../includes/ConfirmButton';
import QrPopover from '../../includes/QrPopover';

export default function Poll({ stage, isPoll, setIsPoll, customLinks, setCustomLinks }) {
  const [selectedLink, setSelectedLink] = useState(null);
  const [generalLink, setGeneralLink] = useState('');

  useEffect(() => {
    if (stage) setGeneralLink(`${window.location.origin}/polls/${stage._id}`);
  }, [stage]);

  function createCustomLink() {
    const newLink = { id: uuid.v4(), name: 'My Custom Link', attributes: [] };
    setCustomLinks([...customLinks || [], newLink]);
    setSelectedLink(newLink.id);
  }

  function updateCustomLink(linkId, link) {
    const newCustomLinks = customLinks?.map(l => l.id === linkId ? link : l);
    setCustomLinks(newCustomLinks);
  }

  return (
    <Box>
      <Heading as='h3' size='md'>Poll mode</Heading>
      <Text color='gray.500' mb={5}>When in poll mode, your stage is also available via a publicly-accessible link allowing anyone to submit responses. As such, responses made using the public link do not have an associated participant ID.</Text>
      <Checkbox size='md' isChecked={isPoll} onChange={e => setIsPoll(e.target.checked)}>Enable poll mode for this stage</Checkbox>

      {isPoll && <>
        <Box p={3} mt={5} borderWidth='1px' rounded='md' bg='gray.50'>

          <Heading as='h3' size='md'>General link</Heading>
          <Text color='gray.500' mb={5}>This is the link that you can share with anyone to allow them to submit responses to this stage.</Text>
          {stage ?
            <Stack direction='row'>
              <InputGroup>
                <Input bg='white' value={generalLink} isReadOnly />
                <InputRightElement w='6rem' mr={2}>
                  <Button colorScheme='blue' size='sm' w='140px' onClick={e => utils.copyToClipboard(generalLink)}>Copy</Button>
                </InputRightElement>
              </InputGroup>
              <QrPopover url={generalLink} name={`${stage?.name}-general-link`} size='md' />
            </Stack>
          :
            <Alert status='info' variant='left-accent'>
              The poll link cannot be shown until the stage has been created. Please save your stage and return to this page to view the link.</Alert>
          }
        </Box>

        <Box p={3} mt={5} borderWidth='1px' rounded='md' bg='gray.50'>
          <Heading as='h3' size='md'>Custom links</Heading>
          <Text color='gray.500' mb={5}>Create custom links that you can share with specific groups of people.</Text>

          <Button colorScheme='blue' size='sm' mt={5} onClick={createCustomLink}>Add custom link</Button>
          {customLinks?.length > 0 && <Table mt={5} variant='simple'>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Attributes</Th>
                <Th>Link</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {customLinks.map(link => {
                const url = `${generalLink}/access/${link.id}`;
                return (<Tr key={link.id}>
                  <Td>{link.name}</Td>
                  <Td>{link.attributes?.length || 0}</Td>
                  <Td>
                    {stage ?
                      <InputGroup>
                        <Input bg='white' size='sm' value={url} isReadOnly />
                        <InputRightElement w='4.5rem' h='2rem'>
                          <Button colorScheme='blue' size='xs' mr={2} w='140px' onClick={e => utils.copyToClipboard(url)}>Copy</Button>
                        </InputRightElement>
                      </InputGroup>
                    : <Text fontSize='sm' color='gray.600'>Link cannot be displayed until stage is created.</Text>}
                  </Td>
                  <Td>
                    {stage &&
                      <QrPopover url={url} name={`${stage?.name}-${link.name}`} size='sm' />
                    }
                    <Button size='sm' colorScheme='blue' ml={2} onClick={e => setSelectedLink(link.id)}>Edit</Button>
                    <ConfirmButton
                      header='Really delete this link?'
                      body='This link will no longer work for anyone who already has it.'
                      action={e => setCustomLinks(customLinks?.filter(l => l.id !== link.id))}
                      actionColor='red'
                      trigger={<Button size='sm' colorScheme='red' ml={2}>Delete</Button>}
                    />
                  </Td>
                </Tr>
              )})}
            </Tbody>
          </Table>}
        </Box>

          <CustomLinkEditor customLink={customLinks?.find(l => l.id === selectedLink)} onComplete={e => setSelectedLink(null)} updateCustomLink={updateCustomLink} />
      </>}
    </Box>
  );
}

function CustomLinkEditor({ customLink, updateCustomLink, onComplete }) {

  function updateName(e) {
    updateCustomLink(customLink.id, { ...customLink, name: e.target.value });
  }

  function addAttribute() {
    updateCustomLink(customLink.id, { ...customLink, attributes: [...customLink.attributes, { name: '', value: '' }] });
  }

  function updateAttribute(index, attr) {
    const attributes = customLink.attributes.map((a, i) => i === index ? attr : a);
    updateCustomLink(customLink.id, { ...customLink, attributes });
  }

  function removeAttribute(index) {
    const attributes = customLink.attributes.filter((a, i) => i !== index);
    updateCustomLink(customLink.id, { ...customLink, attributes });
  }

  return (
    <SimpleModal
      modalProps={{ size: 'xl' }}
      header='Manage custom link'
      isOpen={!!customLink}
      onClose={e => onComplete(null)}
      body={customLink ? <Box>
        <FormControl id='name'>
          <FormLabel>Link name (so you can identify it later)</FormLabel>
          <Input type='text' value={customLink.name} onChange={updateName} />
        </FormControl>

        <Box mt={5} borderWidth='1px' rounded='md' bg='gray.50' p={3}>
          <Heading as='h3' size='md'>Link attributes</Heading>
          <Text color='gray.500' mb={5}>Add attributes to the link to automatically embed additional data in responses when they are submitted.</Text>
          <Button colorScheme='blue' size='sm' mt={5} onClick={addAttribute}>Add attribute</Button>
          {customLink.attributes?.length > 0 && <Table mt={5} variant='simple'>
            <Thead>
              <Tr>
                <Th>Name</Th>
                <Th>Value</Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {customLink.attributes.map((attr, index) => <Tr key={index}>
                <Td><Input bg='white' size='sm' type='text' value={attr.name} onChange={e => updateAttribute(index, { ...attr, name: e.target.value })} /></Td>
                <Td><Input bg='white' size='sm'  type='text' value={attr.value} onChange={e => updateAttribute(index, { ...attr, value: e.target.value })} /></Td>
                <Td>
                  <ConfirmButton header='Really delete this attribute?' action={e => removeAttribute(index)} actionColor='red' trigger={<Button size='sm' colorScheme='red' ml={2}>Delete</Button>} />
                </Td>
              </Tr>)}
            </Tbody>
          </Table>}
        </Box>
      </Box> : null}
      footer={<>
        <Button colorScheme='primary' onClick={e => onComplete(null)}>Close</Button>
      </>}
    />
  );
}
