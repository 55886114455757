import React, { useState, useEffect } from 'react';
import { useToast, CircularProgress, Grid, Box, Heading, Text, Button, Input, InputGroup, InputRightElement, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router';
import moment from 'moment';
import api from '../../api';
import useStore from '../../store';
import utils from '../../utils/utils.js';

import ConfirmButton from '../includes/ConfirmButton';
import EmptyBox from '../includes/EmptyBox';

export default function AccountSettings() {
  const [newKeyName, setNewKeyName] = useState('');
  const [newKeyValidity, setNewKeyValidity] = useState('');
  const [tokens, setTokens] = useState([]);
  const [newToken, setNewToken] = useState();
  const [loadingTokens, setLoadingTokens] = useState(false);
  const [deletePassword, setDeletePassword] = useState('');
  const toast = useToast();
  const navigate = useNavigate();
  const { team, completeLogout } = useStore();

  useEffect(() => {
    setLoadingTokens(true);
    api.accounts.getApiTokens(({ tokens }) => {
      setTokens(tokens);
      setLoadingTokens(false);
    });
  }, []);

  const createApiToken = () => {
    api.accounts.createApiToken({ serviceName: newKeyName, validity: parseInt(newKeyValidity) || 0 }, token => {
      toast({
        position: "bottom-left",
        title: "Token created.",
        description: "We've created that access key for you.",
        status: "success",
        duration: 9000,
        isClosable: true,
      })
      tokens.push(token);
      setNewKeyName('');
      setNewKeyValidity('');
      setTokens(tokens);
      setNewToken(token);
    }, err => {
      toast({title:'Unable to create your token', description: err.message, status:'error'});
    });
  }

  const deleteApiToken = (token) => {
    api.accounts.deleteApiToken(token.id, () => {
      toast({
        title: "Token deleted.",
        status: "success",
        duration: 9000,
        isClosable: true,
      })
      setTokens(tokens.filter(t => t.id !== token.id));
    }, err => {
      toast({title:'Unable to delete your token', description: err.message, status:'error'});
    });
  }

  const deleteAccount = () => {
    const confirm = window.confirm('Really delete your account?');
    if (!confirm) return;
    api.accounts.delete(deletePassword, () => {
      api.accounts.localLogout(() => completeLogout());
      navigate('/');
      toast({
        title: "We've deleted your account.",
        description: 'Sorry to see you go.',
        status: "success",
        duration: 9000,
        isClosable: true,
      })
    }, err => toast({title: 'Unable to delete account', description: err.message, status:'error'}));
  }

  return (
    <>
        <Box rounded='lg' borderWidth='1px' p={3} mt={5}>
          <Heading as='h3' size='md' mb={3}>API keys</Heading>
          <Text>Grant other applications access to your Trialflare account using API keys. Please note that these keys will grant an application the same level of permission as your account.</Text>

          <Heading as='h4' size='sm' mb={2} mt={10}>Generate a new API key</Heading>
          <Box display={{base: 'block', md: 'flex'}} mb={4} >
            <Box flex={1} mr={2}>
              <Text fontSize='sm'><strong>Enter a name for your key.</strong> For example, this could be the name of the service that will use this key.</Text>
            </Box>
            <Input w='200px' placeholder="Key name" value={newKeyName} onChange={e => setNewKeyName(e.target.value)} />
          </Box>
          
          <Box display={{base: 'block', md: 'flex'}} mb={4} >
            <Box flex={1} mr={2}>
              <Text fontSize='sm'><strong>Enter the number of days (from now) that the key should be valid for.</strong> Security best practices recommend rotating keys more frequently. You can leave this value blank if you don't want the key to ever expire.</Text>
            </Box>
            <Input w='200px' placeholder="365" value={newKeyValidity} onChange={e => setNewKeyValidity(e.target.value.replace(/\D/,''))} />
          </Box>
          
          <Button colorScheme='primary' size="sm" onClick={createApiToken}>Create key</Button>

          <Heading as='h4' size='sm' mb={2} mt={10}>Existing keys</Heading>
          <Text mb={4}>The following keys are currently active and have access to your Trialflare account.</Text>
          {loadingTokens && <CircularProgress isIndeterminate />}
          {(!tokens || tokens.length === 0) ?
            <EmptyBox title='API keys will appear here once created' description='No API keys have been created yet.' />
          :
            <Grid templateColumns={{md: 'repeat(2, 1fr)', lg:'repeat(3, 1fr)'}} gap={2} mt={5}>
              {tokens.map(token =>
                <Box borderWidth='1px' rounded='md' width='100%' p={2}>
                  <Heading as='h5' size='sm'>{token.serviceName}</Heading>
                  {token.expiresAt ?
                    <Text fontSize='xs' color={moment(token.expiresAt) < moment() ? 'red' : 'green'}>Expires: {moment(token.expiresAt).format('DD/MM/YYYY HH:mm')}</Text>
                  :
                    <Text fontSize='xs'>No expiry</Text>
                  }
                  <ConfirmButton
                    trigger={<Button mt={2} size='xs' variant='outline' colorScheme='red'>Delete</Button>}
                    header='Really delete this API token?'
                    footer='Other services using this token will lose access to your Trialflare account.'
                    action={e => deleteApiToken(token)}
                    actionColor='red'
                  />
                </Box>
              )}
            </Grid>
          }
        </Box>

        <Box rounded='lg' borderWidth='1px' mt={5} p={3}>
          <Heading as='h3' size='md' mb={4}>Delete account</Heading>
          <Text>This will immediately disassociate your user account from {team && team.name}'s team account and will then fully delete your user account. {team && team.name}'s team account and any trials within it will not be affected. This action cannot be un-done.</Text>
          <Text mt={3} fontSize='lg'>To continue, please enter your Trialflare account password. (Note: if you only login via single sign-on, you can leave your password blank).</Text>

          <Input mt={3} mb={3} placeholder="Your account password" type="password" value={deletePassword} onChange={e => setDeletePassword(e.target.value)} />
          <Button colorScheme="red" onClick={deleteAccount}>Delete account</Button>
        </Box>
        
        <Modal isOpen={newToken} onClose={() => setNewToken()}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Your new Trialflare API key</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Text>Your new key is displayed below.</Text>
              <Text mt={5} fontWeight='bold'>Important: This is the only time it will be shown to you so please make sure you have securely stored it before closing this message.</Text>
              <Input mt={5} value={newToken?.token} />
              <Button mt={2} size='sm' onClick={() => utils.copyToClipboard(newToken?.token)}>
                <CopyIcon mr={2} /> Copy key
              </Button>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme='primary' onClick={() => setNewToken()}>Close message</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
  );
}
