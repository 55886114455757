import React from 'react';
import { useToast, Heading, Text, Box, CircularProgress, Button, Stat, StatLabel, StatNumber, Badge, Tag, TagLabel } from '@chakra-ui/react';
import { ArrowLeftIcon } from '@chakra-ui/icons';

import EmptyBox from '../../includes/EmptyBox';
import ParticipantMessenger from '../../includes/ParticipantMessenger';
import ParticipantNotes from '../../includes/ParticipantNotes';
import moment from 'moment';
import api from '../../../api';
import useStore from '../../../store';
import utils from '../../../utils/utils';

import FitbitImage from '../../../images/fitbit.png';
import GarminImage from '../../../images/garmin.png';

const SERVICES = {
  fitbit: { name: 'Fitbit', image: FitbitImage },
  garmin: { name: 'Garmin', image: GarminImage }
};

export default function Information({ trialId, participantId, participant, setParticipant }) {
  const toast = useToast();
  const { user, trial } = useStore();
  
  function sendNotification() {
    const message = window.prompt(`Write a message to send to the participant's device.`);
    if (message) {
      api.trials.createParticipantNotifications(trialId, { message, participants: [participantId] }, () => {
        toast({title: 'Notification sent', status: 'success'});
      }, err => toast({title: 'Unable to notify participant', description: err.message, status: 'error'}));
    };
  }

  function setParticipantPassword(participant) {
    if (!utils.canManageTrial(user, trial)) return;
    const newPassword = window.prompt(`Provide a new password for ${participant.id} (leave blank to remove the password).`);
    if (newPassword === null) return;
    api.trials.updateParticipant(trial._id, participant._id, { password: newPassword }, data => {
      const newParticipant = {...participant, passwordSet: data.passwordSet};
      setParticipant(newParticipant);
    });
  }
  
  if (!participant) {
    return (
      <Box>
        <CircularProgress isIndeterminate />
      </Box>
    );
  }

  return (
    <Box>
    
      <Box display='flex' justifyContent='end'>
        {participant.pushAvailable && utils.canSupportTrial(user, trial) &&
          <Button size='sm' colorScheme='primary' onClick={sendNotification}>Send a notification</Button>
        }
      </Box>

      <Box display={{md: 'flex'}} mt={5} >
        <Box w='50%' mr={5} borderWidth='1px' p={3} rounded='md'>
          <Heading size='md' mb={5}>General</Heading>
          <Stat>
            <StatLabel>ID</StatLabel>
            <StatNumber>{participant.id}</StatNumber>
          </Stat>
          <Box mt={2}>
            {participant.pushAvailable && <Badge colorScheme='purple' variant='subtle' mr={1}>
              Push available
            </Badge>}
            <Badge colorScheme={participant.passwordSet ? 'green' : 'gray'} variant='subtle'>
              {participant.passwordSet ? 'Password set' : 'No password'}
            </Badge>
            <Button size='xs' ml={2} variant='outline' onClick={() => setParticipantPassword(participant)}>Change or remove password</Button>
          </Box>
          <Stat mt={2}>
            <StatLabel>Created</StatLabel>
            <StatNumber>{moment(participant.createdAt).format('DD/MM/YYYY HH:mm')}</StatNumber>
          </Stat>
          <Stat mt={2}>
            <StatLabel>Last seen online</StatLabel>
            <StatNumber>{participant.lastSeenAt ? moment(participant.lastSeenAt).format('DD/MM/YYYY HH:mm') : <Badge>Not seen yet</Badge>}</StatNumber>
          </Stat>
          <Stat mt={2}>
            <StatLabel>Groups</StatLabel>
            <StatNumber mt={2}>{participant.groupObjects?.length > 0 ?
              <Box>
                {participant.groupObjects.map(g =>
                  <Tag size='sm' key={g._id} variant='subtle' bg={g.colour} mr={1} mb={1}>
                    <TagLabel>{g.name}</TagLabel>
                  </Tag>
                )}
              </Box> : <Badge>No groups</Badge>}
            </StatNumber>
          </Stat>
          <Stat mt={2}>
            <StatLabel>Trial sites</StatLabel>
            <StatNumber mt={2}>{participant.siteObjects?.length > 0 ?
              <Box>
                {participant.siteObjects.map(s =>
                  <Tag size='sm' key={s._id} variant='subtle' mr={1} mb={1}>
                    <TagLabel>{s.name}</TagLabel>
                  </Tag>
                )}
              </Box> : <Badge>No sites</Badge>}
            </StatNumber>
          </Stat>

          {participant.connectedServices?.length > 0 &&
            <Box mt={5}>
              <Heading size='sm'>Connected services</Heading>
              {participant.connectedServices.filter(s => SERVICES[s.key]).map(s =>
                <Box key={s.key} mt={2} display='flex' alignItems='center'>
                  <Box w='80px' mr={5}>
                    <img src={SERVICES[s.key]?.image} alt={SERVICES[s.key]?.name} />
                  </Box>
                  <Text fontSize='sm'>{SERVICES[s.key]?.name} connected on {moment(s.connectedAt).format('DD/MM/YYYY HH:mm')}</Text>
                </Box>
              )}
            </Box>
          }
        </Box>
        <Box flex={1}>
          <ParticipantNotes participant={participant} />
        </Box>
      </Box>
      
      <Box display={{md: 'flex'}} mt={5}>
        <Box rounded='md' p={3} w='50%' mr={5}>
          {utils.canSupportTrial(user, trial) &&
            <ParticipantMessenger trial={trial} participant={participant} />
          }
        </Box>
        
        <Box flex={1} bg='gray.50' rounded='md' p={3}>
          <Heading size='md'>Recent activity</Heading>
          <Text mb={5} fontSize='sm'>Actions recently taken by the participant.</Text>
          {!participant?.recentLogs?.length > 0 &&
            <EmptyBox title='Activity feed' description='Entries will appear here once the participant engages with the trial' />
          }
          <Box overflowY='scroll' maxH='500px'>
            {participant?.recentLogs?.map(log =>
              <Box key={log._id} mb={2} display={{md: 'flex'}}>
                <Text fontSize='xs' mr={2} w='120px'>{moment(log.createdAt).format('DD/MM/YYYY HH:mm')}</Text>
                <Text fontSize='xs' fontWeight='bold'>{log.type}</Text>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
