import api from '.';

export const root = {
  createTeam(data, success, fail) {
    api.authenticatedRequest('POST', '/root/teams', data, success, fail);
  },
  getTeams(success, fail) {
    api.authenticatedRequest('GET', '/root/teams', null, success, fail);
  },
  getTeam(id, success, fail) {
    api.authenticatedRequest('GET', `/root/teams/${id}`, null, success, fail);
  },
  updateTeam(id, data, success, fail) {
    api.authenticatedRequest('PUT', `/root/teams/${id}`, data, success, fail);
  },
  getUsers(success, fail) {
    api.authenticatedRequest('GET', '/root/users', null, success, fail);
  },
};
