import React, { Component } from 'react';
import styled from 'styled-components';
import { Box } from '@chakra-ui/react';

function EntryView({ left, right }) {
  return (
    <Box display={{md: 'flex'}}>
      <Box>
        <Box m={3} bg='gray.50' minW='200px' maxW='500px' p={4} rounded='md' position={{md: 'sticky'}} top='20px'>
          {left}
        </Box>
      </Box>
      <Box width={{md:'50%', lg:'40%'}} p={4}>
        {right}
      </Box>
    </Box>
  );
}

export default EntryView;
