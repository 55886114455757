import api from '.';

export const participants = {
  sendNotification(id, message, success, fail) {
    api.authenticatedRequest('POST', `/participants/${id}/notifications`, { message }, success, fail);
  },
  get(trialId, participantId, success, fail) {
    api.authenticatedRequest('GET', `/trials/${trialId}/participants/${participantId}`, null, success, fail);
  },
  listStages(trialId, participantId, success, fail) {
    api.authenticatedRequest('GET', `/trials/${trialId}/participants/${participantId}/stages`, null, success, fail);
  }
};
