import React, { useEffect } from 'react';
import { Box, Heading } from '@chakra-ui/react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';


function OAuthConnect({ }) {
  const { service } = useParams();
  const { search } = useLocation();
  const navigate = useNavigate();
  -
  useEffect(() => {
    const params = new URLSearchParams(search);
    const code = params.get('code');
    const encodedState = params.get('state');
    const state = JSON.parse(atob(encodedState));
    if (state.callback) { // We send the state back to the page initiating the connection
      navigate(state.callback, { state: { ...state, code, service } });
    }
    else navigate('/');
  }, [search, service]);

  return (
    <Box p={4}>
      <Heading size='md'>Connecting to service...</Heading>
    </Box>
  );
}

export default OAuthConnect;
