import React, { useEffect, useState } from 'react';
import { Container, Box, Heading, Text, Button } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useReward } from 'react-rewards';
import api from '../api';
import useStore from '../store';
import FileChooser from './includes/FileChooser';
import PlansImage from '../images/plans.png';

export default function Setup() {
  const [screen, setScreen] = useState(0);
  const { reward } = useReward('confettiReward', 'confetti', {
    elementCount: 100,
    lifetime: 400,
    spread: 90,
  });

  const { user, team, updateTeam } = useStore();

  useEffect(() => {
    if (user) {
      setTimeout(() => reward(), 1000);
    }
  }, [user]);

  function updateLogo({ storedName }) {
    api.teams.update(team?._id, { logo: storedName }, team => {
      updateTeam(team);
    }, err => toast({title: 'Unable to update team', description: err.message, status: 'error'}));
  }

  if (!user) return null;
  return (
    <Container mt={5} display='flex' flexDirection='column' justifyContent='center' h='600px'>
      {screen === 0 && <Box>
        <Heading size='lg'>👋 Welcome, {user.firstName}!</Heading>
        <Text mt={5}>Your account is set-up and you're nearly ready to create your first trial.</Text>
        <Heading size='md' mt={10}>About your new account</Heading>
        <Text mt={5}>You are the owner of the <strong>{team?.name}</strong> team. When you're ready to upgrade your Trialflare team account, you will be able to invite your teammates.</Text>

        <Box textAlign='center'>
          <Button id='confettiReward' mt={10} colorScheme='primary' size='lg' onClick={e => setScreen(1)}>Continue</Button>
        </Box>
      </Box>}

      {screen === 1 && <Box>
        <Heading size='md' mt={10}>The {team?.name} team logo</Heading>
        <Text mt={5}>Trialflare will display {team?.name}'s logo to participants joining your trial and it provides identity to members of your team.</Text>

        <Box display='flex' flexDirection='column' alignItems='center'>
          {team?.logoUrl &&
            <Box><img src={team?.logoUrl} style={{width: 200, marginTop: 15}} /></Box>
          }
          <Box mt={5}>
            <FileChooser accept='image/*'
              forType='team' forObject={team}
              onComplete={updateLogo}
              trigger={<Button variant={team?.logoUrl ? 'outline' : 'solid'} colorScheme='blue'>{team?.logoUrl ? 'Change logo' : 'Add a logo'}</Button>}
            />
          </Box>
        </Box>

        <Box textAlign='center' mt={10}>
          {team?.logoUrl && <Text fontSize='xl' mb={5} fontWeight='bold'>😎 Great logo!</Text>}
          <Button variant={team?.logoUrl ? 'solid' : 'ghost'} colorScheme='primary' size='lg' onClick={e => setScreen(2)}>{team?.logoUrl ? 'Continue' : 'Skip for now'} </Button>
        </Box>
      </Box>}

      {screen === 2 && <Box>
        <Heading size='md' mt={10}>You're now ready to create a trial</Heading>
        <Box display='flex' justifyContent='center' m={10}><img src={PlansImage} width='300px' /></Box>
        <Text mt={5}>In Trialflare <em>trials</em> represent the main building blocks for your data capture.</Text>
        <Text mt={5}>For each trial you can define the <em>types</em> of data you want to collect, and also <em>how</em> you want to collect them.</Text>
        <Box textAlign='center'>
          <Button mt={10} colorScheme='primary' size='lg' as={Link} to={`/trials/new?name=${user.firstName}'s First Trial`}>Create your first trial</Button>
        </Box>
      </Box>}

    </Container>
  )
};
