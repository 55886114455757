import React, { useState, useEffect } from 'react';
import { useToast, Heading, Text, Button, CircularProgress, Textarea, Select, FormControl, FormLabel, Table, Tr, Th, Td, Thead, Tbody, Modal, ModalBody, ModalContent, ModalOverlay, ModalHeader, ModalCloseButton, ModalFooter } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { useParams } from 'react-router-dom';
import api from '../../../api';
import useStore from '../../../store';

import Empty from '../../../images/empty.png';
import EmptyBox from '../../includes/EmptyBox';
import ConfirmButton from '../../includes/ConfirmButton';

const DAYS = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export default function AutomaticMessages({ }){
  const [loading, setLoading] = useState(false);
  const [wizardIsOpen, setWizardIsOpen] = useState(false);
  const [stages, setStages] = useState([]);
  const [groups, setGroups] = useState([]);
  const [automaticMessages, setAutomaticMessages] = useState([]);
  const toast = useToast();
  const { id } = useParams();
  const { trial } = useStore()

  const trialId = trial?._id;

  useEffect(() => {
    setLoading(true);
    api.trials.getAutomaticMessages(trialId, data => {
      setLoading(false);
      setAutomaticMessages(data.automaticMessages || []);
    });
    api.trials.getStages(trialId, d => setStages(d.stages));
    api.trials.getGroups(trialId, d => setGroups(d.groups));
  }, [trialId]);

  const deleteAutomaticMessage = (messageId) => {
    api.trials.deleteAutomaticMessage(trialId, messageId, () => {
      const newAutomaticMessages = Object.assign([], automaticMessages);
      const index = newAutomaticMessages.map(a => a._id).indexOf(messageId);
      newAutomaticMessages.splice(index, 1);
      setAutomaticMessages(newAutomaticMessages);
      toast({ status: 'success', description: 'Automatic message has been deleted' });
    }, err => {
      toast({ status: 'error', description: err.message });
    });
  }

  return (
    <>
      <Heading mt={5} as='h2' size='lg'>Automatic messages</Heading>
      <Text mb={5}>Set-up automatic messages that are sent to participants on a schedule or as they interact with your trial.</Text>
      <Button colorScheme='primary' onClick={e => setWizardIsOpen(true)}><AddIcon mr={2} /> Create a new automatic message</Button>
      <MessageWizard isOpen={wizardIsOpen} trialId={trialId} groups={groups} stages={stages} 
        onCreated={newAutomaticMessage => {
          const newAutomaticMessages = Object.assign([], automaticMessages);
          newAutomaticMessages.splice(0, 0, newAutomaticMessage);
          setAutomaticMessages(newAutomaticMessages);
        }}
        onClose={e => setWizardIsOpen(false)}
      />

      {loading && <CircularProgress isIndeterminate />}
      {!loading && !automaticMessages?.length &&
        <EmptyBox
          image={Empty}
          title='Your automatic messages will appear here once created'
          description='Automatic messages enable you to provide automatic support and prompts to your participants as they engage with your trial.'
        />
      }
      {automaticMessages?.length > 0 &&
        <Table mt={10}>
          <Thead>
            <Tr>
              <Th>Send when</Th>
              <Th>Stage</Th>
              <Th>Message</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {automaticMessages.map(message => {
              const s = message?.schedule || {};
              let scheduleString = `${s.frequency} ${s.frequency === 'weekly' ? `on ${DAYS[s.day]} ` : ''} at ${s.hour}:00 UTC`;
              const messageGroup = groups?.find(g => g._id === s?.group);
              if (messageGroup) {
                scheduleString += ` (${messageGroup.name})`;
              }
              
              return (<Tr key={message._id}>
                <Td>{message.sendWhen}
                  {message.sendWhen === 'schedule' && message.schedule &&
                    <small><br />{scheduleString}</small>
                  }
                </Td>
                <Td>{stages.filter(s => s._id === message.stage)[0]?.name || 'N/A'}</Td>
                <Td><Text fontSize='sm'>{message.message}</Text></Td>
                <Td style={{textAlign: 'right'}}>
                  <ConfirmButton
                    header='Really delete this automatic message?'
                    body={`It can't be recovered but you can make a new one instead`}
                    trigger={<Button size='xs' colorScheme='red' variant='outline'>Delete</Button>}
                    action={e => deleteAutomaticMessage(message._id)}
                  />
                </Td>
              </Tr>);
            })}
          </Tbody>
        </Table>
      }

    </>
  );
}

function MessageWizard({ isOpen, onClose, trialId, groups, stages, onCreated }) {
  const [newSendWhen, setNewSendWhen] = useState('');
  const [newSendSchedule, setNewSendSchedule] = useState();
  const [newMessage, setNewMessage] = useState('');
  const toast = useToast();

  const createAutomaticMessage = () => {
    let sendWhen = newSendWhen;
    let stage;
    if (sendWhen.indexOf('completedStage.') === 0) {
      stage = sendWhen.replace('completedStage.', '');
      sendWhen = 'completedStage';
    }
    api.trials.createAutomaticMessage(trialId, { sendWhen, sendSchedule: newSendSchedule, stage, message: newMessage }, automaticMessage => {
      onCreated(automaticMessage);
      onClose();
      toast({ status: 'success', description: 'Automatic message created successfully' });
    }, err => {
      toast({ status: 'error', description: err.message });
    });
  }
  
  return (
    <Modal isOpen={isOpen} onClose={onClose} size='3xl'>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create a new automatic message</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Text mb={10} fontWeight='bold'>Your new automatic message will be in-action as soon as it's created.</Text>
          
          <FormControl>
            <FormLabel htmlFor='message'>Message body</FormLabel>
              <Textarea id='message' placeholder='Write your message...' value={newMessage} onChange={e => setNewMessage(e.target.value)} />
          </FormControl>
          
          <FormControl mt={5}>
            <FormLabel htmlFor='sendWhen'>Send</FormLabel>
            <Select id='sendWhen' value={newSendWhen} onChange={e => setNewSendWhen(e.target.value)}>
              <option>Select one</option>
              <option value='schedule'>According to a schedule</option>
              <option value='joinedTrial'>To a participant when they join the trial</option>
              {stages?.map(stage =>
                <option key={stage._id} value={`completedStage.${stage._id}`}>To a participant when they complete stage {stage.name}</option>
              )}
            </Select>
          </FormControl>
          
          {newSendWhen === 'schedule' && <>
            <Text mt={5} fontSize='sm' color='gray.600'>All times are in UTC.</Text>
            <FormControl mt={5}>
              <FormLabel>Send the message on this schedule</FormLabel>
              {groups?.length > 0 &&
                <Select value={newSendSchedule?.group} onChange={e => setNewSendSchedule({...newSendSchedule, group: e.target.value || null })}>
                  <option value=''>Send to all participants</option>
                  {groups.map(g =>
                    <option key={g._id} value={g._id}>Send to participants in {g.name}</option>
                  )}
                </Select>
              }
              <Select mt={2} value={newSendSchedule?.frequency} onChange={e => setNewSendSchedule({...newSendSchedule, frequency: e.target.value })}>
                <option>Send every...</option>
                <option value='daily'>Send every day</option>
                <option value='weekly'>Send once per week</option>
              </Select>
              {newSendSchedule?.frequency === 'weekly' &&
                <Select mt={2} value={newSendSchedule?.day} onChange={e => setNewSendSchedule({...newSendSchedule, day: parseInt(e.target.value) })}>
                  <option>On...</option>
                  {Array.from({length: 7}, (_, i) => i).map(dayIndex =>
                    <option key={dayIndex} value={dayIndex}>On {DAYS[dayIndex]}</option>
                  )}
                </Select>
              }
              <Select mt={2} value={newSendSchedule?.hour} onChange={e => setNewSendSchedule({...newSendSchedule, hour: parseInt(e.target.value) })}>
                <option>At...</option>
                {Array.from({length: 24}, (_, i) => i).map(hour =>
                  <option key={hour} value={hour}>At {hour < 10 && '0'}{hour}:00</option>
                )}
              </Select>
            </FormControl>
          </>}
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose}>Cancel</Button>
          <Button ml={5} colorScheme='primary' onClick={createAutomaticMessage}>Complete setup</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
