import React, { useState, useEffect } from 'react';
import { Button, Heading } from '@chakra-ui/react';
import { ArrowLeftIcon } from '@chakra-ui/icons';
import { Link, useParams } from 'react-router-dom';

import SimpleTabs from '../../includes/SimpleTabs';
import Responses from './Responses';
import HardwareResponses from './HardwareResponses';
import Information from './Information';
import Consent from './Consent';
import Timeline from './Timeline';

import api from '../../../api';
import utils from '../../../utils/utils';
import useStore from '../../../store';
import useTitle from '../../../hooks/useTitle';

export default function Participant({ }) {  
  const { id, participantId } = useParams();
  const [participant, setParticipant] = useState();
  const { user, trial, updateTrial } = useStore();
  useTitle(participant?.id || 'Participant', trial);
  
  useEffect(() => {
    api.participants.get(id, participantId, setParticipant);
  }, [participantId]);
  
  const tabs = [
    {
      title: 'Information',
      content: <Information trialId={id} participantId={participantId} participant={participant} setParticipant={setParticipant} />
    },
    {
      title: 'Responses',
      content: <Responses trialId={id} participantId={participantId} />
    },
    {
      title: 'Timeline',
      content: <Timeline trialId={id} participantId={participantId} />
    }
  ];
  if (utils.canManageTrial(user, trial)) {
    tabs.push({
      title: 'Consent',
      content: <Consent trialId={id} participantId={participantId} participant={participant} />
    })
  }
  if (trial?.garminEnabled) {
    tabs.splice(2, 0, {
      title: 'Wearable Responses',
      content: <HardwareResponses trialId={id} participantId={participantId} />
    })
  }

  return (
    <>
      <Button variant='link' colorScheme='primary' as={Link} to={`/trials/${id}/participants`}><ArrowLeftIcon mr={3} /> Back to participants</Button>
      
      <Heading mt={3}>Participant {participant?.id}</Heading>
      
      <SimpleTabs tabProps={{isLazy: true, mt: 3}} tabs={tabs} />
    </>
  );
}
