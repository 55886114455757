import React from 'react';
import { createRoot } from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { Chart as ChartJS, defaults, CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend, Filler, ArcElement } from 'chart.js'
import moment from 'moment';
import 'moment/locale/en-gb';

import App from './components/App';
import Home from './components/Home';

import Login from './components/entry/Login';
import OAuthLogin from './components/entry/OAuthLogin';
import Signup from './components/entry/Signup';
import ForgottenPassword from './components/entry/ForgottenPassword';
import ResetPassword from './components/entry/ResetPassword';
import Enrol from './components/entry/Enrol';

import Consent from './components/Consent';
import Poll from './components/Poll';

import Setup from './components/Setup';
import Settings from './components/settings/Settings';
import OAuthConnect from './components/OAuthConnect';
import NewTrial from './components/trials/New';
import Team from './components/teams/Team';
import Trials from './components/teams/Trials';

import RootDashboard from './components/root/Dashboard';
import RootHome from './components/root/Home';
import RootEditTeam from './components/root/EditTeam';

import TrialHome from './components/trials/Home';
import TrialSettings from './components/trials/settings';
import TrialResults from './components/trials/results';
import TrialInfo from './components/trials/Info';
import TrialData from './components/trials/Data';
import TrialStages from './components/trials/Stages';
import TrialNewStage from './components/trials/NewStage';
import TrialFiles from './components/trials/Files';
import TrialSites from './components/trials/Sites';
import TrialNewSite from './components/trials/NewSite';
import TrialConsent from './components/trials/Consent';
import TrialParticipants from './components/trials/Participants';
import TrialParticipant from './components/trials/Participant';
import TrialMessages from './components/trials/messages';

import AdminDashboard from './components/includes/AdminDashboard';
import ErrorElement from './components/includes/ErrorElement';

moment.locale('en-gb');

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend, Filler, ArcElement)
defaults.maintainAspectRatio = false
defaults.responsive = true
defaults.spanGaps = true
defaults.backgroundColor = '#9BD0F5';
defaults.color = '#000';

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <ErrorElement />,
    children: [
      { path: '', element: <Home /> },
      { path: '/setup', element: <Setup /> },
      { path: '/login', element: <Login /> },
      { path: '/login/oauth/:service', element: <OAuthLogin /> },
      { path: '/signup', element: <Signup /> },
      { path: '/password/forgotten', element: <ForgottenPassword /> },
      { path: '/password/reset', element: <ResetPassword /> },
      { path: '/enrol', element: <Enrol /> },
      { path: 'settings', element: <Settings /> },
      { path: 'connect/:service', element: <OAuthConnect /> },
      { path: 'root', element: <RootDashboard />, children: [
        { path: 'teams/:teamId/edit', element: <RootEditTeam /> },
        { path: '', element: <RootHome /> },
      ]},
      { path: '/teams/:id', element: <Team /> },
      { path: '/consent/:trialId', element: <Consent /> },
      { path: '/polls/:stageId', element: <Poll /> },
      { path: '/polls/:stageId/access/:linkId', element: <Poll /> },
      { path: '/trials', errorElement: <ErrorElement />, children: [
        { path: '', element: <Trials /> },
        { path: 'new', element: <NewTrial /> },
        { path: ':id', element: <AdminDashboard />, children: [
          { path: '', element: <TrialHome />, errorElement: <ErrorElement /> },
          { path: 'manage', element: <TrialHome />, errorElement: <ErrorElement /> },
          { path: 'settings', element: <TrialSettings />, errorElement: <ErrorElement /> },
          { path: 'files', element: <TrialFiles />, errorElement: <ErrorElement /> },
          { path: 'results', element: <TrialResults />, errorElement: <ErrorElement /> },
          { path: 'info', element: <TrialInfo />, errorElement: <ErrorElement /> },
          { path: 'data', element: <TrialData />, errorElement: <ErrorElement /> },
          { path: 'stages/new', element: <TrialNewStage />, errorElement: <ErrorElement /> },
          { path: 'stages/:stageId', element: <TrialNewStage />, errorElement: <ErrorElement /> },
          { path: 'stages', element: <TrialStages />, errorElement: <ErrorElement /> },
          { path: 'sites/new', element: <TrialNewSite />, errorElement: <ErrorElement /> },
          { path: 'sites/:siteId', element: <TrialNewSite />, errorElement: <ErrorElement /> },
          { path: 'sites', element: <TrialSites />, errorElement: <ErrorElement /> },
          { path: 'consent', element: <TrialConsent />, errorElement: <ErrorElement /> },
          { path: 'participants/:participantId', element: <TrialParticipant />, errorElement: <ErrorElement /> },
          { path: 'participants', element: <TrialParticipants />, errorElement: <ErrorElement /> },
          { path: 'messages/:participantId', element: <TrialMessages />, errorElement: <ErrorElement /> },
          { path: 'messages', element: <TrialMessages />, errorElement: <ErrorElement /> },
        ]}
      ]}
    ]
  }
]);

const theme = extendTheme({
  colors: {
    primary: {
      50: '#E9CCFF',
      100: '#C9AEFF',
      200: '#C9AEFF',
      300: '#AB90F7',
      400: '#AB90F7',
      500: '#7B64D8',
      600: '#7B64D8',
      700: '#2D2B71',
      800: '#2D2B71',
      900: '#23215D',
    },
  }
});

const root = createRoot(document.getElementById('root'));
root.render(
  <ChakraProvider resetCSS theme={theme}>
    <RouterProvider router={router} />
  </ChakraProvider>,
);
