import React, { useState, useEffect } from 'react';
import { useToast, Box, Stack, Heading, Text, Button, Alert } from '@chakra-ui/react';
import api from '../../api';
import useStore from '../../store';
//import { requestToken } from '../../utils/firebase';

export default function NotificationsSettings() {
  const [pushPermissionGranted, setPushPermissionGranted] = useState(false);
  const [requestingPushToken, setRequestingPushToken] = useState(false);
  const toast = useToast();
  const { user } = useStore();

  /*useEffect(() => {
    if (Notification.permission === "granted") {
      setPushPermissionGranted(true);
    }
  }, [Notification.permission]);*/

  function requestPushToken() {
    /*setRequestingPushToken(true);
    requestToken(token => {
      console.log('Push token:', token);
      setPushPermissionGranted(true);
      api.users.createPushToken(user._id, token, () => {
        setRequestingPushToken(false);
        toast({
          title: 'Success',
          description: 'Push notifications enabled',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      }, err => {
        setRequestingPushToken(false);
        toast({
          title: 'There was a problem enabling push notifications',
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      });
    }, err => {
      toast({
        title: 'Error',
        description: err,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setRequestingPushToken(false);
    })*/
  }

  return (
    <>
      <Stack rounded='lg' borderWidth='1px' alignItems='start' p={3} mt={5}>
        <Heading as='h3' size='md' mb={3}>Web push notifications</Heading>
        <Text>Trialflare can notify your browser via push notifications when important events occur.</Text>
        {Notification.permission === "granted" ?
              <Alert colorScheme='green'>You have granted push permissions</Alert> :
              <Alert colorScheme='orange'>You have not yet granted push permissions</Alert>
        }
        <Button mt={3} onClick={requestPushToken} colorScheme='primary' isLoading={requestingPushToken}>Enable notifications</Button>
      </Stack>
    </>
  );
}
