import React, { useEffect } from 'react';
import { Box, Progress, Heading, Text, Button } from '@chakra-ui/react';
import api from '../../api';
import useStore from '../../store';
import utils from '../../utils/utils';

import PlansImage from '../../images/plans2.png';

export default function Overview() {
  const { user, team, updateTeam } = useStore();
  const teamId = team?._id;

  // Refresh team data in case changed since /users/me called.
  useEffect(() => {
    if (!teamId) return;
    api.teams.get(teamId, teamData => {
      updateTeam(teamData);
    });
  }, [teamId]);

  return (<>
    {utils.hasPermission(user, 'team.manage', team?._id) ?
      <Box display={{lg: 'flex'}} justifyContent='space-between'>
  
        <Box p={3} mt={5} rounded='md' bg='gray.100' minW='300px'>
          <Heading size='md' mb={5}>Team usage</Heading>
          <TeamUsage title='Team seats (users)' count={team.userCount} maxCount={team.maxUsers} />
          <TeamUsage title='Trials' count={team.trialCount} maxCount={team.maxTrials} />
          <TeamUsage title='Participants' count={team.participantCount} maxCount={team.maxParticipants} />
        </Box>
  
        <Box ml={5} flex={1} p={3} mt={5} rounded='md' borderWidth='1px' display='flex' alignItems='center'>
          <Box>
            <img src={PlansImage} style={{maxWidth: '300px'}} />
          </Box>
          <Box ml={2} flex={1}>
            <Heading size='md' mb={3}>About your plan</Heading>
            <Text mb={2}>We will always work with you and your team to build a plan that suits you and to ensure that you're getting the best value.</Text>
            <Text mb={5}>If you've outgrown your current plan (or want to shrink it), please just reach out to us.</Text>
            <Button colorScheme='primary' w='100%' size='sm' variant='outline' as='a' href={`mailto:hello@trialflare.com?subject=Plan%20Change%20(${team?.name})`}>Talk to us about your plan</Button>
          </Box>
        </Box>
      </Box>
    :
      <Box>
        <Heading>Page unavailable</Heading>
        <Text>This page is only viewable by users that manage the team</Text>
      </Box>
    }
  </>);
}

function TeamUsage({ title, count, maxCount}) {
  if (!maxCount) return null;
  return (
    <Box mb={5}>
      <Heading size='xs'>{title}</Heading>
      <Box display='flex' alignItems='center'>
        <Progress bg='gray.300' flex='1' size='xs' value={(count || 0) * 100 / maxCount} />
        <Text ml={2}>{count || 0} / {maxCount}</Text>
      </Box>
    </Box>
  );
}
