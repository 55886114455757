import React from 'react';
import { Tag, Box, CircularProgress, CircularProgressLabel, Text } from '@chakra-ui/react';

export default function BMITag({ bmi, asCircular }) {
  if (!bmi) return null;

  let bmiLabel = 'Underweight';
  let bmiColour = 'blue';
  if (bmi > 18 && bmi < 25) {
    bmiColour = 'green';
    bmiLabel = 'Healthy';
  }
  if (bmi >= 25 && bmi < 30) {
    bmiColour = 'yellow';
    bmiLabel = 'Overweight';
  }
  if (bmi >= 30) {
    bmiColour = 'red';
    bmiLabel = 'Obese';
  }

  let bmiValue = (bmi * 100) / 40; // We make 40 the max on the circular bar
  if (bmiValue > 100) bmiValue = 100;

  if (asCircular) {
    return (
      <Box d='flex' alignItems='center'>
        <CircularProgress value={bmiValue} size='80px' thickness='4px' color={`${bmiColour}.400`}>
          <CircularProgressLabel fontSize='sm'>{bmi.toFixed(2)}</CircularProgressLabel>
        </CircularProgress>
        <Box>
          <Text fontSize='small' ml={2} textAlign='center' fontWeight='bold'>{bmiLabel}</Text>
        </Box>
      </Box>
    );
  }
  return (
    <Tag colorScheme={bmiColour}>{bmi?.toFixed(2) || 'Unknown BMI'} ({bmiLabel})</Tag>
  );
}