import api from '.';

export const participantNotes = {
  create(trialId, participantId, data, success, fail) {
    api.authenticatedRequest('POST', `/trials/${trialId}/participants/${participantId}/notes`, data, success, fail);
  },
  listForParticipant(trialId, participantId, success, fail) {
    api.authenticatedRequest('GET', `/trials/${trialId}/participants/${participantId}/notes`, null, success, fail);
  },
  update(trialId, participantId, noteId, data, success, fail) {
    api.authenticatedRequest('PUT', `/trials/${trialId}/participants/${participantId}/notes/${noteId}`, data, success, fail);
  },
  delete(trialId, participantId, noteId, success, fail) {
    api.authenticatedRequest('DELETE', `/trials/${trialId}/participants/${participantId}/notes/${noteId}`, null, success, fail);
  },
};
