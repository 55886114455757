import React, { useState, useRef, useEffect } from 'react';
import { useToast, Badge, Heading, Text, Box, Button, Icon, Accordion, AccordionItem, AccordionPanel, AccordionButton, AccordionIcon, Input, Select, Stack, Tag, TagLabel, TagCloseButton, Table, Thead, Tbody, Th, Tr, Td, Link } from '@chakra-ui/react';
import { FaFile } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { BulletList } from 'react-content-loader';
import moment from 'moment';
import api from '../../api';
import utils from '../../utils/utils';
import useStore from '../../store';
import useTitle from '../../hooks/useTitle';

import EmptyBox from '../includes/EmptyBox';
import ConfirmButton from '../includes/ConfirmButton';
import FileChooser from '../includes/FileChooser';
import SimplePopover from '../includes/SimplePopover';
import FilesImage from '../../images/files.png';
import NotFoundImage from '../../images/notFound.png';

const FILE_TYPES = [
  {key: 'regulatory', name: 'Regulatory'},
  {key: 'contract', name: 'Contract'},
  {key: 'finance', name: 'Finance'},
  {key: 'sop', name: 'SOP'},
  {key: 'other', name: 'Other'},
];

const FILE_STATUSES = [
  {key: 'draft', name: 'Draft', colour: 'orange'},
  {key: 'preApproved', name: 'Pre-Approved', colour: 'blue'},
  {key: 'review', name: 'Review', colour: 'yellow'},
  {key: 'approved', name: 'Approved', colour: 'green'},
];

export default function Files({ }){
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);
  const [nameFilter, setNameFilter] = useState('');
  const [typeFilter, setTypeFilter] = useState();
  const [statusFilter, setStatusFilter] = useState();
  const toast = useToast();
  const { id } = useParams();
  const { user, trial, updateTrial } = useStore();
  useTitle('eTMF', trial);

  useEffect(() => {
    setLoading(true);
    api.files.getForTrial(id, d => {
      setFiles(d.files);
      setLoading(false);
    });
  }, [id]);

  function onFileCreated(newFile) {
    setFiles([newFile, ...files]);
  }

  function updateFile(fileId, data) {
    setFiles(files.map(f => {
      if (f._id === fileId) return Object.assign({}, f, data);
      return f;
    }));
  }

  function deleteFile(fileId) {
    setFiles(files?.filter(f => f._id !== fileId));
  }

  let filteredFiles = files || [];
  if (nameFilter) filteredFiles = filteredFiles.filter(f => f.name?.toLowerCase().indexOf(nameFilter) > -1);
  if (typeFilter) filteredFiles = filteredFiles.filter(f => f.type === typeFilter);
  if (statusFilter) filteredFiles = filteredFiles.filter(f => f.status === statusFilter);

  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Box>
          <Heading as='h2' size='lg'>eTMF</Heading>
          <Text mb={15}>Files associated with this trial.</Text>
        </Box>
        <FileCreator trial={trial} onFileCreated={onFileCreated} />
      </Box>
      {loading ? <BulletList />
      :
        ((files?.length > 0) ? <Box>

          <Box bg='gray.50' p={2} rounded='sm' mb={5}>
            <Heading size='xs'>Search and filter</Heading>
            <Stack direction='row' mt={2}>
              <Input bg='white' size='sm' placeholder='File name...' value={nameFilter} onChange={e => setNameFilter(e.target.value?.toLowerCase())} />
              <Select bg='white' size='sm' placeholder='All types...' value={typeFilter} onChange={e => setTypeFilter(e.target.value)}>
                {FILE_TYPES.map(t => <option key={t.key} value={t.key}>{t.name}</option>)}
              </Select>
              <Select bg='white' size='sm' placeholder='All statuses...' value={statusFilter} onChange={e => setStatusFilter(e.target.value)}>
                {FILE_STATUSES.map(t => <option key={t.key} value={t.key}>{t.name}</option>)}
              </Select>
            </Stack>
          </Box>

          {filteredFiles?.length === 0 &&
            <EmptyBox image={NotFoundImage} title='No files found matching your filters' description='Please try adjusting your search terms.' />
          }

          <Accordion allowMultiple allowToggle>
            {filteredFiles.map(file =>
              <FileItem key={file._id} trial={trial} file={file} onChange={d => updateFile(file._id, d)} onDelete={() => deleteFile(file._id)} />
            )}
          </Accordion>
        </Box> :
          <EmptyBox image={FilesImage} title='Add your first file' description={`Create a new entry in your trial's eTMF.`} action={<FileCreator trial={trial} onFileCreated={onFileCreated} />}
          />

        )
      }
    </>
  );
}

function FileCreator({ trial, onFileCreated }) {
  const [name, setName] = useState('');
  const [type, setType] = useState('regulatory');
  const [creating, setCreating] = useState(false);
  const toast = useToast();
  const { user } = useStore();

  function create() {
    api.files.create(trial._id, {
      name: name,
      type: type,
    }, newFile => {
      onFileCreated(newFile);
    }, err => toast({title: 'Unable to create file', description: err.message, status: 'error'}));
  }

  if (!utils.canWriteFiles(user, trial)) return null;

  return (
    <SimplePopover
      trigger={<Button colorScheme='primary'>Create a new file</Button>}
      header='Add a new file to the eTMF'
      body={
        <Box textAlign='left'>
          <Text>File name</Text>
          <Input placeholder='Trial SOP' value={name} onChange={e => setName(e.target.value)} />

          <Text mt={5}>File type</Text>
          <Select value={type} onChange={e => setType(e.target.value)}>
            {FILE_TYPES.map(t => <option key={t.key} value={t.key}>{t.name}</option>)} 
          </Select>

          <Button mt={5} isLoading={creating} onClick={create}>Create file</Button>
        </Box>
      }
    />
  );
}

function FileItem({ trial, file, onChange, onDelete }) {
  const [newVersion, setNewVersion] = useState('');
  const [newVersionFile, setNewVersionFile] = useState();
  const [name, setName] = useState('');
  const toast = useToast();
  const { user } = useStore();

  useEffect(() => {
    setName(file.name || '');
  }, [file]);

  function addVersion() {
    if (!newVersion || !newVersionFile) return;
    api.files.createVersion(file._id, {
      name: newVersionFile.name,
      storedName: newVersionFile.storedName,
      version: newVersion,
    }, newV => {
      onChange({versions: [newV, ...file.versions || []]});
      setNewVersion('');
      setNewVersionFile();
    }, err => toast({title: 'Unable to create version', description: err.message, status: 'error'}));
  }

  function update(d) {
    api.files.update(file._id, d, onChange, err => toast({title: 'Unable to update file', description: err.message, status: 'error'}));
  }

  function deleteFile() {
    api.files.delete(file._id, () => {
      toast({title:'File deleted', status:'success'});
      onDelete();
    }, err => toast({title: 'Unable to delete file', description: err.message, status: 'error'}));
  }

  const versions = (file.versions || []);
  versions.sort((a,b) => a.createdAt < b.createdAt);

  return (
    <AccordionItem bg='gray.50' mb={5}>
      <AccordionButton bg='gray.100'>
        <Box flex={1} display='flex' textAlign='left' alignItems='center'>
          <Box>
            <Icon as={FaFile} color='gray.500' />
          </Box>
          <Box ml={5}>
            <Heading size='sm'>{file.name}</Heading>
            <Stack direction='row' alignItems='center'>
              <Box w='150px'>{file.type && <Badge>{file.type}</Badge>}</Box>
              {file.status && <Badge colorScheme={FILE_STATUSES.filter(s => s.key === file.status)[0]?.colour}>{file.status}</Badge>}
            </Stack>
          </Box>
        </Box>
        <AccordionIcon />
      </AccordionButton>

      <AccordionPanel>
        {utils.canWriteFiles(user, trial) &&
          <Stack direction='row'>
            <Input bg='white' size='sm' w='150px' placeholder='File name' value={name} onChange={e => setName(e.target.value)} onBlur={e => update({name: e.target.value})} />
            <Select bg='white' size='sm' w='150px' value={file.type} onChange={e => update({type: e.target.value})}>
              <option>Select one...</option>
              {FILE_TYPES.map(t => <option key={t.key} value={t.key}>{t.name}</option>)} 
            </Select>
            <Select bg='white' size='sm' w='150px' value={file.status} onChange={e => update({status: e.target.value})}>
              <option>Select one...</option>
              {FILE_STATUSES.map(t => <option key={t.key} value={t.key}>{t.name}</option>)} 
            </Select>

            <SimplePopover
              closeOnBlur={false}
              header='Create a new version'
              trigger={<Button size='sm' variant='outline' colorScheme='primary'>Create a new version</Button>}
              body={
                <Stack textAlign='left'>
                  <Input size='sm' placeholder='Version name or number' value={newVersion} onChange={e => setNewVersion(e.target.value)} />

                  {newVersionFile &&
                    <Tag>
                      <TagLabel>{newVersionFile.name}</TagLabel>
                      <TagCloseButton onClick={e => setNewVersionFile()} />
                    </Tag>
                  }

                  {!newVersionFile &&
                    <FileChooser
                      forType='trial' forObject={trial}
                      onComplete={setNewVersionFile}
                      trigger={<Button size='sm' variant='outline' >Upload a file</Button>}
                    />
                  }

                  {newVersion?.length && newVersionFile && <Button onClick={addVersion} size='sm' colorScheme='primary'>Create version</Button>}
                </Stack>
              }
            />

            {utils.canDeleteFiles(user, trial) && <ConfirmButton
              action={deleteFile} actionColor='red'
              trigger={<Button size='sm' color='red' variant='ghost' >Delete file</Button>}
              header='Really delete this file?'
              body='This file and all of its versions will be irretrievably removed.'
            />}
          </Stack>
        }

        {versions?.length > 0 ?
          <Table size='sm' variant='simple' mt={5}>
            <Thead>
              <Th>Version name</Th>
              <Th>Added</Th>
              <Th />
            </Thead>
            <Tbody>
              {versions.map(version =>
                <Tr key={version.storedName}>
                  <Td><Link as='a' color='primary' href={version.url}>{version.version}</Link></Td>
                  <Td>{moment(version.createdAt).format('DD/MM/YYYY HH:mm')}</Td>
                  <Td>
                    {version.url &&
                      <Button as='a' href={version.url} target='_blank' rel='noopener noreferrer' size='sm'>View</Button>
                    }
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        :
          <EmptyBox image={FilesImage} title='Create a new version for this file' description={`Use the button above to upload the first version of this file.`} />
        }
      </AccordionPanel>
    </AccordionItem>
  );
}
