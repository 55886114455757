import React, { useState, useEffect } from 'react';
import { Heading, Text, Button, Alert, CircularProgress, Container } from '@chakra-ui/react';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { api } from '../../api';
import useStore from '../../store';

import EntryView from '../includes/EntryView';

export default function OAuthLogin() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { search } = useLocation();
  const { service } = useParams();
  const store = useStore();
-
  useEffect(() => {
    setError('');
    const params = new URLSearchParams(search);
    const code = params.get('code');
    if (!code || !service) {
      setError('There was a problem logging you in. Please try again.');
      return;
    }
    setLoading(true);
    api.accounts.login({ service, code }, (data) => {
      setLoading(false);
      if (data.token) {
        store.completeLogin(data.token);
        navigate('/');
      }
    }, (err) => {
      setError(err.message);
      setLoading(false);
    });
  }, [search, service]);

  return (
    <Container mt={5} mb={5}>
      <Heading size='lg' mb={5}>Logging you in...</Heading>

      {loading && <CircularProgress color='primary.400' mb={5} isIndeterminate />}

      {error && <>
        <Alert status='error' variant='left-accent' flexDirection='column' alignItems='start'>
          <Heading size='sm' mb={2}>Sorry, we couldn't log you in</Heading>
          <Text>{error}</Text>
        </Alert>
        <Button mt={5} colorScheme='primary' as={Link} to='/'>Return to login page</Button>
      </>}
    </Container>
  );
}
