import React from 'react';
import { Button, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';

export default function SimpleTabs({ menuProps, buttonProps, trigger, items }) {
  return (
    <Menu {...menuProps}>
      <MenuButton>{trigger}</MenuButton>
      <MenuList>
        {items.map((item, i) => (
          <MenuItem key={i} onClick={item.onClick}>{item.text}</MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
