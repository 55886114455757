import React, { useState, useRef } from 'react';
import { useToast, Button, Box, CircularProgress, Portal, Text } from '@chakra-ui/react';
import api from '../../api';

export default function FileChooser({ forObject, forType, content, trigger, accept, fileType, onUploadStart, onUploadFinish, onComplete, onError }) {
  const fileInputRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const toast = useToast();

  function startUpload() {
    setIsUploading(true);
    onUploadStart && onUploadStart();
  }

  function finishUpload() {
    setIsUploading(false);
    setUploadProgress(0);
    onUploadFinish && onUploadFinish();
  }

  function chooseFile() {
    fileInputRef?.current?.click()
  }

  function handleFileChosen(e) {
    const apiFunction = fileType === 'video' ?
      api.uploads.generateVideoUploadRequest :
      api.uploads.generateFileUploadRequest;
    const file = e.target.files && e.target.files[0];
    if (file) {
      startUpload();
      apiFunction({
        forType: forType, forId: forObject._id, name: file.name, size: file.size, type: file.type
      }, (response) => {
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', response.signedRequest);
        xhr.setRequestHeader('Content-Type', file.type);
        xhr.upload.addEventListener('progress', (e) => {
          if (e.lengthComputable) {
            setUploadProgress(parseInt((e.loaded * 100) / e.total, 10));
          }
        });
        xhr.onreadystatechange = () => {
          if (xhr.readyState === 4) {
            finishUpload();
            if (xhr.status === 200) {
              onComplete({ expectedUrl: response.expectedUrl, storedName: response.fileName, name: file.name, type: 'file' });
            } else if (onError) {
              finishUpload();
              onError('Unable to upload file');
            }
          }
        };
        xhr.send(file);
      }, (err) => {
        finishUpload();
        if (onError) onError(err.message || 'Unable to upload file');
      });
    }
  }

  return (
    <>
      <input type="file" style={{ display: 'none' }} ref={fileInputRef} onChange={handleFileChosen} accept={accept || '*'} />
      {trigger
        ? React.cloneElement(trigger, { isLoading: isUploading, onClick: chooseFile })
        : <Button size="sm" colorScheme="blue" isLoading={isUploading} onClick={chooseFile}>{content || 'Choose a file'}</Button>
      }

      {isUploading &&
        <Portal>
          <Box style={{ position: 'fixed', 'bottom': '20px', 'right': '20px', zIndex: 9999999}}>
          <Box p={3} w='300px' bg='blue.400' display='flex' alignItems='center'
            rounded='md' shadow='lg'

          >
            <Box h='30px' w='30px' mr={3}>
              <CircularProgress size='30px' value={uploadProgress} />
            </Box>
            <Text color='white'>Uploading...</Text>

          </Box>
          </Box>
        </Portal>
      }
    </>
  );
}