import React,  { useEffect, useState } from 'react';
import { useToast, Alert, Button, Heading, Box, Text, Input, Textarea } from '@chakra-ui/react';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../../api';
import useStore from '../../store';
import useTitle from '../../hooks/useTitle';

import BooksImage from '../../images/books.png';

export default function NewTrial(){
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { user, team } = useStore();
  useTitle('New Trial');

  useEffect(() => {
    if (search) {
      const params = new URLSearchParams(search);
      const name = params.get('name');
      if (name) setName(name);
    }
  }, [search]);

  function createTrial() {
    setError();
    setLoading(true);
    api.teams.createTrial(user.team, { name, description }, trial => {
      navigate(`/trials/${trial._id}/manage`);
      setLoading(false);
      toast({title: 'Trial created successfully', status: 'success'})
    }, err => {
      setError(err.message);
      setLoading(false);
    });
  }

  return (
    <Box p={10} display={{md: 'flex'}}>
      <Box w={{md: 300}} p={4} bg='gray.50'>
        <img alt='Books' src={BooksImage} style={{display:'block', width:100}} />
        <Heading as='h3' size='md'>About trials</Heading>
        <Text mt={10}>A trial on Trialflare is a space for collecting the data you need from your consumers, research participants, or other users.</Text>
        <Text mt={5}>Once you've created a trial, you can specify the types of data you want to collect (data types) and the dynamic timepoints at which this data will be collected (stages).</Text>
      </Box>
      <Box flex={1} p={4}>
        <Heading as='h2' size='lg'>Create a new trial</Heading>
        <Text mb={15}>This trial will be managed by the {team && team.name} team.</Text>
        <Text>Trial name</Text>
        <Input autoFocus value={name} onChange={e => setName(e.target.value)} placeholder='Intervention or trial name' />

        <Text mt={10}>Trial description</Text>
        <Textarea value={description} onChange={e => setDescription(e.target.value)} placeholder='This trial aims to...' />
        <Alert variant='left-accent' mt={15} status='info'>Please note that this information will be visible to participants of your trial. It can be changed at any time.</Alert>

        {error &&
          <Alert variant='left-accent' mt={15} status='error'>There was a problem creating your trial: {error}</Alert>
        }

        <Button isLoading={loading} mt={15} colorScheme='primary' onClick={createTrial}>Create trial</Button>
      </Box>
    </Box>
  );
}
