import React, { useState } from 'react';
import { Button, Popover, PopoverContent, PopoverTrigger, PopoverHeader, PopoverBody, PopoverFooter, Stack } from '@chakra-ui/react';

export default function ConfirmButton({ header, body, footer, trigger, action, actionColor, closeOnBlur }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Popover closeOnBlur={closeOnBlur ?? true} onOpen={() => setIsOpen(true)} onClose={() => setIsOpen(false)} isOpen={isOpen}>
      <PopoverTrigger>
        {trigger}
      </PopoverTrigger>
      <PopoverContent zIndex={4} bg='white' placement='right'>
        <PopoverHeader>{header}</PopoverHeader>
        {body && <PopoverBody>{body}</PopoverBody>}
        <PopoverBody>
          <Stack direction='row'>
            <Button onClick={() => {
              setIsOpen(false);
              action();
            }} colorScheme={actionColor || 'gray'}>Confirm</Button>
            <Button variant='ghost' onClick={() => setIsOpen(false)}>Cancel</Button>
          </Stack>
        </PopoverBody>
        {footer && <PopoverFooter>{footer}</PopoverFooter>}
      </PopoverContent>
    </Popover>
  );
}
