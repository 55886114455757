import api from '.';

export const users = {
  me(success, fail) {
    api.authenticatedRequest('GET', '/users/me', null, success, fail);
  },
  get(username, success, fail) {
    api.authenticatedRequest('GET', `/users/${username}`, null, success, fail);
  },
  update(id, data, success, fail) {
    api.authenticatedRequest('PUT', `/users/${id}`, data, success, fail);
  },
  createPushToken(id, pushToken, success, fail) {
    api.authenticatedRequest('POST', `/users/${id}/pushTokens`, { pushToken }, success, fail);
  },
  delete(id, success, fail) {
    api.authenticatedRequest('DELETE', `/users/${id}`, null, success, fail);
  },
  updatePermissions(id, scope, permissions, success, fail) {
    api.authenticatedRequest('PUT', `/users/${id}/permissions/${scope}`, { permissions }, success, fail);
  },
  updateTeam(id, teamId, success, fail) {
    api.authenticatedRequest('PUT', `/users/${id}/teams/${teamId}`, null, success, fail);
  },
  createEmailSubscription(id, sub, success, fail) {
    api.authenticatedRequest('POST', `/users/${id}/subscriptions/email/${sub}`, null, success, fail);
  },
  deleteEmailSubscription(id, sub, success, fail) {
    api.authenticatedRequest('DELETE', `/users/${id}/subscriptions/email/${sub}`, null, success, fail);
  },
  finishTour(id, tour, status, success, fail) {
    api.authenticatedRequest('PUT', `/users/${id}/tours/${tour}?status=${status}`, null, success, fail)
  },
};
