import React, { useState, useEffect } from 'react';
import { useToast, Box, Heading, Text, Button, Input, FormControl, FormLabel, Alert, AlertDescription } from '@chakra-ui/react';
import api from '../../api';
import useStore from '../../store';

export default function ProfileSettings() {
  const [newFirstName, setNewFirstName] = useState('');
  const [newLastName, setNewLastName] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const toast = useToast();
  const { user, updateUser } = useStore();

  const firstName = user?.firstName;
  const lastName = user?.lastName;
  const email = user?.email;

  useEffect(() => {
    setNewFirstName(firstName);
    setNewLastName(lastName);
    setNewEmail(email);
  }, [firstName, lastName, email]);

  const saveProfile = () => {
    api.users.update(user?._id, { firstName: newFirstName, lastName: newLastName }, u => updateUser(u._id, u));
  }
  const saveEmail = () => {
    api.users.update(user?._id, { email: newEmail }, u => {
      updateUser(u._id, u);
      toast({ title: 'Email updated successfully', status: 'success' });
    }, err => {
      toast({ title: 'Unable to update your email address', description: err.message, status: 'error' });
    });
  }

  return (
    <>
      <Box rounded='lg' borderWidth='1px' p={3} mt={5}>
        <Heading as='h3' size='md' mb={3}>About you</Heading>
        <Text>How others see you on Trialflare.</Text>
        <FormControl mt={5}>
          <FormLabel htmlFor="firstName">First name</FormLabel>
          <Input id='firstName' value={newFirstName} onChange={e => setNewFirstName(e.target.value)} placeholder='Jane' />
        </FormControl>
        <FormControl mt={5}>
          <FormLabel htmlFor="lastName">Last name</FormLabel>
          <Input id='lastName' value={newLastName} onChange={e => setNewLastName(e.target.value)} placeholder='Smith' />
        </FormControl>
        <Button colorScheme='primary' onClick={saveProfile} mt={5} >Save</Button>
      </Box>

      <Box rounded='lg' borderWidth='1px' p={3} mt={5}>
        <Heading as='h3' size='md' mb={3}>Your email address</Heading>
        <Text>This is the address you use to login to Trialflare and where you receive notifications.</Text>
        <FormControl mt={5}>
          <FormLabel htmlFor="email">Email address</FormLabel>
          <Input id='email' value={newEmail} onChange={e => setNewEmail(e.target.value)} placeholder='jane@example.com' />
        </FormControl>
        <Alert mt={5} >
          <AlertDescription>For security we will send an email to both your old and new addresses confirming this change.</AlertDescription>
        </Alert>
        {!user?.hasPassword ? <Alert mt={5} status='warning'>
          <AlertDescription>Your email address is managed by your organisation. You can't change it here.</AlertDescription>
          </Alert>
        :
          <Button colorScheme='primary' onClick={saveEmail} mt={5} >Save</Button>
        }
      </Box>
    </>
  );
}
