import React, { useState, useRef } from 'react';
import { Button, Badge, Box, Text, Alert, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter } from '@chakra-ui/react';
import styled from 'styled-components';

const Table = styled.table`
  width: 100%;
  th {
    text-align:left;
    border-bottom: 1px solid rgb(200,200,200);
    padding-bottom:10px;
  }
  tr {
    border-bottom: 1px solid rgb(230,230,230);
    margin-bottom:30px;
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }
  td {
    padding: 5px;
    text-align:left;
  }
`;

function CSVChooser({ trigger, title, instructions, formatFunction, onComplete }) {
  const [open, setOpen] = useState(false);
  const [header, setHeader] = useState([]);
  const [rows, setRows] = useState();
  const inputRef = useRef();

  function handleFileChosen(event) {
    const reader = new FileReader();
    reader.addEventListener('load', (event) => {
      const text = event.target.result;
      const [h, r] = formatFunction(text);
      setHeader(h);
      setRows(r);
    });
    reader.readAsText(event.target.files[0]);
  }

  return (
    <>
      {trigger
        ? React.cloneElement(trigger, { onClick: () => setOpen(true) })
        : <Button colorScheme="blue" onClick={() => setOpen(true)}>Upload a CSV file</Button>
      }
      <Modal isOpen={open} onClose={() => setOpen(false)} size='3xl'>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title || 'Upload a CSV'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {instructions && <Text fontSize='md' mb={5}>{instructions}</Text>}
            <input type="file" style={{ display: 'none' }} ref={inputRef} onChange={handleFileChosen} accept={'.csv'} />
            <Button onClick={() => inputRef.current.click()} colorScheme='primary' variant='outline'>Choose a file</Button>
          </ModalBody>
          {rows?.length > 0 &&
            <ModalBody>
              <Text fontWeight='bold' mb={5}>Ready to import the following {rows?.filter(r => !r.error).length} rows?</Text>
              {rows?.filter(r => r.error).length > 0 &&
                <Alert size='sm' variant='left-accent' status='warning' mb={5}>Some rows have errors and will be ignored. To remove the errors please check the table below and adjust your CSV file accordingly.</Alert>
              }

              <Box h={300} overflowY='scroll'>
                <Table>
                  <thead>
                    <tr>
                      {header.map(h => <th>{h}</th>)}
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {rows.map(row =>
                      <tr>
                        {header.map(h =>
                          <td>{row[h]}</td>
                        )}
                        <td>{row.error ? <Badge colorScheme='orange'>{row.error}</Badge> : <Badge colorScheme='green'>Ready</Badge>}</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Box>
            </ModalBody>
          }

          <ModalFooter>
            <Button variant='ghost' mr={3} onClick={() => setOpen(false)}>Cancel</Button>
            {rows?.length > 0 &&
              <Button colorScheme='primary' onClick={() => {setOpen(false);onComplete(rows)}}>Import {rows?.filter(r => !r.error).length} rows</Button>
            }
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CSVChooser;
