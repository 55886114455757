import api from '.';

export const uploads = {
  generateFileUploadRequest({ forType, forId, name, size, type }, success, fail) {
    api.authenticatedRequest('GET', `/uploads/file/request?name=${name}&size=${size}&type=${type}&forType=${forType}&forId=${forId}`, null, success, fail);
  },
  generateVideoUploadRequest({ forType, forId, name, size, type }, success, fail) {
    api.authenticatedRequest('GET', `/uploads/video/request?name=${name}&size=${size}&type=${type}&forType=${forType}&forId=${forId}`, null, success, fail);
  },
  generateFileDownloadRequest({ forType, forId, name }, success, fail) {
    api.authenticatedRequest('GET', `/downloads/file/request?name=${name}&forType=${forType}&forId=${forId}`, null, success, fail);
  },
};
