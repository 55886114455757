import React, { useState, useEffect } from 'react';
import { Container, Stack, Grid, Text, Button, CircularProgress, Icon, Heading, Box, Badge, InputGroup, InputLeftElement, Input } from '@chakra-ui/react';
import { AddIcon, SearchIcon } from '@chakra-ui/icons';
import { FaEnvelope, FaExclamationTriangle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import moment from 'moment';
import api from '../../api';
import useStore from '../../store';
import utils from '../../utils/utils.js';
import useTitle from '../../hooks/useTitle';

import EmptyBox from '../includes/EmptyBox';

import PlansImage from '../../images/plans.png';
import NotFoundImage from '../../images/notFound.png';

export default function TeamTrials() {
  const [trials, setTrials] = useState([]);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const { user } = useStore();
  useTitle('Trials');

  const teamId = user?.team;
  useEffect(() => {
    if (!teamId) return;
    setLoading(true);
    api.teams.getTrials(teamId, ({ trials }) => {
      setTrials(trials);
      setLoading(false);
    });
  }, [teamId]);

  let filtered;
  if (filter) {
    const exp = new RegExp(filter, 'i');
    filtered = filter && trials.filter(t => t.name.match(exp));
  } else filtered = trials;

  let allPermissions = 0;
  Object.keys(user?.permissions || {})?.forEach(scope => {
    allPermissions += user.permissions[scope]?.length || 0;
  });

  if (!user) return null;

  return (
    <Container maxW='4xl' mt={10}>
      {trials.length > 0 &&
        <Box textAlign='right' display='flex' justifyContent='space-between'>
          <Heading as='h2' size='lg' mb={4}>Your trials</Heading>
          <InputGroup width='200px'>
            <InputLeftElement
              pointerEvents='none'
              children={<SearchIcon color='gray.300' />}
            />
            <Input placeholder="Filter trials..." value={filter} onChange={e => setFilter(e.target.value)} />
          </InputGroup>
        </Box>
      }

      {loading && !trials?.length && <Box m={10} textAlign='center'><CircularProgress isIndeterminate /></Box>}
      {!loading && !trials?.length && <>
        {utils.hasPermission(user, 'trials.create', user.team) &&
          <Stack bg='green.50' p={3} rounded='md' shadow='md' display='flex' flexDirection='column' justifyContent='center'>
            <Heading size='sm'>Do you need help getting started?</Heading> 
            <Text>Check out our Getting Started guide on how to get the most out of Trialflare.</Text>
            <Button colorScheme='green' as='a' href='https://support.seastorm.co/hc/help-center/articles/1723665502-getting-started' target='_blank'>View Guide</Button>
          </Stack>
        }
        <EmptyBox image={PlansImage} title={`You don't have any trials yet`}
          description={allPermissions === 0 && `You may need to ask your account manager for permission or you will need to be a collaborator on a trial.`}
          action={utils.hasPermission(user, 'trials.create', user.team) && <Button colorScheme='primary' as={Link} to={`/trials/new`}>Create your first trial</Button>} />
      </>}

      {trials?.length > 0 && !filtered?.length &&
        <EmptyBox image={NotFoundImage} title='Your search returned no results' description='Try changing your search terms.' />
      }

      {filtered?.length > 0 &&
        <Grid templateColumns={{md: 'repeat(2, 1fr)', lg:'repeat(3, 1fr)'}} gap={4} mt={5}>
          {utils.hasPermission(user, 'trials.create', user.team) &&
            <Box as={Link} to={`/trials/new`} borderWidth='1px' rounded='md' p={3} width='100%' shadow='md' display='flex' flexDirection='column' justifyContent='center' bg='primary.50' transition='0.5s' _hover={{bg: 'primary.100'}}>
              <Button variant='ghost' width='100%' mb={2} colorScheme='primary' as={Link} to='/trials/new'><AddIcon mr={2}/> Create a new trial</Button>
            </Box>
          }
          {filtered.map(t =>
            <Box as={Link} to={`/trials/${t._id}/manage`} key={t._id} borderWidth='1px' rounded='md' p={3} width='100%' shadow='sm' display='flex' flexDirection='column' justifyContent='space-between'
            transition='0.25s'
            _hover={{
              shadow: 'lg'
            }}
            >
              <Box>
                <Heading size='sm'>{t.name}</Heading>
                {utils.canManageTrial(user, t) && <Badge colorScheme='blue'>You're a trial admin</Badge>}
              </Box>

              <Box mt={2} p={2} rounded='sm' bg='gray.50'>
                {(t.dataTypeCount > 0 && t.stageCount > 0) ?
                  <Box>
                    {t.hasUnreadMessages && <Text color='blue.500' fontSize='sm'><Icon as={FaEnvelope} /> Unread messages</Text>}
                    <Text color='gray.400' fontSize='sm'>{t.participantCount} participants</Text>
                    <Text mb={1} color='gray.400' fontSize='sm'>{t.responseCount} responses</Text>
                  </Box>
                :
                  <Text color='yellow.500' fontSize='sm'><Icon as={FaExclamationTriangle} /> Setup required</Text>
                }
              </Box>

              <Box mt={2}>
                <Text textAlign='right' color='gray.400' fontSize='sm'>
                  Created {moment(t.createdAt).fromNow()}
                </Text>
              </Box>
            </Box>
          )}
        </Grid>
      }
    </Container>
  );
}
