import React, { useState } from 'react';
import { useToast, Container, Button, Box, FormControl, FormLabel, FormHelperText, Input, Heading, Text, Alert, AlertTitle, AlertIcon, AlertDescription } from '@chakra-ui/react';
import { useNavigate } from 'react-router';
import useStore from '../../store';
import api from '../../api';

import Terms from '../includes/Terms';
import useTitle from '../../hooks/useTitle';

export default function Enrol() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [termsAgreed, setTermsAgreed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const toast = useToast();
  const navigate = useNavigate();
  const store = useStore();
  useTitle('Enrol');

  function enrol() {
    if (!password || !termsAgreed) {
      return setError('Please ensure you have set a password and agreed to the terms.');
    }
    setError(null);
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    setLoading(true);
    api.accounts.enrol(token, firstName, lastName, password, termsAgreed, (authToken) => {
      setLoading(false);
      store.completeLogin(authToken);
      toast({title: 'Password set successfully.', status: 'success'});
      navigate("/");
    }, (err) => {
      setLoading(false);
      setError(err.message);
      toast({title: 'Unable to enrol your account', description: err.message, status: 'error'});
    });
  }

  return (
    <Container mt={5} mb={5}>
      <Alert mb={5} status='info' variant='left-accent' rounded='md' shadow='md'>
        <Text>You're on this page because someone has invited you to join a team on Trialflare.</Text>
      </Alert>

      <Heading size='lg' mb={3}>Set-up your Trialflare account</Heading>
      <Heading size='sm' mb={5}>Your account is nearly ready. We just need a bit more info.</Heading>

      <FormControl mt={10}>
        <FormLabel htmlFor="firstName">Your first name</FormLabel>
        <Input autoFocus id="firstName" placeholder='Jane' aria-describedby="name-helper-text" value={firstName} onChange={e => setFirstName(e.target.value)}/>
        <FormHelperText id="name-helper-text">
          This is to enable others in your team to identify you.
        </FormHelperText>
      </FormControl>
      <FormControl mt={5}>
        <FormLabel htmlFor="lastName">Your last name</FormLabel>
        <Input id="lastName" placeholder='Doe' value={lastName} onChange={e => setLastName(e.target.value)}/>
      </FormControl>
      <FormControl mt={5}>
        <FormLabel htmlFor="password">A password for your account</FormLabel>
        <Input id='password' placeholder='Enter a secure password...' type="password" value={password} onChange={e => setPassword(e.target.value)} aria-describedby="password-helper-text"/>
        <FormHelperText id="password-helper-text">
          This needs to be at least 8 characters long.
        </FormHelperText>
      </FormControl>
      <Terms value={termsAgreed} onChange={setTermsAgreed} />

      {error &&
        <Alert status='error'>
          <AlertIcon />
          <Box>
            <AlertTitle mr={2}>There was a problem</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Box>
        </Alert>
      }

      <Button disabled={!password || !termsAgreed} mt={15} colorScheme="primary" onClick={enrol} isLoading={loading}>Complete Setup</Button>
    </Container>
  );
}
