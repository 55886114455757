import React, { useState, useEffect } from 'react';
import { Link, useParams, Outlet } from 'react-router-dom';
import { Link as ChakraLink, Button, CircularProgress, Box, Tag, Menu, MenuButton, MenuList, MenuItem, MenuGroup, MenuDivider, Alert, Heading, Text, Icon, useToast } from '@chakra-ui/react';
import { ChevronDownIcon, CheckCircleIcon, AddIcon, WarningIcon, LockIcon, UnlockIcon } from "@chakra-ui/icons"
import { FaFilePdf } from 'react-icons/fa';
import api from '../../api';
import utils from '../../utils/utils.js';
import useTitle from '../../hooks/useTitle';
import useStore from '../../store';
import { ReRunTour } from '../../components/includes/Tour';

import TrialLocker from '../../components/includes/TrialLocker';
import SimplePopover from '../../components/includes/SimplePopover';
import VerticalNavSystem from '../includes/VerticalNavSystem';
import Login from '../entry/Login';

const SideDivider = () => (
  <Box height={'1px'} mt={2} mb={2} bg='gray.200' />
);

const SideLink = ({ active, to, content, children, className, numberValue }) => {
  return (
    <Box display='flex' alignItems='center' justifyContent='space-between'
      as={Link} to={to} mb={1}
      style={{ marginLeft: -10, marginRight: -10, padding: '5px 10px'}}
      bg={active ? 'primary.100' : null}
      transition='0.25s'
      _hover={{
        bg: 'gray.200'
      }}
      fontSize='sm'
      className={`${active ? 'active' : ''} ${className}`}>
        {content || children}
        {numberValue !== undefined && <Tag borderRadius='full' ml={2} size='sm' colorScheme='gray' variant='solid'>{numberValue || 0}</Tag>}
    </Box>
  );
}

export default function AdminDashboard({ children }) {
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [trials, setTrials] = useState([]);
  const toast = useToast();
  const { setTrial, team, user, trial } = useStore();
  const { id: trialId } = useParams();
  useTitle(trial?.name || 'Trial');

  useEffect(() => {
    if (!trialId || !user) return;
    setTrial(null);
    setLoading(true);
    api.trials.get(trialId, trial => {
      setLoading(false);
      setTrial(trial);
      //setTimeout(() => setRunJoyride(true), 3000);
    });
  }, [trialId, user]);

  const exportPDF = () => {
    setExporting(true);
    api.trials.getResponses(trial._id, 'pdf', ({ request }) => {
      const element = document.createElement('a');
      element.setAttribute('href', request);
      element.setAttribute('download', `${trial.name.replace(/ /g, '_')}.pdf`);
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      toast({status:'success', title:'PDF file generated successfully', description: 'It should now be in your downloads folder.'});
      setExporting(false);
    }, err => {
      toast({status:'error', title:err.message});
      setExporting(false);
    });
  };

  if (!user) return <Login withWarning />;

  return (
    <VerticalNavSystem 
      title={trial?.name || 'Trial'}
      nav={<Box p={3} className='joyride-sidebar'>
          {trials?.length > 0 &&
            <Menu>
              <MenuButton className='joyride-trial-selector' as={Box} mb={5} cursor='pointer' p={2} rounded='md' bg='gray.200' _hover={{bg: 'gray.100'}}>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <Text fontWeight='bold'>{trial ? trial.name : 'Your trials'}</Text>
                  <ChevronDownIcon color='primary.500' />
                </Box>
              </MenuButton>
              <MenuList>
                <MenuGroup title="Choose a trial">
                  {trials.map(t => <MenuItem as={Link} to={`/trials/${t._id}/manage`} key={t._id}>{(trial?._id) === t._id && <CheckCircleIcon mr={2} />} {t.name}</MenuItem>)}
                </MenuGroup>
                <MenuDivider />
                <MenuGroup title="Options">
                  <MenuItem as={Link} to='/trials/new'><AddIcon mr={2} /> Create a new trial</MenuItem>
                </MenuGroup>
              </MenuList>
            </Menu>
          }
          {trial &&
            <>
              {trial.unreadMessages > 0 &&
                <Alert status="warning" variant='top-accent' mb={5}>
                  <Text fontSize='xs'>There are {trial.unreadMessages} unread messages. <ChakraLink as={Link} to={`/trials/${trial._id}/messages`} color='orange.600'>View now</ChakraLink></Text>
                </Alert>
              }
              
              {utils.canManageTrial(user, trial) &&
                <SimplePopover
                  trigger={
                    <Button mb={5} variant='outline' colorScheme={trial.isLocked ? 'green' : 'grey'} size='xs' w='100%'>
                      <Box mr={2}>{trial.isLocked ? <LockIcon color='green.800' /> : <UnlockIcon color='grey.700' />}</Box>
                      <Text>{trial.isLocked ? 'Trial locked' : 'Trial unlocked'}</Text>
                    </Button>
                    }
                  header='Trial lock'
                  body={
                    <Box>
                      <Alert size='xs' colorScheme={trial.isLocked ? 'green' : 'yellow'} variant='left-accent' flexDirection='column' alignItems='start'>
                        <Box w='100%' display='flex' alignItems='center'>
                          <Box mr={2}>{trial.isLocked ? <LockIcon color='green.800' /> : <UnlockIcon color='yellow.800' />}</Box>
                          <Heading size='xs'>
                            {trial.isLocked ? 'Trial locked' : 'Trial unlocked'}
                          </Heading>
                        </Box>
                        <Text fontSize='xs'>The trial settings {trial.isLocked ? 'cannot' : 'can'} be edited.</Text>
                        <TrialLocker
                         trigger={<Button w='100%' size='xs' mt={2}>{trial.isLocked ? 'Unlock trial' : 'Lock trial'}</Button>}
                         isLocking={!trial.isLocked} trial={trial}
                         onComplete={isLocked => setTrial({ ...trial, isLocked })}
                        />
                      </Alert>
                    </Box>
                  }
                />
              }
              
              <SideLink active={utils.activePath('manage')} as={Link} to={`/trials/${trial._id}/manage`} content='Overview'/>
              <SideLink className='joyride-trial-info' active={utils.activePath('info')} as={Link} to={`/trials/${trial._id}/info`}>
                About
                {(trial.name && trial.description && trial.code) ? <CheckCircleIcon color='green.500' ml={2} />: <WarningIcon color='orange.500' ml={2} />}
              </SideLink>

              {utils.canManageTrial(user, trial) && <>
                <SideDivider />
                <SideLink className='joyride-trial-data' active={utils.activePath('data')} as={Link} to={`/trials/${trial._id}/data`}>
                  Data types
                  {trial.dataTypeCount > 0 ? <CheckCircleIcon color='green.500' ml={2} /> : <WarningIcon color='orange.500' ml={2} />}
                </SideLink>
                <SideLink className='joyride-trial-stages' active={utils.activePath('stages')} as={Link} to={`/trials/${trial._id}/stages`}>
                  Stages
                  {trial.stageCount > 0 ? <CheckCircleIcon color='green.500' ml={2} /> : <WarningIcon color='orange.500' ml={2} />}
                </SideLink>
                {utils.hasFeature(team, 'eConsent') &&
                  <SideLink active={utils.activePath('consent')} as={Link} to={`/trials/${trial._id}/consent`}>
                    Consent & eligibility
                    {trial.eConsentEnabled && <CheckCircleIcon color='green.500' ml={2} />}
                  </SideLink>
                }
                <SideLink active={utils.activePath('sites')} as={Link} to={`/trials/${trial._id}/sites`} numberValue={trial.siteCount}>
                  Sites
                </SideLink>
                <SideDivider />
                <SideLink className='joyride-trial-settings' active={utils.activePath('settings')} as={Link} to={`/trials/${trial._id}/settings`} content='Settings'/>
              </>}
              
              {utils.hasFeature(team, 'eTmf') && utils.canReadFiles(user, trial) &&
                <SideLink active={utils.activePath('files')} as={Link} to={`/trials/${trial._id}/files`}>
                  eTMF
                </SideLink>
              }

              {utils.canReadTrial(user, trial) && <>
                <SideLink className='joyride-trial-results' active={utils.activePath('results')} as={Link} to={`/trials/${trial._id}/results`} content='Results' numberValue={trial.responseCount}/>
              </>}
              {utils.canAccessTrial(user, trial) && <>
                <SideLink className='joyride-trial-participants' active={window.location.pathname.indexOf(`/trials/${trial._id}/participants`) === 0} as={Link} to={`/trials/${trial._id}/participants`} numberValue={trial.participantCount}>
                  Participants
                </SideLink>
              </>}
              {utils.canSupportTrial(user, trial) && <>
                <SideLink className='joyride-trial-messages' active={utils.activePath('messages')} as={Link} to={`/trials/${trial._id}/messages`} content='Messages' />
              </>}

              {false && trial.stageCount > 0 && trial.dataTypeCount > 0 && utils.canManageTrial && utils.hasFeature(team, 'advancedExport') &&
                <Button colorScheme='blue' variant='ghost' w='100%' size='xs' isLoading={exporting} onClick={exportPDF}><Icon as={FaFilePdf} mr={1} /> Download trial as PDF</Button>
              }

              {/*<Box mt={5}>
                <ReRunTour id='trial' label='Show me around' style={{width: '100%'}} />
              </Box>*/}
            </>
          }
      </Box>
    }
    body={<Box>
      {loading ? 
        <Box textAlign='center'><CircularProgress isIndeterminate /></Box>
        : (trial && <Outlet />)
      }
    </Box>}
  />
  );
}
