import React, { useEffect, useState } from 'react';
import { Box, Select, FormControl, FormLabel, InputGroup, InputRightAddon, Input } from '@chakra-ui/react';
import utils from '../../utils/utils';

export default function WeightEditor({ value, onChange }) {
  const [measurement, setMeasurement] = useState('metric');
  const [weight, setWeight] = useState();
  const [weightPounds, setWeightPounds] = useState();
  const [weightOunces, setWeightOunces] = useState();

  useEffect(() => {
    const val = value?.toString().replace(/^0.+/, '') || '';
    setWeight(val);
    const imperial = utils.kToLbs(val || 0);
    if (imperial) {
      setWeightPounds(imperial.pounds);
      setWeightOunces(imperial.ounces);
    }
  }, [value])

  const updateMetricWeight = kg => {
    if (kg === '' || parseFloat(kg) >= 0) {
      setWeight(kg);
      onChange(kg);
    }
    const imperial = utils.kToLbs(kg || 0);
    if (imperial) {
      setWeightPounds(imperial.pounds);
      setWeightOunces(imperial.ounces);
    }
  };

  const updateImperialWeight = (pounds, ounces) => {
    setWeightPounds(pounds);
    if (ounces === '' || (parseFloat(ounces) >= 0 && parseFloat(ounces) < 16)) {
      setWeightOunces(ounces);
    }
    if (pounds || ounces) {
      const kg = utils.lbToK(pounds || 0, ounces || 0);
      setWeight(kg);
      onChange(kg);
    }
  };

  return (
    <Box>
      <Box>
        <Select w='200px' placeholder='Measurement type...' size='xs' mr={2} bg='white'
          value={measurement} onChange={e => setMeasurement(e.target.value || 'metric')}>
          <option value='metric'>Metric (kg)</option>
          <option value='imperial'>Imperial (lb & oz)</option>
        </Select>
      </Box>

      <Box display={{md: 'flex'}} mt={2}>
        {measurement === 'metric' && <>
          <FormControl bg='blue.100' rounded='md' p={2} w='150px'>
            <FormLabel fontSize='xs'><strong>Weight</strong></FormLabel>
            <InputGroup size='sm'>
              <Input width='80px' bg='white' size='sm' value={weight} onChange={e => updateMetricWeight(e.target.value.replace(/[^\d.]/g, ''))} />
              <InputRightAddon children='kg' />
            </InputGroup>
          </FormControl>
        </>}

        {measurement === 'imperial' && <>
          <FormControl bg='blue.100' rounded='md' p={2} w='300px'>
            <FormLabel fontSize='xs'><strong>Weight</strong></FormLabel>
            <Box display='flex'>
            <InputGroup size='sm'>
              <Input width='60px' bg='white' size='sm' value={weightPounds} onChange={e => updateImperialWeight(e.target.value.replace(/[^\d.]/g, ''), weightOunces)} />
              <InputRightAddon children='pounds' />
            </InputGroup>
            <InputGroup size='sm'>
              <Input width='60px' bg='white' size='sm' value={weightOunces} onChange={e => updateImperialWeight(weightPounds, e.target.value.replace(/[^\d.]/g, ''))} />
              <InputRightAddon children='ounces' />
            </InputGroup>
            </Box>
          </FormControl>
        </>}
      </Box>
    </Box>
  );
}