import React from 'react';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, Box, AccordionIcon } from '@chakra-ui/react';

export default function SimpleAccordion({ items }) {
  return (
    <Accordion allowToggle>
      {items.map((item, i) =>
        <AccordionItem key={item.key || i}>
          <AccordionButton display='flex' justifyContent='space-between'>
            <Box >
              {item.label}
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4}>
            {item.content}
          </AccordionPanel>
        </AccordionItem>
      )}
    </Accordion>
  );
}